import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../../services/data.service";
import { ChangeContext, Options } from "ng5-slider";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs/Subject";
import { Title } from "@angular/platform-browser";
import * as moment from "moment";
import { AuthServiceMain } from "../../auth/auth.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import {
  ScrollToConfigOptions,
  ScrollToService,
} from "@nicky-lenaers/ngx-scroll-to";
import { VariablesService } from "../../services/vars.service";
import { GoogleAnalyticsService } from "../../google-analytics.service";

@Component({
  selector: "app-hotel-list",
  templateUrl: "./hotel-list.component.html",
  styleUrls: ["./hotel-list.component.scss"],
  host: {
    "(document:click)": "onClickBody($event)",
  },
})
export class HotelListComponent implements OnInit, OnDestroy {
  @ViewChild("filterMenu", { static: false })
  private scrollContainer: ElementRef;
  envMode = environment.production;
  showViewMap = true;
  showViewMapMobile = true;
  oldScrollFromTop = 0;
  sortHeader = false;
  searchthisAreaBtn = false;
  autoCompliteMenuFilter = false;
  autoCompliteFilterActive = false;
  hotelSearchFilter = new Subject<string>();
  autoCompliteFilter = new Subject<string>();
  autoCompliteFilterText = "";
  centerLatLang = "";
  filterapplied = false;
  sortingEnabled = true;
  submitting = false;
  ActiveOneItem = true;
  activecheckbox;
  dataRELATED;
  filterreditemsByText;
  apiResponse: any;
  isSearching: boolean;
  SearchByNameText;
  filterreditems = [];
  centerChangeValue = false;
  Params;
  numbertoShow = 3;
  mapHotelNotavailability = false;
  result;
  policyCurrency;
  activeSort = false;
  activeScroll = false;
  filterFreeCancellation = false;
  test = "Embassy Suites by Hilton Washington DC Chevy Chase Pavilion";
  filterFreeCancellationCheck = false;
  showWithinPolicyFilter;
  companyPolicyHotelRules;
  exceptionValue;
  exceptionCurrency;
  hasException = false;
  policyDetails;
  showRewardsBox = true;
  filterWithinPolicyCheck = false;
  filterWithinPolicy = false;
  errorData = false;
  queryParams: any = {
    checkIn: "",
    checkOut: "",
    searchQuery: "",
    paxes: "2",
    taxBreakdown: true,
    searchCode: "",
    searchType: "",
  };
  public isCollapsed = true;
  hotelList;
  hoteloneItem;
  data;
  currency;
  AmenitiesArray = [];
  mapItemActive = false;
  numberOfHotels = 10;
  currentHotelList;
  displayGoogleRatingFilter = false;
  numberOfNights;
  showCashBackDesc;
  order = "index";
  ParentSort = "default";
  SortType = "default";
  search = "";
  selectedRating;
  ReviewValueText = $localize`:@@poor:Poor`;
  starArry = [];
  maxPrice = 100;
  PriceArray = [];
  minValue = 0;
  maxValue = 500;
  isInteger = true;
  filteringActive;
  listView = true;
  zoom = 12;
  mapItem;
  MainLatitude;
  MainLongitude;
  myFacilities = [];
  ratingArray: any = [
    { id: "5", number: 5, name: $localize`:@@5star:5-star`, isChecked: false },
    { id: "4", number: 4, name: $localize`:@@4star:4-star`, isChecked: false },
    { id: "3", number: 3, name: $localize`:@@3star:3-star`, isChecked: false },
    { id: "2", number: 2, name: $localize`:@@2star:2-star`, isChecked: false },
    { id: "1", number: 1, name: $localize`:@@1star:1-star`, isChecked: false },
  ];
  Amenities = [
    {
      hrsCode: "21",
      isChecked: false,
      title: $localize`:@@freeBreakfast:Free Breakfast`,
    },
    {
      hrsCode: "18",
      isChecked: false,
      title: $localize`:@@freeWiFi:Free Wifi`,
    },
    {
      hrsCode: "19",
      isChecked: false,
      title: $localize`:@@freeparking:Free Parking`,
    },
    {
      hrsCode: "20",
      isChecked: false,
      title: $localize`:@@freeAirportTransport:Free Airport Transportation`,
    },
    {
      hrsCode: "14",
      isChecked: false,
      title: $localize`:@@bar:Bar`,
    },
    {
      hrsCode: "12",
      isChecked: false,
      title: $localize`:@@businessCenter:Business Center`,
    },
    {
      hrsCode: "3",
      isChecked: false,
      title: $localize`:@@gym:Gym`,
    },
    {
      hrsCode: "9",
      isChecked: false,
      title: $localize`:@@swimmingPool:Swimming Pool`,
    },
  ];
  clusterStyles = [
    {
      url: "assets/img/map-cluster.png",
      width: 53,
      height: 53,
      fontFamily: "comic sans ms",
      textSize: 15,
      textColor: "white",
    },
  ];
  clusterOptions = {
    gridSize: 200,
    minimumClusterSize: 12,
    averageCenter: false,
    zoomOnClick: true,
    maxZoom: 200,
  };
  optionsSlider: Options = {
    floor: 0,
    ceil: 500,
    step: 5,
    animate: false,
  };
  ReviewValue = 0.0;
  optionsReview: Options = {
    floor: 0,
    ceil: 5.0,
    step: 0.5,
    animate: false,
    showSelectionBarEnd: true,
  };
  ReviewArray = [
    { name: "2+", value: 2, active: false },
    { name: "3+", value: 3, active: false },
    { name: "4+", value: 4, active: false },
    { name: "4.5+", value: 4.5, active: false },
  ];
  PaxesArray = [];
  activePopUp = false;
  sortPopUp = false;
  filterPopUp = false;
  placholder: any;
  discountMsg = this.VarsService.messages.USER_HAS_DISCOUNT;
  cashbackMsg = this.VarsService.messages.USER_HAS_CASHBACK;
  discountData;
  extraCashbackData;
  defaultFavForNoUserDataHotels = [];
  config = {
    direction: "horizontal",
    loop: false,
    slidesPerView: "auto",
    spaceBetween: 5,
    observer: true,
    freeMode: true,
  };
  oneitemlistMode = false;
  defaultNavigateUrl;
  hotelNavigateUrl;
  showPolicyDetails = true;
  sub: any;
  travelerId;
  constructor(
    private route: ActivatedRoute,
    public VarsService: VariablesService,
    public Service: DataService,
    public Vars: VariablesService,
    public logedIn: AuthServiceMain,
    public router: Router,
    private titleService: Title,
    private scrollToService: ScrollToService,
    private eref: ElementRef,
    public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.Service.componentMethodCalled$.subscribe(() => {
      if (this.Service.urlWithoutParams === "/availability") {
        if (this.Service.isCompany() && !this.logedIn.isLogedIn) {
          return;
        }
        if (!this.Service.isCompany()) {
          this.defaultNavigateUrl = "availability";
        } else if (
          this.Service.isCompany() &&
          this.logedIn.isLogedIn &&
          this.logedIn.companyInfo
        ) {
          this.defaultNavigateUrl = "/availability";
        }
        if (!this.Service.isCompany()) {
          this.hotelNavigateUrl = "/availability/hotel";
        } else if (
          this.Service.isCompany() &&
          this.logedIn.isLogedIn &&
          this.logedIn.companyInfo
        ) {
          this.hotelNavigateUrl = "/availability/hotel";
        }
        this.Service.unlockRate = true;
        this.Service.hotellistLoader = true;
        if (this.Service.isCompany()) {
          if (this.router.url.includes("travelerId=")) {
            this.travelerId = ("" + this.router.url).substring(
              ("" + this.router.url).indexOf("travelerId=") + 11
            );
            let travelerIdKey = "travelerId";
            this.queryParams[travelerIdKey] = this.travelerId;
          } else {
            this.travelerId = null;
          }
          this.route.queryParams.subscribe((params) => {
            this.Params = params;
            if (params.searchCode || params.id) {
              if (params.searchCode) {
                this.queryParams.searchCode = params.searchCode;
              } else {
                this.queryParams.searchCode = params.id;
              }
              this.queryParams.searchType = params.searchType;
              delete this.queryParams.searchQuery;
            } else {
              this.queryParams.searchQuery = params.searchQuery;
              delete this.queryParams.searchType;
              delete this.queryParams.searchCode;
            }
            if (params.checkinOffset && !params.checkIn) {
              const checkinOffset = Number(params.checkinOffset);
              this.queryParams.checkIn = moment()
                .add(checkinOffset, "days")
                .format("YYYY-MM-DD");
              if (!params.checkOut) {
                this.queryParams.checkOut = moment()
                  .add(3 + checkinOffset, "days")
                  .format("YYYY-MM-DD");
              }
            }
            if (params.nights && !params.checkOut) {
              const nights = Number(params.nights);
              if (params.checkIn) {
                this.queryParams.checkIn = moment(params.checkIn).format(
                  "YYYY-MM-DD"
                );
                this.queryParams.checkOut = moment(params.checkIn)
                  .add(nights, "days")
                  .format("YYYY-MM-DD");
              } else {
                this.queryParams.checkOut = moment(this.queryParams.checkIn)
                  .add(nights, "days")
                  .format("YYYY-MM-DD");
              }
            }
            if (
              params.checkOut === "null" ||
              params.checkIn === "null" ||
              moment(params.checkIn).isBefore(moment(), "day") ||
              !moment(params.checkIn).isValid() ||
              !moment(params.checkOut).isValid() ||
              moment
                .utc(params.checkOut)
                .diff(moment.utc(params.checkIn), "days") < 0
            ) {
              if (this.queryParams.checkIn === "") {
                this.queryParams.checkIn = moment()
                  .add(14, "days")
                  .format("YYYY-MM-DD");
              }
              if (this.queryParams.checkOut === "") {
                this.queryParams.checkOut = moment()
                  .add(17, "days")
                  .format("YYYY-MM-DD");
              }
              if (!params.checkinOffset && !params.nights) {
                this.Service.GlobelPreviousUrlFunc();
                this.router.navigate([this.defaultNavigateUrl], {
                  queryParams: {
                    checkIn: this.queryParams.checkIn,
                    checkOut: this.queryParams.checkOut,
                  },
                  queryParamsHandling: "merge",
                });
                return;
              }
            } else if (
              params.checkIn !== undefined &&
              params.checkOut !== undefined &&
              params.paxes !== undefined
            ) {
              this.queryParams.checkOut = moment(params.checkOut).format(
                "YYYY-MM-DD"
              );
              this.queryParams.checkIn = moment(params.checkIn).format(
                "YYYY-MM-DD"
              );
            } else {
              if (
                params.checkIn === undefined &&
                this.queryParams.checkIn === ""
              ) {
                this.queryParams.checkIn = moment()
                  .add(14, "days")
                  .format("YYYY-MM-DD");
              } else if (this.queryParams.checkIn === "") {
                this.queryParams.checkIn = moment(params.checkIn).format(
                  "YYYY-MM-DD"
                );
              }
              if (
                params.checkOut === undefined &&
                this.queryParams.checkOut === ""
              ) {
                this.queryParams.checkOut = moment()
                  .add(17, "days")
                  .format("YYYY-MM-DD");
              } else {
                this.queryParams.checkOut = moment(params.checkOut).format(
                  "YYYY-MM-DD"
                );
              }
            }
            if (!params.nights && !params.checkinOffset && !params.checkIn) {
              this.queryParams.checkIn = moment()
                .add(14, "days")
                .format("YYYY-MM-DD");
              this.queryParams.checkOut = moment()
                .add(17, "days")
                .format("YYYY-MM-DD");
              this.Service.GlobelPreviousUrlFunc();
              this.router.navigate([this.defaultNavigateUrl], {
                queryParams: {
                  checkIn: this.queryParams.checkIn,
                  checkOut: this.queryParams.checkOut,
                },
                queryParamsHandling: "merge",
              });
              return;
            }
            this.numberOfNights = this.calculateNumberOfNights(
              this.queryParams.checkIn,
              this.queryParams.checkOut
            );
            if (this.numberOfNights === 0) {
              this.queryParams.checkOut = moment(params.checkIn)
                .add(1, "days")
                .format("YYYY-MM-DD");
              this.Service.GlobelPreviousUrlFunc();
              this.router.navigate([this.defaultNavigateUrl], {
                queryParams: {
                  checkIn: this.queryParams.checkIn,
                  checkOut: this.queryParams.checkOut,
                },
                queryParamsHandling: "merge",
              });
              return;
            }
            if (this.numberOfNights <= 0) {
              this.queryParams.checkIn = moment()
                .add(14, "days")
                .format("YYYY-MM-DD");
              this.queryParams.checkOut = moment()
                .add(17, "days")
                .format("YYYY-MM-DD");
            }
            if (params.editSearchBar === "") {
              this.isCollapsed = false;
            }
            if (params.paxes === undefined) {
              this.queryParams.paxes = "2";
              this.PaxesArray = this.queryParams.paxes.split(",");
            } else {
              this.queryParams.paxes = params.paxes;
              this.PaxesArray = this.queryParams.paxes.split(",");
            }
          });
          let header = "";
          if (sessionStorage.getItem("session-token")) {
            header = sessionStorage.getItem("session-token");
          }
          if (
            this.Params.checkOut === "null" ||
            this.Params.checkIn === "null" ||
            moment(this.Params.checkIn).isBefore(moment(), "day") ||
            !moment(this.Params.checkIn).isValid() ||
            !moment(this.Params.checkOut).isValid() ||
            moment
              .utc(this.Params.checkOut)
              .diff(moment.utc(this.Params.checkIn), "days") <= 0
          ) {
            if (!this.Params.checkinOffset && !this.Params.nights) {
              return;
            }
          }
          if (
            (!this.Params.nights &&
              !this.Params.checkinOffset &&
              !this.Params.checkIn) ||
            this.numberOfNights === 0
          ) {
            return;
          }
        }
        let header = "";
        if (sessionStorage.getItem("session-token")) {
          header = sessionStorage.getItem("session-token");
        }
        this.sub = this.Service.availabilityList(
          this.queryParams,
          header
        ).subscribe(
          (success) => {
            if (sessionStorage.getItem("session-token")) {
              sessionStorage.removeItem("session-token");
            }
            this.data = success;
            if (this.data.errorCode === 0) {
              let tmp_state = "unknown";
              this.currency =
                this.data.data.hotels[0].lowestSellingPriceRate.averageNightlySellingPriceConvertedCurrency;
              if (this.data.data.extraData) {
                if (this.data.data.extraData.userData) {
                  this.showWithinPolicyFilter =
                    this.data.data.extraData.userData.displayCompanyPoliciesFilter;
                  if (this.data.data.extraData.userData.policy) {
                    this.companyPolicyHotelRules =
                      this.data.data.extraData.userData.policy.companyPolicyHotelRules;
                    this.policyDetails =
                      this.data.data.extraData.userData.policy;
                    this.policyCurrency =
                      this.policyDetails.companyPolicyHotelRules.avgNightlyPriceLimitCurrency;
                    this.policyDetails.companyPolicyHotelRules.exceptions.forEach(
                      (item, index) => {
                        if (item.isApplied) {
                          this.hasException = true;
                          this.exceptionValue = item.avgNightlyPriceLimit;
                          this.exceptionCurrency = item.avgNightlyPriceLimitCurrency;
                        }
                      }
                    );
                  }
                }
                if (this.data.data.extraData.state) {
                  tmp_state = this.data.data.extraData.state;
                } else {
                  tmp_state = this.data.data.extraData.city;
                }
                this.Service.destCity = this.data.data.extraData.city;
                this.Service.destCountryCode =
                  this.data.data.extraData.countryCode;
                this.Service.destStateCode = this.data.data.extraData.state;
                this.titleService.setTitle(
                  "Hotels in " + this.data.data.searchCaption + " | SafarHub"
                );
                this.MainLongitude = this.data.data.extraData.longitude;
                this.MainLatitude = this.data.data.extraData.latitude;
                this.currency =
                  this.data.data.hotels[0].lowestSellingPriceRate.averageNightlySellingPriceConvertedCurrency;
              }
              this.displayGoogleRatingFilter =
                this.data.data.displayGoogleRatingFilter;
              setTimeout(() => {
                this.showViewMapMobile = false;
              }, 3000);
              if (!this.data.data.hotels) {
                this.errorData = true;
                this.isCollapsed = false;
                this.Service.sendCaption(
                  this.queryParams.searchQuery,
                  this.data.data.searchCaption,
                  this.queryParams.paxes,
                  this.queryParams.searchCode,
                  this.queryParams.searchType
                );
                this.Service.hotellistLoader = false;
                return;
              }
              if (
                this.data.data.hotels.length === 1 ||
                this.data.data.hotels.length === 0
              ) {
                let lastItemWithOnlinePriceArray: any = [];
                if (
                  (this.data.data.hotels[0] &&
                    !this.data.data.hotels[0].lowestSellingPriceRate) ||
                  this.data.data.hotels.length === 0
                ) {
                  this.ActiveOneItem = false;
                }
                this.hoteloneItem = this.data.data.hotels;
                let searchQuery: any;
                if (this.data.data.hotels.length === 1) {
                  searchQuery =
                    this.hoteloneItem[0].code + ";RELATED_PROPERTIES";
                  this.currency =
                    this.hoteloneItem[0].lowestSellingPriceRate.averageNightlySellingPriceConvertedCurrency;
                } else {
                  if (this.queryParams.searchCode) {
                    searchQuery =
                      this.queryParams.searchCode + ";RELATED_PROPERTIES";
                  } else {
                    let searchCode: any =
                      this.queryParams.searchQuery.split(";");
                    searchCode = searchCode[0];
                    searchQuery = searchCode + ";RELATED_PROPERTIES";
                  }
                }
                const hoteloneItemqueryParams = {
                  checkIn: this.queryParams.checkIn,
                  checkOut: this.queryParams.checkOut,
                  searchQuery: searchQuery,
                  paxes: this.queryParams.paxes,
                  taxBreakdown: true,
                };
                if (this.router.url.includes("travelerId=")) {
                  this.travelerId = ("" + this.router.url).substring(
                    ("" + this.router.url).indexOf("travelerId=") + 11
                  );
                  let travelerIdKey = "travelerId";
                  hoteloneItemqueryParams[travelerIdKey] = this.travelerId;
                }
                this.sub = this.Service.availabilityList(
                  hoteloneItemqueryParams,
                  header
                ).subscribe(
                  (success) => {
                    this.dataRELATED = success;
                    if (this.dataRELATED.errorCode === 0) {
                      this.hotelList = this.dataRELATED.data.hotels;
                      if (!this.dataRELATED.data.hotels) {
                        this.errorData = true;
                        this.isCollapsed = false;
                        this.Service.sendCaption(
                          this.queryParams.searchQuery,
                          this.data.data.searchCaption,
                          this.queryParams.paxes,
                          this.queryParams.searchCode,
                          this.queryParams.searchType
                        );
                        this.Service.hotellistLoader = false;
                        return;
                      }
                      this.oneitemlistMode = true;
                      this.hotelList = this.dataRELATED.data.hotels;
                      this.hotelList.forEach((item, index) => {
                        item.icon = "assets/img/marker.png";
                        if (
                          item.lowestSellingPriceRate
                            .privateAverageNightlySellingPrice &&
                          this.logedIn.isLead
                        ) {
                          item.color = "#8859E0";
                        } else {
                          item.color = "#000";
                        }
                        if (
                          !(item.additionalData && item.additionalData.userData)
                        ) {
                          this.defaultFavForNoUserDataHotels[item.code] = false;
                        }
                        if (
                          item.lowestSellingPriceRate
                            .privateAverageNightlySellingPrice
                        ) {
                          item.displayPrice =
                            item.lowestSellingPriceRate.privateAverageNightlySellingPrice;
                        } else if (
                          item.lowestSellingPriceRate
                            .publicAverageNightlySellingPrice
                        ) {
                          item.displayPrice =
                            item.lowestSellingPriceRate.publicAverageNightlySellingPrice;
                        } else {
                          item.displayPrice =
                            item.lowestSellingPriceRate.averageNightlySellingPrice;
                        }
                        item.index = index;
                        if (index < 11) {
                          if (item.onlinePrice) {
                            lastItemWithOnlinePriceArray.push(item.code);
                            item.OnlinePricesSent = true;
                          } else {
                            item.index = index + 3 * 3642;
                            if (
                              this.filterreditems.length === 0 &&
                              !this.filterapplied
                            ) {
                              this.hotelList.sort((a, b) => {
                                if (a.index > b.index) {
                                  return 1;
                                } else if (b.index > a.index) {
                                  return -1;
                                } else {
                                  return 0;
                                }
                              });
                              this.currentHotelList = this.hotelList.slice(
                                0,
                                this.numberOfHotels
                              );
                            } else {
                              this.filterreditems.sort((a, b) => {
                                if (a.index > b.index) {
                                  return 1;
                                } else if (b.index > a.index) {
                                  return -1;
                                } else {
                                  return 0;
                                }
                              });
                              this.currentHotelList = this.filterreditems.slice(
                                0,
                                this.numberOfHotels
                              );
                            }
                          }
                        }
                      });
                      this.currentHotelList = this.hotelList.slice(
                        0,
                        this.numberOfHotels
                      );
                      this.Service.hotellistLoader = false;
                      this.getMaxPrsice();
                      this.setNewCeil();
                      if (this.Params["filter-stars"]) {
                        const starNumber = parseFloat(
                          this.Params["filter-stars"]
                        );
                        for (let i = starNumber; i < 5.5; i = i + 0.5) {
                          this.starArry.push(i);
                        }
                        this.ratingArray.forEach((element) => {
                          if (this.starArry.includes(element.number)) {
                            element.isChecked = true;
                          }
                        });
                        this.applyfilters();
                      }
                      if (this.Params["filter-name"]) {
                        this.search = this.Params["filter-name"];
                        this.SearchByNameText = this.Params["filter-name"];
                        this.applyfilters();
                      }
                      this.Service.sendCaption(
                        this.queryParams.searchQuery,
                        this.data.data.searchCaption,
                        this.queryParams.paxes,
                        this.queryParams.searchCode,
                        this.queryParams.searchType
                      );
                    } else {
                      this.Service.hotellistLoader = false;
                      this.hoteloneItem = this.data.data.hotels;
                      this.oneitemlistMode = true;
                    }
                  },
                  (error) => {
                    this.Service.hotellistLoader = false;
                    this.hoteloneItem = this.data.data.hotels;
                    this.oneitemlistMode = true;
                  }
                );
                if (this.hoteloneItem[0] && this.hoteloneItem[0].onlinePrice) {
                  this.hoteloneItem[0].OnlinePricesSent = true;
                } else {
                  if (this.hoteloneItem[0]) {
                    this.hoteloneItem[0].onlinePrice = false;
                  }
                }
                return;
              }
              this.hotelList = this.data.data.hotels;
              let lastItemWithOnlinePriceArray: any = [];
              this.hotelList.forEach((item, index) => {
                item.icon = "assets/img/marker.png";
                if (
                  item.lowestSellingPriceRate
                    .privateAverageNightlySellingPrice &&
                  this.logedIn.isLead
                ) {
                  item.color = "#8859E0";
                } else {
                  item.color = "#000";
                }
                if (!(item.additionalData && item.additionalData.userData)) {
                  this.defaultFavForNoUserDataHotels[item.code] = false;
                }
                if (
                  item.lowestSellingPriceRate.privateAverageNightlySellingPrice
                ) {
                  item.displayPrice =
                    item.lowestSellingPriceRate.privateAverageNightlySellingPrice;
                } else if (
                  item.lowestSellingPriceRate.publicAverageNightlySellingPrice
                ) {
                  item.displayPrice =
                    item.lowestSellingPriceRate.publicAverageNightlySellingPrice;
                } else {
                  item.displayPrice =
                    item.lowestSellingPriceRate.averageNightlySellingPrice;
                }
                item.index = index;
                if (index < 11) {
                  if (item.onlinePrice) {
                    lastItemWithOnlinePriceArray.push(item.code);
                    item.OnlinePricesSent = true;
                  } else {
                    item.index = index + 3 * 3642;
                    if (
                      this.filterreditems.length === 0 &&
                      !this.filterapplied
                    ) {
                      this.hotelList.sort((a, b) => {
                        if (a.index > b.index) {
                          return 1;
                        } else if (b.index > a.index) {
                          return -1;
                        } else {
                          return 0;
                        }
                      });
                      this.currentHotelList = this.hotelList.slice(
                        0,
                        this.numberOfHotels
                      );
                    } else {
                      this.filterreditems.sort((a, b) => {
                        if (a.index > b.index) {
                          return 1;
                        } else if (b.index > a.index) {
                          return -1;
                        } else {
                          return 0;
                        }
                      });
                      this.currentHotelList = this.filterreditems.slice(
                        0,
                        this.numberOfHotels
                      );
                    }
                  }
                }
              });
              this.sortingEnabled = false;
              setTimeout(() => {
                this.showViewMap = false;
              }, 2000);
              this.currentHotelList = this.hotelList.slice(
                0,
                this.numberOfHotels
              );
              this.Service.hotellistLoader = false;
              this.getMaxPrsice();
              this.setNewCeil();
              if (this.Params["filter-stars"]) {
                const starNumber = parseFloat(this.Params["filter-stars"]);
                for (let i = starNumber; i < 5.5; i = i + 0.5) {
                  this.starArry.push(i);
                }
                this.ratingArray.forEach((element) => {
                  if (this.starArry.includes(element.number)) {
                    element.isChecked = true;
                  }
                });
                this.applyfilters();
              }
              if (this.Params["filter-name"]) {
                this.search = this.Params["filter-name"];
                this.SearchByNameText = this.Params["filter-name"];
                this.applyfilters();
              }
              this.Service.sendCaption(
                this.queryParams.searchQuery,
                this.data.data.searchCaption,
                this.queryParams.paxes,
                this.queryParams.searchCode,
                this.queryParams.searchType
              );
            } else {
              this.Service.hotellistLoader = false;
              this.errorData = true;
              this.Service.messages(this.data.message, "warning");
            }
            if (this.errorData) {
              this.isCollapsed = false;
            }
          },
          (error) => {
            this.sortingEnabled = false;
            setTimeout(() => {
              this.showViewMap = false;
            }, 2000);
            this.errorData = true;
            this.Service.messages(environment.messages.error, "error");
            this.Service.hotellistLoader = false;
          }
        );
        this.Service.alerts = [];
        if (
          this.logedIn.isLogedIn &&
          !this.logedIn.isPrime &&
          !this.Service.isCompany()
        ) {
          if (this.Service.urlWithoutParams === "/availability") {
            this.Service.checkCashback(null).subscribe((data) => {
              this.extraCashbackData = data;
              if (this.extraCashbackData.errorCode === 0) {
                if (
                  this.extraCashbackData.data.totalAmount > 0 &&
                  this.router.url.indexOf("availability") > 0 &&
                  !(this.router.url.indexOf("booking") > 0)
                ) {
                  this.cashbackMsg = this.cashbackMsg.replace(
                    "%total",
                    this.extraCashbackData.data.totalAmount
                  );
                  this.Service.messages(this.cashbackMsg, "info");
                }
              } else {
                this.Service.alerts = [];
              }
            });
          }
        }
      }
    });
    this.isSearching = false;
    this.apiResponse = [];
  }

  toggleFavoriteHotelList(item) {
    const hotelId = item.code;
    this.Service.fav(hotelId).subscribe((data) => {
      this.Service.toggleData = data;
      if (this.Service.toggleData.errorCode === 0) {
        this.hotelList.map((hotel) => {
          if (hotel.code === item.code) {
            if (hotel.additionalData && hotel.additionalData.userData) {
              hotel.additionalData.userData.favorite =
                !hotel.additionalData.userData.favorite;
            } else {
              this.defaultFavForNoUserDataHotels[item.code] =
                !this.defaultFavForNoUserDataHotels[item.code];
            }
          }
        });
      } else {
      }
    });
  }

  mapViewtoggle() {
    if (this.listView) {
      if (this.filterreditems.length === 0) {
        this.currentHotelList = this.hotelList;
        if (this.oneitemlistMode && this.Service.desktopView) {
          this.currentHotelList = this.hotelList.concat(this.hoteloneItem);
          this.clickedMarker(this.hoteloneItem[0], this.hotelList.length, "");
        }
      } else {
        this.currentHotelList = this.filterreditems;
        if (this.oneitemlistMode && this.Service.desktopView) {
          this.currentHotelList = this.filterreditems.concat(this.hoteloneItem);
          this.clickedMarker(
            this.filterreditems[0],
            this.filterreditems.length,
            ""
          );
        }
      }
    } else {
      if (this.filterreditems.length === 0) {
        this.currentHotelList = this.hotelList.slice(0, this.numberOfHotels);
      } else {
        this.currentHotelList = this.filterreditems.slice(
          0,
          this.numberOfHotels
        );
      }
    }
    this.listView = !this.listView;
    this.Service.workspaceViewListFlag = this.listView;
  }

  getApplicablePolicyExp() {
    if (
      this.Service.applicablePolicy &&
      this.Service.applicablePolicy.companyPolicyHotelRules
    ) {
      this.Service.applicablePolicy.companyPolicyHotelRules.exceptions.forEach(
        (element, index) => {
          this.route.queryParams.subscribe((params) => {
            if (params.searchQuery) {
              let searchQuery = params.searchQuery.substring(
                0,
                params.searchQuery.indexOf(";")
              );
              if (searchQuery === element.code) {
                this.Service.applicablePolicyExp = element.avgNightlyPriceLimit;
              }
            }
          });
        }
      );
      if (!this.Service.applicablePolicyExp) {
        this.Service.applicablePolicyExp =
          this.Service.applicablePolicy.companyPolicyHotelRules.avgNightlyPriceLimit;
      }
    }
    return this.Service.applicablePolicyExp;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.Service.applicablePolicyExp = null;
    this.Service.hotelFilter = false;
    if (this.router.url.includes("travelerId=")) {
      this.travelerId = ("" + this.router.url).substring(
        ("" + this.router.url).indexOf("travelerId=") + 11
      );
    } else {
      this.travelerId = null;
    }
    if (this.Service.isSiteRtl()) {
      this.optionsSlider.rightToLeft = true;
      this.optionsReview.rightToLeft = true;
    }
    if (this.Service.isCompany() && !this.logedIn.isLogedIn) {
      return;
    }
    if (!this.Service.isCompany()) {
      this.defaultNavigateUrl = "availability";
    } else if (
      this.Service.isCompany() &&
      this.logedIn.isLogedIn &&
      this.logedIn.companyInfo
    ) {
      this.defaultNavigateUrl = "/availability";
    }
    if (!this.Service.isCompany()) {
      this.hotelNavigateUrl = "/availability/hotel";
    } else if (
      this.Service.isCompany() &&
      this.logedIn.isLogedIn &&
      this.logedIn.companyInfo
    ) {
      this.hotelNavigateUrl = "/availability/hotel";
    }
    this.Service.unlockRate = true;
    this.Service.bookingPage = false;
    this.Service.flightBookingPage = false;
    this.Service.bookingSubmit = false;
    this.Service.flightBookingSubmit = false;
    this.Service.hotellistLoader = true;
    setTimeout(() => {
      this.hotelSearchFilter
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((value) => {
          this.filteringActive = true;
          value = value.toLowerCase();
          this.updateHotelList();
          this.search = value;
          this.applyfilters();
        });

      this.autoCompliteFilter
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((value) => {
          value = value.toLowerCase();
          this.search = "";
          this.applyfilters();
          this.autoCompliteFilterText = value;
          if (value.length > 1) {
            this.autoCompliteMenuFilter = true;
            if (this.filterreditems && this.filterreditems.length) {
              this.filterreditemsByText = this.filterreditems.filter((item) => {
                const find = item.name.toLowerCase().indexOf(value);
                return find !== -1;
              });
            } else {
              this.filterreditemsByText = this.currentHotelList.filter(
                (item) => {
                  const find = item.name.toLowerCase().indexOf(value);
                  return find !== -1;
                }
              );
            }
          } else {
            this.autoCompliteMenuFilter = false;
            this.filterreditemsByText = [];
          }
        });
    }, 1500);
    this.Service.alerts = [];
    this.route.queryParams.subscribe((params) => {
      this.Params = params;
      if (this.router.url.includes("travelerId=")) {
        this.travelerId = ("" + this.router.url).substring(
          ("" + this.router.url).indexOf("travelerId=") + 11
        );
        let travelerIdKey = "travelerId";
        this.queryParams[travelerIdKey] = this.travelerId;
      } else {
        this.travelerId = null;
      }
      if (params.searchCode || params.id) {
        if (params.searchCode) {
          this.queryParams.searchCode = params.searchCode;
        } else {
          this.queryParams.searchCode = params.id;
        }
        this.queryParams.searchType = params.searchType;
        delete this.queryParams.searchQuery;
      } else {
        this.queryParams.searchQuery = params.searchQuery;
        delete this.queryParams.searchType;
        delete this.queryParams.searchCode;
      }
      if (params.checkinOffset && !params.checkIn) {
        const checkinOffset = Number(params.checkinOffset);
        this.queryParams.checkIn = moment()
          .add(checkinOffset, "days")
          .format("YYYY-MM-DD");
        if (!params.checkOut) {
          this.queryParams.checkOut = moment()
            .add(3 + checkinOffset, "days")
            .format("YYYY-MM-DD");
        }
      }
      if (params.nights && !params.checkOut) {
        const nights = Number(params.nights);
        if (params.checkIn) {
          this.queryParams.checkIn = moment(params.checkIn).format(
            "YYYY-MM-DD"
          );
          this.queryParams.checkOut = moment(params.checkIn)
            .add(nights, "days")
            .format("YYYY-MM-DD");
        } else {
          this.queryParams.checkOut = moment(this.queryParams.checkIn)
            .add(nights, "days")
            .format("YYYY-MM-DD");
        }
      }
      if (
        params.checkOut === "null" ||
        params.checkIn === "null" ||
        moment(params.checkIn).isBefore(moment(), "day") ||
        !moment(params.checkIn).isValid() ||
        !moment(params.checkOut).isValid() ||
        moment.utc(params.checkOut).diff(moment.utc(params.checkIn), "days") < 0
      ) {
        if (this.queryParams.checkIn === "") {
          this.queryParams.checkIn = moment()
            .add(14, "days")
            .format("YYYY-MM-DD");
        }
        if (this.queryParams.checkOut === "") {
          this.queryParams.checkOut = moment()
            .add(17, "days")
            .format("YYYY-MM-DD");
        }
        if (!params.checkinOffset && !params.nights) {
          this.Service.GlobelPreviousUrlFunc();
          this.router.navigate([this.defaultNavigateUrl], {
            queryParams: {
              checkIn: this.queryParams.checkIn,
              checkOut: this.queryParams.checkOut,
            },
            queryParamsHandling: "merge",
          });
          return;
        }
      } else if (
        params.checkIn !== undefined &&
        params.checkOut !== undefined &&
        params.paxes !== undefined
      ) {
        this.queryParams.checkOut = moment(params.checkOut).format(
          "YYYY-MM-DD"
        );
        this.queryParams.checkIn = moment(params.checkIn).format("YYYY-MM-DD");
      } else {
        if (params.checkIn === undefined && this.queryParams.checkIn === "") {
          this.queryParams.checkIn = moment()
            .add(14, "days")
            .format("YYYY-MM-DD");
        } else if (this.queryParams.checkIn === "") {
          this.queryParams.checkIn = moment(params.checkIn).format(
            "YYYY-MM-DD"
          );
        }
        if (params.checkOut === undefined && this.queryParams.checkOut === "") {
          this.queryParams.checkOut = moment()
            .add(17, "days")
            .format("YYYY-MM-DD");
        } else {
          this.queryParams.checkOut = moment(params.checkOut).format(
            "YYYY-MM-DD"
          );
        }
      }
      if (!params.nights && !params.checkinOffset && !params.checkIn) {
        this.queryParams.checkIn = moment()
          .add(14, "days")
          .format("YYYY-MM-DD");
        this.queryParams.checkOut = moment()
          .add(17, "days")
          .format("YYYY-MM-DD");
        this.Service.GlobelPreviousUrlFunc();
        this.router.navigate([this.defaultNavigateUrl], {
          queryParams: {
            checkIn: this.queryParams.checkIn,
            checkOut: this.queryParams.checkOut,
          },
          queryParamsHandling: "merge",
        });
        return;
      }
      this.numberOfNights = this.calculateNumberOfNights(
        this.queryParams.checkIn,
        this.queryParams.checkOut
      );
      if (this.numberOfNights === 0) {
        this.queryParams.checkOut = moment(params.checkIn)
          .add(1, "days")
          .format("YYYY-MM-DD");
        this.Service.GlobelPreviousUrlFunc();
        this.router.navigate([this.defaultNavigateUrl], {
          queryParams: {
            checkIn: this.queryParams.checkIn,
            checkOut: this.queryParams.checkOut,
          },
          queryParamsHandling: "merge",
        });
        this.Service.hotellistLoader = false;
        return;
      }
      if (this.numberOfNights <= 0) {
        this.queryParams.checkIn = moment()
          .add(14, "days")
          .format("YYYY-MM-DD");
        this.queryParams.checkOut = moment()
          .add(17, "days")
          .format("YYYY-MM-DD");
      }
      if (params.editSearchBar === "") {
        this.isCollapsed = false;
      }
      if (params.paxes === undefined) {
        this.queryParams.paxes = "2";
        this.PaxesArray = this.queryParams.paxes.split(",");
      } else {
        this.queryParams.paxes = params.paxes;

        this.PaxesArray = this.queryParams.paxes.split(",");
      }
    });
    let header = "";
    if (sessionStorage.getItem("session-token")) {
      header = sessionStorage.getItem("session-token");
    }
    if (
      this.Params.checkOut === "null" ||
      this.Params.checkIn === "null" ||
      moment(this.Params.checkIn).isBefore(moment(), "day") ||
      !moment(this.Params.checkIn).isValid() ||
      !moment(this.Params.checkOut).isValid() ||
      moment
        .utc(this.Params.checkOut)
        .diff(moment.utc(this.Params.checkIn), "days") <= 0
    ) {
      if (!this.Params.checkinOffset && !this.Params.nights) {
        return;
      }
    }
    if (
      (!this.Params.nights &&
        !this.Params.checkinOffset &&
        !this.Params.checkIn) ||
      this.numberOfNights === 0
    ) {
      return;
    }
    this.sub = this.Service.availabilityList(
      this.queryParams,
      header
    ).subscribe(
      (success) => {
        if (sessionStorage.getItem("session-token")) {
          sessionStorage.removeItem("session-token");
        }
        this.data = success;
        if (this.data.errorCode === 0) {
          let tmp_state = "unknown";
          if (this.data.data.extraData) {
            if (this.data.data.extraData.userData) {
              this.showWithinPolicyFilter =
                this.data.data.extraData.userData.displayCompanyPoliciesFilter;
              if (this.data.data.extraData.userData.policy) {
                this.Service.setApplicablePolicyDetails(
                  this.data.data.extraData.userData.policy
                );
                this.companyPolicyHotelRules =
                  this.data.data.extraData.userData.policy.companyPolicyHotelRules;
                this.policyDetails = this.data.data.extraData.userData.policy;
                this.policyCurrency =
                  this.policyDetails.companyPolicyHotelRules.avgNightlyPriceLimitCurrency;
                this.policyDetails.companyPolicyHotelRules.exceptions.forEach(
                  (item, index) => {
                    if (item.isApplied) {
                      this.hasException = true;
                      this.exceptionValue = item.avgNightlyPriceLimit;
                      this.exceptionCurrency = item.avgNightlyPriceLimitCurrency;
                    }
                  }
                );
              }
            }
            if (this.data.data.extraData.state) {
              tmp_state = this.data.data.extraData.state;
            } else {
              tmp_state = this.data.data.extraData.city;
            }
            this.Service.destCity = this.data.data.extraData.city;
            this.Service.destCountryCode = this.data.data.extraData.countryCode;
            this.Service.destStateCode = this.data.data.extraData.state;
            this.titleService.setTitle(
              "Hotels in " + this.data.data.searchCaption + " | SafarHub"
            );
            this.MainLongitude = this.data.data.extraData.longitude;
            this.MainLatitude = this.data.data.extraData.latitude;
          }
          this.displayGoogleRatingFilter =
            this.data.data.displayGoogleRatingFilter;
          setTimeout(() => {
            this.showViewMapMobile = false;
          }, 3000);
          if (!this.data.data.hotels) {
            this.errorData = true;
            this.isCollapsed = false;
            this.Service.sendCaption(
              this.queryParams.searchQuery,
              this.data.data.searchCaption,
              this.queryParams.paxes,
              this.queryParams.searchCode,
              this.queryParams.searchType
            );
            this.Service.hotellistLoader = false;
            return;
          }
          if (
            this.data.data.hotels.length === 1 ||
            this.data.data.hotels.length === 0
          ) {
            let lastItemWithOnlinePriceArray: any = [];
            if (
              (this.data.data.hotels[0] &&
                !this.data.data.hotels[0].lowestSellingPriceRate) ||
              this.data.data.hotels.length === 0
            ) {
              this.ActiveOneItem = false;
            }
            this.hoteloneItem = this.data.data.hotels;
            let searchQuery: any;
            if (this.data.data.hotels.length === 1) {
              searchQuery = this.hoteloneItem[0].code + ";RELATED_PROPERTIES";
            } else {
              if (this.queryParams.searchCode) {
                searchQuery =
                  this.queryParams.searchCode + ";RELATED_PROPERTIES";
              } else {
                let searchCode: any = this.queryParams.searchQuery.split(";");
                searchCode = searchCode[0];
                searchQuery = searchCode + ";RELATED_PROPERTIES";
              }
            }
            const hoteloneItemqueryParams = {
              checkIn: this.queryParams.checkIn,
              checkOut: this.queryParams.checkOut,
              searchQuery: searchQuery,
              paxes: this.queryParams.paxes,
              taxBreakdown: true,
            };
            if (this.router.url.includes("travelerId=")) {
              this.travelerId = ("" + this.router.url).substring(
                ("" + this.router.url).indexOf("travelerId=") + 11
              );
              let travelerIdKey = "travelerId";
              hoteloneItemqueryParams[travelerIdKey] = this.travelerId;
            }
            this.sub = this.Service.availabilityList(
              hoteloneItemqueryParams,
              header
            ).subscribe(
              (success) => {
                this.dataRELATED = success;
                if (this.dataRELATED.errorCode === 0) {
                  this.hotelList = this.dataRELATED.data.hotels;
                  if (!this.dataRELATED.data.hotels) {
                    this.errorData = true;
                    this.isCollapsed = false;
                    this.Service.sendCaption(
                      this.queryParams.searchQuery,
                      this.data.data.searchCaption,
                      this.queryParams.paxes,
                      this.queryParams.searchCode,
                      this.queryParams.searchType
                    );
                    this.Service.hotellistLoader = false;
                    return;
                  }
                  this.oneitemlistMode = true;
                  this.hotelList = this.dataRELATED.data.hotels;
                  this.hotelList.forEach((item, index) => {
                    item.icon = "assets/img/marker.png";
                    if (
                      item.lowestSellingPriceRate
                        .privateAverageNightlySellingPrice &&
                      this.logedIn.isLead
                    ) {
                      item.color = "#8859E0";
                    } else {
                      item.color = "#000";
                    }
                    if (
                      !(item.additionalData && item.additionalData.userData)
                    ) {
                      this.defaultFavForNoUserDataHotels[item.code] = false;
                    }
                    if (
                      item.lowestSellingPriceRate
                        .privateAverageNightlySellingPrice
                    ) {
                      item.displayPrice =
                        item.lowestSellingPriceRate.privateAverageNightlySellingPrice;
                    } else if (
                      item.lowestSellingPriceRate
                        .publicAverageNightlySellingPrice
                    ) {
                      item.displayPrice =
                        item.lowestSellingPriceRate.publicAverageNightlySellingPrice;
                    } else {
                      item.displayPrice =
                        item.lowestSellingPriceRate.averageNightlySellingPrice;
                    }
                    item.index = index;
                    if (index < 11) {
                      if (item.onlinePrice) {
                        lastItemWithOnlinePriceArray.push(item.code);
                        item.OnlinePricesSent = true;
                      } else {
                        if (
                          this.filterreditems.length === 0 &&
                          !this.filterapplied
                        ) {
                          this.hotelList.sort((a, b) => {
                            if (a.index > b.index) {
                              return 1;
                            } else if (b.index > a.index) {
                              return -1;
                            } else {
                              return 0;
                            }
                          });
                          this.currentHotelList = this.hotelList.slice(
                            0,
                            this.numberOfHotels
                          );
                        } else {
                          this.filterreditems.sort((a, b) => {
                            if (a.index > b.index) {
                              return 1;
                            } else if (b.index > a.index) {
                              return -1;
                            } else {
                              return 0;
                            }
                          });
                          this.currentHotelList = this.filterreditems.slice(
                            0,
                            this.numberOfHotels
                          );
                        }
                      }
                    }
                  });
                  this.currentHotelList = this.hotelList.slice(
                    0,
                    this.numberOfHotels
                  );
                  this.Service.hotellistLoader = false;
                  this.getMaxPrsice();
                  this.setNewCeil();
                  if (this.Params["filter-stars"]) {
                    const starNumber = parseFloat(this.Params["filter-stars"]);
                    for (let i = starNumber; i < 5.5; i = i + 0.5) {
                      this.starArry.push(i);
                    }
                    this.ratingArray.forEach((element) => {
                      if (this.starArry.includes(element.number)) {
                        element.isChecked = true;
                      }
                    });
                    this.applyfilters();
                  }
                  if (this.Params["filter-name"]) {
                    this.search = this.Params["filter-name"];
                    this.SearchByNameText = this.Params["filter-name"];
                    this.applyfilters();
                  }
                  this.Service.sendCaption(
                    this.queryParams.searchQuery,
                    this.data.data.searchCaption,
                    this.queryParams.paxes,
                    this.queryParams.searchCode,
                    this.queryParams.searchType
                  );
                } else {
                  this.Service.hotellistLoader = false;
                  this.hoteloneItem = this.data.data.hotels;
                  this.oneitemlistMode = true;
                  this.Service.sendCaption(
                    this.queryParams.searchQuery,
                    this.data.data.searchCaption,
                    this.queryParams.paxes,
                    this.queryParams.searchCode,
                    this.queryParams.searchType
                  );
                }
              },
              (error) => {
                this.Service.hotellistLoader = false;
                this.hoteloneItem = this.data.data.hotels;
                this.oneitemlistMode = true;
                this.Service.sendCaption(
                  this.queryParams.searchQuery,
                  this.data.data.searchCaption,
                  this.queryParams.paxes,
                  this.queryParams.searchCode,
                  this.queryParams.searchType
                );
              }
            );
            if (this.hoteloneItem[0] && this.hoteloneItem[0].onlinePrice) {
              this.hoteloneItem[0].OnlinePricesSent = true;
            } else {
              if (this.hoteloneItem[0]) {
                this.hoteloneItem[0].onlinePrice = false;
              }
            }
            return;
          }
          this.hotelList = this.data.data.hotels;
          let lastItemWithOnlinePriceArray: any = [];
          this.hotelList.forEach((item, index) => {
            item.icon = "assets/img/marker.png";
            if (
              item.lowestSellingPriceRate.privateAverageNightlySellingPrice &&
              this.logedIn.isLead
            ) {
              item.color = "#8859E0";
            } else {
              item.color = "#000";
            }
            if (item.lowestSellingPriceRate.privateAverageNightlySellingPrice) {
              item.displayPrice =
                item.lowestSellingPriceRate.privateAverageNightlySellingPrice;
            } else if (
              item.lowestSellingPriceRate.publicAverageNightlySellingPrice
            ) {
              item.displayPrice =
                item.lowestSellingPriceRate.publicAverageNightlySellingPrice;
            } else {
              item.displayPrice =
                item.lowestSellingPriceRate.averageNightlySellingPrice;
            }
            if (!(item.additionalData && item.additionalData.userData)) {
              this.defaultFavForNoUserDataHotels[item.code] = false;
            }
            item.index = index;
            if (index < 11) {
              if (item.onlinePrice) {
                lastItemWithOnlinePriceArray.push(item.code);
                item.OnlinePricesSent = true;
              } else {
                if (this.filterreditems.length === 0 && !this.filterapplied) {
                  this.hotelList.sort((a, b) => {
                    if (a.index > b.index) {
                      return 1;
                    } else if (b.index > a.index) {
                      return -1;
                    } else {
                      return 0;
                    }
                  });
                  this.currentHotelList = this.hotelList.slice(
                    0,
                    this.numberOfHotels
                  );
                } else {
                  this.filterreditems.sort((a, b) => {
                    if (a.index > b.index) {
                      return 1;
                    } else if (b.index > a.index) {
                      return -1;
                    } else {
                      return 0;
                    }
                  });
                  this.currentHotelList = this.filterreditems.slice(
                    0,
                    this.numberOfHotels
                  );
                }
              }
            }
          });
          this.sortingEnabled = false;
          setTimeout(() => {
            this.showViewMap = false;
          }, 2000);
          this.currentHotelList = this.hotelList.slice(0, this.numberOfHotels);
          this.Service.hotellistLoader = false;
          this.getMaxPrsice();
          this.setNewCeil();
          if (this.Params["filter-stars"]) {
            const starNumber = parseFloat(this.Params["filter-stars"]);
            for (let i = starNumber; i < 5.5; i = i + 0.5) {
              this.starArry.push(i);
            }
            this.ratingArray.forEach((element) => {
              if (this.starArry.includes(element.number)) {
                element.isChecked = true;
              }
            });
            this.applyfilters();
          }
          if (this.Params["filter-name"]) {
            this.search = this.Params["filter-name"];
            this.SearchByNameText = this.Params["filter-name"];
            this.applyfilters();
          }
          this.Service.sendCaption(
            this.queryParams.searchQuery,
            this.data.data.searchCaption,
            this.queryParams.paxes,
            this.queryParams.searchCode,
            this.queryParams.searchType
          );
        } else {
          this.Service.hotellistLoader = false;
          this.sortingEnabled = false;
          setTimeout(() => {
            this.showViewMap = false;
          }, 2000);
          this.errorData = true;
          this.Service.messages(this.data.message, "warning");
        }
        if (this.errorData) {
          this.isCollapsed = false;
        }
      },
      (error) => {
        this.errorData = true;
        this.Service.messages(environment.messages.error, "error");
        this.Service.hotellistLoader = false;
        this.sortingEnabled = false;
        setTimeout(() => {
          this.showViewMap = false;
        }, 2000);
      }
    );
  }

  onScroll() {
    if (
      this.hotelList &&
      this.numberOfHotels < this.hotelList.length &&
      this.filterreditems.length === 0
    ) {
      this.numberOfHotels = this.numberOfHotels + 2;
      this.hotelList.forEach((item, index) => {
        if (
          item.onlinePrice &&
          !item.onlinePriceVal &&
          index <= this.numberOfHotels &&
          !item.OnlinePricesSent
        ) {
          item.OnlinePricesSent = true;
        }
      });
      this.currentHotelList = this.hotelList.slice(0, this.numberOfHotels);
    }
    if (
      this.numberOfHotels < this.filterreditems.length &&
      this.filterreditems.length !== 0
    ) {
      this.numberOfHotels++;
      this.filterreditems.forEach((item, index) => {
        if (
          item.onlinePrice &&
          !item.onlinePriceVal &&
          index <= this.numberOfHotels &&
          !item.OnlinePricesSent
        ) {
          item.OnlinePricesSent = true;
        }
      });
      this.currentHotelList = this.filterreditems.slice(0, this.numberOfHotels);
    }
  }

  getMaxPrsice() {
    this.maxPrice = Math.max.apply(
      Math,
      this.hotelList.map((price) => {
        if (price.lowestSellingPriceRate) {
          return Math.ceil(
            price.lowestSellingPriceRate.averageNightlySellingPrice
          );
        } else {
          return 0;
        }
      })
    );
  }

  calculateNumberOfNights(checkin, checkout) {
    return moment.utc(checkout).diff(moment.utc(checkin), "days");
  }

  showCashBackDescFun(item, data) {
    if (this.Service.mobileView) {
      this.Service.setModal("Cash Back information", "CashBackinfo", data);
    } else {
      this.showCashBackDesc = item;
    }
  }

  showPrivateDescFunClick() {
    this.Service.PromoBoxShow = true;
    this.Service.PromoshowPrivateDesc = true;
      this.googleAnalyticsService.eventEmitter(
        "Website-Pass-Free-Trial",
        "Unlock-hidden-rate-CTA",
        "/availability",
        1
      );

    this.Service.setModal("", "PromoBox", "");
    this.Service.PromoBox = true;
    if (this.Service.modalName === "PromoBox") {
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("modal-promo");
    }
  }

  setNewCeil(): void {
    if (this.Params["filter-price"]) {
      this.maxValue = parseFloat(this.Params["filter-price"]);
      this.PriceArray.push("0");
      this.PriceArray.push(this.maxValue);
      this.applyfilters();
    } else {
      if (this.maxPrice === 500) {
        this.maxValue = 500;
      }
      const newOptions: Options = Object.assign({}, this.optionsSlider);
      newOptions.ceil = this.maxValue;
      this.optionsSlider = newOptions;
      this.getMaxPrsice();
    }
  }

  updateHotelList() {
    this.filterInActive();
    setTimeout(() => {
      this.maxPrice = Math.max.apply(
        Math,
        this.hotelList.map((price) => {
          if (price.lowestSellingPriceRate) {
            return Math.ceil(
              price.lowestSellingPriceRate.averageNightlySellingPrice
            );
          } else {
            return 0;
          }
        })
      );
    }, 500);
  }

  filterInActive() {
    this.Service.hotellistLoader = true;
    setTimeout(() => {
      this.Service.hotellistLoader = false;
    }, 800);
  }

  dataChanged(value, index) {
    setTimeout(() => {
      this.updateHotelList();
      if (value.target.checked === true) {
        if (value.target.attributes.id.nodeValue) {
          const starNumber = parseFloat(value.target.attributes.id.nodeValue);
          this.selectedRating = parseFloat(
            value.target.attributes.id.nodeValue
          );
          this.starArry.push(starNumber);
          if (starNumber < 5) {
            this.starArry.push(starNumber + 0.5);
          }
        }
        this.starArry = this.starArry.filter((item) => item !== 6);
      } else {
        const starNumber = parseFloat(value.target.attributes.id.nodeValue);
        this.starArry = this.starArry.filter((item) => item !== starNumber);
        if (starNumber < 5) {
          this.starArry = this.starArry.filter(
            (item) => item !== starNumber + 0.5
          );
        }
      }
      this.filteringActive = true;
      for (let i = 0; i < this.ratingArray.length; i++) {
        if (this.ratingArray[i].id === value.target.attributes.id.nodeValue) {
          this.ratingArray[i].isChecked = !this.ratingArray[i].isChecked;
        }
      }
      this.ratingArray = this.ratingArray.filter((item) => item !== 6);
      this.Service.scrollTop();
      this.applyfilters();
    }, 500);
    this.activecheckbox = index;
  }

  filterFreeCancellationFunc() {
    this.updateHotelList();
    setTimeout(() => {
      this.filteringActive = true;
      this.Service.scrollTop();
      this.applyfilters();
    }, 500);
    this.filterFreeCancellationCheck = true;
  }

  filterWithinPolicyFunc() {
    this.updateHotelList();
    setTimeout(() => {
      this.filteringActive = true;
      this.Service.scrollTop();
      this.applyfilters();
    }, 500);
    this.filterWithinPolicyCheck = true;
  }

  dataChangedAmenities(value, index) {
    setTimeout(() => {
      const hrsCode = value.target.attributes.id.nodeValue.replace("Q", "");
      this.updateHotelList();
      if (value.target.checked === true) {
        if (value.target.attributes.id.nodeValue) {
          this.AmenitiesArray.push(hrsCode);
        }
        this.AmenitiesArray = this.AmenitiesArray.filter(
          (item) => item !== 6776
        );
      } else {
        this.AmenitiesArray = this.AmenitiesArray.filter(
          (item) => item !== hrsCode
        );
      }
      this.filteringActive = true;
      for (let i = 0; i < this.Amenities.length; i++) {
        if (this.Amenities[i].hrsCode === hrsCode) {
          this.Amenities[i].isChecked = !this.Amenities[i].isChecked;
        }
      }
      this.Amenities = this.Amenities.filter((item) => {
        return item.hrsCode !== "6767";
      });
      this.Service.scrollTop();
      this.applyfilters();
    }, 500);

    this.activecheckbox = index;
  }

  PriceChangeEnd(changeContext: ChangeContext): void {
    this.PriceArray = [];
    this.PriceArray.push(`${changeContext.value}`);
    if (changeContext.highValue === 500) {
      this.PriceArray.push(this.maxPrice);
    } else {
      this.PriceArray.push(`${changeContext.highValue}`);
    }
    this.updateHotelList();
    this.filteringActive = true;
    this.Service.scrollTop();
    this.applyfilters();
  }

  ReviewChangeEnd(changeContext: ChangeContext): void {
    this.updateHotelList();
    this.Service.scrollTop();
    this.applyfilters();
  }

  ReviewChange(changeContext: ChangeContext): void {
    if (this.ReviewValue > 0) {
      this.order = "googleReview";
    } else {
      this.order = "index";
    }
    if (this.ReviewValue <= 1.5) {
      this.ReviewValueText = $localize`:@@poor:Poor`;
    } else if (this.ReviewValue > 1.5 && this.ReviewValue <= 3) {
      this.ReviewValueText = $localize`:@@fair:Fair`;
    } else if (this.ReviewValue === 3.5) {
      this.ReviewValueText = $localize`:@@good:Good`;
    } else if (this.ReviewValue === 4) {
      this.ReviewValueText = $localize`:@@veryGood:Very Good`;
    } else if (this.ReviewValue > 4) {
      this.ReviewValueText = $localize`:@@excellent:Excellent`;
    }
    this.filteringActive = true;
    if (Number.isInteger(this.ReviewValue)) {
      this.isInteger = true;
    } else {
      this.isInteger = false;
    }
  }

  clearFilters() {
    this.ratingArray = [
      {
        id: "5",
        number: 5,
        name: $localize`:@@5star:5-star`,
        isChecked: false,
      },
      {
        id: "4",
        number: 4,
        name: $localize`:@@4star:4-star`,
        isChecked: false,
      },
      {
        id: "3",
        number: 3,
        name: $localize`:@@3star:3-star`,
        isChecked: false,
      },
      {
        id: "2",
        number: 2,
        name: $localize`:@@2star:2-star`,
        isChecked: false,
      },
      {
        id: "1",
        number: 1,
        name: $localize`:@@1star:1-star`,
        isChecked: false,
      },
    ];
    this.Amenities = [
      {
        hrsCode: "21",
        isChecked: false,
        title: $localize`:@@freeBreakfast:Free Breakfast`,
      },

      {
        hrsCode: "18",
        isChecked: false,
        title: $localize`:@@freeWiFi:Free Wifi`,
      },
      {
        hrsCode: "19",
        isChecked: false,
        title: $localize`:@@freeparking:Free Parking`,
      },
      {
        hrsCode: "20",
        isChecked: false,
        title: $localize`:@@freeAirportTransport:Free Airport Transportation`,
      },
      {
        hrsCode: "14",
        isChecked: false,
        title: $localize`:@@bar:Bar`,
      },
      {
        hrsCode: "12",
        isChecked: false,
        title: $localize`:@@businessCenter:Business Center`,
      },
      {
        hrsCode: "3",
        isChecked: false,
        title: $localize`:@@gym:Gym`,
      },

      {
        hrsCode: "9",
        isChecked: false,
        title: $localize`:@@swimmingPool:Swimming Pool`,
      },
    ];
    this.filterInActive();
    this.ParentSort = "default";
    this.SortType = "default";
    this.SearchByNameText = "";
    this.search = "";
    this.starArry = [];
    this.PriceArray = [];
    this.AmenitiesArray = [];
    this.ReviewValue = 0;
    this.filterapplied = false;
    this.minValue = 0;
    this.getMaxPrsice();
    this.maxValue = 500;
    this.numberOfHotels = 10;
    this.filterFreeCancellation = false;
    this.filterWithinPolicy = false;
    this.currentHotelList = this.hotelList.slice(0, this.numberOfHotels);
    this.filterreditems = [];
    if (!this.listView) {
      this.currentHotelList = this.hotelList;
    }
    this.applyfilters();
    this.filteringActive = false;
  }

  autoCompliteClick(item, index) {
    this.mapItemActive = true;
    this.mapItem = item;
    this.currentHotelList.forEach((elm, i) => {
      elm.icon = "assets/img/marker.png";
      if (
        elm.lowestSellingPriceRate.privateAverageNightlySellingPrice &&
        elm.lowestSellingPriceRate.averageNightlySellingPrice &&
        this.logedIn.isLead
      ) {
        elm.color = "#8859E0";
      } else {
        elm.color = "#000";
      }
      if (index === elm.index) {
        if (
          elm.lowestSellingPriceRate.privateAverageNightlySellingPrice &&
          elm.lowestSellingPriceRate.averageNightlySellingPrice &&
          this.logedIn.isLead
        ) {
          elm.icon = "assets/img/marker-prime.png";
        } else {
          elm.icon = "assets/img/marker-active.png";
        }
        elm.color = "#FFF";
        elm.visited = true;
      }
      if (elm.visited === true && elm.index !== index) {
        elm.icon = "assets/img/marker-visited.png";
      }
    });
    this.MainLatitude = item.latitude;
    this.MainLongitude = item.longitude;
    this.zoom = 18;
    this.autoCompliteMenuFilter = false;
  }

  ReviewChanged(data) {
    this.ReviewValue = data;
    this.applyfilters();
  }

  clickedMarker(item, index, events) {
    this.mapItemActive = true;
    this.mapItem = item;
    this.currentHotelList.forEach((elm, elmindex) => {
      elm.icon = "assets/img/marker.png";
      if (
        elm.lowestSellingPriceRate.privateAverageNightlySellingPrice &&
        this.logedIn.isLead
      ) {
        elm.color = "#8859E0";
      } else {
        elm.color = "#000";
      }
      elm.index = elmindex;
      if (elmindex === index) {
        if (
          item.lowestSellingPriceRate.privateAverageNightlySellingPrice &&
          this.logedIn.isLead
        ) {
          this.currentHotelList[index].icon = "assets/img/marker-prime.png";
        } else {
          this.currentHotelList[index].icon = "assets/img/marker-active.png";
        }
        this.currentHotelList[index].color = "#FFF";
        this.currentHotelList[index].index = 10000;
        this.currentHotelList[index].visited = true;
      }
      if (elm.visited === true && elmindex !== index) {
        elm.icon = "assets/img/marker-visited.png";
      }
    });
    if (item) {
      this.mapHotelNotavailability = !item.lowestSellingPriceRate;

      this.myFacilities = item.myFacilities;
    }
  }

  closeHotelMap() {
    this.mapItemActive = false;
    this.currentHotelList.forEach((item) => {
      if (
        item.icon === "assets/img/marker-active.png" ||
        item.icon === "assets/img/marker-prime.png"
      ) {
        item.icon = "assets/img/marker-visited.png";
        if (
          item.lowestSellingPriceRate.privateAverageNightlySellingPrice &&
          this.logedIn.isLead
        ) {
          item.color = "#8859E0";
        } else {
          item.color = "#000";
        }
      }
    });
  }

  customComparator(itemA, itemB) {
    return itemA > itemB ? 1 : -1;
  }

  getCountryName(countryCode) {
    if (this.VarsService.isoCountries.hasOwnProperty(countryCode)) {
      return this.VarsService.isoCountries[countryCode];
    } else {
      return countryCode;
    }
  }

  applyfilters() {
    this.Service.hotelFilter = true;
    this.numberOfHotels = 10;
    this.filterreditems = this.hotelList;
    if (this.filterWithinPolicy) {
      this.filterreditems = this.filterreditems.filter((item) => {
        return (
          item.lowestSellingPriceRate.userData.exceedsPolicyLimit === false
        );
      });
    }
    this.filterWithinPolicyCheck = false;
    if (this.filterFreeCancellation) {
      this.filterreditems = this.filterreditems.filter((item) => {
        const freeCan = item.lowestSellingPriceRate.freeCancellation === true;
        return freeCan;
      });
    }
    this.filterFreeCancellationCheck = false;
    if (this.AmenitiesArray.length > 0) {
      this.filterreditems = this.filterreditems.filter((item) => {
        const LArrar = this.AmenitiesArray;
        let itsExists = false;
        if (item.myFacilities && LArrar.length > 0) {
          let count = 0;
          item.myFacilities.map(function (entry) {
            LArrar.forEach(function (entrys) {
              if (entrys === entry.hrsCode) {
                count++;
              }
              if (LArrar.length === count) {
                itsExists = true;
              }
              if (count === 0) {
                return [-1];
              }
            });
          });
          return itsExists;
        } else {
          return false;
        }
      });
    }
    this.filterreditems = this.filterreditems.filter((item) => {
      const find = item.name.toLowerCase().indexOf(this.search.toLowerCase());
      return find !== -1;
    });
    if (this.ReviewValue > 0) {
      this.filterreditems = this.filterreditems.filter((item) => {
        if (item.googleReview && this.ReviewValue !== 0) {
          return item.googleReview.rating >= this.ReviewValue;
        } else {
          return this.ReviewValue === 0;
        }
      });
    }
    if (this.starArry.length > 0) {
      this.filterreditems = this.filterreditems.filter((item) => {
        if (this.starArry.length > 0) {
          return this.starArry.includes(item.rating);
        } else {
          return true;
        }
      });
    }
    if (this.PriceArray.length > 0) {
      this.filterreditems = this.filterreditems.filter((item) => {
        const priceArray = this.PriceArray;
        return this.PriceArray.reduce(
          () =>
            priceArray[0] <= item.displayPrice &&
            priceArray[1] >= item.displayPrice,
          true
        );
      });
    }
    this.filterreditems.forEach((item, index) => {
      if (
        index < 11 &&
        item.onlinePrice &&
        !item.onlinePriceVal &&
        this.numberOfHotels > index &&
        !item.OnlinePricesSent
      ) {
        item.OnlinePricesSent = true;
      }
    });
    this.currentHotelList = this.filterreditems.slice(0, this.numberOfHotels);
    this.filterapplied = true;
    this.sortFunc(this.SortType, this.ParentSort);
    this.AmenitiesArray = this.AmenitiesArray.filter((item) => item !== 6776);
    this.activecheckbox = undefined;
    const inputElements = Array.from(document.getElementsByTagName("input"));
    let anyChecked = false;
    inputElements.forEach((checkboxItem) => {
      if (checkboxItem.type === "checkbox" && checkboxItem.checked) {
        anyChecked = true;
      }
    });
    if (!anyChecked) {
      this.filteringActive = false;
    }
  }

  sortFunc(type, parent) {
    this.Service.hotelFilter = true;
    this.activeSort = true;
    this.Service.hotellistLoader = true;
    this.SortType = type;
    this.ParentSort = parent;
    if (this.filterreditems.length === 0 && !this.filterapplied) {
      this.filterreditems = this.hotelList;
    }
    if (type === "default") {
      this.activeSort = false;
      this.filterreditems.sort((a, b) => {
        if (a.index > b.index) {
          return 1;
        } else if (b.index > a.index) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    if (type === "guestRating") {
      this.activeSort = false;
      let filterredItemOnlyWithGoogleRating = this.filterreditems.filter(
        (itemFiltered) => itemFiltered.googleReview
      );
      filterredItemOnlyWithGoogleRating.sort((a, b) => {
        if (a.googleReview && b.googleReview) {
          if (a.googleReview.rating < b.googleReview.rating) {
            return 1;
          } else if (b.googleReview.rating < a.googleReview.rating) {
            return -1;
          } else {
            return 0;
          }
        }
      });
      let filterredItemOnlyWithNOGoogleRating = this.filterreditems.filter(
        (itemFiltered) => !itemFiltered.googleReview
      );
      this.filterreditems = filterredItemOnlyWithGoogleRating.concat(
        filterredItemOnlyWithNOGoogleRating
      );
    }
    if (type === "ratingLow") {
      this.filterreditems.sort((a, b) => {
        if (a.rating < b.rating) {
          return 1;
        } else if (b.rating < a.rating) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    if (type === "PriceHigh") {
      this.filterreditems.sort((a, b) => {
        if (a.displayPrice > b.displayPrice) {
          return 1;
        } else if (b.displayPrice > a.displayPrice) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    if (type === "PriceLow") {
      this.filterreditems.sort((a, b) => {
        if (a.displayPrice < b.displayPrice) {
          return 1;
        } else if (b.displayPrice < a.displayPrice) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    if (type === "ratingHigh") {
      this.filterreditems.sort((a, b) => {
        if (a.rating > b.rating) {
          return 1;
        } else if (b.rating > a.rating) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    if (type === "ratingLow") {
      this.filterreditems.sort((a, b) => {
        if (a.rating < b.rating) {
          return 1;
        } else if (b.rating < a.rating) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    if (type === "SavingHigh") {
      this.filterreditems.sort((a, b) => {
        if (
          a.lowestSellingPriceRate.nightlySavings &&
          b.lowestSellingPriceRate.nightlySavings
        ) {
          if (
            a.lowestSellingPriceRate.nightlySavings >
            b.lowestSellingPriceRate.nightlySavings
          ) {
            return 1;
          } else if (
            b.lowestSellingPriceRate.nightlySavings >
            a.lowestSellingPriceRate.nightlySavings
          ) {
            return -1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      });
    }
    if (type === "SavingLow") {
      this.filterreditems.sort((a, b) => {
        if (
          a.lowestSellingPriceRate.nightlySavings &&
          b.lowestSellingPriceRate.nightlySavings
        ) {
          if (
            a.lowestSellingPriceRate.nightlySavings <
            b.lowestSellingPriceRate.nightlySavings
          ) {
            return 1;
          } else if (
            b.lowestSellingPriceRate.nightlySavings <
            a.lowestSellingPriceRate.nightlySavings
          ) {
            return -1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      });
    }
    this.numberOfHotels = 10;
    if (this.listView) {
      this.currentHotelList = this.filterreditems.slice(0, this.numberOfHotels);
    } else {
      this.currentHotelList = this.filterreditems;
    }
    this.filterapplied = true;
    setTimeout(() => (this.Service.hotellistLoader = false), 1000);
  }

  freeCancellationRemove() {
    this.filterFreeCancellation = false;
    this.applyfilters();
    this.triggerScrollTo();
  }

  withPolicyFilterRemove() {
    this.filterWithinPolicy = false;
    this.applyfilters();
    this.triggerScrollTo();
  }

  PriceRemove() {
    this.PriceArray = [];
    this.minValue = 0;
    this.getMaxPrsice();
    this.maxValue = 500;
    this.applyfilters();
    this.triggerScrollTo();
  }

  ratingArrayRemove() {
    this.ratingArray = [
      { id: "5", number: 5, name: "5-star", isChecked: false },
      { id: "4", number: 4, name: "4-star", isChecked: false },
      { id: "3", number: 3, name: "3-star", isChecked: false },
      { id: "2", number: 2, name: "2-star", isChecked: false },
      { id: "1", number: 1, name: "1-star", isChecked: false },
    ];
    this.starArry = [];
    this.applyfilters();
    this.triggerScrollTo();
  }

  AmenitiesRemove(hrsCode) {
    setTimeout(() => {
      this.updateHotelList();
      this.AmenitiesArray = this.AmenitiesArray.filter(
        (item) => item !== hrsCode
      );
      for (let i = 0; i < this.Amenities.length; i++) {
        if (this.Amenities[i].hrsCode === hrsCode) {
          this.Amenities[i].isChecked = !this.Amenities[i].isChecked;
        }
      }
      // this added (and its mean nothing) because the map filter will not updated without filter to array
      this.Amenities = this.Amenities.filter((item) => {
        return item.hrsCode !== "6767";
      });
      this.applyfilters();
    }, 500);
    this.triggerScrollTo();
  }

  ReviewValueRemove() {
    this.ReviewValue = 0;
    this.applyfilters();
    this.triggerScrollTo();
  }

  onWindowScroll(event) {
    if (this.Service.mobileView && this.scrollContainer) {
      const currentPosition = this.scrollContainer.nativeElement.offsetTop;
      const scrollFromTop = event.currentTarget.scrollY;
      if (scrollFromTop >= currentPosition) {
        this.activeScroll = true;
      } else {
        this.activeScroll = false;
      }
      if (
        this.activeScroll &&
        this.oldScrollFromTop > event.currentTarget.scrollY
      ) {
        this.sortHeader = true;
      } else {
        this.sortHeader = false;
      }
      this.oldScrollFromTop = event.currentTarget.scrollY;
    }
  }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: "hotelListMobile",
      duration: 650,
      easing: "easeOutElastic",
      offset: -150,
    };
    this.scrollToService.scrollTo(config);
  }

  centerChange(event) {
    this.centerChangeValue = true;
    this.centerLatLang = event.lat + "," + event.lng;
  }

  zoomChange(event) {
    this.searchthisAreaBtn = event > 15 && this.centerChangeValue;
  }

  searcAreaMap() {
    this.submitting = true;
    let header = "";
    if (sessionStorage.getItem("session-token")) {
      header = sessionStorage.getItem("session-token");
    }
    let params = {
      checkIn: this.queryParams.checkIn,
      checkOut: this.queryParams.checkOut,
      paxes: this.queryParams.paxes,
      taxBreakdown: true,
      searchCode: this.centerLatLang + ",3",
      searchType: "GEO",
    };
    if (this.router.url.includes("travelerId=")) {
      this.travelerId = ("" + this.router.url).substring(
        ("" + this.router.url).indexOf("travelerId=") + 11
      );
      let travelerIdKey = "travelerId";
      params[travelerIdKey] = this.travelerId;
    }
    this.sub = this.Service.availabilityList(params, header).subscribe(
      (success) => {
        if (sessionStorage.getItem("session-token")) {
          sessionStorage.removeItem("session-token");
        }
        this.data = success;
        if (this.data.errorCode === 0) {
          if (!this.data.data.hotels || this.data.data.hotels.length <= 0) {
            this.searchthisAreaBtn = false;
            this.Service.SnakBar(this.VarsService.messages.noHotelsFound);
            this.isCollapsed = false;
            this.submitting = false;
            this.Service.hotellistLoader = false;
            this.applyfilters();
            return;
          }
          this.Service.sendCaption(
            undefined,
            this.data.data.searchCaption,
            this.queryParams.paxes,
            this.centerLatLang,
            "GEO"
          );
          this.hotelList = this.data.data.hotels;
          this.titleService.setTitle(
            "Hotels in " + this.data.data.searchCaption + " | SafarHub"
          );
          this.hotelList.forEach((item, index) => {
            item.icon = "assets/img/marker.png";
            if (
              item.lowestSellingPriceRate.privateAverageNightlySellingPrice &&
              this.logedIn.isLead
            ) {
              item.color = "#8859E0";
            } else {
              item.color = "#000";
            }
            if (item.lowestSellingPriceRate.privateAverageNightlySellingPrice) {
              item.displayPrice =
                item.lowestSellingPriceRate.privateAverageNightlySellingPrice;
            } else if (
              item.lowestSellingPriceRate.publicAverageNightlySellingPrice
            ) {
              item.displayPrice =
                item.lowestSellingPriceRate.publicAverageNightlySellingPrice;
            } else {
              item.displayPrice =
                item.lowestSellingPriceRate.averageNightlySellingPrice;
            }
            item.index = index;
            if (!(item.additionalData && item.additionalData.userData)) {
              this.defaultFavForNoUserDataHotels[item.code] = false;
            }
          });
          this.displayGoogleRatingFilter =
            this.data.data.displayGoogleRatingFilter;
          setTimeout(() => {
            this.showViewMapMobile = false;
          }, 3000);
          this.applyfilters();
          this.getMaxPrsice();
          this.setNewCeil();
          this.MainLongitude = this.data.data.extraData.longitude;
          this.MainLatitude = this.data.data.extraData.latitude;
          if (this.data.data.extraData.userData) {
            this.showWithinPolicyFilter =
              this.data.data.extraData.userData.displayCompanyPoliciesFilter;
            if (this.data.data.extraData.userData.policy) {
              this.companyPolicyHotelRules =
                this.data.data.extraData.userData.policy.companyPolicyHotelRules;
              this.policyDetails = this.data.data.extraData.userData.policy;
              this.policyCurrency =
                this.policyDetails.companyPolicyHotelRules.avgNightlyPriceLimitCurrency;
              this.policyDetails.companyPolicyHotelRules.exceptions.forEach(
                (item, index) => {
                  if (item.isApplied) {
                    this.hasException = true;
                    this.exceptionValue = item.avgNightlyPriceLimit;
                    this.exceptionCurrency = item.avgNightlyPriceLimitCurrency;
                  }
                }
              );
            }
          }
          this.searchthisAreaBtn = false;
          this.submitting = false;
        } else {
          this.submitting = false;
          this.applyfilters();
        }
      },
      (error) => {
        this.submitting = false;
        this.applyfilters();
      }
    );
  }

  autoCompliteFilterFunc() {
    this.autoCompliteFilterActive = true;
    this.search = this.autoCompliteFilterText;
    setTimeout(() => {
      this.applyfilters();
      this.autoCompliteFilterActive = false;
    }, 200);
    this.autoCompliteMenuFilter = false;
  }

  autocompleteOnFocus(event) {
    event.target.select();
  }

  onClickBody(event) {
    if (
      this.eref.nativeElement.querySelector(".search-label-map") &&
      !this.eref.nativeElement
        .querySelector(".search-label-map")
        .contains(event.target)
    ) {
      this.autoCompliteMenuFilter = false;
    }
  }

  openSearch() {
    this.isCollapsed = false;
    let element: HTMLElement = document.querySelector(
      "#daterange"
    ) as HTMLElement;
    setTimeout(() => {
      element.click();
    }, 200);
  }

  showGuestRating() {
    if (this.currentHotelList) {
      let show = this.currentHotelList.filter(
        (itemFiltered) => itemFiltered.googleReview
      );
      if (show.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
