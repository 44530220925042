import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { WorkspaceSignupComponent } from './workspace-signup/workspace-signup.component';
import { CompanyAccountFormComponent } from './company-account-form/company-account-form.component';
import { UploadCompanyLogoComponent } from './upload-company-logo/upload-company-logo.component';
import { WorkspaceLoginComponent } from './workspace-login/workspace-login.component';
import { WorkspaceOnboardingComponent } from './workspace-onboarding/workspace-onboarding.component';
import { CompanyLoginComponent } from './company-login/company-login.component';
import { CompanyInvitationSignupComponent } from './company-invitation-signup/company-invitation-signup.component';
import { WorkspaceDashboardHomeComponent } from './workspace-dashboard/workspace-dashboard-home/workspace-dashboard-home.component';
import { WorkspaceSettingsComponent } from './workspace-all-settings/workspace-settings/workspace-settings.component';
import { TeamsListComponent } from './workspace-teams/teams-list/teams-list.component';
import { TeamDetailsComponent } from './workspace-teams/team-details/team-details.component';
import { AddNewPaymentMethodComponent } from './workspace-all-settings/workspace-payments/add-new-payment-method/add-new-payment-method.component';
import { AddTeamMembersComponent } from './workspace-teams/add-team-members/add-team-members.component';
import { UserProfileComponent } from './workspace-user/user-profile/user-profile.component';
import { PoliciesListComponent } from './workspace-all-settings/workspace-policies/policies-list/policies-list.component';
import { UsersListComponent } from './workspace-all-settings/workspace-users/users-list/users-list.component';
import { UserDetailsComponent } from './workspace-all-settings/workspace-users/user-details/user-details.component';
import { UserDeactivateComponent } from './workspace-all-settings/workspace-users/user-deactivate/user-deactivate.component';
import { AccountInfoComponent } from './workspace-all-settings/workspace-account/account-info/account-info.component';
import { PaymentMethodsListComponent } from './workspace-all-settings/workspace-payments/payment-methods-list/payment-methods-list.component';
import { TermsComponent } from '../staticComponents/terms/terms.component';
import { PrivacyComponent } from '../staticComponents/privacy/privacy.component';
import { AboutComponent } from '../staticComponents/about/about.component';
import { ContactComponent } from '../controls/contact/contact.component';
import { HotelsListComponent } from './workspace-availability/hotels-list/hotels-list.component';
import { HotelDetailsComponent } from './workspace-availability/hotel-details/hotel-details.component';
import { BookingPageComponent } from './workspace-availability/booking-page/booking-page.component';
import { VoucherPageComponent } from './workspace-booking/voucher-page/voucher-page.component';
import { ApprovalsListComponent } from './workspace-approvals/approvals-list/approvals-list.component';
import { ApprovalsDetailsComponent } from './workspace-approvals/approvals-details/approvals-details.component';
import { RequestsListComponent } from './workspace-user/user-request-approvals/requests-list/requests-list.component';
import { RequestDetailsComponent } from './workspace-user/user-request-approvals/request-details/request-details.component';
import { BillingSubscriptionComponent } from './workspace-all-settings/workspace-billing-and-plans/billing-subscription/billing-subscription.component';
import { BillingHistoryComponent } from './workspace-all-settings/workspace-billing-and-plans/billing-history/billing-history.component';
import { UserPaymentsListComponent } from './workspace-user/user-payment/user-payments-list/user-payments-list.component';
import { UserFavoritesComponent } from './workspace-user/user-favorites/user-favorites.component';
import { UserTripsComponent } from './workspace-user/user-trips/user-trips.component';
import { UserManageBookingComponent } from './workspace-user/user-bookings/user-manage-booking/user-manage-booking.component';
import { UserManageFlightBookingComponent } from './workspace-user/user-bookings/user-manage-flight-booking/user-manage-flight-booking.component';
import { UserCancelBookingComponent } from './workspace-user/user-bookings/user-cancel-booking/user-cancel-booking.component';
import { ResetPasswordComponent } from '../controls/reset-password/reset-password.component';
import { DeactivateSubscriptionComponent } from './workspace-all-settings/workspace-billing-and-plans/deactivate-subscription/deactivate-subscription.component';
import { DeactivateSubscriptionReasonsComponent } from './workspace-all-settings/workspace-billing-and-plans/deactivate-subscription-reasons/deactivate-subscription-reasons.component';
import { WorkspaceReportsComponent } from './workspace-reports/workspace-reports.component';
import { TransferOwnershipComponent } from './workspace-all-settings/workspace-account/transfer-ownership/transfer-ownership.component';
import { IntegrationsListComponent } from './workspace-all-settings/workspace-integrations/integrations-list/integrations-list.component';
import { IntegrationSettingsComponent } from './workspace-all-settings/workspace-integrations/integration-settings/integration-settings.component';
import { CreateEditTeamPageComponent } from './workspace-teams/create-edit-team-page/create-edit-team-page.component';
import { InviteUsersPageComponent } from './workspace-all-settings/workspace-users/invite-users-page/invite-users-page.component';
import { CreateEditPolicyPageComponent } from './workspace-all-settings/workspace-policies/create-edit-policy-page/create-edit-policy-page.component';
import { UserUpdatePageComponent } from './workspace-all-settings/workspace-users/user-update-page/user-update-page.component';
import { FlightListComponent } from './workspace-availability/flight-list/flight-list.component';
import { FlightBookingPageComponent } from './workspace-availability/flight-booking-page/flight-booking-page.component';
import { FlightVoucherPageComponent } from './workspace-booking/flight-voucher-page/flight-voucher-page.component';
import { WorkspaceRewardsSettingsComponent } from './workspace-all-settings/workspace-rewards/workspace-rewards-settings/workspace-rewards-settings.component';
import { FlightRequestDetailsComponent } from './workspace-user/user-request-approvals/flight-request-details/flight-request-details.component';
import { UserRewardsComponent } from './workspace-user/user-rewards/user-rewards.component';
import { FlightApprovalsDetailsComponent } from './workspace-approvals/flight-approvals-details/flight-approvals-details.component';
import { UserRewardsHistoryComponent } from './workspace-user/user-rewards-history/user-rewards-history.component';
import { WorkspaceCostsListSettingsComponent } from './workspace-all-settings/workspace-costs/workspace-costs-list/workspace-costs-list.component';


const routes: Routes = [
//  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'join', component: WorkspaceSignupComponent, pathMatch: 'full' },
  { path: 'login', component: CompanyLoginComponent, pathMatch: 'full' },
  { path: 'create', component: CompanyAccountFormComponent, pathMatch: 'full' },
  {
    path: 'info',
    children: [
      { path: 'terms', component: TermsComponent, pathMatch: 'full' },
      { path: 'privacy', component: PrivacyComponent, pathMatch: 'full' },
      { path: 'about', component: AboutComponent, pathMatch: 'full' },
      { path: 'contact-us', component: ContactComponent, pathMatch: 'full' },
    ]
  },
 /* {
    path: ':companyId',
    children: [*/
      { path: '', component: WorkspaceDashboardHomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      {
        path: 'flights', canActivate: [AuthGuard],
        children: [
          {
            path: 'availability',
            children: [
              { path: '', component: FlightListComponent, pathMatch: 'full' },
              { path: 'booking', component: FlightBookingPageComponent, pathMatch: 'full' },
              { path: 'voucher', component: FlightVoucherPageComponent, pathMatch: 'full' },
            ]
          },
          {
            path: 'approvals',
            children: [
              { path: ':approvalId', component: FlightApprovalsDetailsComponent, pathMatch: 'full' },
            ]
          },
        ]
      },
      {
        path: 'availability', canActivate: [AuthGuard],
        children: [
          { path: '', component: HotelsListComponent, pathMatch: 'full' },
          { path: 'hotel', component: HotelDetailsComponent, pathMatch: 'full' },
          { path: 'booking', component: BookingPageComponent, pathMatch: 'full' },
          { path: 'voucher', component: VoucherPageComponent, pathMatch: 'full' },
        ]
      },
//      { path: 'login', component: WorkspaceLoginComponent, pathMatch: 'full' },
      {
        path: 'password',
        children: [
          { path: 'reset', component: ResetPasswordComponent, pathMatch: 'full' },
        ]
      },
      { path: 'onboarding', component: WorkspaceOnboardingComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'upload-logo', component: UploadCompanyLogoComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      {
        path: 'approvals', canActivate: [AuthGuard],
        children: [
          { path: '', component: ApprovalsListComponent, pathMatch: 'full' },
          { path: ':approvalId', component: ApprovalsDetailsComponent, pathMatch: 'full' },
        ]
      },
      {
        path: 'settings', canActivate: [AuthGuard],
        children: [
          { path: '', component: WorkspaceSettingsComponent, pathMatch: 'full' },
          {
            path: 'billing',
            children: [
              { path: '', component: BillingSubscriptionComponent, pathMatch: 'full' },
              { path: 'history', component: BillingHistoryComponent, pathMatch: 'full' },
              {
                path: 'cancel-subscription',
                children: [
                  { path: '', component: DeactivateSubscriptionComponent, pathMatch: 'full' },
                  { path: 'reasons', component: DeactivateSubscriptionReasonsComponent, pathMatch: 'full' },
                ]
              },
            ]
          },
          {
            path: 'payment',
            children: [
              { path: '', component: PaymentMethodsListComponent, pathMatch: 'full' },
              { path: 'add', component: AddNewPaymentMethodComponent, pathMatch: 'full' },
            ]
          },
          {
            path: 'rewards',
            children: [
              { path: '', component: WorkspaceRewardsSettingsComponent, pathMatch: 'full' },
            ]
          },
          {
            path: 'integrations',
            children: [
              { path: '', component: IntegrationsListComponent, pathMatch: 'full' },
              { path: 'expensify', component: IntegrationSettingsComponent, pathMatch: 'full' },
            ]
          },
          {
            path: 'account',
            children: [
              { path: '', component: AccountInfoComponent, pathMatch: 'full' },
              { path: 'edit', component: AccountInfoComponent, pathMatch: 'full' },
              { path: 'transfer-ownership', component: TransferOwnershipComponent, pathMatch: 'full' },
            ]
          },
          {
            path: 'cost-management',
            children: [
              { path: '', component: WorkspaceCostsListSettingsComponent, pathMatch: 'full' },
            ]
          },
        ]
      },
      {
        path: 'user', canActivate: [AuthGuard],
        children: [
          { path: 'profile', component: UserProfileComponent, pathMatch: 'full' },
          { path: 'payment', component: UserPaymentsListComponent, pathMatch: 'full' },
          { path: 'favorites', component: UserFavoritesComponent, pathMatch: 'full' },
          { path: 'trips', component: UserTripsComponent, pathMatch: 'full' },
          {
            path: 'requests', canActivate: [AuthGuard],
            children: [
              { path: '', component: RequestsListComponent, pathMatch: 'full' },
              { path: ':requestId', component: RequestDetailsComponent, pathMatch: 'full' },
            ]
          },
          {
            path: 'rewards',
            children: [
              { path: '', component: UserRewardsComponent, pathMatch: 'full' },
              { path: 'history', component: UserRewardsHistoryComponent },
            ]
          },
          {
            path: 'flights', canActivate: [AuthGuard],
            children: [
              {
                path: 'requests',
                children: [
                  { path: ':requestId', component: FlightRequestDetailsComponent, pathMatch: 'full' },
                ]
              },
            ]
          },
        ]
      },
      {
        path: 'hotel', canActivate: [AuthGuard],
        children: [
          {
            path: 'booking',
            children: [
              { path: 'details', component: UserManageBookingComponent },
              { path: 'cancel', component: UserCancelBookingComponent },
            ]
          },
        ]
      },
      {
        path: 'flight', canActivate: [AuthGuard],
        children: [
          {
            path: 'booking',
            children: [
              { path: 'details', component: UserManageFlightBookingComponent },
            ]
          },
        ]
      },
      {
        path: 'teams', canActivate: [AuthGuard],
        children: [
          { path: '', component: TeamsListComponent, pathMatch: 'full' },
          { path: 'create', component: CreateEditTeamPageComponent, pathMatch: 'full' },
          {
            path: ':teamId',
            children: [
              { path: '', component: TeamDetailsComponent, pathMatch: 'full' },
              { path: 'edit', component: CreateEditTeamPageComponent, pathMatch: 'full' },
              { path: 'add-members', component: AddTeamMembersComponent, pathMatch: 'full' },
            ]
          },
        ]
      },
      {
        path: 'members', canActivate: [AuthGuard],
        children: [
          { path: '', component: UsersListComponent, pathMatch: 'full' },
          { path: 'invite', component: InviteUsersPageComponent, pathMatch: 'full' },
          {
            path: ':userId',
            children: [
              { path: '', component: UserDetailsComponent, pathMatch: 'full' },
              { path: 'edit', component: UserUpdatePageComponent, pathMatch: 'full' },
              { path: 'deactivate', component: UserDeactivateComponent, pathMatch: 'full' },
            ]
          },
        ]
      },
      {
        path: 'travel-policies', canActivate: [AuthGuard],
        children: [
          { path: '', component: PoliciesListComponent, pathMatch: 'full' },
          { path: 'create', component: CreateEditPolicyPageComponent, pathMatch: 'full' },
          {
            path: ':policyId',
            children: [
              { path: 'edit', component: CreateEditPolicyPageComponent, pathMatch: 'full' },
            ]
          },
        ]
      },
      { path: 'reports', canActivate: [AuthGuard], component: WorkspaceReportsComponent, pathMatch: 'full' },
   /* ]
  },*/
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CorporateTravelRoutingModule { }
