<div
  class="sidenav_container"
  [@onSideNavChange]="this.dataService.sideNavState ? 'open' : 'close'"
>
  <div fxLayout="column" fxLayoutGap="10px" style="height: 100%">
    <div class="user_menu">
      <mat-nav-list>
        <div
          class="d-flex pointer mb-3"
          *ngIf="!router.url.includes('/availability/booking')"
          (click)="this.router.navigate(['/'])"
        >
          <div class="position-relative container-left-margin">
            <img
              src="assets/img/logo-icon.svg"
              height="29"
              width="29"
              class="logo-margin"
              [@animateText]="!this.dataService.linkText ? 'show' : 'hide'"
            />
          </div>
          <img
            src="assets/img/logo.svg"
            height="29"
            width="180"
            class="logo-padding"
            height="35"
            [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
          />
        </div>

        <div
          class="d-flex pointer mb-3"
          *ngIf="router.url.includes('/availability/booking')"
          (click)="this.dataService.dropDowmBookingForm = true"
        >
          <div class="position-relative container-left-margin">
            <img
              src="assets/img/logo-icon.svg"
              width="29"
              class="logo-margin"
              [@animateText]="!this.dataService.linkText ? 'show' : 'hide'"
            />
          </div>
          <img
            src="assets/img/logo.svg"
            width="180"
            class="logo-padding"
            height="35"
            [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
          />
        </div>

        <div
          class="toggle-btn"
          [@animateText]="!this.dataService.linkText ? 'show' : 'hide'"
          (click)="
            onSideNavToggle(); this.dataService.initialMarginValue = false
          "
        >
          <div
            class="mx-auto dashboard-icon-container text-center arrow-left-right d-flex justify-content-center align-items-center"
          >
            <i
              [ngClass]="{
                'icon-double-arrows-right': dataService.isSiteLtr(),
                'icon-double-arrows-left': dataService.isSiteRtl()
              }"
              class="font-12"
            ></i>
          </div>
        </div>
        <span
          mat-list-item
          class="primaray-text font-weight-bold sharp-font manage-text pr-5 invisible"
          [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
          >Manage
        </span>

        <div class="drawer-container mt-0">
          <ng-container
            *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive"
          >
            <div
              class="d-flex pointer"
              (click)="
                this.router.navigate([
                  '/members'
                ])
              "
              [ngClass]="{
                active:
                  this.auth.companyInfo && this.router.url.includes('members')
              }"
            >
              <div
                class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin"
                [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
              >
                <i
                  class="icon-workspace-users-colored gray-text-dark font-16 d-inline"
                ></i>
              </div>
              <p
                [ngClass]="{
                  'ml-3': dataService.isSiteLtr(),
                  'mr-3': dataService.isSiteRtl()
                }"
                class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                i18n="@@menuItemMembers"
              >
                Manage Members
              </p>
            </div>
            <div
              class="d-flex pointer"
              (click)="
                this.router.navigate([
                  '/user/trips'
                ])
              "
              [ngClass]="{
                active:
                  this.auth.companyInfo &&
                  this.router.url.includes('user/trips')
              }"
            >
              <div
                class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin"
                [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
              >
                <i
                  class="icon-workspace-trips gray-text-dark font-16 d-inline"
                ></i>
              </div>
              <p
                [ngClass]="{
                  'ml-3': dataService.isSiteLtr(),
                  'mr-3': dataService.isSiteRtl()
                }"
                class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                i18n="@@menuItemCompTrips"
              >
                Company Trips
              </p>
            </div>
            <div
              class="d-flex pointer"
              (click)="
                this.router.navigate([
                   '/reports'
                ])
              "
              [ngClass]="{
                active:
                  this.auth.companyInfo && this.router.url.includes('reports')
              }"
            >
              <div
                class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin"
                [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
              >
                <i
                  class="icon-workspace-reports gray-text-dark font-14 d-inline"
                ></i>
              </div>
              <p
                [ngClass]="{
                  'ml-3': dataService.isSiteLtr(),
                  'mr-3': dataService.isSiteRtl()
                }"
                class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                i18n="@@menuItemReports"
              >
                Reports
              </p>
            </div>
            <div
              class="d-flex pointer"
              (click)="
                this.router.navigate([
                  '/settings/billing'
                ])
              "
              [ngClass]="{
                active:
                  this.auth.companyInfo && this.router.url.includes('billing')
              }"
            >
              <div
                class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin"
                [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
              >
                <i class="icon-billing gray-text-dark font-18 d-inline"></i>
              </div>
              <p
                [ngClass]="{
                  'ml-3': dataService.isSiteLtr(),
                  'mr-3': dataService.isSiteRtl()
                }"
                class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                i18n="@@menuItemBilling"
              >
                Billing
              </p>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              this.auth.companyInfo && this.auth.companyInfo.inactive === false
            "
          >
            <div
              class="mb-4 pb-4"
              *ngIf="
                this.auth.isManager() ||
                this.auth.isAdmin() ||
                this.auth.isOwner()
              "
            >
              <div
                class="d-flex pointer"
                *ngIf="
                  this.auth.isManager() ||
                  this.auth.isAdmin() ||
                  this.auth.isOwner()
                "
                (click)="
                  this.router.navigate([
                     '/approvals'
                  ])
                "
                [ngClass]="{
                  active:
                    this.auth.companyInfo &&
                    this.router.url.includes('approvals')
                }"
              >
                <div
                  class="dashboard-icon-container bg-white text-center my-1 position-relative container-left-margin"
                  [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
                >
                  <i
                    class="icon-workspace-approvals gray-text-dark font-18 d-inline"
                  ></i>
                  <div
                    class="workspace-approvals-badge text-white font-12 text-center"
                    *ngIf="
                      this.auth.isLogedIn &&
                      this.dataService.workspaceApprovalsBadge &&
                      this.dataService.workspaceApprovalsBadge < 10 &&
                      this.dataService.workspaceApprovalsBadge > 0
                    "
                  >
                    {{ this.dataService.workspaceApprovalsBadge }}
                  </div>
                  <div
                    class="workspace-approvals-badge-large text-white px-1 font-12 text-center"
                    *ngIf="
                      this.auth.isLogedIn &&
                      this.dataService.workspaceApprovalsBadge &&
                      this.dataService.workspaceApprovalsBadge >= 10
                    "
                  >
                    {{ this.dataService.workspaceApprovalsBadge }}
                  </div>
                </div>
                <div
                  [ngClass]="{
                    'ml-3': dataService.isSiteLtr(),
                    'mr-3': dataService.isSiteRtl()
                  }"
                  class="py-2 my-1 mb-0 gray-text-dark p-height-30 p-approvals position-relative"
                  [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                >
                  <ng-container i18n="@@menuItemApprovals"
                    >Approvals</ng-container
                  >
                  <div
                    class="workspace-approvals-badge up-text text-white font-12 text-center"
                    *ngIf="
                      this.auth.isLogedIn &&
                      this.dataService.workspaceApprovalsBadge &&
                      this.dataService.workspaceApprovalsBadge < 10 &&
                      this.dataService.workspaceApprovalsBadge > 0
                    "
                  >
                    {{ this.dataService.workspaceApprovalsBadge }}
                  </div>
                  <div
                    class="workspace-approvals-badge-large text-white px-1 font-12 text-center"
                    *ngIf="
                      this.auth.isLogedIn &&
                      this.dataService.workspaceApprovalsBadge &&
                      this.dataService.workspaceApprovalsBadge >= 10
                    "
                  >
                    {{ this.dataService.workspaceApprovalsBadge }}
                  </div>
                </div>
              </div>

              <div
                class="d-flex pointer"
                *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
                (click)="
                  this.router.navigate([
                    '/members'
                  ])
                "
                [ngClass]="{
                  active:
                    this.auth.companyInfo && this.router.url.includes('members')
                }"
              >
                <div
                  class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin"
                  [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
                >
                  <i
                    class="icon-workspace-users-colored gray-text-dark font-16 d-inline"
                  ></i>
                </div>
                <p
                  [ngClass]="{
                    'ml-3': dataService.isSiteLtr(),
                    'mr-3': dataService.isSiteRtl()
                  }"
                  class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                  [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                  i18n="@@menuItemMembers"
                >
                  Manage Members
                </p>
              </div>

              <div
                class="d-flex pointer"
                *ngIf="
                  this.auth.isManager() ||
                  this.auth.isAdmin() ||
                  this.auth.isOwner()
                "
                (click)="
                  this.router.navigate([
                    '/teams'
                  ])
                "
                [ngClass]="{
                  active:
                    this.auth.companyInfo && this.router.url.includes('teams')
                }"
              >
                <div
                  class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin"
                  [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
                >
                  <i
                    class="icon-workspace-left-teams gray-text-dark font-12 d-inline"
                  ></i>
                </div>
                <p
                  [ngClass]="{
                    'ml-3': dataService.isSiteLtr(),
                    'mr-3': dataService.isSiteRtl()
                  }"
                  class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                  [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                  i18n="@@menuItemTeams"
                >
                  Manage Teams
                </p>
              </div>

              <div
                class="d-flex pointer"
                *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
                (click)="
                  this.router.navigate([
                   '/travel-policies'
                  ])
                "
                [ngClass]="{
                  active:
                    this.auth.companyInfo &&
                    this.router.url.includes('travel-policies')
                }"
              >
                <div
                  class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin policies"
                  [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
                >
                  <i class="icon-policies gray-text-dark d-inline"></i>
                </div>
                <p
                  [ngClass]="{
                    'ml-3': dataService.isSiteLtr(),
                    'mr-3': dataService.isSiteRtl()
                  }"
                  class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                  [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                  i18n="@@menuItemPolicies"
                >
                  Travel Policies
                </p>
              </div>

              <div
                class="d-flex pointer"
                *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
                (click)="
                  this.router.navigate([
                    '/reports'
                  ])
                "
                [ngClass]="{
                  active:
                    this.auth.companyInfo && this.router.url.includes('reports')
                }"
              >
                <div
                  class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin"
                  [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
                >
                  <i
                    class="icon-workspace-reports gray-text-dark font-14 d-inline"
                  ></i>
                </div>
                <p
                  [ngClass]="{
                    'ml-3': dataService.isSiteLtr(),
                    'mr-3': dataService.isSiteRtl()
                  }"
                  class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                  [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                  i18n="@@menuItemReports"
                >
                  Reports
                </p>
              </div>
            </div>
            <div
              class="d-flex pointer"
              (click)="this.router.navigate(['/'])"
              [ngClass]="{
                active:
                  this.auth.companyInfo &&
                  this.router.url === '/'
              }"
            >
              <div
                class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin"
                [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
              >
                <i class="icon-book-trip gray-text-dark font-18 d-inline"></i>
              </div>
              <p
                [ngClass]="{
                  'ml-3': dataService.isSiteLtr(),
                  'mr-3': dataService.isSiteRtl()
                }"
                class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                i18n="@@menuItemBookTrip"
              >
                Book a Trip
              </p>
            </div>

            <div
              class="d-flex pointer"
              (click)="
                this.router.navigate([
                   '/user/trips'
                ])
              "
              [ngClass]="{
                active:
                  this.auth.companyInfo &&
                  this.router.url.includes('/user/trips')
              }"
            >
              <div
                class="dashboard-icon-container my-trips bg-white text-center my-1 pointer container-left-margin"
                [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
              >
                <i
                  class="icon-plane flipped font-12 gray-text-dark d-inline"
                ></i>
              </div>
              <p
                [ngClass]="{
                  'ml-3': dataService.isSiteLtr(),
                  'mr-3': dataService.isSiteRtl()
                }"
                class="pt-2 my-1 gray-text-dark mb-0 p-height-30"
                [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                i18n="@@menuItemMyTrips"
              >
                My Trips
              </p>
            </div>

            <div
              class="d-flex pointer"
              (click)="
                this.router.navigate([
                 '/user/favorites'
                ])
              "
              [ngClass]="{
                active:
                  this.auth.companyInfo &&
                  this.router.url.includes('/user/favorites')
              }"
            >
              <div
                class="dashboard-icon-container fav bg-white text-center my-1 pointer container-left-margin"
                [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
              >
                <i
                  class="icon-workspace-fav flipped gray-text-dark font-18 d-inline"
                ></i>
              </div>
              <p
                [ngClass]="{
                  'ml-3': dataService.isSiteLtr(),
                  'mr-3': dataService.isSiteRtl()
                }"
                class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                i18n="@@menuItemFavorites"
              >
                Favorites
              </p>
            </div>

            <div
              class="d-flex pointer"
              (click)="
                this.router.navigate([
                  '/user/requests'
                ])
              "
              [ngClass]="{
                active:
                  this.auth.companyInfo && this.router.url.includes('requests')
              }"
            >
              <div
                class="dashboard-icon-container trip-approval bg-white text-center my-1 pointer container-left-margin"
                [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
              >
                <i
                  class="icon-workspace-approval-requests flipped gray-text-dark font-20 d-inline"
                ></i>
              </div>
              <p
                [ngClass]="{
                  'ml-3': dataService.isSiteLtr(),
                  'mr-3': dataService.isSiteRtl()
                }"
                class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
                i18n="@@menuItemMyApprovals"
              >
                My Trip Approvals
              </p>
            </div>

            <div
              class="d-flex pointer"
              (click)="
                this.router.navigate([
                  '/user/profile'
                ])
              "
              [ngClass]="{
                active:
                  this.auth.companyInfo &&
                  (this.router.url.includes('/user/profile') ||
                    this.router.url.includes('/user/payment') ||
                    this.router.url.includes('/user/rewards'))
              }"
            >
              <div
                class="dashboard-icon-container bg-white text-center my-1 pointer container-left-margin"
                [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
                [ngClass]="{
                  'overflow-hidden p-0 border-0':
                    auth.userInfo &&
                    auth.userInfo.imageUrl &&
                    !this.dataService.showDefaultUserImage
                }"
              >
                <i
                  class="icon-workspace-user flipped gray-text-dark font-16 d-inline"
                  *ngIf="
                    (auth.userInfo && !auth.userInfo.imageUrl) ||
                    this.dataService.showDefaultUserImage
                  "
                ></i>
                <img
                  width="30"
                  height="30"
                  loading="lazy"
                  *ngIf="
                    auth.userInfo &&
                    auth.userInfo.imageUrl &&
                    !this.dataService.showDefaultUserImage
                  "
                  class="user-img initial-height"
                  onError="this.src='assets/img/default-user.png'"
                  [src]="
                    this.dataService.userProfileImageWithRandomText ||
                    auth.userInfo.imageUrl
                  "
                />
              </div>
              <p
                [ngClass]="{
                  'ml-3': dataService.isSiteLtr(),
                  'mr-3': dataService.isSiteRtl()
                }"
                class="py-2 my-1 gray-text-dark mb-0 p-height-30"
                *ngIf="auth.userInfo"
                [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
              >
                {{ auth.userInfo.name }} {{ auth.userInfo.lastName }}
              </p>
            </div>
          </ng-container>
        </div>
      </mat-nav-list>
    </div>
  </div>
  <span class="spacer"></span>
  <ng-container
    *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive === false"
  >
    <div
      class="position-fixed setting pointer mb-3 py-1"
      *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
      (click)="
        this.router.navigate(['/settings'])
      "
      [ngClass]="{
        active:
          this.auth.companyInfo &&
          this.router.url.includes('settings') &&
          !this.router.url.includes('members') &&
          !this.router.url.includes('travel-policies'),
        'expand-width': this.dataService.sideNavState,
        'decrease-width': !this.dataService.sideNavState
      }"
    >
      <div class="d-flex">
        <div
          class="text-center"
          [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
        >
          <i
            class="icon-workspace-settings d-inline"
            [ngClass]="{
              'blue-text':
                this.auth.companyInfo &&
                this.router.url.includes('settings') &&
                !this.router.url.includes('members') &&
                !this.router.url.includes('travel-policies'),
              'gray-text-dark':
                this.auth.companyInfo && !this.router.url.includes('settings')
            }"
          ></i>
        </div>
        <p
          [ngClass]="{
            'ml-3': dataService.isSiteLtr(),
            'mr-3': dataService.isSiteRtl()
          }"
          class="text gray-text-dark mb-0"
          [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
          i18n="@@menuItemSettings"
        >
          Settings
        </p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive">
    <div
      class="position-fixed logout pointer mb-3 py-2"
      (click)="this.auth.logout()"
      [ngClass]="{
        'expand-width': this.dataService.sideNavState,
        'decrease-width': !this.dataService.sideNavState
      }"
    >
      <div class="d-flex">
        <div
          class="text-center"
          [@animateText]="this.dataService.linkText ? 'hide' : 'show'"
        >
          <i class="icon-sign-out d-inline gray-text-dark"></i>
        </div>
        <p
          [ngClass]="{
            'ml-3': dataService.isSiteLtr(),
            'mr-3': dataService.isSiteRtl()
          }"
          class="text gray-text-dark mb-0"
          [@animateText]="this.dataService.linkText ? 'show' : 'hide'"
          i18n="@@logOutBtn"
        >
          Logout
        </p>
      </div>
    </div>
  </ng-container>
</div>
