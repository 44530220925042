import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {AuthServiceMain} from '../../auth/auth.service';
import * as moment from 'moment';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {Route, Router} from '@angular/router';
import {Location} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {FireService} from '../../services/firebase.service';
import 'firebase/remote-config';
import { browserRefresh } from '../../app.component';
@Component({
  selector: 'app-flight-booking',
  templateUrl: './flight-booking.component.html',
  styleUrls: ['./flight-booking.component.scss']
})
export class FlightBookingComponent implements OnInit {
  public browserRefresh: boolean;
  userInfo;
  data;
  params;
  diffInDays;
  PaxesArray;
  PaxesCount;
  summaryDetailsDepart;
  summaryDetailsReturn;
  constructor(public Service: DataService, public Auth: AuthServiceMain, public googleAnalyticsService: GoogleAnalyticsService,
              public router: Router, private location: Location, private titleService: Title, public fireService: FireService) {

    this.Service.componentMethodCalled$.subscribe(
      () => {


        if (this.Service.urlWithoutParams === '/flights/availability/booking' ||
          (this.Auth.isLogedIn && this.Auth.companyInfo && this.Service.urlWithoutParams === '/flights/availability/booking')) {
          //this.Service.clearAllMsgs();
          this.Service.flightRatesDuration('bookingFormExpiry');
          this.Service.validateFlight(false);
        }



      }
    );

  }

  ngOnInit() {
    this.Service.flightRatesExpiry = false;
    this.browserRefresh = browserRefresh;
    this.Service.defultCountry = {};
    this.Service.defultCountry.dial_code = '+1';
    this.Service.defultCountry.code = 'US';
    this.Service.dropDowmBookingForm = false;
    this.Service.flightBookingPage = true;
    if (!sessionStorage.getItem('flightData') && this.Service.isCompany()) {
      this.router.navigate(['/']);
    } else if (!sessionStorage.getItem('flightData') && !this.Service.isCompany()) {
      this.router.navigate(['/']);
    }
    this.Service.flightData = JSON.parse(sessionStorage.getItem('flightData'));
    this.summaryDetailsDepart = this.Service.flightData.details.originDestinationOptionsGroups[0].summary;
    if (this.Service.flightData.trip === 'roundtrip'){
      this.summaryDetailsReturn = this.Service.flightData.details.originDestinationOptionsGroups[1].summary;
    }
    this.Service.getFlightRules(this.Service.flightData.details.fareInfo.fareKey);
    this.Service.flightParams = JSON.parse(sessionStorage.getItem('flightParams'));
    this.PaxesArray = this.Service.flightParams.paxes.split(',');
   // this.PaxesCount = this.PaxesArray.length + Number(this.PaxesArray[0]) - 1;
  //  this.diffInDays =  moment.utc( this.Service.params.checkOut).diff(moment.utc(this.Service.params.checkIn), 'days');
    this.userInfo = this.Auth.userInfo;
   // this.Service.clearAllMsgs();
    this.Service.flightRatesDuration('bookingFormExpiry');
    if (this.browserRefresh) {
      this.Service.validateFlight(true, this.Service.flightData.details.userData.travelerId);
    } else {
      sessionStorage.removeItem('flightBookingFormFields');
    }

  /*  if (this.Service.flightData) {
      this.Service.flightData.myFacilities.map(item =>{
        if (item.featured) {
          this.Service.FacilitiesFeaturedavailabile = true;
        }
      });
    }

    this.fireService.remoteConfig();*/
    this.userInfo = this.Auth.userInfo;
    this.fireService.remoteConfig();
  }

  /*formatDate(date) {
    return  moment(date).format('ddd') + ', ' + moment(date).format('MMM') + ' ' + moment(date).format('DD');
  }*/
 /* refreshPage() {
    location.reload();
  }*/
  showDetails(itemData, tripType) {
    const flightData = {
      details: itemData,
      trip: tripType
    };
    this.Service.setRightSideDrawer('flightDetails', flightData);
  }
}
