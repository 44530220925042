import {Component, ElementRef, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import { SidenavService} from '../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../auth/auth.service';
import {VariablesService} from '../../../services/vars.service';
import {environment} from '../../../../environments/environment';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-flight-approvals-details',
  templateUrl: './flight-approvals-details.component.html',
  styleUrls: ['./flight-approvals-details.component.scss'],
  animations: [ onMainContentChange ],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class FlightApprovalsDetailsComponent implements OnInit {
  
  constructor(private formBuilder: FormBuilder,  private eref: ElementRef, public dataService: DataService, private sidenavService: SidenavService,
              public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  urlArray = this.router.url.split('/');
  approvalId = this.urlArray[this.urlArray.length - 1];
  approvalActions = false;
  data;
  approvalForm: FormGroup;
  // approvalMoreInfoForm: FormGroup;
  submitting;
  submitted;
  errorMsg;
  ngOnInit(): void {
    this.dataService.getFlightApprovalDetailsInfo(this.approvalId);
    this.approvalForm = this.formBuilder.group({
      comment: [''],
    }, {
    });
  }
  get f() { return this.approvalForm.controls; }

  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.approval-drop-down') && !this.eref.nativeElement.querySelector('.approval-drop-down').contains(event.target) ){
      this.approvalActions = false;
    }
  }
  approvalSubmitMoreInfo() {
    this.errorMsg = '';
    this.submitted = true;
    this.submitting = true;
    if (this.approvalForm.controls.comment.value === '') {
      this.errorMsg = $localize`:@@enterMsgError:Please enter you message`;
      this.submitting = false;
      return;
    }
    this.dataService.flightApprovalRequestMoreInfo(this.approvalId, this.approvalForm.controls.comment.value).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.getFlightApprovalDetailsInfo(this.approvalId);
          this.submitting = false;
          this.submitted = false;
          this.approvalForm.get('comment').setValue('');
          window.scrollTo(0, document.body.scrollHeight);
        } else {
          this.dataService.messages(this.data.message, 'error');
          this.submitting = false;
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
        this.submitting = false;
      }
    );
  }
  disapproveFlight(approvalIdValue, requesterNameValue) {
    let commentAndReasonValue = '';
    if (this.approvalForm.controls.comment.value) {
      commentAndReasonValue += ('&commment=' + this.approvalForm.controls.comment.value);
    }
    const disapprovalData = {
      approvalId: approvalIdValue,
      requesterName: requesterNameValue,
      commentAndReason: commentAndReasonValue,
      isFlight: true,
      pageType: 'details'};
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@declineTrip:Decline trip`, 'tripDisapproval', disapprovalData);
    }
  }
  approveFlight(approvalIdValue, sellingTotalFaresValue) {
    let ignoreRatePriceChangeValue = '&ignoreRatePriceChange=false';
    if (this.approvalForm.controls.comment.value) {
      ignoreRatePriceChangeValue += ('&commment=' + this.approvalForm.controls.comment.value);
    }
    const approvalData = {
      approvalId: approvalIdValue,
      ignoreRatePriceChange: ignoreRatePriceChangeValue,
      sellingTotalFares: sellingTotalFaresValue,
      isFlight: true,
      pageType: 'details'};
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@tripApproval:Trip Approval`, 'tripApproval', approvalData);
    } else {
      this.dataService.setBottomSheet('tripApproval', approvalData);
    }
  }
/*  formatDate(date) {
    return moment(date).format('ddd') + ' ' + moment(date).format('MMM')
      + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }*/
  showDetails(itemData, tripType) {
    let tripVal = 'oneway';
    if (tripType) {
      tripVal = 'roundtrip';
    }
    const flightData = {
      details: itemData,
      trip: tripVal,
      approvalDetails: true
    };
    this.dataService.setRightSideDrawer('flightDetails', flightData);
  }
  showApprovalsMobileActions(approvalIdValue, sellingTotalFaresValue, requesterNameValue){

    let ignoreRatePriceChangeValue = '&ignoreRatePriceChange=false';
    if (this.approvalForm.controls.comment.value) {
      ignoreRatePriceChangeValue += ('&commment=' + this.approvalForm.controls.comment.value);
    }
    const approvalData = {
      approvalId: approvalIdValue,
      ignoreRatePriceChange: ignoreRatePriceChangeValue,
      sellingTotalFares: sellingTotalFaresValue,
      isFlight: true,
      pageType: 'details'};


    let commentAndReasonValue = '';
    if (this.approvalForm.controls.comment.value) {
      commentAndReasonValue += ('&commment=' + this.approvalForm.controls.comment.value);
    }
    const disapprovalData = {
      approvalId: approvalIdValue,
      requesterName: requesterNameValue,
      commentAndReason: commentAndReasonValue,
      isFlight: true,
      pageType: 'details'};



    const comment = this.approvalForm.controls.comment.value;
    const bothApprovalDisapprovalObj = {
      disapprovalObj: disapprovalData,
      approvalObj: approvalData,
      commentValue: comment,
      approvalId: approvalIdValue
    };
    this.dataService.errorMsg = '';
    this.dataService.setBottomSheet('bothApprovalDisapproval', bothApprovalDisapprovalObj);


  }
}
