<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="approvals-list container pt-4 pt-lg-5">
        <div class="row mx-lg-4">
          <div class="col-12 d-flex">
            <div
              class="font-24 gray-primaray-text bold-600"
              i18n="@@menuItemApprovals"
            >
              Approvals
            </div>
            <div
              *ngIf="dataService.mobileView"
              class="pt-3"
              [ngClass]="{
                'ml-auto': dataService.isSiteLtr(),
                'mr-auto': dataService.isSiteRtl()
              }"
            >
              <i
                class="icon-refresh-list font-18 pointer"
                (click)="refreshList()"
              ></i>
            </div>
          </div>
        </div>
        <div class="mx-0 mx-lg-3 pt-4">
          <div
            class="row mx-lg-2 pt-2"
            *ngIf="
              dataService.approvalsList &&
              !dataService.approvalsList.length &&
              dataService.approvalsFlightList &&
              !dataService.approvalsFlightList
            "
          >
            <div class="col-12">
              <p class="gray-text-dark font-18 mb-0" i18n="@@approvalsListDesc">
                Travel approval requests will be listed here. You can view trip
                details, approve/decline travel requests or ask for more info.
                Only trips that require approvals will be list here.
              </p>
            </div>
          </div>
          <div
            class="tabs-section-approvals row mx-lg-4 font-16 filtering gray-text mt-5 mb-lg-5"
            *ngIf="dataService.desktopView"
          >
            <div
              class="col-auto px-5 pb-3 pt-4 pointer"
              [ngClass]="{
                active: this.dataService.sortType === 'pending_approval',
                'd-block prevent-pointer-event':
                  this.dataService.hotellistLoader
              }"
              (click)="filterBy('pending_approval')"
            >
              <span class="pb-2 px-2"
                ><ng-container i18n="@@pending">Pending</ng-container>
                <span
                  [ngClass]="{
                    'ml-2': dataService.isSiteLtr(),
                    'mr-2': dataService.isSiteRtl()
                  }"
                  class="workspace-approvals-pending smaller"
                  *ngIf="
                    this.auth.isLogedIn &&
                    this.dataService.workspaceApprovalsBadge &&
                    this.dataService.workspaceApprovalsBadge > 0
                  "
                  >{{ this.dataService.workspaceApprovalsBadge }}</span
                ></span
              >
            </div>
            <div
              class="col-auto px-5 pb-3 pt-4 pointer"
              [ngClass]="{
                active: this.dataService.sortType === 'approved',
                'd-block prevent-pointer-event':
                  this.dataService.hotellistLoader
              }"
              (click)="filterBy('approved')"
            >
              <span class="pb-2 px-2"
                ><ng-container i18n="@@approved">Approved</ng-container></span
              >
            </div>
            <div
              class="col-auto px-5 pb-3 pt-4 pointer"
              [ngClass]="{
                active: this.dataService.sortType === 'disapproved',
                'd-block prevent-pointer-event':
                  this.dataService.hotellistLoader
              }"
              (click)="filterBy('disapproved')"
            >
              <span class="pb-2 px-2"
                ><ng-container i18n="@@declined">Declined</ng-container></span
              >
            </div>
            <div
              class="col-auto px-5 pb-3 pt-4 pointer"
              [ngClass]="{
                active: this.dataService.sortType === 'expired',
                'd-block prevent-pointer-event':
                  this.dataService.hotellistLoader
              }"
              (click)="filterBy('expired')"
            >
              <span class="pb-2 px-2"
                ><ng-container i18n="@@expired">Expired</ng-container></span
              >
            </div>
            <div
              class="pt-4 mt-1 position-relative"
              [ngClass]="{
                'ml-auto': dataService.isSiteLtr(),
                'mr-auto': dataService.isSiteRtl()
              }"
            >
              <i
                class="icon-refresh-list pointer position-absolute font-18"
                (click)="refreshList(this.dataService.sortType)"
              ></i>
            </div>
          </div>
          <div
            class="d-flex overflow-auto font-weight-bold blue-text pb-4"
            *ngIf="dataService.mobileView"
          >
            <div
              [ngClass]="{
                'pr-3 border-right': dataService.isSiteLtr(),
                'pl-3 border-left': dataService.isSiteRtl(),
                'gray-primaray-text':
                  this.dataService.sortType === 'pending_approval'
              }"
              (click)="filterBy('pending_approval')"
            >
              <span
                ><ng-container i18n="@@pending">Pending</ng-container
                ><span
                  class="workspace-approvals-pending font-12"
                  [ngClass]="{
                    'ml-2': dataService.isSiteLtr(),
                    'mr-2': dataService.isSiteRtl()
                  }"
                  *ngIf="
                    this.auth.isLogedIn &&
                    this.dataService.workspaceApprovalsBadge &&
                    this.dataService.workspaceApprovalsBadge > 0
                  "
                  >{{ this.dataService.workspaceApprovalsBadge }}</span
                ></span
              >
            </div>
            <div
              class="px-3 text-center"
              [ngClass]="{
                'border-right': dataService.isSiteLtr(),
                'border-left': dataService.isSiteRtl(),
                'gray-primaray-text': this.dataService.sortType === 'approved'
              }"
              (click)="filterBy('approved')"
            >
              <span i18n="@@approved">Approved</span>
            </div>
            <div
              class="px-3"
              [ngClass]="{
                'text-right border-right': dataService.isSiteLtr(),
                'text-left border-left': dataService.isSiteRtl(),
                'gray-primaray-text':
                  this.dataService.sortType === 'disapproved'
              }"
              (click)="filterBy('disapproved')"
            >
              <span i18n="@@declined">Declined</span>
            </div>
            <div
              [ngClass]="{
                'text-right pl-3': dataService.isSiteLtr(),
                'text-left pr-3': dataService.isSiteRtl(),
                'gray-primaray-text': this.dataService.sortType === 'expired'
              }"
              (click)="filterBy('expired')"
            >
              <span i18n="@@expired">Expired</span>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            !this.dataService.filteredApprovalsList ||
            !this.dataService.filteredApprovalsFlightList ||
            this.dataService.hotellistLoader
          "
          class="loading_icon_caption text-center mt-5"
        ></div>
        <div
          *ngIf="
            !this.dataService.filteredApprovalsList ||
            !this.dataService.filteredApprovalsFlightList ||
            this.dataService.hotellistLoader
          "
          class="loading-message text-center"
          i18n="@@loading"
        >
          Loading
        </div>
        <div
          *ngIf="
            ((this.dataService.filteredApprovalsList &&
              this.dataService.filteredApprovalsList.length > 0) ||
              (this.dataService.filteredApprovalsFlightList &&
                this.dataService.filteredApprovalsFlightList.length > 0)) &&
            !this.dataService.hotellistLoader
          "
          class="mx-lg-3 mb-5"
        >
          <div
            *ngFor="
              let item of this.dataService.filteredApprovalsList;
              let i = index
            "
          >
            <div
              class="row px-3 mx-0 mx-lg-4 py-3 position-relative pointer hover-row-list"
              (click)="this.router.navigate([this.router.url + '/' + item.id])"
              [ngClass]="{
                'row-max-width': dataService.sideNavState,
                'border-bottom': dataService.desktopView,
                'rounded border mb-4': dataService.mobileView
              }"
            >
              <div
                class="col-lg-3"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <div class="d-flex">
                  <img
                    width="35"
                    height="35"
                    loading="lazy"
                    *ngIf="item.requesterProfileImageUrl"
                    class="user-img d-inline-block"
                    [ngClass]="{
                      'mr-2': dataService.isSiteLtr(),
                      'ml-2': dataService.isSiteRtl()
                    }"
                    onError="this.src='assets/img/default-user.png'"
                    [src]="item.requesterProfileImageUrl"
                  />
                  <div
                    class="name-first-letters small"
                    [ngClass]="{
                      'mr-3': dataService.isSiteLtr(),
                      'ml-3': dataService.isSiteRtl()
                    }"
                    *ngIf="!item.requesterProfileImageUrl"
                    [ngStyle]="{
                      'background-color':
                        item.requesterProfileImageBackgroundHexColor
                    }"
                  >
                    {{ item.requesterFirstname.substring(0, 1).toUpperCase()
                    }}{{ item.requesterLastname.substring(0, 1).toUpperCase() }}
                  </div>
                  <div class="dotted-text min-height-dotted-div">
                    <p class="pb-1 mb-0 dotted-text dotted-text-direction">
                      <span class="pr-2" *ngIf="item.requesterFirstname">{{
                        item.requesterFirstname
                      }}</span>
                      <span *ngIf="item.requesterLastname">{{
                        item.requesterLastname
                      }}</span>
                    </p>
                    <div class="d-flex">
                      <p
                        class="gray-text-dark font-12 mb-0 dotted-text dotted-text-direction text-bg py-2 px-3 d-inline"
                        *ngIf="item.policy && item.policy.name"
                      >
                        {{ item.policy.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p
                  class="gray-primaray-text mb-0 mb-lg-2 dotted-text dotted-text-direction mt-4 mt-lg-0"
                >
                  {{
                    item.bookingInfo.hotel.cityName
                      ? item.bookingInfo.hotel.cityName
                      : ""
                  }}{{
                    item.bookingInfo.hotel.stateCode &&
                    item.bookingInfo.hotel.cityName
                      ? ", "
                      : ""
                  }}{{
                    item.bookingInfo.hotel.stateCode
                      ? item.bookingInfo.hotel.stateCode
                      : ""
                  }}
                </p>
                <p class="gray-text mb-0 dotted-text dotted-text-direction">
                  {{ item.bookingInfo.hotel.countryName }}
                </p>
              </div>
              <div
                class="col-lg-3"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p
                  class="gray-primaray-text mb-0 dotted-text dotted-text-direction pb-0 pb-lg-2 mt-4 mt-lg-0"
                >
                  {{ item.bookingInfo.hotel.name }}
                </p>
                <p class="gray-text mb-0">
                  <span
                    class="d-inline-block"
                    [ngClass]="{
                      'mr-1': dataService.isSiteLtr(),
                      'ml-1': dataService.isSiteRtl()
                    }"
                  >
                    <span
                      class="night-cont"
                      *ngIf="
                        diffInDays(
                          item.bookingInfo.searchRequest.checkOut,
                          item.bookingInfo.searchRequest.checkIn
                        ) > 1;
                        else Night
                      "
                    >
                      <ng-container
                        *ngIf="
                          diffInDays(
                            item.bookingInfo.searchRequest.checkOut,
                            item.bookingInfo.searchRequest.checkIn
                          ) > 2
                        "
                      >
                        {{
                          diffInDays(
                            item.bookingInfo.searchRequest.checkOut,
                            item.bookingInfo.searchRequest.checkIn
                          )
                        }}
                        <ng-container i18n="@@nights"
                          >nights</ng-container
                        ></ng-container
                      >
                      <ng-container
                        i18n="@@twoNights"
                        *ngIf="
                          diffInDays(
                            item.bookingInfo.searchRequest.checkOut,
                            item.bookingInfo.searchRequest.checkIn
                          ) === 2
                        "
                      >
                        2 nights</ng-container
                      >
                    </span>

                    <span class="night-cont">
                      <ng-template #Night>
                        <ng-container i18n="@@night">1 night</ng-container>
                      </ng-template>
                    </span>
                  </span>
                </p>
                <p class="gray-text mb-0">
                  <span
                    *ngIf="
                      item.bookingInfo.hotel.lowestSellingPriceRate.refundable
                    "
                    i18n="@@refundable"
                    >Refundable</span
                  >
                  <span
                    *ngIf="
                      !item.bookingInfo.hotel.lowestSellingPriceRate.refundable
                    "
                    i18n="@@nonrefundable"
                    >Non Refundable</span
                  >
                </p>
              </div>
              <div
                class="col-lg-2"
                [ngClass]="{
                  'pl-0': dataService.mobileView && dataService.isSiteLtr(),
                  'pr-0': dataService.mobileView && dataService.isSiteRtl()
                }"
              >
                <p class="gray-primaray-text mb-0 pb-0 pb-lg-4 mt-4 mt-lg-0">
                  {{item.bookingInfo.hotel.rooms[0].rates[0]
                        .averageNightlySellingPriceConvertedCurrency}} {{
                  item.bookingInfo.hotel.rooms[0].rates[0]
                          .averageNightlySellingPriceConverted | number : "1.0-0"
                  }}<ng-container i18n="@@slashNight">/night</ng-container>
                </p>
                <p
                  class="gray-text mb-0 text-nowrap"
                  *ngIf="item.limitExceeded"
                >
                  <ng-container i18n="@@outOfPolicy"
                    >Out of policy</ng-container
                  >
                  <i
                    class="icon-warning-msg yellow-text"
                    [ngClass]="{
                      'ml-2': dataService.isSiteLtr(),
                      'mr-2': dataService.isSiteRtl()
                    }"
                  ></i>
                </p>
                <p
                  class="gray-text mb-0 text-nowrap"
                  *ngIf="!item.limitExceeded"
                  i18n="@@inPolicy"
                >
                  In-policy
                </p>
              </div>
              <div
                class="col-lg-1 pt-4"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p class="action-hide-show"></p>
                <div class="d-flex">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <i
                      class="approval-drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                      [ngClass]="{ 'hotel-mobile': dataService.mobileView }"
                      (click)="
                        $event.stopPropagation();
                        approvalActions = !approvalActions;
                        selectedIndex = i
                      "
                    ></i>
                    <div
                      class="border shadow font-14 position-absolute bg-white drop"
                      [ngClass]="{
                        'hotel-mobile-drop': dataService.mobileView
                      }"
                      *ngIf="approvalActions && i === selectedIndex"
                    >
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="
                          this.router.navigate([
                            this.router.url + '/' + item.id
                          ])
                        "
                        i18n="@@view"
                      >
                        View
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        *ngIf="item.status.toLowerCase() == 'pending_approval'"
                        (click)="
                          $event.stopPropagation();
                          approve(
                            item.id,
                            item.bookingInfo.hotel.rooms[0].rates[0]
                              .finalSellingPriceConvertedCurrency
                          )
                        "
                        i18n="@@approve"
                      >
                        Approve
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        *ngIf="item.status.toLowerCase() == 'pending_approval'"
                        (click)="
                          $event.stopPropagation();
                          disapprove(
                            item.id,
                            item.requesterFirstname +
                              ' ' +
                              item.requesterLastname
                          )
                        "
                        i18n="@@decline"
                      >
                        Decline
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngFor="
              let item of this.dataService.filteredApprovalsFlightList;
              let i = index
            "
          >
            <div
              class="row px-3 mx-0 mx-lg-4 py-3 position-relative pointer hover-row-list"
              (click)="
                this.router.navigate([
                    '/flights/approvals/' +
                    item.id
                ])
              "
              [ngClass]="{
                'row-max-width': dataService.sideNavState,
                'border-bottom': dataService.desktopView,
                'rounded border mb-4': dataService.mobileView
              }"
            >
              <div
                class="col-md-3"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <div class="d-flex">
                  <img
                    width="35"
                    height="35"
                    loading="lazy"
                    *ngIf="item.requesterProfileImageUrl"
                    class="user-img d-inline-block"
                    [ngClass]="{
                      'mr-2': dataService.isSiteLtr(),
                      'ml-2': dataService.isSiteRtl()
                    }"
                    onError="this.src='assets/img/default-user.png'"
                    [src]="item.requesterProfileImageUrl"
                  />
                  <div
                    class="name-first-letters small"
                    [ngClass]="{
                      'mr-3': dataService.isSiteLtr(),
                      'ml-3': dataService.isSiteRtl()
                    }"
                    *ngIf="!item.requesterProfileImageUrl"
                    [ngStyle]="{
                      'background-color':
                        item.requesterProfileImageBackgroundHexColor
                    }"
                  >
                    {{ item.requesterFirstname.substring(0, 1).toUpperCase()
                    }}{{ item.requesterLastname.substring(0, 1).toUpperCase() }}
                  </div>
                  <div class="dotted-text min-height-dotted-div">
                    <p class="pb-1 mb-0 dotted-text dotted-text-direction">
                      <span class="pr-2" *ngIf="item.requesterFirstname">{{
                        item.requesterFirstname
                      }}</span>
                      <span *ngIf="item.requesterLastname">{{
                        item.requesterLastname
                      }}</span>
                    </p>
                    <div class="d-flex">
                      <p
                        class="gray-text-dark font-12 mb-0 dotted-text dotted-text-direction text-bg py-2 px-3 d-inline"
                        *ngIf="item.policy && item.policy.name"
                      >
                        {{ item.policy.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3"></div>
              <div
                class="col-md-3"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <div
                  class="d-flex gray-primaray-text mb-0 pb-0 pb-lg-2 mt-4 mt-lg-0"
                >
                  <ng-container i18n="@@flight">Flight</ng-container>&nbsp;
                  <p class="dotted-text dotted-text-direction">
                    ({{
                      item.airItineraryValidationResponse.airItinerary
                        .originDestinationOptionsGroups[0].summary
                        .departureAirport.city
                    }}
                    -
                    {{
                      item.airItineraryValidationResponse.airItinerary
                        .originDestinationOptionsGroups[0].summary
                        .arrivalAirport.city
                    }}<span
                      *ngIf="
                        item.approvalRequest.bookRequest.searchRequest.roundTrip
                      "
                    >
                      -
                      {{
                        item.airItineraryValidationResponse.airItinerary
                          .originDestinationOptionsGroups[1].summary
                          .arrivalAirport.city
                      }}</span
                    >)
                  </p>
                </div>
                <p class="gray-text mb-0">
                  {{
                    item.approvalRequest.bookRequest.searchRequest.roundTrip
                      ? dataService.roundTrip
                      : dataService.oneWay
                  }}
                </p>
              </div>
              <div
                class="col-md-2"
                [ngClass]="{ 'pl-0': dataService.mobileView }"
              >
                <p class="gray-primaray-text mb-0 pb-0 pb-lg-2 mt-4 mt-lg-0">
                  ${{
                    item.airItineraryValidationResponse.airItinerary.fareInfo
                      .sellingTotalFares.total.amount | number : "1.0-0"
                  }}
                </p>
                <p
                  class="gray-text mb-0 text-nowrap"
                  *ngIf="item.limitExceeded"
                >
                  <ng-container i18n="@@outOfPolicy"
                    >Out of policy</ng-container
                  >
                  <i
                    class="icon-warning-msg yellow-text"
                    [ngClass]="{
                      'ml-2': dataService.isSiteLtr(),
                      'mr-2': dataService.isSiteRtl()
                    }"
                  ></i>
                </p>
                <p
                  class="gray-text mb-0 text-nowrap"
                  *ngIf="!item.limitExceeded"
                >
                  <ng-container i18n="@@inPolicy">In-policy</ng-container>
                  <i
                    class="icon-warning-msg green-text"
                    [ngClass]="{
                      'ml-2': dataService.isSiteLtr(),
                      'mr-2': dataService.isSiteRtl()
                    }"
                  ></i>
                </p>
              </div>
              <div
                class="col-md-1 pt-4"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p class="action-hide-show"></p>
                <div class="d-flex">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <i
                      class="flight-approval-drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                      (click)="
                        $event.stopPropagation();
                        flightApprovalActions = !flightApprovalActions;
                        selectedIndexFlight = i
                      "
                    ></i>
                    <div
                      class="border shadow font-14 position-absolute bg-white drop"
                      *ngIf="flightApprovalActions && i === selectedIndexFlight"
                    >
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="
                          this.router.navigate([
                              '/flights/approvals/' +
                              item.id
                          ])
                        "
                        i18n="@@view"
                      >
                        View
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        *ngIf="item.status.toLowerCase() == 'pending_approval'"
                        (click)="
                          $event.stopPropagation();
                          approveFlight(
                            item.id,
                            item.airItineraryValidationResponse.airItinerary
                              .fareInfo.sellingTotalFares.total.amount
                          )
                        "
                        i18n="@@approve"
                      >
                        Approve
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        *ngIf="item.status.toLowerCase() == 'pending_approval'"
                        (click)="
                          $event.stopPropagation();
                          disapproveFlight(
                            item.id,
                            item.requesterFirstname +
                              ' ' +
                              item.requesterLastname
                          )
                        "
                        i18n="@@decline"
                      >
                        Decline
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row mx-lg-4 px-3 pt-3"
          *ngIf="!this.dataService.hotellistLoader"
        >
          <div
            class="col px-0"
            *ngIf="
              this.dataService.pendingArray &&
              !this.dataService.pendingArray.length &&
              this.dataService.pendingArrayFlight &&
              !this.dataService.pendingArrayFlight.length &&
              this.dataService.sortType === 'pending_approval'
            "
            i18n="@@approvalListMsg1"
          >
            You do not have any pending approvals
          </div>
          <div
            class="col px-0"
            *ngIf="
              this.dataService.approvedArray &&
              !this.dataService.approvedArray.length &&
              this.dataService.approvedArrayFlight &&
              !this.dataService.approvedArrayFlight.length &&
              this.dataService.sortType === 'approved'
            "
            i18n="@@approvalListMsg2"
          >
            You still did not approve any request
          </div>
          <div
            class="col px-0"
            *ngIf="
              this.dataService.disapprovedArray &&
              !this.dataService.disapprovedArray.length &&
              this.dataService.disapprovedArrayFlight &&
              !this.dataService.disapprovedArrayFlight.length &&
              this.dataService.sortType === 'disapproved'
            "
            i18n="@@approvalListMsg3"
          >
            You still did not decline any request
          </div>
          <div
            class="col px-0"
            *ngIf="
              this.dataService.expiredArray &&
              !this.dataService.expiredArray.length &&
              this.dataService.expiredArrayFlight &&
              !this.dataService.expiredArrayFlight.length &&
              this.dataService.sortType === 'expired'
            "
            i18n="@@approvalListMsg4"
          >
            You do not have any expired request
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
