import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { SidenavService } from '../../../workspace-dashboard/sidenav.service';
import { onMainContentChange } from '../../../workspace-dashboard/animations/animations';
import { Router } from '@angular/router';
import { VariablesService } from '../../../../services/vars.service';
import { environment } from '../../../../../environments/environment';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthServiceMain } from '../../../../auth/auth.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss'],
  animations: [onMainContentChange]
})
export class RequestDetailsComponent implements OnInit {

  constructor(private titleService: Title, private formBuilder: FormBuilder, public dataService: DataService, private sidenavService: SidenavService,
    public router: Router, public vars: VariablesService, public auth: AuthServiceMain) {
    this.titleService.setTitle('My travel request details | SafarHub');
    this.sidenavService.sideNavState$.subscribe(res => {
      this.dataService.onSideNavChange = res;
    });
  }
  urlArray = this.router.url.split('/');
  approvalId = this.urlArray[this.urlArray.length - 1];
  data;
  requestMoreInfoForm: FormGroup;
  submitting;
  submitted;
  ngOnInit(): void {
    this.dataService.getApprovalDetailsInfo(this.approvalId);
    this.requestMoreInfoForm = this.formBuilder.group({
      moreComment: ['', [Validators.required]],
    }, {
    });
  }
  get c() { return this.requestMoreInfoForm.controls; }
  diffInDays(checkOut, checkIn) {
    return moment.utc(checkOut).diff(moment.utc(checkIn), 'days');
  }
  formatDate(date) {
    return '(' + moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY') + ')';
  }
  requestSubmitMoreInfo() {
    this.submitted = true;
    this.submitting = true;
    if (this.requestMoreInfoForm.invalid) {
      this.submitting = false;
      return;
    }
    this.dataService.approvalSubmitMoreInfo(this.approvalId, this.requestMoreInfoForm.controls.moreComment.value).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.getApprovalDetailsInfo(this.approvalId);
          this.submitting = false;
          this.submitted = false;
          this.requestMoreInfoForm.get('moreComment').setValue('');
        } else {
          this.dataService.messages(this.data.message, 'error');
          this.submitting = false;
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
        this.submitting = false;
      }
    );
  }
  withdrawApprovalRequestPopup(approvalId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@withdrawApprovalRequest:Withdraw Approval Request`, 'withdrawApprovalRequest', approvalId);
    } else {
      this.dataService.setBottomSheet('withdrawApprovalRequest', approvalId);
    }
  }
  /* formatDailyDate(date) {
     return moment(date).format('ddd') + ' ' + moment(date).format('MMM')
       + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
   }*/
}
