import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

    LIGHT_PURPLE = '#8859E0';
    LIGHT_GREEN = '#21BC87';
    BLUE = '#5AA4E1';
    DARK_PURPLE = '#2F3467';
    DARK_GREEN = '#469770';
    YELLOW = '#F2C94C';
    RED = '#EB5757';
    ORANGE = '#F2994A';
    chartColorsArray = [this.LIGHT_PURPLE, this.LIGHT_GREEN, this.BLUE, this.DARK_PURPLE, this.DARK_GREEN, this.YELLOW, this.RED, this.ORANGE];
  classType: any = [
    {value: 'ECONOMY', name: $localize`:@@economy:Economy`},
    {value: 'PREMIUM_ECONOMY', name: $localize`:@@premEconomy:Premium Economy`},
    {value: 'BUSINESS', name: $localize`:@@businessClass:Business Class`},
    {value: 'FIRST', name: $localize`:@@firstClass:First Class`}
  ];
    intervals: any = [
        {value: 'MONTHLY', name: $localize`:@@monthly:Monthly`},
        {value: 'YEARLY', name: $localize`:@@annually:Annually`}
    ];
  industries = [
      {id: 'Accounting', name: $localize`:@@industry1:Accounting`},
      {id: 'Airlines/Aviation', name: $localize`:@@industry2:Airlines/Aviation`},
      {id: 'Alternative Dispute Resolution', name: $localize`:@@industry3:Alternative Dispute Resolution`},
      {id: 'Alternative Medicine', name: $localize`:@@industry4:Alternative Medicine`},
      {id: 'Animation', name: $localize`:@@industry5:Animation`},
      {id: 'Apparel and Fashion', name: $localize`:@@industry6:Apparel & Fashion`},
      {id: 'Architecture and Planning', name: $localize`:@@industry7:Architecture & Planning`},
      {id: 'Arts and Crafts', name: $localize`:@@industry8:Arts and Crafts`},
      {id: 'Automotive', name: $localize`:@@industry9:Automotive`},
      {id: 'Aviation and Aerospace', name: $localize`:@@industry10:Aviation Aerospace`},
      {id: 'Banking', name: $localize`:@@industry11:Banking`},
      {id: 'Biotechnology', name: $localize`:@@industry12:Biotechnology`},
      {id: 'Broadcast Media', name: $localize`:@@industry13:Broadcast Media`},
      {id: 'Building Materials', name: $localize`:@@industry14:Building Materials`},
      {id: 'Business Supplies and Equipment', name: $localize`:@@industry15:Business Supplies and Equipment`},
      {id: 'Capital Markets', name: $localize`:@@industry16:Capital Markets`},
      {id: 'Chemicals', name: $localize`:@@industry17:Chemicals`},
      {id: 'Civic and Social Organization', name: $localize`:@@industry18:Civic & Social Organization`},
      {id: 'Civil Engineering', name: $localize`:@@industry19:Civil Engineering`},
      {id: 'Commercial Real Estate', name: $localize`:@@industry20:Commercial Real Estate`},
      {id: 'Computer and Network Security', name: $localize`:@@industry21:Computer & Network Security`},
      {id: 'Computer Games', name: $localize`:@@industry22:Computer Games`},
      {id: 'Computer Hardware', name: $localize`:@@industry23:Computer Hardware`},
      {id: 'Computer Networking', name: $localize`:@@industry24:Computer Networking`},
      {id: 'Computer Software', name: $localize`:@@industry25:Computer Software`},
      {id: 'Internet', name: $localize`:@@industry26:Internet`},
      {id: 'Construction', name: $localize`:@@industry27:Construction`},
      {id: 'Consumer Electronics', name: $localize`:@@industry28:Consumer Electronics`},
      {id: 'Consumer Goods', name: $localize`:@@industry29:Consumer Goods`},
      {id: 'Consumer Services', name: $localize`:@@industry30:Consumer Services`},
      {id: 'Cosmetics', name: $localize`:@@industry31:Cosmetics`},
      {id: 'Dairy', name: $localize`:@@industry32:Dairy`},
      {id: 'Defense and Space', name: $localize`:@@industry33:Defense & Space`},
      {id: 'Design', name: $localize`:@@industry34:Design`},
      {id: 'Education Management', name: $localize`:@@industry35:Education Management`},
      {id: 'E-Learning', name: $localize`:@@industry36:E-Learning`},
      {id: 'Electrical/Electronic Manufacturing', name: $localize`:@@industry37:Electrical/Electronic Manufacturing`},
      {id: 'Entertainment', name: $localize`:@@industry38:Entertainment`},
      {id: 'Environmental Services', name: $localize`:@@industry39:Environmental Services`},
      {id: 'Events Services', name: $localize`:@@industry40:Events Services`},
      {id: 'Executive Office', name: $localize`:@@industry41:Executive Office`},
      {id: 'Facilities Services', name: $localize`:@@42:Facilities Services`},
      {id: 'Farming', name: $localize`:@@industry43:Farming`},
      {id: 'Financial Services', name: $localize`:@@industry44:Financial Services`},
      {id: 'Fine Art', name: $localize`:@@industry45:Fine Art`},
      {id: 'Fishery', name: $localize`:@@industry46:Fishery`},
      {id: 'Food and Beverages', name: $localize`:@@industry47:Food & Beverages`},
      {id: 'Food Production', name: $localize`:@@industry48:Food Production`},
      {id: 'Fund-Raising', name: $localize`:@@industry49:Fund-Raising`},
      {id: 'Furniture', name: $localize`:@@industry50:Furniture`},
      {id: 'Gambling and Casinos', name: $localize`:@@industry51:Gambling & Casinos`},
      {id: 'Glass, Ceramics and Concrete', name: $localize`:@@industry52:Glass, Ceramics & Concrete`},
      {id: 'Government Administration', name: $localize`:@@industry53:Government Administration`},
      {id: 'Government Relations', name: $localize`:@@industry54:Government Relations`},
      {id: 'Graphic Design', name: $localize`:@@industry55:Graphic Design`},
      {id: 'Health, Wellness and Fitness', name: $localize`:@@industry56:Health, Wellness and Fitness`},
      {id: 'Higher Education', name: $localize`:@@industry57:Higher Education`},
      {id: 'Hospital and Health Care', name: $localize`:@@industry58:Hospital & Health Care`},
      {id: 'Hospitality', name: $localize`:@@industry59:Hospitality`},
      {id: 'Human Resources', name: $localize`:@@industry60:Human Resources`},
      {id: 'Import and Export', name: $localize`:@@industry61:Import and Export`},
      {id: 'Individual and Family Services', name: $localize`:@@industry62:Individual & Family Services`},
      {id: 'Industrial Automation', name: $localize`:@@industry63:Industrial Automation`},
      {id: 'Information Services', name: $localize`:@@industry64:Information Services`},
      {id: 'Information Technology and Services', name: $localize`:@@industry65:Information Technology and Services`},
      {id: 'Insurance', name: $localize`:@@industry66:Insurance`},
      {id: 'International Affairs', name: $localize`:@@industry67:International Affairs`},
      {id: 'International Trade and Development', name: $localize`:@@industry68:International Trade and Development`},
      {id: 'Investment Banking', name: $localize`:@@industry69:Investment Banking`},
      {id: 'Investment Management', name: $localize`:@@industry70:Investment Management`},
      {id: 'Judiciary', name: $localize`:@@industry71:Judiciary`},
      {id: 'Law Enforcement', name: $localize`:@@industry72:Law Enforcement`},
      {id: 'Law Practice', name: $localize`:@@industry73:Law Practice`},
      {id: 'Legal Services', name: $localize`:@@industry74:Legal Services`},
      {id: 'Legislative Office', name: $localize`:@@industry75:Legislative Office`},
      {id: 'Leisure, Travel and Tourism', name: $localize`:@@industry76:Leisure, Travel & Tourism`},
      {id: 'Libraries', name: $localize`:@@industry77:Libraries`},
      {id: 'Logistics and Supply Chain', name: $localize`:@@industry78:Logistics and Supply Chain`},
      {id: 'Luxury Goods and Jewelry', name: $localize`:@@industry79:Luxury Goods & Jewelry`},
      {id: 'Machinery', name: $localize`:@@industry80:Machinery`},
      {id: 'Management Consulting', name: $localize`:@@industry81:Management Consulting`},
      {id: 'Maritime', name: $localize`:@@industry82:Maritime`},
      {id: 'Market Research', name: $localize`:@@industry83:Market Research`},
      {id: 'Marketing and Advertising', name: $localize`:@@industry84:Marketing and Advertising`},
      {id: 'Mechanical or Industrial Engineering', name: $localize`:@@industry85:Mechanical or Industrial Engineering`},
      {id: 'Media Production', name: $localize`:@@industry86:Media Production`},
      {id: 'Medical Devices', name: $localize`:@@industry87:Medical Devices`},
      {id: 'Medical Practice', name: $localize`:@@industry88:Medical Practice`},
      {id: 'Mental Health Care', name: $localize`:@@industry89:Mental Health Care`},
      {id: 'Military', name: $localize`:@@industry90:Military`},
      {id: 'Mining and Metals', name: $localize`:@@industry91:Mining & Metals`},
      {id: 'Motion Pictures and Film', name: $localize`:@@industry92:Motion Pictures and Film`},
      {id: 'Museums and Institutions', name: $localize`:@@industry93:Museums and Institutions`},
      {id: 'Music', name: $localize`:@@industry94:Music`},
      {id: 'Nanotechnology', name: $localize`:@@industry95:Nanotechnology`},
      {id: 'Newspapers', name: $localize`:@@industry96:Newspapers`},
      {id: 'Nonprofit Organization Management', name: $localize`:@@industry97:Nonprofit Organization Management`},
      {id: 'Oil and Energy', name: $localize`:@@industry98:Oil & Energy`},
      {id: 'Online Media', name: $localize`:@@industry99:Online Media`},
      {id: 'Outsourcing/Offshoring', name: $localize`:@@industry100:Outsourcing/Offshoring`},
      {id: 'Package/Freight Delivery', name: $localize`:@@industry101:Package/Freight Delivery`},
      {id: 'Packaging and Containers', name: $localize`:@@industry102:Packaging and Containers`},
      {id: 'Paper and Forest Products', name: $localize`:@@industry103:Paper & Forest Products`},
      {id: 'Performing Arts', name: $localize`:@@industry104:Performing Arts`},
      {id: 'Pharmaceuticals', name: $localize`:@@industry105:Pharmaceuticals`},
      {id: 'Philanthropy', name: $localize`:@@industry106:Philanthropy`},
      {id: 'Photography', name: $localize`:@@industry107:Photography`},
      {id: 'Plastics', name: $localize`:@@industry108:Plastics`},
      {id: 'Political Organization', name: $localize`:@@industry109:Political Organization`},
      {id: 'Primary/Secondary Education', name: $localize`:@@industry110:Primary/Secondary Education`},
      {id: 'Printing', name: $localize`:@@industry111:Printing`},
      {id: 'Professional Training and Coaching', name: $localize`:@@industry112:Professional Training & Coaching`},
      {id: 'Program Development', name: $localize`:@@industry113:Program Development`},
      {id: 'Public Policy', name: $localize`:@@industry114:Public Policy`},
      {id: 'Public Relations and Communications', name: $localize`:@@industry115:Public Relations and Communications`},
      {id: 'Public Safety', name: $localize`:@@industry116:Public Safety`},
      {id: 'Publishing', name: $localize`:@@industry117:Publishing`},
      {id: 'Railroad Manufacture', name: $localize`:@@industry118:Railroad Manufacture`},
      {id: 'Ranching', name: $localize`:@@industry119:Ranching`},
      {id: 'Real Estate', name: $localize`:@@industry120:Real Estate`},
      {id: 'Recreational Facilities and Services', name: $localize`:@@industry121:Recreational Facilities and Services`},
      {id: 'Religious Institutions', name: $localize`:@@industry122:Religious Institutions`},
      {id: 'Renewables and Environment', name: $localize`:@@industry123:Renewables & Environment`},
      {id: 'Research', name: $localize`:@@industry124:Research`},
      {id: 'Restaurants', name: $localize`:@@industry125:Restaurants`},
      {id: 'Retail', name: $localize`:@@industry126:Retail`},
      {id: 'Security and Investigations', name: $localize`:@@industry127:Security and Investigations`},
      {id: 'Semiconductors', name: $localize`:@@industry128:Semiconductors`},
      {id: 'Shipbuilding', name: $localize`:@@industry129:Shipbuilding`},
      {id: 'Sporting Goods', name: $localize`:@@industry130:Sporting Goods`},
      {id: 'Sports', name: $localize`:@@industry131:Sports`},
      {id: 'Staffing and Recruiting', name: $localize`:@@industry132:Staffing and Recruiting`},
      {id: 'Supermarkets', name: $localize`:@@industry133:Supermarkets`},
      {id: 'Telecommunications', name: $localize`:@@industry134:Telecommunications`},
      {id: 'Textiles', name: $localize`:@@industry135:Textiles`},
      {id: 'Think Tanks', name: $localize`:@@industry136:Think Tanks`},
      {id: 'Tobacco', name: $localize`:@@industry137:Tobacco`},
      {id: 'Translation and Localization', name: $localize`:@@industry138:Translation and Localization`},
      {id: 'Transportation/Trucking/Railroad', name: $localize`:@@industry139:Transportation/Trucking/Railroad`},
      {id: 'Utilities', name: $localize`:@@industry140:Utilities`},
      {id: 'Venture Capital and Private Equity', name: $localize`:@@industry141:Venture Capital & Private Equity`},
      {id: 'Veterinary', name: $localize`:@@industry142:Veterinary`},
      {id: 'Warehousing', name: $localize`:@@industry143:Warehousing`},
      {id: 'Wholesale', name: $localize`:@@industry144:Wholesale`},
      {id: 'Wine and Spirits', name: $localize`:@@industry145:Wine and Spirits`},
      {id: 'Wireless', name: $localize`:@@industry146:Wireless`},
      {id: 'Writing and Editing', name: $localize`:@@industry147:Writing and Editing`}
  ];
 /* numberOfEmployeesArray: any = [
      {value: $localize`:@@numberOfEmployeesArray1:1-9`, id: '1-9'},
      {value: $localize`:@@numberOfEmployeesArray2:10-29`, id: '10-29'},
      {value: $localize`:@@numberOfEmployeesArray3:30-99`, id: '30-99'},
      {value: $localize`:@@numberOfEmployeesArray4:100-499`, id: '100-499'},
      {value: $localize`:@@numberOfEmployeesArray5:500+`, id: '500'}];*/
     numberOfEmployeesArray: any = [
        {value: '1-9', id: '1-9'},
        {value: '10-29', id: '10-29'},
        {value: '30-99', id: '30-99'},
        {value: '100-499', id: '100-499'},
        {value: '500+', id: '500'}];
  annualTravelSpend = [
    'Less than $1,500',
    '$1,500 - $5,000',
    '$5,000 - $10,000',
    '$10,000 - $100,000',
    '$100,000 - $500,000',
    'more than $500,000' ];
  cardType = [

    {name: 'Visa',
      url:'assets/img/payment_methods/visa.png',

    },

    {name: 'MasterCard',
      url:'assets/img/payment_methods/mastercard.png',

    },
    {name: 'Discover',
      url:'assets/img/payment_methods/discover.png',

    },
    {name: 'American Express',
      url:'assets/img/payment_methods/american_express.png',

    },

    {name: 'Diners Club',
      url:'assets/img/payment_methods/diners_club.png',

    },
    {name: 'JCB',
      url:'assets/img/payment_methods/jcb.png',

    },
    {name: 'UnionPay',
      url:'assets/img/payment_methods/union.png',

    },
    {name: 'UnionPay',
      url:'assets/img/payment_methods/union.png',

    },
    {name: 'Unknown',
      url:'assets/img/payment_methods/unknown.png',

    },
  ];

  messages = {
    error: 'Something went wrong. Let\'s try that again.',
    ACCOUNT_NOT_VERIFIED: 'Your account hasn\'t been verified yet. Please check your email for verification instructions.',
    ADD_WALLET: 'You need to add a payment method in order to redeem your Cash Back Add your preferred Cash Back payment method here',
    WALLET_SAVED: 'Your Cash Back payment method has been saved.',
    PAYMENT_CARD_SAVED: $localize`:@@yourCardSaved:Your Card has been saved.`,
    UPDATED_PROFILE: 'Your profile has been updated.',
    UPDATED_ADDRESS: 'Your address has been updated.',
    NO_AVAILABILITY: '<p>Unfortunately, there are no rooms available on selected dates. Rates availability can change frequently try to change the selected dates or the numbers of guests and try again.</p>',
    PRICE_GUARANTEE_SUCCESS: 'Claim submitted successfully, our team will contact you via email.',
    PRICE_GUARANTEE_ERROR: 'There was a problem in submitting your request,. Let\'s try that again.',
    THANKS_CONTACT_US: 'Thank you! one of our team will get in touch with you as soon as possible.',
    CONTACT_US_ERROR: 'There was a problem in submitting your request,. Let\'s try that again.',
    VERIFICATION_SENT: 'We sent a verification code to your email address.',
    USER_HAS_CASHBACK: 'Welcome to Triphop. We’re glad you’re here! We’ve credited your account with $%total USD which  will be redeemable on your next hotel stay valued at greater than $200 USD',
    USER_HAS_DISCOUNT: 'Don\'t forget - We owe you $%amount off your first booking.',
    ACCOUNT_VERIFIED: 'Congratulations! You now have a verified Triphop account!',
    ACCOUNT_VERIFICATION_ERROR: 'We weren’t able to send your verification email. Please try again',
    PASSWORD_RESET_SUCCESSFUL: 'Your password has been reset.',
    PasswordAssigned: 'Your password has been assigned.',
    THANKS_REVIEW : 'Thanks for your review!',
    PASSWORD_RESET_ERROR: 'Sorry, that\'s an invalid user code. Please try again.',
    noHotelsFound: 'Whoops, we could not find any hotel!',

      priceChangeMsg: '<b>' + $localize`:@@weHateThis:We hate it when this happens!` +  '</b><br/>' + $localize`:@@priceChangeMsg:The price of this room has changed. Rates can change frequently, please check below for the updated price and book now.`,
    priceFlightChangeMsg: '<b>' + $localize`:@@weHateThis:We hate it when this happens!` +  '</b><br/>' + $localize`:@@priceFlightChangeMsg:The price of this flight has changed. Rates can change frequently, please check the updated price.`,

    holdFailureMessage:'We’re sorry, something wrong happened. Please try again or contact our customer support',
    holdConfirmed:'Your room is on hold till',
    holdCanceled:'Your room hold was canceled',
    holdExpired:'Your room hold has expired on',
    holdBooked:'Your reservation is confirmed and paid in full',

    holdCanceledCTA:'Find a Room',
    holdBookedCTA:'View My Reservation',
    planSuccess: 'your plan has been updated successfully',
    unsubscribeSuccessfully:  'You have successfully unsubscribed from our email alerts',
    COMPANY_SENT_VERIFICATION_EMAIL_SUCCESS: 'Verification email was sent to $email successfully',
    COMPANY_SENT_VERIFICATION_EMAIL_ERROR: 'Verification email was not sent ',
    Covid19: 'Travel Alert: The Coronavirus (Covid-19) may have impacted your travel. <a target="_blank" class="blue-text" href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/index.html">Find out more</a>'
  };


  countries =
    [
      {
        "name": $localize`:@@AF:Afghanistan`,
        "dial_code": "+93",
        "code": "AF"
      },
      {
        "name": $localize`:@@AX:Aland Islands`,
        "dial_code": "+358",
        "code": "AX"
      },
      {
        "name": $localize`:@@AL:Albania`,
        "dial_code": "+355",
        "code": "AL"
      },
      {
        "name": $localize`:@@DZ:Algeria`,
        "dial_code": "+213",
        "code": "DZ"
      },
      {
        "name": $localize`:@@AS:American Samoa`,
        "dial_code": "+1 684",
        "code": "AS"
      },
      {
        "name": $localize`:@@AD:Andorra`,
        "dial_code": "+376",
        "code": "AD"
      },
      {
        "name": $localize`:@@AO:Angola`,
        "dial_code": "+244",
        "code": "AO"
      },
      {
        "name": $localize`:@@AI:Anguilla`,
        "dial_code": "+1264",
        "code": "AI"
      },
      {
        "name": $localize`:@@AQ:Antarctica`,
        "dial_code": "+672",
        "code": "AQ"
      },
      {
        "name": $localize`:@@AG:Antigua and Barbuda`,
        "dial_code": "+1268",
        "code": "AG"
      },
      {
        "name": $localize`:@@AR:Argentina`,
        "dial_code": "+54",
        "code": "AR"
      },
      {
        "name": $localize`:@@AM:Armenia`,
        "dial_code": "+374",
        "code": "AM"
      },
      {
        "name": $localize`:@@AW:Aruba`,
        "dial_code": "+297",
        "code": "AW"
      },
      {
        "name": $localize`:@@AU:Australia`,
        "dial_code": "+61",
        "code": "AU"
      },
      {
        "name": $localize`:@@AT:Austria`,
        "dial_code": "+43",
        "code": "AT"
      },
      {
        "name": $localize`:@@AZ:Azerbaijan`,
        "dial_code": "+994",
        "code": "AZ"
      },
      {
        "name": $localize`:@@BS:Bahamas`,
        "dial_code": "+1 242",
        "code": "BS"
      },
      {
        "name": $localize`:@@BH:Bahrain`,
        "dial_code": "+973",
        "code": "BH"
      },
      {
        "name": $localize`:@@BD:Bangladesh`,
        "dial_code": "+880",
        "code": "BD"
      },
      {
        "name": $localize`:@@BB:Barbados`,
        "dial_code": "+1246",
        "code": "BB"
      },
      {
        "name": $localize`:@@BY:Belarus`,
        "dial_code": "+375",
        "code": "BY"
      },
      {
        "name": $localize`:@@BE:Belgium`,
        "dial_code": "+32",
        "code": "BE"
      },
      {
        "name": $localize`:@@BZ:Belize`,
        "dial_code": "+501",
        "code": "BZ"
      },
      {
        "name": $localize`:@@BJ:Benin`,
        "dial_code": "+229",
        "code": "BJ"
      },
      {
        "name": $localize`:@@BM:Bermuda`,
        "dial_code": "+1441",
        "code": "BM"
      },
      {
        "name": $localize`:@@BT:Bhutan`,
        "dial_code": "+975",
        "code": "BT"
      },
      {
        "name": $localize`:@@BO:Bolivia, Plurinational State of`,
        "dial_code": "+591",
        "code": "BO"
      },
      {
        "name":  $localize`:@@BA:Bosnia and Herzegovin`,
        "dial_code": "+387",
        "code": "BA"
      },
      {
        "name": $localize`:@@BW:Botswana`,
        "dial_code": "+267",
        "code": "BW"
      },
      {
        "name": $localize`:@@BR:Brazil`,
        "dial_code": "+55",
        "code": "BR"
      },
      {
        "name": $localize`:@@IO:British Indian Ocean Territory`,
        "dial_code": "+246",
        "code": "IO"
      },
      {
        "name": $localize`:@@BN:Brunei Darussalam`,
        "dial_code": "+673",
        "code": "BN"
      },
      {
        "name": $localize`:@@BG:Bulgaria`,
        "dial_code": "+359",
        "code": "BG"
      },
      {
        "name": $localize`:@@BF:Burkina Faso`,
        "dial_code": "+226",
        "code": "BF"
      },
      {
        "name": $localize`:@@BI:Burundi`,
        "dial_code": "+257",
        "code": "BI"
      },
      {
        "name": $localize`:@@KH:Cambodia`,
        "dial_code": "+855",
        "code": "KH"
      },
      {
        "name": $localize`:@@CM:Cameroon`,
        "dial_code": "+237",
        "code": "CM"
      },
      {
        "name": $localize`:@@CA:Canada`,
        "dial_code": "+1",
        "code": "CA"
      },
      {
        "name": $localize`:@@CV:Cape Verde`,
        "dial_code": "+238",
        "code": "CV"
      },
      {
        "name": $localize`:@@KY:Cayman Islands`,
        "dial_code": "+345",
        "code": "KY"
      },
      {
        "name": $localize`:@@CF:Central African Republic`,
        "dial_code": "+236",
        "code": "CF"
      },
      {
        "name": $localize`:@@TD:Chad`,
        "dial_code": "+235",
        "code": "TD"
      },
      {
        "name": $localize`:@@CL:Chile`,
        "dial_code": "+56",
        "code": "CL"
      },
      {
        "name": $localize`:@@CN:China`,
        "dial_code": "+86",
        "code": "CN"
      },
      {
        "name": $localize`:@@CX:Christmas Island`,
        "dial_code": "+61",
        "code": "CX"
      },
      {
        "name": $localize`:@@CC:Cocos (Keeling) Islands`,
        "dial_code": "+61",
        "code": "CC"
      },
      {
        "name": $localize`:@@CO:Colombia`,
        "dial_code": "+57",
        "code": "CO"
      },
      {
        "name": $localize`:@@KM:Comoros`,
        "dial_code": "+269",
        "code": "KM"
      },
      {
        "name": $localize`:@@CG:Congo`,
        "dial_code": "+242",
        "code": "CG"
      },
      {
        "name": $localize`:@@CD:Congo, The Democratic Republic of the Congo`,
        "dial_code": "+243",
        "code": "CD"
      },
      {
        "name": $localize`:@@CK:Cook Islands`,
        "dial_code": "+682",
        "code": "CK"
      },
      {
        "name": $localize`:@@CR:Costa Rica`,
        "dial_code": "+506",
        "code": "CR"
      },
      {
        "name": $localize`:@@CI:Cote d'Ivoire`,
        "dial_code": "+225",
        "code": "CI"
      },
      {
        "name": $localize`:@@HR:Croatia`,
        "dial_code": "+385",
        "code": "HR"
      },
      {
        "name": $localize`:@@CU:Cuba`,
        "dial_code": "+53",
        "code": "CU"
      },
      {
        "name": $localize`:@@CY:Cyprus`,
        "dial_code": "+357",
        "code": "CY"
      },
      {
        "name": $localize`:@@CZ:Czech Republic`,
        "dial_code": "+420",
        "code": "CZ"
      },
      {
        "name": $localize`:@@DK:Denmark`,
        "dial_code": "+45",
        "code": "DK"
      },
      {
        "name": $localize`:@@DJ:Djibouti`,
        "dial_code": "+253",
        "code": "DJ"
      },
      {
        "name": $localize`:@@DM:Dominica`,
        "dial_code": "+1767",
        "code": "DM"
      },
      {
        "name": $localize`:@@DO:Dominican Republic`,
        "dial_code": "+1849",
        "code": "DO"
      },
      {
        "name": $localize`:@@EC:Ecuador`,
        "dial_code": "+593",
        "code": "EC"
      },
      {
        "name": $localize`:@@EG:Egypt`,
        "dial_code": "+20",
        "code": "EG"
      },
      {
        "name": $localize`:@@SV:El Salvador`,
        "dial_code": "+503",
        "code": "SV"
      },
      {
        "name": $localize`:@@GQ:Equatorial Guinea`,
        "dial_code": "+240",
        "code": "GQ"
      },
      {
        "name": $localize`:@@ER:Eritrea`,
        "dial_code": "+291",
        "code": "ER"
      },
      {
        "name": $localize`:@@EE:Estonia`,
        "dial_code": "+372",
        "code": "EE"
      },
      {
        "name": $localize`:@@ET:Ethiopia`,
        "dial_code": "+251",
        "code": "ET"
      },
      {
        "name": $localize`:@@FK:Falkland Islands (Malvinas)`,
        "dial_code": "+500",
        "code": "FK"
      },
      {
        "name": $localize`:@@FO:Faroe Islands`,
        "dial_code": "+298",
        "code": "FO"
      },
      {
        "name": $localize`:@@FJ:Fiji`,
        "dial_code": "+679",
        "code": "FJ"
      },
      {
        "name": $localize`:@@FI:Finland`,
        "dial_code": "+358",
        "code": "FI"
      },
      {
        "name": $localize`:@@FR:France`,
        "dial_code": "+33",
        "code": "FR"
      },
      {
        "name": $localize`:@@GF:French Guiana`,
        "dial_code": "+594",
        "code": "GF"
      },
      {
        "name": $localize`:@@PF:French Polynesia`,
        "dial_code": "+689",
        "code": "PF"
      },
      {
        "name": $localize`:@@GA:Gabon`,
        "dial_code": "+241",
        "code": "GA"
      },
      {
        "name": $localize`:@@GM:Gambia`,
        "dial_code": "+220",
        "code": "GM"
      },
      {
        "name": $localize`:@@GE:Georgia`,
        "dial_code": "+995",
        "code": "GE"
      },
      {
        "name": $localize`:@@DE:Germany`,
        "dial_code": "+49",
        "code": "DE"
      },
      {
        "name": $localize`:@@GH:Ghana`,
        "dial_code": "+233",
        "code": "GH"
      },
      {
        "name": $localize`:@@GI:Gibraltar`,
        "dial_code": "+350",
        "code": "GI"
      },
      {
        "name": $localize`:@@GR:Greece`,
        "dial_code": "+30",
        "code": "GR"
      },
      {
        "name": $localize`:@@GL:Greenland`,
        "dial_code": "+299",
        "code": "GL"
      },
      {
        "name": $localize`:@@GD:Grenada`,
        "dial_code": "+1 473",
        "code": "GD"
      },
      {
        "name": $localize`:@@GP:Guadeloupe`,
        "dial_code": "+590",
        "code": "GP"
      },
      {
        "name": $localize`:@@GU:Guam`,
        "dial_code": "+1 671",
        "code": "GU"
      },
      {
        "name": $localize`:@@GT:Guatemala`,
        "dial_code": "+502",
        "code": "GT"
      },
      {
        "name": $localize`:@@GG:Guernsey`,
        "dial_code": "+44",
        "code": "GG"
      },
      {
        "name": $localize`:@@GN:Guinea`,
        "dial_code": "+224",
        "code": "GN"
      },
      {
        "name": $localize`:@@GW:Guinea-Bissau`,
        "dial_code": "+245",
        "code": "GW"
      },
      {
        "name": $localize`:@@GY:Guyana`,
        "dial_code": "+595",
        "code": "GY"
      },
      {
        "name": $localize`:@@HT:Haiti`,
        "dial_code": "+509",
        "code": "HT"
      },
      {
        "name": $localize`:@@VA:Holy See (Vatican City State)`,
        "dial_code": "+379",
        "code": "VA"
      },
      {
        "name": $localize`:@@HN:Honduras`,
        "dial_code": "+504",
        "code": "HN"
      },
      {
        "name": $localize`:@@HK:Hong Kong`,
        "dial_code": "+852",
        "code": "HK"
      },
      {
        "name": $localize`:@@HU:Hungary`,
        "dial_code": "+36",
        "code": "HU"
      },
      {
        "name": $localize`:@@IS:Iceland`,
        "dial_code": "+354",
        "code": "IS"
      },
      {
        "name": $localize`:@@IN:India`,
        "dial_code": "+91",
        "code": "IN"
      },
      {
        "name": $localize`:@@ID:Indonesia`,
        "dial_code": "+62",
        "code": "ID"
      },
      {
        "name": $localize`:@@IR:Iran, Islamic Republic of Persian Gulf`,
        "dial_code": "+98",
        "code": "IR"
      },
      {
        "name": $localize`:@@IQ:Iraq`,
        "dial_code": "+964",
        "code": "IQ"
      },
      {
        "name": $localize`:@@IE:Ireland`,
        "dial_code": "+353",
        "code": "IE"
      },
      {
        "name": $localize`:@@IM:Isle of Man`,
        "dial_code": "+44",
        "code": "IM"
      },
      {
        "name": $localize`:@@IL:Israel`,
        "dial_code": "+972",
        "code": "IL"
      },
      {
        "name": $localize`:@@IT:Italy`,
        "dial_code": "+39",
        "code": "IT"
      },
      {
        "name": $localize`:@@JM:Jamaica`,
        "dial_code": "+1 876",
        "code": "JM"
      },
      {
        "name": $localize`:@@JP:Japan`,
        "dial_code": "+81",
        "code": "JP"
      },
      {
        "name": $localize`:@@JE:Jersey`,
        "dial_code": "+44",
        "code": "JE"
      },
      {
        "name": $localize`:@@JO:Jordan`,
        "dial_code": "+962",
        "code": "JO"
      },
      {
        "name": $localize`:@@KZ:Kazakhstan`,
        "dial_code": "+7 7",
        "code": "KZ"
      },
      {
        "name": $localize`:@@KE:Kenya`,
        "dial_code": "+254",
        "code": "KE"
      },
      {
        "name": $localize`:@@KI:Kiribati`,
        "dial_code": "+686",
        "code": "KI"
      },
      {
        "name": $localize`:@@KP:Korea, Democratic People's Republic of Korea`,
        "dial_code": "+850",
        "code": "KP"
      },
      {
        "name": $localize`:@@KR:Korea, Republic of South Korea`,
        "dial_code": "+82",
        "code": "KR"
      },
      {
        "name": $localize`:@@KW:Kuwait`,
        "dial_code": "+965",
        "code": "KW"
      },
      {
        "name": $localize`:@@KG:Kyrgyzstan`,
        "dial_code": "+996",
        "code": "KG"
      },
      {
        "name": $localize`:@@LA:Laos`,
        "dial_code": "+856",
        "code": "LA"
      },
      {
        "name": $localize`:@@LV:Latvia`,
        "dial_code": "+371",
        "code": "LV"
      },
      {
        "name": $localize`:@@LB:Lebanon`,
        "dial_code": "+961",
        "code": "LB"
      },
      {
        "name": $localize`:@@LS:Lesotho`,
        "dial_code": "+266",
        "code": "LS"
      },
      {
        "name": $localize`:@@LR:Liberia`,
        "dial_code": "+231",
        "code": "LR"
      },
      {
        "name": $localize`:@@LY:Libyan Arab Jamahiriya`,
        "dial_code": "+218",
        "code": "LY"
      },
      {
        "name": $localize`:@@LI:Liechtenstein`,
        "dial_code": "+423",
        "code": "LI"
      },
      {
        "name": $localize`:@@LT:Lithuania`,
        "dial_code": "+370",
        "code": "LT"
      },
      {
        "name": $localize`:@@LU:Luxembourg`,
        "dial_code": "+352",
        "code": "LU"
      },
      {
        "name": $localize`:@@MO:Macao`,
        "dial_code": "+853",
        "code": "MO"
      },
      {
        "name": $localize`:@@MK:Macedonia`,
        "dial_code": "+389",
        "code": "MK"
      },
      {
        "name": $localize`:@@MG:Madagascar`,
        "dial_code": "+261",
        "code": "MG"
      },
      {
        "name": $localize`:@@MW:Malawi`,
        "dial_code": "+265",
        "code": "MW"
      },
      {
        "name": $localize`:@@MY:Malaysia`,
        "dial_code": "+60",
        "code": "MY"
      },
      {
        "name": $localize`:@@MV:Maldives`,
        "dial_code": "+960",
        "code": "MV"
      },
      {
        "name": $localize`:@@ML:Mali`,
        "dial_code": "+223",
        "code": "ML"
      },
      {
        "name": $localize`:@@MT:Malta`,
        "dial_code": "+356",
        "code": "MT"
      },
      {
        "name": $localize`:@@MH:Marshall Islands`,
        "dial_code": "+692",
        "code": "MH"
      },
      {
        "name": $localize`:@@MQ:Martinique`,
        "dial_code": "+596",
        "code": "MQ"
      },
      {
        "name": $localize`:@@MR:Mauritania`,
        "dial_code": "+222",
        "code": "MR"
      },
      {
        "name": $localize`:@@MU:Mauritius`,
        "dial_code": "+230",
        "code": "MU"
      },
      {
        "name": $localize`:@@YT:Mayotte`,
        "dial_code": "+262",
        "code": "YT"
      },
      {
        "name": $localize`:@@MX:Mexico`,
        "dial_code": "+52",
        "code": "MX"
      },
      {
        "name": $localize`:@@FM:Micronesia, Federated States of Micronesia`,
        "dial_code": "+691",
        "code": "FM"
      },
      {
        "name": $localize`:@@MD:Moldova`,
        "dial_code": "+373",
        "code": "MD"
      },
      {
        "name": $localize`:@@MC:Monaco`,
        "dial_code": "+377",
        "code": "MC"
      },
      {
        "name": $localize`:@@MN:Mongolia`,
        "dial_code": "+976",
        "code": "MN"
      },
      {
        "name": $localize`:@@ME:Montenegro`,
        "dial_code": "+382",
        "code": "ME"
      },
      {
        "name": $localize`:@@MS:Montserrat`,
        "dial_code": "+1664",
        "code": "MS"
      },
      {
        "name": $localize`:@@MA:Morocco`,
        "dial_code": "+212",
        "code": "MA"
      },
      {
        "name": $localize`:@@MZ:Mozambique`,
        "dial_code": "+258",
        "code": "MZ"
      },
      {
        "name": $localize`:@@MM:Myanmar`,
        "dial_code": "+95",
        "code": "MM"
      },
      {
        "name": $localize`:@@NA:Namibia`,
        "dial_code": "+264",
        "code": "NA"
      },
      {
        "name": $localize`:@@NR:Nauru`,
        "dial_code": "+674",
        "code": "NR"
      },
      {
        "name": $localize`:@@NP:Nepal`,
        "dial_code": "+977",
        "code": "NP"
      },
      {
        "name": $localize`:@@NL:Netherlands`,
        "dial_code": "+31",
        "code": "NL"
      },
      {
        "name": $localize`:@@AN:Netherlands Antilles`,
        "dial_code": "+599",
        "code": "AN"
      },
      {
        "name": $localize`:@@NC:New Caledonia`,
        "dial_code": "+687",
        "code": "NC"
      },
      {
        "name": $localize`:@@NZ:New Zealand`,
        "dial_code": "+64",
        "code": "NZ"
      },
      {
        "name": $localize`:@@NI:Nicaragua`,
        "dial_code": "+505",
        "code": "NI"
      },
      {
        "name": $localize`:@@NE:Niger`,
        "dial_code": "+227",
        "code": "NE"
      },
      {
        "name": $localize`:@@NG:Nigeria`,
        "dial_code": "+234",
        "code": "NG"
      },
      {
        "name": $localize`:@@NU:Niue`,
        "dial_code": "+683",
        "code": "NU"
      },
      {
        "name": $localize`:@@NF:Norfolk Island`,
        "dial_code": "+672",
        "code": "NF"
      },
      {
        "name": $localize`:@@MP:Northern Mariana Islands`,
        "dial_code": "+1670",
        "code": "MP"
      },
      {
        "name": $localize`:@@NO:Norway`,
        "dial_code": "+47",
        "code": "NO"
      },
      {
        "name": $localize`:@@OM:Oman`,
        "dial_code": "+968",
        "code": "OM"
      },
      {
        "name": $localize`:@@PK:Pakistan`,
        "dial_code": "+92",
        "code": "PK"
      },
      {
        "name": $localize`:@@PW:Palau`,
        "dial_code": "+680",
        "code": "PW"
      },
      {
        "name": $localize`:@@PS:Palestinian Territory, Occupied`,
        "dial_code": "+970",
        "code": "PS"
      },
      {
        "name": $localize`:@@PA:Panama`,
        "dial_code": "+507",
        "code": "PA"
      },
      {
        "name": $localize`:@@PG:Papua New Guinea`,
        "dial_code": "+675",
        "code": "PG"
      },
      {
        "name": $localize`:@@PY:Paraguay`,
        "dial_code": "+595",
        "code": "PY"
      },
      {
        "name": $localize`:@@PE:Peru`,
        "dial_code": "+51",
        "code": "PE"
      },
      {
        "name": $localize`:@@PH:Philippines`,
        "dial_code": "+63",
        "code": "PH"
      },
      {
        "name": $localize`:@@PN:Pitcairn`,
        "dial_code": "+872",
        "code": "PN"
      },
      {
        "name": $localize`:@@PL:Poland`,
        "dial_code": "+48",
        "code": "PL"
      },
      {
        "name": $localize`:@@PT:Portugal`,
        "dial_code": "+351",
        "code": "PT"
      },
      {
        "name": $localize`:@@PR:Puerto Rico`,
        "dial_code": "+1939",
        "code": "PR"
      },
      {
        "name": $localize`:@@QA:Qatar`,
        "dial_code": "+974",
        "code": "QA"
      },
      {
        "name": $localize`:@@RO:Romania`,
        "dial_code": "+40",
        "code": "RO"
      },
      {
        "name": $localize`:@@RU:Russia`,
        "dial_code": "+7",
        "code": "RU"
      },
      {
        "name": $localize`:@@RW:Rwanda`,
        "dial_code": "+250",
        "code": "RW"
      },
      {
        "name": $localize`:@@RE:Reunion`,
        "dial_code": "+262",
        "code": "RE"
      },
      {
        "name": $localize`:@@BL:Saint Barthelemy`,
        "dial_code": "+590",
        "code": "BL"
      },
      {
        "name": $localize`:@@SH:Saint Helena, Ascension and Tristan Da Cunha`,
        "dial_code": "+290",
        "code": "SH"
      },
      {
        "name": $localize`:@@KN:Saint Kitts and Nevis`,
        "dial_code": "+1 869",
        "code": "KN"
      },
      {
        "name": $localize`:@@LC:Saint Lucias`,
        "dial_code": "+1 758",
        "code": "LC"
      },
      {
        "name": $localize`:@@MF:Saint Martin`,
        "dial_code": "+590",
        "code": "MF"
      },
      {
        "name": $localize`:@@PM:Saint Pierre and Miquelon`,
        "dial_code": "+508",
        "code": "PM"
      },
      {
        "name": $localize`:@@VC:Saint Vincent and the Grenadines`,
        "dial_code": "+1 784",
        "code": "VC"
      },
      {
        "name": $localize`:@@WS:Samoa`,
        "dial_code": "+685",
        "code": "WS"
      },
      {
        "name": $localize`:@@SM:San Marino`,
        "dial_code": "+378",
        "code": "SM"
      },
      {
        "name": $localize`:@@ST:Sao Tome and Principe`,
        "dial_code": "+239",
        "code": "ST"
      },
      {
        "name": $localize`:@@SA:Saudi Arabia`,
        "dial_code": "+966",
        "code": "SA"
      },
      {
        "name": $localize`:@@SN:Senegal`,
        "dial_code": "+221",
        "code": "SN"
      },
      {
        "name": $localize`:@@RS:Serbia`,
        "dial_code": "+381",
        "code": "RS"
      },
      {
        "name": $localize`:@@SC:Seychelles`,
        "dial_code": "+248",
        "code": "SC"
      },
      {
        "name": $localize`:@@SL:Sierra Leone`,
        "dial_code": "+232",
        "code": "SL"
      },
      {
        "name": $localize`:@@SG:Singapore`,
        "dial_code": "+65",
        "code": "SG"
      },
      {
        "name": $localize`:@@SK:Slovakia`,
        "dial_code": "+421",
        "code": "SK"
      },
      {
        "name": $localize`:@@SI:Slovenia`,
        "dial_code": "+386",
        "code": "SI"
      },
      {
        "name": $localize`:@@SB:Solomon Islands`,
        "dial_code": "+677",
        "code": "SB"
      },
      {
        "name": $localize`:@@SO:Somalia`,
        "dial_code": "+252",
        "code": "SO"
      },
      {
        "name": $localize`:@@ZA:South Africa`,
        "dial_code": "+27",
        "code": "ZA"
      },
      {
        "name": $localize`:@@GS:South Georgia and the South Sandwich Islands`,
        "dial_code": "+500",
        "code": "GS"
      },
      {
        "name": $localize`:@@ES:Spain`,
        "dial_code": "+34",
        "code": "ES"
      },
      {
        "name": $localize`:@@LK:Sri Lanka`,
        "dial_code": "+94",
        "code": "LK"
      },
      {
        "name": $localize`:@@SD:Sudan`,
        "dial_code": "+249",
        "code": "SD"
      },
      {
        "name": $localize`:@@SR:Suriname`,
        "dial_code": "+597",
        "code": "SR"
      },
      {
        "name": $localize`:@@SJ:Svalbard and Jan Mayen`,
        "dial_code": "+47",
        "code": "SJ"
      },
      {
        "name": $localize`:@@SZ:Swaziland`,
        "dial_code": "+268",
        "code": "SZ"
      },
      {
        "name": $localize`:@@SE:Sweden`,
        "dial_code": "+46",
        "code": "SE"
      },
      {
        "name": $localize`:@@CH:Switzerland`,
        "dial_code": "+41",
        "code": "CH"
      },
      {
        "name": $localize`:@@SY:Syrian Arab Republic`,
        "dial_code": "+963",
        "code": "SY"
      },
      {
        "name": $localize`:@@TW:Taiwan`,
        "dial_code": "+886",
        "code": "TW"
      },
      {
        "name": $localize`:@@TJ:Tajikistan`,
        "dial_code": "+992",
        "code": "TJ"
      },
      {
        "name": $localize`:@@TZ:Tanzania, United Republic of Tanzania`,
        "dial_code": "+255",
        "code": "TZ"
      },
      {
        "name": $localize`:@@TH:Thailand`,
        "dial_code": "+66",
        "code": "TH"
      },
      {
        "name": $localize`:@@TL:Timor-Leste`,
        "dial_code": "+670",
        "code": "TL"
      },
      {
        "name": $localize`:@@TG:Togo`,
        "dial_code": "+228",
        "code": "TG"
      },
      {
        "name": $localize`:@@TK:Tokelau`,
        "dial_code": "+690",
        "code": "TK"
      },
      {
        "name": $localize`:@@TO:Tonga`,
        "dial_code": "+676",
        "code": "TO"
      },
      {
        "name": $localize`:@@TT:Trinidad and Tobago`,
        "dial_code": "+1 868",
        "code": "TT"
      },
      {
        "name": $localize`:@@TN:Tunisia`,
        "dial_code": "+216",
        "code": "TN"
      },
      {
        "name": $localize`:@@TR:Turkey`,
        "dial_code": "+90",
        "code": "TR"
      },
      {
        "name": $localize`:@@TM:Turkmenistan`,
        "dial_code": "+993",
        "code": "TM"
      },
      {
        "name": $localize`:@@TC:Turks and Caicos Islands`,
        "dial_code": "+1 649",
        "code": "TC"
      },
      {
        "name": $localize`:@@TV:Tuvalu`,
        "dial_code": "+688",
        "code": "TV"
      },
      {
        "name": $localize`:@@UG:Uganda`,
        "dial_code": "+256",
        "code": "UG"
      },
      {
        "name": $localize`:@@UA:Ukraine`,
        "dial_code": "+380",
        "code": "UA"
      },
      {
        "name": $localize`:@@AE:United Arab Emirates`,
        "dial_code": "+971",
        "code": "AE"
      },
      {
        "name": $localize`:@@GB:United Kingdom`,
        "dial_code": "+44",
        "code": "GB"
      },
      {
        "name": $localize`:@@US:United States`,
        "dial_code": "+1",
        "code": "US"
      },
      {
        "name": $localize`:@@UY:Uruguay`,
        "dial_code": "+598",
        "code": "UY"
      },
      {
        "name": $localize`:@@UZ:Uzbekistan`,
        "dial_code": "+998",
        "code": "UZ"
      },
      {
        "name": $localize`:@@VU:Vanuatu`,
        "dial_code": "+678",
        "code": "VU"
      },
      {
        "name": $localize`:@@VE:Venezuela, Bolivarian Republic of Venezuela`,
        "dial_code": "+58",
        "code": "VE"
      },
      {
        "name": $localize`:@@VN:Vietnam`,
        "dial_code": "+84",
        "code": "VN"
      },
      {
        "name": $localize`:@@VG:Virgin Islands, British`,
        "dial_code": "+1 284",
        "code": "VG"
      },
      {
        "name": $localize`:@@VI:Virgin Islands, U.S.`,
        "dial_code": "+1 340",
        "code": "VI"
      },
      {
        "name": $localize`:@@WF:Wallis and Futuna`,
        "dial_code": "+681",
        "code": "WF"
      },
      {
        "name": $localize`:@@YE:Yemen`,
        "dial_code": "+967",
        "code": "YE"
      },
      {
        "name": $localize`:@@ZM:Zambia`,
        "dial_code": "+260",
        "code": "ZM"
      },
      {
        "name": $localize`:@@ZW:Zimbabwe`,
        "dial_code": "+263",
        "code": "ZW"
      }
    ];
  states = [
    {
      name: $localize`:@@AL_state:Alabama`,
      code: 'AL'
    },
    {
      name: $localize`:@@AK_state:Alaska`,
      code: 'AK'
    },
    {
      name: $localize`:@@AS_state:American Samoa`,
      code: 'AS'
    },
    {
      name: $localize`:@@AZ_state:Arizona`,
      code: 'AZ'
    },
    {
      name: $localize`:@@AR_state:Arkansas`,
      code: 'AR'
    },
    {
      name: $localize`:@@CA_state:California`,
      code: 'CA'
    },
    {
      name: $localize`:@@CO_state:Colorado`,
      code: 'CO'
    },
    {
      name: $localize`:@@CT_state:Connecticut`,
      code: 'CT'
    },
    {
      name: $localize`:@@DE_state:Delaware`,
      code: 'DE'
    },
    {
      name: $localize`:@@DC_state:District Of Columbia`,
      code: 'DC'
    },
    {
      name: $localize`:@@FM_state:Federated States Of Micronesia`,
      code: 'FM'
    },
    {
      name: $localize`:@@FL_state:Florida`,
      code: 'FL'
    },
    {
      name: $localize`:@@GA_state:Georgia`,
      code: 'GA'
    },
    {
      name: $localize`:@@GU_state:Guam`,
      code: 'GU'
    },
    {
      name: $localize`:@@HI_state:Hawaii`,
      code: 'HI'
    },
    {
      name: $localize`:@@ID_state:Idaho`,
      code: 'ID'
    },
    {
      name: $localize`:@@IL_state:Illinois`,
      code: 'IL'
    },
    {
      name: $localize`:@@IN_state:Indiana`,
      code: 'IN'
    },
    {
      name: $localize`:@@IA_state:Iowa`,
      code: 'IA'
    },
    {
      name: $localize`:@@KS_state:Kansas`,
      code: 'KS'
    },
    {
      name: $localize`:@@KY_state:Kentucky`,
      code: 'KY'
    },
    {
      name: $localize`:@@LA_state:Louisiana`,
      code: 'LA'
    },
    {
      name: $localize`:@@ME_state:Maine`,
      code: 'ME'
    },
    {
      name: $localize`:@@MH_state:Marshall Islands`,
      code: 'MH'
    },
    {
      name: $localize`:@@MD_state:Maryland`,
      code: 'MD'
    },
    {
      name: $localize`:@@MA_state:Massachusetts`,
      code: 'MA'
    },
    {
      name: $localize`:@@MI_state:Michigan`,
      code: 'MI'
    },
    {
      name: $localize`:@@MN_state:Minnesota`,
      code: 'MN'
    },
    {
      name: $localize`:@@MS_state:Mississippi`,
      code: 'MS'
    },
    {
      name: $localize`:@@MO_state:Missouri`,
      code: 'MO'
    },
    {
      name: $localize`:@@MT_state:Montana`,
      code: 'MT'
    },
    {
      name: $localize`:@@NE_state:Nebraska`,
      code: 'NE'
    },
    {
      name: $localize`:@@NV_state:Nevada`,
      code: 'NV'
    },
    {
      name: $localize`:@@NH_state:New Hampshire`,
      code: 'NH'
    },
    {
      name: $localize`:@@NJ_state:New Jersey`,
      code: 'NJ'
    },
    {
      name: $localize`:@@NM_state:New Mexico`,
      code: 'NM'
    },
    {
      name: $localize`:@@NY_state:New York`,
      code: 'NY'
    },
    {
      name: $localize`:@@NC_state:North Carolina`,
      code: 'NC'
    },
    {
      name: $localize`:@@ND_state:North Dakota`,
      code: 'ND'
    },
    {
      name: $localize`:@@MP_state:Northern Mariana Islands`,
      code: 'MP'
    },
    {
      name: $localize`:@@OH_state:Ohio`,
      code: 'OH'
    },
    {
      name: $localize`:@@OK_state:Oklahoma`,
      code: 'OK'
    },
    {
      name: $localize`:@@OR_state:Oregon`,
      code: 'OR'
    },
    {
      name: $localize`:@@PW_state:Palau`,
      code: 'PW'
    },
    {
      name: $localize`:@@PA_state:Pennsylvania`,
      code: 'PA'
    },
    {
      name: $localize`:@@PR_state:Puerto Rico`,
      code: 'PR'
    },
    {
      name: $localize`:@@RI_state:Rhode Island`,
      code: 'RI'
    },
    {
      name: $localize`:@@SC_state:South Carolina`,
      code: 'SC'
    },
    {
      name: $localize`:@@SD_state:South Dakota`,
      code: 'SD'
    },
    {
      name: $localize`:@@TN_state:Tennessee`,
      code: 'TN'
    },
    {
      name: $localize`:@@TX_state:Texas`,
      code: 'TX'
    },
    {
      name: $localize`:@@UT_state:Utah`,
      code: 'UT'
    },
    {
      name: $localize`:@@VT_state:Vermont`,
      code: 'VT'
    },
    {
      name: $localize`:@@VI_state:Virgin Islands`,
      code: 'VI'
    },
    {
      name: $localize`:@@VA_state:Virginia`,
      code: 'VA'
    },
    {
      name: $localize`:@@WA_state:Washington`,
      code: 'WA'
    },
    {
      name: $localize`:@@WV_state:West Virginia`,
      code: 'WV'
    },
    {
      name: $localize`:@@WI_state:Wisconsin`,
      code: 'WI'
    },
    {
      name: $localize`:@@WY_state:Wyoming`,
      code: 'WY'
    }
    ];

  isoCountries = {
    AF: 'Afghanistan',
    AX: 'Aland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua And Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia And Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, Democratic Republic',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: 'Cote D\'Ivoire',
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island & Mcdonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic Of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle Of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KR: 'Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Lao People\'s Democratic Republic',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libyan Arab Jamahiriya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States Of',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory, Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthelemy',
    SH: 'Saint Helena',
    KN: 'Saint Kitts And Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    PM: 'Saint Pierre And Miquelon',
    VC: 'Saint Vincent And Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome And Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia And Sandwich Isl.',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard And Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad And Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks And Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis And Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  };

  apiUrls = {
    collect_lead: '/access/collect_lead',
    lookup: '/booking/lookup',
    lookupAirports: '/booking/lookup_airports',
    lookupDestination: '/booking/lookup_destination',
    login: '/access/login',
    userInfo: '/user/info',
    socialMedia: '/access/social_login?socialNetwork=',
    logOut: '/user/logout',
    signUp: '/access/signup',
    checkmail: '/access/check_email',
    forgetPassword: '/access/forget_password',
    availability: '/booking/v2/availability',
    flightAvailability: '/booking/air/availability',
    airBook: '/booking/air/book',
    airItineraryRules: '/booking/air/rules',
    contactUs: '/access/contact_us',
    retrieve: '/booking/retrieve_booking',
    retrieveFlightBookingConsumer: '/booking/air/retrieve_booking',
    priceGuarantee: '/misc/price_guarantee_claim',
    nearby: '/booking/nearby',
    tripAdviser: '/booking/v2/ta_hotel_review_summ',
    userInvite: '/user/invite',
    refererDetails: '/access/referer_details',
    refInfo: '/access/ref',
    checkCancellationFees: '/booking/calculate_cancellation_fee',
    leaveBookingFeedback: '/booking/submit_feedback',
    sendVoucher: '/booking/send_voucher',
    cancel: '/booking/cancel_booking',
    userDashboard: '/user/dashboard',
    userUpdate: '/user/update',
    changeEmail: '/user/change_email',
    changePassword: '/user/change_password',
    listWallets: '/user/redemption_channels',
    deleteWallets: '/user/delete_redemption_channel',
    editWallet: '/user/update_redemption_channel',
    recheck: '/booking/recheck',
    booking: '/booking/v2/book',
    bookingHold: '/booking/book_hold',
    getPreferences: '/access/system_conf',
    checkCashback: '/user/bc_locked_cashback',
    addWallet: '/user/add_redemption_channel',
    savedCard: '/user/payment_methods',
    addFrequentFlyerProgram: '/user/add_frequent_flyer_program',
    updateFrequentFlyerProgramConsumer: '/user/update_frequent_flyer_program',
    removeFrequentFlyerProgramConsumer: '/user/remove_frequent_flyer_program',
    addTravelDocumentConsumer: '/user/add_travel_document',
    updateTravelDocumentConsumer: '/user/update_travel_document',
    removeTravelDocumentConsumer: '/user/remove_travel_document',
    applyCoupon: '/misc/apply_coupon',
    clearCoupon: '/misc/clear_coupon',
    discounts: '/user/discounts',
    clearDiscount: '/user/clear_discount',
    applyDiscount: '/user/apply_discount',
    listPayments: '/user/payment_methods',

    updatePayment: '/user/update_card',
    deletePayment: '/user/delete_card',
    listBookings: '/user/bookings',
    checkRedemptionFees: '/user/check_cashback_redeem_fees',
    listCashback: '/user/list_cashback_statement',
    redeemCashback: '/user/redeem_cashback',
    promoContent: '/misc/promo_content',
    leadsAccess: '/access/collect_lead',
    mailId: '/misc/mail_id_info',
    resendVerificationCode: '/user/send_user_verification_email',
    fav: '/user/fav',
    getFavorites: '/user/get_favorites',
    verify: '/access/verify_email',
    resetPassword: '/access/reset_password',
    addPayment: '/user/add_card',
    shareHotels: '/misc/share_hotels',
    searchHistory: '/user/search_history',
    flightSearchHistory: '/company/me/flight_search_history',
    favDelete: '/user/fav_delete',
    linkProfile: '/access/link_profile',
    linkUserSocialProfile: '/user/link_profile',
    sendAccountLink: '/access/send_account_link',
    linkAccountVerification: '/access/link_account_verification',
    holdRate: '/booking/hold_rate',
    retrieveHold: '/booking/retrieve_hold',
    retrieveAllBookingsConsumer: '/user/all_bookings',
    primeSubscribe: '/user/prime_subscribe',
    primeChangePlan: '/user/prime_change_plan',
    primePlans: '/access/prime_plans',
    primeCancel: '/user/prime_cancel',
    primeAutoRenew: '/user/prime_autorenew',
    primeChangePaymentMethod: '/user/prime_change_payment_method',
    checkPrimePhoneNumber: '/user/check_prime_phone',
    primeBilling: '/user/prime_billing',
    answerPersonalizedQuestion: '/user/answer_personalized_question',
    getFeefoSerivceReviews:   '/access/customer_reviews',
    getOnlinePrices:  '/booking/online_prices',
    getOnlinePricesV2:  '/booking/v2/online_prices',

    getPersonalizedQuestions: '/user/get_personalized_questions',
    getPersonalizedQuestion: '/user/get_personalized_question',
    savePushToken: '/booking/save_push_token',
    userDeals: '/booking/user_deals',
    generalDeals: '/booking/general_deals',
    generalDealsV2: '/booking/v2/general_deals',
    finishedAnswers: '/booking/finished_answers',
    activatePrimePreorder:'/user/activate_prime_preorder',
    unsub: '/misc/unsub',
    validateUnsubCode: '/misc/unsub_validate',
    passOrderDetails: '/access/prepass',
userPassOrder: '/user/prepass_order',
    OurRecommendation: '/booking/deals',
    passSubscribePartner:'/user/pass_subscribe_partner',
    partnerOrder:'/access/partner_order',
    actuator: '/actuator/health',
    couponOffers: '/misc/coupon_offers',
    couponOrder: '/misc/coupon_order',
    isPass:'/user/is_pass',
    sendCompanyVerificationEmail: '/access/company/verify_email',
    calculateSavings: '/access/company/calculate_savings',
    getSavingsCalculatorQuestions: '/access/company/savings_calculator_questions',
    getCompanyCodeEmail: '/access/company/get_verification_code_info',
    createCompany: '/access/company/create',
    companyLinkEmailToSocial: '/access/company/link_profile',
    getCompanyByEmail: '/access/company/by_email',
    checkCompanyId: '/access/company/id_check',
    socialMediaCompany: '/access/company/social_login?socialNetwork=',
    companyLogin: '/access/company/login',
    companyForgetPassword: '/access/company/forget_password',
    companyResetPassword: '/access/company/reset_password',
    companyInfoById: '/access/company/by_id',
    registerToCompany: '/access/company/register',
    getEmailByInvitationCode: '/access/company/get_invitation_code_info',
    updateCompanyLogo: '/company/update_logo',
    updateCompany: '/company/update',
    getCompanyInfo: '/company/info',
    companyLogout: '/company/logout',
    setOwner: '/company/set_owner',
    companyAvailability: '/company/hotel/availability',
    addCompanyPayment: '/company/add_payment_method',
    companyListPaymentMethods: '/company/list_payment_methods',
    deleteCompanyPayment: '/company/delete_payment_method',
    updateCompanyPayment: '/company/update_payment_method',
    inviteToCompany: '/company/invite',
    updateCompanyAddress: '/company/update_address',
    disableTeamPersonalPayments: '/company/team/disable_enable_personal_payments',
    getTeamsList: '/company/team/list',
    getTeamDetails: '/company/team/details',
    createTeam: '/company/team/create',
    updateTeam: '/company/team/update',
    getTeamMembers: '/company/team/members',
    deleteTeam: '/company/team/delete',
    addUsersToTeam: '/company/team/add_user',
    removeUsersFromTeam: '/company/team/remove_user',
    removeTeamManager: '/company/team/remove_team_manager',
    removeTeamPolicy: '/company/team/remove_team_policy',
    teamListPaymentMethods: '/company/team/list_payment_methods',
    deleteTeamPayment: '/company/team/delete_payment_method',
    addTeamPayment: '/company/team/add_payment_method',
    policyList: '/company/policy/list',
    createPolicy: '/company/policy/v2/create',
    deletePolicy: '/company/policy/delete',
    editPolicy: '/company/policy/v2/edit',
    getPolicyDetails: '/company/policy/details',
    resetDefaultPolicy: '/company/reset_default_policy',
    setDefaultPolicy: '/company/set_default_policy',
    userList: '/company/user/list',
    disablePersonalPayments: '/company/user/disable_enable_personal_payments',
    deactivateUser: '/company/user/deactivate',
    userReactivation: '/company/user/reactivate',
    userReactivationFees: '/company/user/reactivation_fees',
    getPendingInvitations: '/company/pending_invitations',
    companyUserInfo: '/company/user/info',
    addPaymentMethodToUser: '/company/user/add_payment_method',
    deletePaymentMethodFromUser: '/company/user/delete_payment_method',
    updateCompanyUser: '/company/user/update',
    removeUserPolicy: '/company/user/remove_policy',
    addFavoriteHotel: '/company/me/fav',
    setProfileImage: '/company/me/set_profile_image',
    removeProfileImage: '/company/me/remove_profile_image',
    delFavoriteHotels: '/company/me/fav_delete',
    getFavoriteHotels: '/company/me/get_favorites',
    getUserSearchHistory: '/company/me/search_history',
    addPaymentMe: '/company/me/add_card',
    deletePaymentMe: '/company/me/delete_card',
    updatePaymentMe: '/company/me/update_card',
    getPaymentsMe: '/company/me/payment_methods',
    changePasswordMe: '/company/me/change_password',
    getUserMetricsMe: '/company/me/dashboard',
    userUpdateMe: '/company/me/update',
    cancelBookingMe: '/company/me/cancel_booking',
    calculateCancellationFeesMe: '/company/me/calculate_cancellation_fee',
    retrieveBookingsMe: '/company/me/hotel/bookings',
    retrieveAllBookingsMe: '/company/me/all_bookings',
    retrieveBookingMe: '/company/me/hotel/retrieve_booking',
    sendCompanyBookingVoucher: '/company/me/share_hotel_booking',
    companyShareHotels: '/company/me/share_hotel',
    getBookingPaymentMethods: '/company/booking/payment_methods',
    recheckRate: '/company/hotel/recheck',
    airValidate: '/booking/air/validate',
    companyBooking: '/company/hotel/booking/book',
    requiresApprovalCheck: '/company/hotel/booking/requires_approval',
    requestApproval: '/company/hotel/booking/request_approval',
    listRequesterApprovals: '/company/hotel/booking/list_requester_approvals',
    listRequesterFlightApprovals: '/company/air/booking/list_requester_approvals',
    listApproversApprovals: '/company/hotel/booking/list_approver_approvals',
    approvalDisapprove: '/company/hotel/booking/disapprove',
    approvalDisapproveFlight: '/company/air/booking/disapprove',
    approvalApprove: '/company/hotel/booking/approve',
    approvalApproveFlight: '/company/air/booking/approve',
    withdrawApprovalRequest: '/company/hotel/booking/withdraw_approval_request',
    withdrawFlightApprovalRequest: '/company/air/booking/withdraw_approval_request',
    approvalDetails: '/company/hotel/booking/approval_details',
    approvalRequestMoreInfo: '/company/hotel/booking/approval_request_more_info',
    flightApprovalRequestMoreInfo: '/company/air/booking/approval_request_more_info',
    approvalSubmitMoreInfo: '/company/hotel/booking/approval_submit_more_info',
    retrieveCompanyBookings: '/company/hotel/bookings',
    bookingByRef: '/company/hotel/booking/by_reference_number',
    subscriptionDetails: '/company/subscription_details',
    subscriptionDetailsV2: '/company/subscription_details/v2',
    addBillingCard: '/company/add_billing_card',
    deleteBillingCard: '/company/delete_billing_card',
    downgradeSub: '/company/downgrade_subscription',
    switchPlan: '/company/switch_plan',
    changePlan: '/company/change_plan',
    listBillingMethods: '/company/list_billing_methods',
    planSwitchFees: '/company/plan_switch_fees',
    changePlanFees: '/company/change_plan_fees',
    updateBillingCard: '/company/update_billing_card',
    upgradeSub: '/company/upgrade_subscription',
    workspacePlans: '/company/plans',
    invoices: '/company/invoices',
    invoicesManual: '/company/subscription_details/history/v2',
    cancelSubscriptionSchedule: '/company/cancel_subscription_schedule',
    deactivateCompany: '/company/deactivate',
    reactivateCompany: '/company/reactivate',
    reactivationFees: '/company/reactivation_fees',
    resendPendingInvitation: '/company/resend_pending_invitation',
    cancelPendingInvitation: '/company/cancel_pending_invitation',
    lastSubscriptionDetails: '/company/last_subscription_details',
    companyDashboard: '/company/dashboard',
    getExpensifyCreds: '/company/expensify_credentials',
    removeExpensifyCreds: '/company/expensify_credentials_remove',
    setExpensifyCreds: '/company/expensify_credentials_set',
    getExpensifyPolicies: '/company/expensify_policies',
    getExpensifyStatus: '/company/expensify_status',
    enableExpensifyCreds: '/company/expensify_credentials_enable',
    expensifyAddHotelBookingExpense: '/company/me/expensify_add_hotel_booking_expense',
    expensifyReports: '/company/me/expensify_reports',
    companyLookup: '/company/booking/lookup',
    companyAirAvailability: '/company/air/availability',
    companyAirBook: '/company/air/book',
    companyAirValidate: '/company/air/validate',
    saveRewardProgram: '/company/save_reward_program',
    deleteRewardProgram: '/company/delete_reward_program',
    requiresAirApprovalCheck: '/company/air/booking/requires_approval',
    flightListApproversApprovals: '/company/air/booking/list_approver_approvals',
    requestFlightApproval: '/company/air/booking/request_approval',
    flightApprovalSubmitMoreInfo: '/company/air/booking/approval_submit_more_info',
    flightApprovalDetails: '/company/air/booking/approval_details',
    airItineraryRulesCompany: '/company/air/rules',
    getRewardWalletOperations: '/company/me/reward_wallet_history',
    retrieveAirBookingMe: '/company/me/air/retrieve_booking',
    getOtaPrices: '/company/hotel/online_prices',
    companyLookupAirports: '/company/booking/lookup_airports',
    addFlyerProgram: '/company/me/add_frequent_flyer_program',
    addTravelDocument: '/company/me/add_travel_document',
    getAirLoyaltyPrograms: '/booking/air/loyalty_programs',
    getAirLoyaltyProgramsCompany: '/company/booking/air_loyalty_programs',
    updateFrequentFlyerProgram: '/company/me/update_frequent_flyer_program',
    updateTravelDocument: '/company/me/update_travel_document',
    removeFrequentFlyerProgram: '/company/me/remove_frequent_flyer_program',
    removeTravelDocument: '/company/me/remove_travel_document',
    connectSlack: '/company/me/slack/connect',
    getExperiences: '/booking/experiences',
    getApplicablePolicy: '/company/get_applicable_policy',
    shareHotelBookingByEmail: '/company/me/share_hotel_booking_by_email',
    shareFlightBookingByEmail: '/company/me/share_air_booking_by_email',
    getAddresses: '/company/get_addresses',
    addOtherAddress: '/company/add_other_address',
    updateOtherAddress: '/company/update_other_address',
    removeOtherAddress: '/company/remove_other_address',
    company: '/company/',
    amazonSign: '/amazon/payment/request/sign'
  };

  addressApis = {
      assignTeamToAddress: '/company/assign_team_to_address',
      unAssignTeamAddress: '/company/unassign_team_from_address',
  };

  costManegementApis = {
    createCostCenter: 'create_cost_center',
    updateCostCenter: 'update_cost_center',
    setCostCenterStatus: 'set_cost_center_status',
    getCostCenters: 'get_cost_centers',
    createLabel: 'create_label',
    updateLabel: 'update_label',
    setLabelStatus: 'set_label_status',
    getLabels: 'get_labels',
    getMandatory:'info',
    setMandatory:'set_company_config',
    getActiveCostCenters:'me/get_active_cost_centers',
    getActiveLabels:'me/get_active_labels',
  };

  statisticsApis = {
    getBookingsStatistics: 'bookings_statistics'
  };

  timeOutPushNotification = 5000;
  extraOnlinePriceAddOn = 5;
    amazonUserCardsReturnUrl = 'https://api.triphop.com:8787/amazon/payment/user/add_amazon_card';


  constructor() {
      this.countries.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
      });

      this.states.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
      });

      this.industries.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
      });

  }
}
