<div class="workspace-home" *ngIf="this.auth.companyInfo">
  <div class="container" *ngIf="dataService.mobileView">
    <div class="mx-lg-5">
      <p
        class="font-weight-bold mt-5 primaray-text font-32-to-24"
        i18n="@@dashboardMsg1"
      >
        Book your next business trip
      </p>
    </div>
  </div>

  <div class="search-container mb-5">
    <div class="container" *ngIf="dataService.desktopView">
      <div class="mx-lg-5 pt-3">
        <p
          class="font-weight-bold text-white font-32-to-24 pt-4 mb-5"
          i18n="@@dashboardMsg1"
        >
          Book your next business trip
        </p>
      </div>
    </div>
    <div [ngClass]="{ 'container search-padding': dataService.desktopView }">
      <div
        class="mx-lg-5"
        [ngClass]="{
          'mobile-tab pt-5': dataService.mobileView,
          'pb-5':
            dataService.mobileView &&
            (!this.dataService.historyArray ||
              !this.dataService.historyArray.length)
        }"
      >
        <ngb-tabset [destroyOnHide]="false">
          <ngb-tab title="{{ hotels }}" id="hotels">
            <ng-template ngbTabContent class="tab-content">
              <div [ngClass]="{ 'd-flex': dataService.desktopView }">
                <div
                  class="search_box p-md-5 px-3 py-5 pt-lg-4 pb-lg-4 px-lg-4 position-relative"
                  *ngIf="this.dataService.desktopView"
                  [ngClass]="{
                    'rounded-bottom-left rounded-right':
                      dataService.isSiteLtr(),
                    'rounded-bottom-right rounded-left': dataService.isSiteRtl()
                  }"
                >
                  <app-search-controller
                    *ngIf="auth.userInfo?.role"
                    [getHistory]="true"
                  ></app-search-controller>
                </div>
                <div
                  class="p-md-5 px-3 py-5 pt-lg-4 pb-lg-4 mx-3 px-lg-4 bg-white position-relative"
                  *ngIf="this.dataService.mobileView"
                >
                  <app-search-controller-mobile
                    *ngIf="auth.userInfo?.role"
                  ></app-search-controller-mobile>
                </div>

                <div
                  class="history-box p-4 p-lg-5 mt-5 mt-lg-0 bg-white"
                  *ngIf="
                    this.dataService.historyArray &&
                    this.dataService.historyArray?.length
                  "
                  [ngClass]="{
                    'rounded w-100 position-relative': dataService.desktopView,
                    'ml-1': dataService.isSiteLtr() && dataService.desktopView,
                    'mr-1': dataService.isSiteRtl() && dataService.desktopView
                  }"
                >
                  <div
                    [ngClass]="{
                      'white-box position-relative': dataService.mobileView
                    }"
                  >
                    <p
                      class="font-16 gray-primaray-text font-weight-bold"
                      i18n="@@dashboardMsg2"
                    >
                      Continue your search
                    </p>
                    <div
                      *ngFor="
                        let item of this.dataService.historyArray;
                        let i = index
                      "
                    >
                      <div
                        class="mb-4 pointer"
                        (click)="openHistoryUrl(item)"
                        *ngIf="i < 3"
                      >
                        <p
                          class="font-16 gray-text-dark mb-1 dotted-text dotted-text-direction"
                        >
                          <i
                            class="icon-hotel-item font-20 gray-text"
                            [ngClass]="{
                              'mr-1': dataService.isSiteLtr(),
                              'ml-1': dataService.isSiteRtl()
                            }"
                          ></i>
                          {{ item.caption }}
                        </p>
                        <p class="font-12 gray-text">
                          {{ dataService.formatDateEEMMMdy(item.checkIn) }}
                          <span i18n="@@to">To</span>
                          {{ dataService.formatDateEEMMMdy(item.checkOut) }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="close-history pointer position-absolute blue-text p-2 font-12"
                      (click)="clearHistory()"
                    >
                      <span i18n="@@clearBtn">Clear</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>

          <ngb-tab title="{{ flight }}" id="flights">
            <ng-template ngbTabContent>
              <div [ngClass]="{ 'd-flex': dataService.desktopView }">
                <div
                  class="search_box p-md-5 px-3 py-5 pt-lg-4 pb-lg-4 px-lg-4 position-relative"
                  [ngClass]="{
                    'rounded-bottom-left rounded-right':
                      dataService.isSiteLtr(),
                    'rounded-bottom-right rounded-left':
                      dataService.isSiteRtl(),
                    'multi-city-width':
                      this.dataService.selectedTrip === 'Multi-city'
                  }"
                  *ngIf="this.dataService.desktopView"
                >
                  <app-flights-search-controller
                    *ngIf="auth.userInfo?.role"
                    [fromHomePage]="true"
                  ></app-flights-search-controller>
                </div>
                <div
                  class="p-md-5 px-3 pb-5 pt-4 mx-3 bg-white position-relative"
                  *ngIf="this.dataService.mobileView"
                >
                  <app-flights-search-controller-mobile
                    *ngIf="auth.userInfo?.role"
                  ></app-flights-search-controller-mobile>
                </div>
                <div
                  class="history-box p-4 p-lg-5 mt-5 mt-lg-0 bg-white"
                  *ngIf="flightHistoryArrayEmpty"
                  [ngClass]="{
                    'rounded w-100 position-relative': dataService.desktopView,
                    'ml-1': dataService.isSiteLtr() && dataService.desktopView,
                    'mr-1': dataService.isSiteRtl() && dataService.desktopView
                  }"
                >
                  <div
                    [ngClass]="{
                      'white-box position-relative': dataService.mobileView
                    }"
                  >
                    <p
                      class="font-16 gray-primaray-text font-weight-bold"
                      i18n="@@dashboardMsg2"
                    >
                      Continue your search
                    </p>
                    <div
                      *ngFor="
                        let item of this.dataService.flightHistoryArray;
                        let i = index
                      "
                    >
                      <div
                        class="mb-4 pointer"
                        (click)="openFlightHistoryUrl(item)"
                        *ngIf="i < 3"
                      >
                        <p
                          class="font-16 gray-text-dark mb-1 dotted-text dotted-text-direction"
                        >
                          <i
                            class="icon-flight-item font-14 gray-text"
                            [ngClass]="{
                              'mr-1': dataService.isSiteLtr(),
                              'ml-1': dataService.isSiteRtl()
                            }"
                          ></i>
                          {{ item.roundTrip ? "Roundtrip" : "One-way" }} ({{
                            item.airQueries[0].departureSearchCode
                          }}
                          - {{ item.airQueries[0].arrivalSearchCode
                          }}<ng-container *ngIf="item.roundTrip">
                            -
                            {{
                              item.airQueries[0].departureSearchCode
                            }}</ng-container
                          >)
                        </p>
                        <p class="font-12 gray-text">
                          {{
                            dataService.formatDateEEMMMdy(
                              item.airQueries[0].departureDate
                            )
                          }}
                          <ng-container *ngIf="item.airQueries[0].returnDate"
                            ><span i18n="@@to">to</span>
                            {{
                              dataService.formatDateEEMMMdy(
                                item.airQueries[0].returnDate
                              )
                            }}</ng-container
                          >
                        </p>
                      </div>
                    </div>
                    <div
                      class="close-history pointer position-absolute blue-text p-2 font-12"
                      (click)="clearFlightHistory()"
                    >
                      <span i18n="@@clearBtn">Clear</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>

  <div class="container mt-14 dashboard-page">
    <div class="mx-lg-5">
      <div
        class="custom-container"
        *ngIf="upcomingBooking && upcomingBooking.length"
      >
        <div class="max-width-600">
          <p class="primaray-text font-26 pt-4 bold-600" i18n="@@dashboardMsg3">
            Your next trips
          </p>

          <div *ngFor="let item of upcomingBooking; let i = index">
            <div *ngIf="item.bookingType === 'HOTEL'">
              <div class="d-flex">
                <i
                  class="icon-hotel-item font-25 gray-text"
                  [ngClass]="{
                    'mr-2': dataService.isSiteLtr(),
                    'ml-3': dataService.isSiteRtl()
                  }"
                ></i>
                <p class="gray-primaray-text mt-1 mb-2">
                  {{ dataService.formatDateEEMMMdy(item.checkinDate) }}
                </p>
              </div>
              <div
                class="mb-5 p-4 hotel-item rounded pointer position-relative"
                (click)="
                  viewDetails(
                    {
                      ref: item.referenceNumber,
                      identifier: item.emailAddress
                    },
                    item.bookingType
                  )
                "
              >
                <div class="details">
                  <h2 class="gray-primaray-text bold-600 font-16 mb-2">
                    {{ item.hotelName }}
                  </h2>
                  <div
                    class="gray-text mb-3 pb-3"
                    [ngClass]="{
                      'pr-3': dataService.isSiteLtr(),
                      'pl-3': dataService.isSiteRtl()
                    }"
                  >
                    {{ item.hotelAddress ? item.hotelAddress + ", " : "" }}
                    <br *ngIf="item.cityName" />
                    {{ item.cityName ? item.cityName : ""
                    }}{{ item.stateCode && item.cityName ? ", " : ""
                    }}{{ item.stateCode ? item.stateCode : ""
                    }}{{
                      (item.stateCode || item.cityName) && item.countryName
                        ? ", "
                        : ""
                    }}{{ item.countryName ? item.countryName : "" }}
                  </div>
                  <div class="gray-text mb-1">
                    {{ dataService.formatDateMMMdd(item.checkinDate) }} -
                    {{ dataService.formatDateMMMdd(item.checkoutDate) }}
                  </div>
                  <p
                    *ngIf="dataService.inactiveWorkspace"
                    class="view-guest font-16"
                  >
                    {{ item.guestFirstName }} {{ item.guestLastName }}
                  </p>
                </div>
                <div
                  *ngIf="item.rewardAmount && dataService.mobileView"
                  style="height: 34px"
                ></div>
                <div
                  class="reward-promo d-flex position-absolute"
                  *ngIf="item.rewardAmount"
                >
                  <div class="promo-triangle"></div>
                  <div class="promo-text text-white font-12 px-3 pt-2">
                    ${{ item.rewardAmount | number : "1.0-0" }}
                    <span
                      i18n="@@inRewards"
                      [ngClass]="{
                        'pl-2': dataService.isSiteLtr(),
                        'pr-2': dataService.isSiteRtl()
                      }"
                      >in Rewards</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="item.bookingType === 'FLIGHT'">
              <div class="d-flex">
                <i
                  class="icon-flight-item font-18 gray-text"
                  [ngClass]="{
                    'mr-2': dataService.isSiteLtr(),
                    'ml-3': dataService.isSiteRtl()
                  }"
                ></i>
                <p class="gray-primaray-text mb-2">
                  {{
                    dataService.formatDateEEMMMdy(
                      item.airItinerary.originDestinationOptionsGroups[0]
                        .summary.departureDate
                    )
                  }}
                  <span
                    *ngIf="item.airItinerary.originDestinationOptionsGroups[1]"
                  >
                    -
                  </span>
                  <span
                    *ngIf="item.airItinerary.originDestinationOptionsGroups[1]"
                    class="gray-text-dark"
                    >{{
                      dataService.formatDateEEMMMdy(
                        item.airItinerary.originDestinationOptionsGroups[1]
                          .summary.departureDate
                      )
                    }}</span
                  >
                </p>
              </div>
              <div
                class="position-relative mb-5"
                (click)="
                  viewDetails(
                    {
                      ref: item.hrsReferenceNumber,
                      identifier: item.bookRequest.email
                    },
                    item.bookingType
                  )
                "
              >
                <div
                  class="p-4 hotel-item rounded pointer"
                  *ngFor="
                    let destination of item.airItinerary
                      .originDestinationOptionsGroups
                  "
                >
                  <div class="details">
                    <div class="row">
                      <div class="col-8">
                        <h2 class="gray-primaray-text bold-600 font-16 mb-2">
                          {{ destination.summary.departureAirport.city }} -
                          {{ destination.summary.arrivalAirport.city }}
                        </h2>
                        <p class="font-14 primaray-text font-weight-bold mt-3">
                          <span
                            class="mb-0 primaray-text"
                            [innerHTML]="
                              this.dataService.formatDateTime12Hours(
                                destination.summary.departureDate
                              )
                            "
                            >10:15pm</span
                          >
                          -
                          <span
                            class="mb-0 primaray-text"
                            [innerHTML]="
                              this.dataService.formatDateTime12Hours(
                                destination.summary.arrivalDate
                              )
                            "
                            >10:15pm</span
                          >

                          <span
                            class="font-weight-normal"
                            *ngIf="
                              dataService.checkNextDay(
                                destination.summary.departureDate,
                                destination.summary.arrivalDate
                              ) === 1
                            "
                            i18n="@@nextDay"
                          >
                            (Next day)</span
                          >
                          <span
                            class="font-weight-normal"
                            *ngIf="
                              dataService.checkNextDay(
                                destination.summary.departureDate,
                                destination.summary.arrivalDate
                              ) > 1
                            "
                          >
                            +{{
                              dataService.checkNextDay(
                                destination.summary.departureDate,
                                destination.summary.arrivalDate
                              )
                            }}</span
                          >
                          <span
                            class="font-weight-normal"
                            *ngIf="
                              dataService.checkPreviousDay(
                                destination.summary.departureDate,
                                destination.summary.arrivalDate
                              )
                            "
                            i18n="@@prevDay"
                          >
                            (Previous day)</span
                          >
                        </p>
                        <p class="gray-text-dark font-14">
                          <span>{{
                            destination.summary.departureAirport.iata
                          }}</span
                          ><span class="super-vertical-align"> ____ </span
                          ><span>{{
                            destination.summary.arrivalAirport.iata
                          }}</span>
                        </p>
                      </div>
                      <div
                        class="col-4"
                        [ngClass]="{ 'text-right': dataService.mobileView }"
                      >
                        <div
                          [ngClass]="{
                            'h-100 d-flex pt-4': dataService.mobileView
                          }"
                        >
                          <p
                            class="mb-0 primaray-text"
                            [ngClass]="{
                              'my-auto ml-auto': dataService.mobileView
                            }"
                          >
                            <span
                              [innerHTML]="
                                this.dataService.calDuration(
                                  destination.summary.journeyDurationInMins
                                )
                              "
                              >6h 45m</span
                            >
                            <br />
                            <span
                              *ngIf="destination.summary.totalStops === 1"
                              i18n="@@oneStop"
                            >
                              1 stop</span
                            >
                            <span
                              *ngIf="destination.summary.totalStops === 2"
                              i18n="@@twoStops"
                            >
                              2 stops</span
                            >
                            <span *ngIf="destination.summary.totalStops > 2">
                              {{ destination.summary.totalStops }}
                              <ng-container i18n="@@stops"
                                >stops</ng-container
                              ></span
                            >
                            <span
                              class="green-text"
                              *ngIf="destination.summary.totalStops === 0"
                              i18n="@@nonStop"
                              >Nonstop</span
                            >
                          </p>
                        </div>
                        <!-- <div  *ngFor="let stop of destination.summary.stopSummaries ,  let i = index" >
                                 <p class="gray-text-dark mb-0">
                                   <span [innerHTML]="this.dataService.calDuration(stop.stopDurationInMinutes)"></span> in <span>{{stop.airport.iata}}</span>
                                 </p>
                               </div>-->
                        <!-- <p class="green-text" *ngIf="destination.summary.totalStops === 0">Nonstop</p>-->
                      </div>
                    </div>
                    <div class="airlines">
                      <p
                        class="mb-0 gray-text-dark"
                        *ngIf="
                          destination.summary.airlineInfoSummary
                            .marketingAirlines.length === 1
                        "
                      >
                        {{
                          destination.summary.airlineInfoSummary
                            .marketingAirlines[0]
                        }}.
                        {{
                          destination.originDestinationOptions[0].flightSegment
                            .cabinClassName
                        }}
                      </p>
                      <p
                        class="mb-0 gray-text-dark"
                        *ngIf="
                          destination.summary.airlineInfoSummary
                            .marketingAirlines.length > 1
                        "
                      >
                        <span i18n="@@multipleAirlines"
                          >Multiple Airlines:
                        </span>
                        <span
                          *ngFor="
                            let airlineItem of destination.summary
                              .airlineInfoSummary.marketingAirlines
                          "
                        >
                          {{ airlineItem }}.
                        </span>
                        {{
                          destination.originDestinationOptions[0].flightSegment
                            .cabinClassName
                        }}
                      </p>
                    </div>
                  </div>
                  <div
                    *ngIf="item.rewardAmount && dataService.mobileView"
                    style="height: 34px"
                  ></div>
                  <div
                    class="reward-promo d-flex position-absolute"
                    *ngIf="item.rewardAmount"
                  >
                    <div class="promo-triangle"></div>
                    <div class="promo-text text-white font-12 px-3 pt-2">
                      ${{ item.rewardAmount | number : "1.0-0" }}
                      <span
                        i18n="@@inRewards"
                        [ngClass]="{
                          'pl-2': dataService.isSiteLtr(),
                          'pr-2': dataService.isSiteRtl()
                        }"
                        >in Rewards</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--   <div *ngIf="dataService.mobileView">
                <p class="primaray-text font-18 pt-4">Your next trips</p>
                <div *ngFor="let item of upcomingBooking; let i = index">
                  <div class=" mb-4 hotel-item rounded" >

                    <div >

                      <div class="row">
                        <div class="col-5 col-lg-4">
                          <div class="hotel_image">
                            <a class="clickable-cell" [routerLink]="['/' + this.companyID + '/hotel/booking/details']" [queryParams]="{ref: item.referenceNumber, identifier : item.emailAddress}">
                              <div *ngIf="item.hotelImages[0] && item.hotelImages[0].url && item.hotelImages[0].url.includes('https://')" >
                                <img width="1000" height="667" class="rounded-left" [src]="item.hotelImages[0].url "  onError="this.src='assets/img/no_image.png'"
                                     loading="lazy" alt="hotel image">
                              </div>

                              <div width="1000" height="667" *ngIf="item.hotelImages[0] && item.hotelImages[0].url && !item.hotelImages[0].url.includes('https://') " >
                                <img class="rounded-left" [src]="item.hotelImages[0].url.replace('http://','https://')"  onError="this.src='assets/img/no_image.png'"
                                     loading="lazy" alt="hotel image">
                              </div>
                              <ng-container *ngIf="!item.hotelImages || !item.hotelImages[0] || !item.hotelImages[0].url">
                                <img width="279" height="239" class="rounded-left" loading="lazy" src="assets/img/no_image.png" />

                              </ng-container>

                            </a>
                            <span class="position-absolute font-20" *ngIf="this.upcomingBooking[i].triphopId"
                                  [ngClass]="{ 'icon-colored-heart': this.upcomingBooking[i].favorite, 'icon-heart' : !this.upcomingBooking[i].favorite}"
                                  (click)="this.dataService.toggleFavoriteHotel(this.upcomingBooking[i].triphopId); this.upcomingBooking[i].favorite = !this.upcomingBooking[i].favorite">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>

                          </div>

                        </div>
                        <div class="col-7 col-lg-8 pl-0">
                          <a class="clickable-cell" [routerLink]="['/' + this.companyID + '/hotel/booking/details']" [queryParams]="{ref: item.referenceNumber, identifier : item.emailAddress}">
                            <div class="pt-2 pt-lg-4 pb-4 pr-4">
                              <div class="row details">

                                <div class="col-lg-6 pl-3 pl-lg-4">
                                  <h2 class="primaray-text bolder font-18-to-14 mb-0 mb-lg-2 hotel-name">{{item.hotelName}}</h2>
                                </div>

                                <div class="col-lg-5 col-md-6 pl-3 pl-lg-0">

                                  <div class="price mt-0 mt-lg-5 cash-back-cont bookings-item">
                                    <div class="mt-1 mt-lg-2 cashback text-left">
                                      <div class="value font-12 gray-primaray-text">{{formatDailyDate(item.checkinDate)}} To {{formatDailyDate(item.checkoutDate)}}
                                      </div>
                                    </div>
                                  </div>
                                </div>


                              </div></div>

                          </a>
                        </div>


                      </div>


                    </div>

                  </div>
                </div>
              </div>-->
      </div>

      <div [ngClass]="{ 'custom-container': dataService.desktopView }">
        <div
          class="mb-5 pb-4"
          *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
        >
          <div
            *ngIf="
              !this.dataService.companyPoliciesList ||
              (this.usersList && this.usersList.length === 1)
            "
          >
            <ng-container
              *ngIf="
                (this.usersList && this.usersList.length === 1) ||
                (!this.dataService.companyPoliciesList &&
                  this.dataService.policyLoader)
              "
            >
              <p
                class="primaray-text font-26 mt-5 pt-4 bold-600"
                *ngIf="dataService.desktopView"
                i18n="@@dashboardMsg4"
              >
                Complete your corporate workspace
              </p>
              <p
                class="primaray-text font-18 mt-5 pt-4 bold-600"
                *ngIf="dataService.mobileView"
                i18n="@@dashboardMsg4"
              >
                Complete your corporate workspace
              </p>
            </ng-container>
            <div
              class="border my-4 gray-primaray-text bold-600 px-4 px-lg-5 py-4 company-section-with-thick-border"
              [ngClass]="{
                'rounded-right': dataService.desktopView,
                'thick-top rounded-bottom': dataService.mobileView
              }"
              *ngIf="this.usersList && this.usersList.length === 1"
            >
              <div
                class="row py-2 h-100 justify-content-center align-items-center"
              >
                <div class="col-md-7">
                  <p
                    class="font-18-to-16 gray-primaray-text bold-600"
                    i18n="@@dashboardMsg5"
                  >
                    Build your team
                  </p>
                  <span
                    class="font-14 font-weight-normal gray-text-dark"
                    i18n="@@dashboardMsg6"
                    >Invite coworkers, create and manage teams to organize your
                    workspace.</span
                  >
                </div>
                <div class="col-md-5 text-right">
                  <a
                    (click)="
                      this.router.navigate([this.router.url + '/members'])
                    "
                    class="btn-block white px-5 py-3 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center primaray-text sharp-font bold-500 bg-white desktop-btn"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                    i18n="@@inviteNow"
                    >Invite Now</a
                  >
                </div>
              </div>
            </div>
            <div
              class="my-4 border px-4 px-lg-5 py-4 company-section-with-thick-border"
              [ngClass]="{
                'rounded-right': dataService.desktopView,
                'thick-top rounded-bottom': dataService.mobileView
              }"
              *ngIf="
                !this.dataService.companyPoliciesList &&
                this.dataService.policyLoader &&
                (this.auth.isAdmin() || this.auth.isOwner())
              "
            >
              <div
                class="row py-2 h-100 justify-content-center align-items-center"
              >
                <div class="col-md-7">
                  <p
                    class="font-18-to-16 gray-primaray-text bold-600"
                    i18n="@@dashboardMsg7"
                  >
                    Set Travel Policies
                  </p>
                  <span
                    class="font-14 font-weight-normal gray-text-dark"
                    i18n="@@dashboardMsg8"
                    >Create your corporate travel policies, set booking limits
                    and approvals.</span
                  >
                </div>
                <div class="col-md-5 text-right">
                  <a
                    (click)="
                      this.router.navigate([
                        this.router.url + '/travel-policies'
                      ])
                    "
                    class="btn-block white px-4 py-3 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center primaray-text sharp-font bold-500 bg-white desktop-btn"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                    i18n="@@createPolicy"
                  >
                    Create Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <app-dashboard></app-dashboard>-->
      </div>
      <ng-container *ngIf="env.rewardsOnSystemLevel !== false">
        <div
          class="mb-5 pb-5 pt-4 rewards-bg"
          *ngIf="
            !this.auth.companyInfo.rewardProgram &&
            (this.auth.isAdmin() || this.auth.isOwner())
          "
        >
          <p
            class="primaray-text font-26 mb-5 pt-4 bold-600"
            *ngIf="dataService.desktopView"
            i18n="@@dashboardMsg9"
          >
            Save more by rewarding your employees
          </p>
          <p
            class="primaray-text font-18 mb-5 pt-4 bold-600"
            *ngIf="dataService.mobileView"
            i18n="@@dashboardMsg9"
          >
            Save more by rewarding your employees
          </p>
          <div class="d-flex mb-5">
            <div
              class="number-circle primaray-text font-16 bold-600 text-center"
              [ngClass]="{
                'mr-3': dataService.isSiteLtr(),
                'ml-3': dataService.isSiteRtl()
              }"
            >
              1
            </div>
            <div>
              <p
                class="gray-primaray-text font-16 mb-0 bold-600"
                i18n="@@dashboardMsg10"
              >
                Set Travel Budget
              </p>
              <p class="gray-text-dark mb-0" i18n="@@dashboardMsg11">
                Set your Travel Policy cap for travel
              </p>
            </div>
          </div>

          <div class="d-flex mb-5">
            <div
              class="number-circle primaray-text font-16 bold-600 text-center"
              [ngClass]="{
                'mr-3': dataService.isSiteLtr(),
                'ml-3': dataService.isSiteRtl()
              }"
            >
              2
            </div>
            <div>
              <p
                class="gray-primaray-text font-16 mb-0 bold-600"
                i18n="@@dashboardMsg12"
              >
                Give Rewards
              </p>
              <p class="gray-text-dark mb-0" i18n="@@dashboardMsg13">
                Incentives your employees to book cheaper travel
              </p>
            </div>
          </div>

          <div class="d-flex mb-4">
            <div
              class="number-circle primaray-text font-16 bold-600 text-center"
              [ngClass]="{
                'mr-3': dataService.isSiteLtr(),
                'ml-3': dataService.isSiteRtl()
              }"
            >
              3
            </div>
            <div>
              <p
                class="gray-primaray-text font-16 mb-0 bold-600"
                i18n="@@dashboardMsg14"
              >
                Save More
              </p>
              <p class="gray-text-dark mb-0" i18n="@@dashboardMsg15">
                Employees book cheaper travel to get percentage of savings
              </p>
            </div>
          </div>
          <a
            *ngIf="dataService.desktopView"
            class="font-16 blue-text mb-5"
            [ngClass]="{
              'ml-5 pl-4': dataService.isSiteLtr(),
              'mr-5 pr-4': dataService.isSiteRtl()
            }"
            (click)="
              this.router.navigate([this.router.url + '/settings/rewards'])
            "
            i18n="@@createProg"
            >Create My Program</a
          >
          <div *ngIf="dataService.mobileView" class="container">
            <button
              class="btn btn-outline-blue font-14 sharp-font px-3 w-100 px-5"
              (click)="
                this.router.navigate([this.router.url + '/settings/rewards'])
              "
              i18n="@@createProg"
            >
              Create My Program
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
