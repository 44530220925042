<div class="primary-menu-wrapper popup mobile menu-mobile scroll-y" [ngClass]="{'show': dataService.manageWorkspaceMenuActive && this.auth.companyInfo && this.auth.companyInfo.inactive === false}" *ngIf="dataService.mobileView">
<!--<div class="position-fixed bg-white w-100 index-1">-->
  <div class="header">
    <a (click)="this.router.navigate(['/']);" class="logo_link" (click)="closeMenu()">
      <img width="145" height="35" loading="lazy" [src]="this.dataService.desktopView ? 'assets/img/logo.svg'  : 'assets/img/logo-mobile.svg'"
           class="logo-image initial-height" [ngClass]="{'max-width' : this.dataService.mobileView}" alt="SafarHub Logo">
    </a>
  </div>
  <div class="close_popup" (click)="closeMenu()">
    <i class="icon-cross font-weight-bold"></i>
  </div>
<!--</div>
<div class="my-50px" *ngIf="router.url !== '/'" [ngClass]="{'header-popup-body' : !this.auth.isLogedIn, 'header-popup-body-height' : this.auth.isLogedIn}">-->
  <div *ngIf="router.url !== '/'" [ngClass]="{'header-popup-body' : !this.auth.isLogedIn, 'header-popup-body-height' : this.auth.isLogedIn}">
    <div class="mb-5" *ngIf="this.auth.isManager() || this.auth.isAdmin() || this.auth.isOwner()">

      <div class="d-flex my-2" *ngIf="this.auth.isManager() || this.auth.isAdmin() || this.auth.isOwner()"
           (click)="this.router.navigate(['/approvals']); closeMenu()"
           [ngClass] = "{'active': this.auth.companyInfo &&  this.router.url.includes('approvals')}">
        <div class="dashboard-icon-container bg-white text-center position-relative container-left-margin" >
          <i class="icon-workspace-approvals gray-primaray-text font-16 d-inline"></i>
          <div class="workspace-approvals-badge text-white font-12  text-center" *ngIf="this.auth.isLogedIn && this.dataService.workspaceApprovalsBadge && this.dataService.workspaceApprovalsBadge < 10 && this.dataService.workspaceApprovalsBadge > 0">
            {{this.dataService.workspaceApprovalsBadge}}
          </div>
          <div class="workspace-approvals-badge-large text-white px-1 font-12  text-center" *ngIf="this.auth.isLogedIn && this.dataService.workspaceApprovalsBadge && this.dataService.workspaceApprovalsBadge >= 10">
            {{this.dataService.workspaceApprovalsBadge}}
          </div>
        </div>
        <p class="item-text-pt mt-2 mb-0 primaray-text" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemApprovals">Approvals</p>
      </div>

      <div class="d-flex my-2" *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
           (click)="this.router.navigate(['/members']);  closeMenu()"
           [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('members') && !this.router.url.includes('teams')}">
        <div class="dashboard-icon-container bg-white text-center container-left-margin" >
           <i class="icon-workspace-users-colored gray-primaray-text-text font-16 d-inline"></i>



        </div>
        <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemMembers">Manage Members</p>
      </div>


      <div class="d-flex my-2"  *ngIf="this.auth.isManager() || this.auth.isAdmin() || this.auth.isOwner()"
           (click)="this.router.navigate(['/teams']); closeMenu()"
           [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('teams')}">
        <div class="dashboard-icon-container bg-white text-center container-left-margin" >
          <i class="icon-manage-team gray-primaray-text font-18 d-inline"></i>
        </div>
        <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemTeams">Manage Teams</p>
      </div>

      <div class="d-flex my-2"  *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
           (click)="this.router.navigate(['/travel-policies']); closeMenu()"
           [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('travel-policies')}">
        <div class="dashboard-icon-container bg-white text-center container-left-margin" >
          <i class="icon-policies gray-primaray-text font-18 d-inline"></i>
        </div>
        <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemPolicies">Travel Policies</p>
      </div>

      <div class="d-flex my-2" *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
           (click)="this.router.navigate(['/reports']); closeMenu()"
           [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('reports')}">
        <div class="dashboard-icon-container bg-white text-center container-left-margin" >
          <i class="icon-workspace-reports gray-primaray-text font-14 d-inline"></i>
        </div>
        <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemReports">Reports</p>
      </div>
    </div>

    <div class="d-flex my-2" (click)="this.router.navigate(['/' ]); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && this.router.url === '/' }">
      <div class="dashboard-icon-container bg-white text-center container-left-margin" >
        <i class="icon-book-trip gray-primaray-text font-18 d-inline"></i>
      </div>
      <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemBookTrip">Book a Trip</p>
    </div>

    <div class="d-flex my-2"  (click)="this.router.navigate(['/user/trips']); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('/user/trips')}">
      <div class="dashboard-icon-container my-trips bg-white text-center container-left-margin">
        <i class="icon-plane flipped gray-primaray-text font-12 d-inline"></i>
      </div>
      <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemMyTrips">My Trips</p>
    </div>



    <div class="d-flex my-2"  (click)="this.router.navigate(['/user/favorites']); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('/user/favorites')}">
      <div class="dashboard-icon-container fav bg-white text-center container-left-margin">
        <i class="icon-workspace-fav flipped gray-primaray-text font-18 d-inline"></i>
      </div>
      <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemFavorites">Favorites</p>
    </div>

    <div class="d-flex my-2"  (click)="this.router.navigate(['/user/requests']); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('requests')}">
      <div class="dashboard-icon-container trip-approval bg-white text-center container-left-margin">
        <i class="icon-workspace-approval-requests flipped gray-primaray-text font-20 d-inline"></i>
      </div>
      <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemMyApprovals">My Trip Approvals</p>
    </div>



    <div *ngIf="env.rewardsOnSystemLevel !== false" class="d-flex my-2"  (click)="this.router.navigate(['/user/profile']); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && (this.router.url.includes('/user/profile') || this.router.url.includes('/user/payment') || this.router.url.includes('/user/rewards'))}">
      <div class="dashboard-icon-container bg-white text-center container-left-margin"
           [ngClass]="{'overflow-hidden p-0 border-0': auth.userInfo && auth.userInfo.imageUrl && !this.dataService.showDefaultUserImage}">
        <i class="icon-workspace-user flipped  font-16 d-inline" *ngIf="auth.userInfo && !auth.userInfo.imageUrl || this.dataService.showDefaultUserImage"></i>
        <img width="30" height="30" loading="lazy" *ngIf="auth.userInfo && auth.userInfo.imageUrl && !this.dataService.showDefaultUserImage"
             class="user-img"
             onError="this.src='assets/img/default-user.png'" [src]="this.dataService.userProfileImageWithRandomText || auth.userInfo.imageUrl" />
      </div>
      <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" *ngIf="auth.userInfo">{{auth.userInfo.name}} {{auth.userInfo.lastName}}</p>
    </div>
   <!-- <div class="setting t-2 mb-0  pt-2 pb-4 " *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
         (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('settings') && !this.router.url.includes('users') && !this.router.url.includes('policies')}">
      <div class="d-flex">
        <div class="text-center" [ngClass]="{'gray-primaray-text': this.auth.companyInfo && !this.router.url.includes('settings'), 'blue-text': this.auth.companyInfo && this.router.url.includes('settings') && !this.router.url.includes('users') && !this.router.url.includes('policies')}">
          <i class="icon-workspace-settings d-inline" ></i>
        </div>
        <p class="text ml-3 mb-0" [ngClass]="{'gray-primaray-text': this.auth.companyInfo && !this.router.url.includes('settings'), 'blue-text': this.auth.companyInfo && this.router.url.includes('settings') && !this.router.url.includes('users') && !this.router.url.includes('policies')}">Settings</p>
      </div>
    </div>-->


  </div>





  <div class="footer">
    <div class="setting mb-0 pt-2 pb-4 " *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
         (click)="this.router.navigate(['/settings']); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('settings') && !this.router.url.includes('members') && !this.router.url.includes('travel-policies')}">
      <div class="d-flex">
        <div class="text-center" [ngClass]="{'gray-primaray-text': this.auth.companyInfo && !this.router.url.includes('settings'), 'blue-text': this.auth.companyInfo && this.router.url.includes('settings') && !this.router.url.includes('members') && !this.router.url.includes('travel-policies')}">
          <i class="icon-workspace-settings d-inline" ></i>
        </div>
        <p class="text mb-0" [ngClass]="{'gray-primaray-text': this.auth.companyInfo && !this.router.url.includes('settings'),
        'blue-text': this.auth.companyInfo && this.router.url.includes('settings') && !this.router.url.includes('members') && !this.router.url.includes('travel-policies'),
        'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemSettings">Settings</p>
      </div>
    </div>
  </div>



</div>






<div class="primary-menu-wrapper popup mobile menu-mobile" [ngClass]="{'show': dataService.manageWorkspaceMenuActive && this.auth.companyInfo && this.auth.companyInfo.inactive}" *ngIf="dataService.mobileView">

  <div class="header">
    <a (click)="this.router.navigate(['/']);" class="logo_link" (click)="closeMenu()">
      <img width="145" height="35" loading="lazy" [src]="this.dataService.desktopView ? 'assets/img/logo.svg'  : 'assets/img/logo-mobile.svg'"
           class="logo-image initial-height" [ngClass]="{'max-width' : this.dataService.mobileView}" alt="SafarHub Logo">
    </a>
  </div>
  <div class="close_popup" (click)="closeMenu()">
    <i class="icon-cross font-weight-bold"></i>
  </div>

  <div *ngIf="router.url !== '/'" [ngClass]="{'header-popup-body' : !this.auth.isLogedIn, 'header-popup-body-height' : this.auth.isLogedIn}">
    <div class="mb-5" >



      <div class="d-flex my-2"
           (click)="this.router.navigate(['/members']);  closeMenu()"
           [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('members')}">
        <div class="dashboard-icon-container bg-white text-center container-left-margin" >
           <i class="icon-workspace-users-colored gray-primaray-text font-16 d-inline"></i>



        </div>
        <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemMembers">Manage Members</p>
      </div>

      <div class="d-flex my-2" (click)="this.router.navigate(['/user/trips']); closeMenu()"
           [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('user/trips')}">
        <div class="dashboard-icon-container bg-white text-center container-left-margin" >
          <i class="icon-workspace-reports gray-primaray-text font-14 d-inline"></i>
        </div>
        <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemCompTrips">Company Trips</p>
      </div>
      <div class="d-flex my-2" (click)="this.router.navigate(['/reports']); closeMenu()"
           [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('reports')}">
        <div class="dashboard-icon-container bg-white text-center container-left-margin" >
          <i class="icon-workspace-reports gray-primaray-text font-14 d-inline"></i>
        </div>
        <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemReports">Reports</p>
      </div>
      <div class="d-flex my-2" (click)="this.router.navigate(['/settings/billing']); closeMenu()"
           [ngClass] = "{'active': this.auth.companyInfo && this.router.url.includes('billing')}">
        <div class="dashboard-icon-container bg-white text-center container-left-margin" >
          <i class="icon-billing gray-primaray-text font-14 d-inline"></i>
        </div>
        <p class="item-text-pt mt-2 primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@menuItemBilling">Billing</p>
      </div>
    </div>


    <!--<div class="position-fixed logout my-2 mb-3 py-2"
         (click)="this.auth.logout(); closeMenu()">
      <div class="d-flex">
        <div class="text-center">
          <i class="icon-sign-out d-inline gray-primaray-text"></i>
        </div>
        <p class="text ml-3 primaray-text mb-0">Logout</p>
      </div>
    </div>-->


  </div>



  <div class="footer">

    <div class="logout my-2 mb-3 py-2"
         (click)="this.auth.logout(); closeMenu()">
      <div class="d-flex">
        <div class="text-center">
          <i class="icon-sign-out d-inline gray-primaray-text"></i>
        </div>
        <p i18n="@@logOutBtn" class="text primaray-text mb-0" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}">Logout</p>
      </div>
    </div>
  </div>





</div>






<div class="primary-menu-wrapper popup mobile menu-mobile" [ngClass]="{'show': dataService.landingWorkspaceMenuActive}" *ngIf="dataService.mobileView">

  <div class="header">
    <a routerLink="/" class="logo_link" (click)="closeMenu()" >
      <img width="145" height="35" loading="lazy" src="assets/img/logo-mobile.svg"
           class="logo-image initial-height max-width" alt="SafarHub Logo">
    </a>
  </div>



  <div class="close_popup" (click)="closeMenu()">
    <i class="icon-cross font-weight-bold"></i>
  </div>

  <div class="container">


    <div *ngIf="!auth.isLogedIn" class="my-4" (click)="this.router.navigate(['/login']); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && this.router.url === '/login' }">
      <p i18n="@@signInBtn" class="pt-2 mt-1 primaray-text mb-0">Sign In</p>
    </div>

    <div class="my-4"  (click)="this.router.navigate(['/info/about']); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && this.router.url === '/info/about' }">
      <p i18n="@@about" class="pt-2 mt-1 primaray-text mb-0">About SafarHub</p>
    </div>

    <div class="my-4"  (click)="this.router.navigate(['/info/contact-us']); closeMenu()"
         [ngClass] = "{'active': this.auth.companyInfo && this.router.url === '/info/contact-us' }">
      <p i18n="@@contactUs" class="pt-2 mt-1 primaray-text mb-0">Contact us</p>
    </div>
    <div *ngIf="auth.isLogedIn" class="my-4" (click)="this.auth.logout(); closeMenu()">
      <p i18n="@@logOutBtn" class="pt-2 mt-1 primaray-text mb-0" >Logout</p>
    </div>

    <div class="mt-5 pt-4"  *ngIf="!auth.isLogedIn">
      <button i18n="@@freeTrialTitle" (click)="this.router.navigate(['/join']); closeMenu()"
              class="btn px-5 py-2 rounded text-center sharp-font bold-500 ml-auto font-14 btn-primary text-white w-100">Start a free trial</button>
    </div>

    <div class="mt-5 pt-4"  *ngIf="auth.isLogedIn">
      <button i18n="@@myWorkSpace" (click)="this.router.navigate(['/']); closeMenu()"
              class="btn px-5 py-2 rounded text-center sharp-font bold-500 ml-auto font-14 btn-primary text-white w-100">My Workspace</button>
    </div>

  </div>








</div>
