<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="payment-list main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">
          <div class="font-weight-bold blue-text pb-4 font-16-to-14 mx-lg-4">
            <div class="row">
              <div
                [ngClass]="{
                  'col-auto': dataService.desktopView,
                  'col-md-12': dataService.mobileView
                }"
              >
                <div class="d-flex">
                  <p
                    class="text-center pointer"
                    [ngClass]="{
                      'pr-3 border-right': dataService.isSiteLtr(),
                      'pl-3 border-left': dataService.isSiteRtl()
                    }"
                    (click)="
                      this.router.navigate([
                       '/user/profile'
                      ])
                    "
                    i18n="@@profile"
                  >
                    Profile
                  </p>
                  <p
                    class="gray-primaray-text px-3"
                    [ngClass]="{
                      'text-right border-right mr-3': dataService.isSiteLtr(),
                      'text-left border-left ml-3': dataService.isSiteRtl()
                    }"
                    i18n="@@paymentMethods"
                  >
                    Payment Methods
                  </p>
                  <p
                    class="pointer"
                    *ngIf="
                      (this.auth.companyInfo.rewardProgram &&
                        this.auth.companyInfo.rewardProgram
                          .flightSavingsPercentage > 0) ||
                      (this.auth.companyInfo.rewardProgram &&
                        this.auth.companyInfo.rewardProgram
                          .hotelSavingsPercentage > 0) ||
                      (this.auth.userInfo.rewardWallet &&
                        this.auth.userInfo.rewardWallet.balance > 0)
                    "
                    (click)="
                      this.router.navigate([
                       '/user/rewards'
                      ])
                    "
                    i18n="@@rewards"
                  >
                    Rewards
                  </p>
                </div>
              </div>
              <div
                *ngIf="dataService.desktopView"
                [ngClass]="{
                  'col-auto': dataService.desktopView,
                  'col-md-12': dataService.mobileView,
                  'ml-auto': dataService.isSiteLtr() && dataService.desktopView,
                  'mr-auto': dataService.isSiteRtl() && dataService.desktopView
                }"
              >
                <button
                  (click)="this.auth.logout()"
                  class="btn-block btn btn-outline-gray px-5 gray-text-dark font-14 sharp-font bold-500"
                  i18n="@@logOutBtn"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              (this.dataService.paymentMethods &&
                this.dataService.paymentMethods.cards &&
                this.dataService.paymentMethods.cards.length === 0) ||
              (this.dataService.paymentMethods | json) === '{}' ||
              this.dataService.paymentMethods === null ||
              this.dataService.paymentMethods === undefined
            "
            class="mt-lg-5 mb-5 px-4 px-lg-5 py-3 py-lg-3 company-section-with-thick-border border"
            [ngClass]="{
              'rounded-right': dataService.desktopView,
              'thick-top rounded-bottom': dataService.mobileView
            }"
          >
            <div
              class="row h-100 justify-content-center align-items-center py-3"
            >
              <div class="col-md-8 font-16">
                <p class="mb-4" i18n="@@userPayMsg1">
                  It’s easy to save your preferred payment method to your
                  SafarHub account.
                </p>
                <p class="mb-0" i18n="@@userPayMsg2">
                  Simply select “remember the payment method” while you’re
                  booking.
                </p>
              </div>
              <div
                class="col-md-4"
                [ngClass]="{
                  'text-right': dataService.isSiteLtr(),
                  'text-left': dataService.isSiteRtl()
                }"
              >
                <button
                  (click)="NewPaymentCard()"
                  class="btn px-5 py-2 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center sharp-font bold-500 font-14 btn-min-width"
                  [ngClass]="{
                    'w-100 btn-block bg-white primaray-text desktop-btn mt-5':
                      dataService.mobileView,
                    'btn-primary text-white': dataService.desktopView,
                    'ml-auto': dataService.isSiteLtr(),
                    'mr-auto': dataService.isSiteRtl()
                  }"
                  i18n="@@addCard"
                >
                  Add Card
                </button>
              </div>
            </div>
          </div>
          <div
            class="pt-4 items-full-list mt-lg-5"
            *ngIf="this.dataService.paymentMethods"
            [ngClass]="{ 'px-4': dataService.desktopView }"
          >
            <div
              *ngIf="
                this.dataService.paymentMethods.cards &&
                this.dataService.paymentMethods.cards.length > 0
              "
            >
              <div [ngClass]="{ 'pb-5 mb-5': dataService.mobileView }">
                <div
                  *ngFor="
                    let method of this.dataService.paymentMethods.cards;
                    let i = index
                  "
                >
                  <div
                    class="row hover-row-list mx-0 pb-3 pb-lg-4 pt-4 row-max-width"
                    [ngClass]="{
                      'border-bottom': dataService.desktopView,
                      'rounded border mb-4': dataService.mobileView
                    }"
                  >
                    <div
                      class="col-lg-5"
                      [ngClass]="{
                        'h-100 justify-content-center align-items-center':
                          dataService.desktopView
                      }"
                    >
                      <div class="d-flex">
                        <div
                          class="card_type card_type_{{ method.brand }}"
                          [ngClass]="{
                            'max-width-card': dataService.mobileView,
                            'text-right mr-4':
                              dataService.desktopView &&
                              dataService.isSiteLtr(),
                            'text-left ml-4':
                              dataService.desktopView && dataService.isSiteRtl()
                          }"
                        ></div>
                        <div
                          [ngClass]="{
                            'mr-auto': dataService.isSiteLtr(),
                            'ml-auto': dataService.isSiteRtl()
                          }"
                        >
                          <div class="mb-1" *ngIf="method.alias">
                            <span>{{ method.alias }}</span>
                          </div>
                          <div class="mb-1" *ngIf="!method.alias">
                            <span i18n="@@noAlias">No alias</span>
                          </div>
                          <p class="gray-text mb-0" dir="ltr">
                            **** **** **** {{ method.lastFour }}
                          </p>
                        </div>
                      </div>
                      <div class="d-flex" *ngIf="dataService.mobileView">
                        <div style="min-width: 66px">
                          <span
                            *ngIf="method.primary"
                            class="text-bg py-1 px-2 font-12 gray-text-dark"
                            i18n="@@primary"
                            >Primary</span
                          >
                        </div>
                        <span
                          *ngIf="this.dataService.userPaymentExpiryList[i]"
                          class="text-danger mb-0"
                          ><i
                            class="icon-warning-msg"
                            [ngClass]="{
                              'mr-2': dataService.isSiteLtr(),
                              'ml-2': dataService.isSiteRtl()
                            }"
                          ></i
                          ><span i18n="@@expired">Expired</span></span
                        >
                      </div>
                    </div>
                    <div *ngIf="dataService.desktopView" class="col-lg-5 pt-2">
                      <span
                        *ngIf="method.primary"
                        class="text-bg py-2 px-3 font-12 gray-text-dark"
                        i18n="@@primary"
                        >Primary</span
                      ><br />
                      <p
                        *ngIf="this.dataService.userPaymentExpiryList[i]"
                        class="text-danger mb-0"
                      >
                        <i
                          class="icon-warning-msg"
                          [ngClass]="{
                            'mr-2': dataService.isSiteLtr(),
                            'ml-2': dataService.isSiteRtl()
                          }"
                        ></i
                        ><span i18n="@@expired">Expired</span>
                      </p>
                    </div>
                    <div
                      class="col-lg-2 pt-2"
                      [ngClass]="{
                        'text-left': dataService.isSiteLtr(),
                        'text-right': dataService.isSiteRtl()
                      }"
                    >
                      <div class="d-flex">
                        <div class="actions">
                          <i
                            class="drop-down{{
                              i
                            }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                            [ngClass]="{
                              'primary-expired':
                                method.primary ||
                                this.dataService.userPaymentExpiryList[i]
                            }"
                            (click)="
                              showActions = !showActions; selectedIndex = i
                            "
                          ></i>
                          <div
                            class="border shadow font-14 position-absolute bg-white drop"
                            [ngClass]="{
                              'primary-expired-drop':
                                method.primary ||
                                this.dataService.userPaymentExpiryList[i]
                            }"
                            *ngIf="showActions && i === selectedIndex"
                          >
                            <p
                              class="pointer py-2"
                              [ngClass]="{
                                'pl-4': dataService.isSiteLtr(),
                                'pr-4': dataService.isSiteRtl()
                              }"
                              (click)="
                                editAlias(
                                  method.id,
                                  method.paymentGatewayProvider,
                                  method.alias,
                                  method.primary
                                )
                              "
                              i18n="@@edit"
                            >
                              Edit
                            </p>
                            <p
                              class="pointer py-2"
                              [ngClass]="{
                                'pl-4': dataService.isSiteLtr(),
                                'pr-4': dataService.isSiteRtl()
                              }"
                              *ngIf="!method.primary"
                              (click)="
                                makePrimary(
                                  method.id,
                                  method.paymentGatewayProvider,
                                  method.channelType
                                )
                              "
                              i18n="@@setPrimary"
                            >
                              Set as primary
                            </p>
                            <p
                              class="pointer py-2"
                              [ngClass]="{
                                'pl-4': dataService.isSiteLtr(),
                                'pr-4': dataService.isSiteRtl()
                              }"
                              (click)="openDeleteDialogue(method.id)"
                              i18n="@@deleteBtn"
                            >
                              Delete
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-5"
              *ngIf="
                this.dataService.paymentMethods &&
                this.dataService.paymentMethods.cards &&
                this.dataService.paymentMethods.cards.length > 0
              "
            >
              <div
                [ngClass]="{
                  'col-auto': dataService.desktopView,
                  'col-md-6': dataService.mobileView
                }"
              >
                <button
                  (click)="NewPaymentCard()"
                  class="btn-outline-blue btn px-5 w-100 mb-3 font-14 sharp-font bold-500"
                >
                  <span class="px-lg-4" i18n="@@addNewCard">Add New Card</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="dataService.mobileView" class="col-md-6 px-0 mt-5">
          <button
            (click)="this.auth.logout()"
            class="btn-block btn btn-outline-gray px-5 gray-text-dark font-14 sharp-font bold-500"
            i18n="@@logOutBtn"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
