<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="approvals-list container pt-4 pt-lg-5">
        <div class="row mx-lg-4">
          <div class="col-12 d-flex">
            <div
              class="mb-4 mb-lg-0 font-24 gray-primaray-text bold-600"
              i18n="@@menuItemMyApprovals"
            >
              My Trip Approvals
            </div>
            <div
              *ngIf="dataService.mobileView"
              class="pt-3"
              [ngClass]="{
                'ml-auto': dataService.isSiteLtr(),
                'mr-auto': dataService.isSiteRtl()
              }"
            >
              <i
                class="icon-refresh-list font-18 pointer placeholder-text"
                (click)="refreshList()"
              ></i>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            !dataService.requestsList ||
            !dataService.requestsFlightList ||
            this.dataService.hotellistLoader
          "
          class="loading_icon_caption text-center mt-5"
        ></div>
        <div
          *ngIf="
            !dataService.requestsList ||
            !dataService.requestsFlightList ||
            this.dataService.hotellistLoader
          "
          class="loading-message text-center"
          i18n="@@loading"
        >
          Loading
        </div>
        <div
          class="mx-lg-5"
          *ngIf="
            !this.dataService.hotellistLoader &&
            dataService.requestsList &&
            !dataService.requestsList.length &&
            dataService.requestsFlightList &&
            !dataService.requestsFlightList.length
          "
        >
          <div
            class="mt-5 company-section-with-thick-border border"
            [ngClass]="{
              'custom-container': dataService.desktopView,
              'rounded-right':
                dataService.desktopView && dataService.isSiteLtr(),
              'rounded-left':
                dataService.desktopView && dataService.isSiteRtl(),
              'thick-top rounded-bottom': dataService.mobileView
            }"
          >
            <div
              class="row mx-0 p-4 h-100 justify-content-center align-items-center"
            >
              <div class="col-md-8 font-16">
                <p
                  class="mb-0 gray-primaray-text"
                  i18n="@@noTripApprovalRequests"
                >
                  You do not have any trip approvals
                </p>
              </div>
              <div
                class="col-md-4"
                [ngClass]="{
                  'text-right': dataService.isSiteLtr(),
                  'text-left': dataService.isSiteRtl()
                }"
              >
                <button
                  (click)="
                    this.router.navigate(['/'])
                  "
                  class="w-100 btn px-5 py-2 mt-4 mb-3 my-lg-2 rounded text-center sharp-font bold-500 ml-auto font-14 find-btn btn-primary"
                  i18n="@@menuItemBookTrip"
                >
                  Book a trip
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="pt-0 pt-lg-3 mt-0 mt-lg-5"
          *ngIf="
            ((dataService.requestsList &&
              dataService.requestsList.length > 0) ||
              (dataService.requestsFlightList &&
                dataService.requestsFlightList.length > 0)) &&
            !this.dataService.hotellistLoader
          "
        >
          <div class="tabs-section row mx-lg-4 mb-3">
            <div class="col" [ngClass]="{ 'px-0': dataService.mobileView }">
              <div
                class="font-16-to-14 font-weight-bold filtering blue-text rounded pb-2"
                [ngClass]="{
                  row: dataService.desktopView,
                  'd-flex overflow-auto': dataService.mobileView
                }"
              >
                <div class="pb-3 px-0 text-nowrap">
                  <span
                    class="pointer px-lg-4"
                    [ngClass]="{
                      'border-right': dataService.isSiteLtr(),
                      'border-left': dataService.isSiteRtl(),
                      active: dataService.requestSortType === 'all',
                      'pl-4 pr-3': dataService.mobileView,
                      'col-auto': dataService.desktopView
                    }"
                    (click)="filterBy('all')"
                    ><ng-container i18n="@@all">All</ng-container>&nbsp;<span
                      >({{
                        this.dataService.requestsList.length +
                          this.dataService.requestsFlightList.length
                      }})</span
                    ></span
                  >
                </div>
                <div class="pb-3 px-0 text-nowrap">
                  <span
                    class="pointer px-3 px-lg-4"
                    [ngClass]="{
                      'border-right': dataService.isSiteLtr(),
                      'border-left': dataService.isSiteRtl(),
                      active:
                        dataService.requestSortType === 'pending_approval',
                      'col-auto': dataService.desktopView
                    }"
                    (click)="filterBy('pending_approval')"
                    i18n="@@pending"
                    >Pending</span
                  >
                </div>
                <div class="pb-3 px-0 text-nowrap">
                  <span
                    class="pointer px-3 px-lg-4"
                    [ngClass]="{
                      'border-right': dataService.isSiteLtr(),
                      'border-left': dataService.isSiteRtl(),
                      active: dataService.requestSortType === 'approved',
                      'col-auto': dataService.desktopView
                    }"
                    (click)="filterBy('approved')"
                    i18n="@@approved"
                    >Approved</span
                  >
                </div>
                <div class="pb-3 px-0 text-nowrap">
                  <span
                    class="pointer px-3 px-lg-4"
                    [ngClass]="{
                      'border-right': dataService.isSiteLtr(),
                      'border-left': dataService.isSiteRtl(),
                      active: dataService.requestSortType === 'disapproved',
                      'col-auto': dataService.desktopView
                    }"
                    (click)="filterBy('disapproved')"
                    i18n="@@declined"
                    >Declined</span
                  >
                </div>
                <div class="pb-3 px-0 text-nowrap">
                  <span
                    class="pointer px-3 px-lg-4"
                    [ngClass]="{
                      'border-right': dataService.isSiteLtr(),
                      'border-left': dataService.isSiteRtl(),
                      active: dataService.requestSortType === 'expired',
                      'col-auto': dataService.desktopView
                    }"
                    (click)="filterBy('expired')"
                    i18n="@@expired"
                    >Expired</span
                  >
                </div>
                <div class="pb-3 px-0 text-nowrap">
                  <span
                    class="pointer px-3 px-lg-4"
                    [ngClass]="{
                      active: dataService.requestSortType === 'withdrawn',
                      'col-auto': dataService.desktopView
                    }"
                    (click)="filterBy('withdrawn')"
                    i18n="@@withdrawn"
                    >Withdrawn</span
                  >
                </div>

                <div
                  class="refresh-container pt-1 position-relative"
                  *ngIf="dataService.desktopView"
                  [ngClass]="{
                    'ml-auto': dataService.isSiteLtr(),
                    'mr-auto': dataService.isSiteRtl()
                  }"
                >
                  <i
                    class="refresh icon-refresh-list pointer position-absolute font-18 placeholder-text"
                    (click)="refreshList()"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngFor="
              let item of dataService.requesterFilteredApprovalsList;
              let i = index
            "
          >
            <div
              class="row px-3 mx-0 mx-lg-5 py-4 pointer position-relative hover-row-list"
              (click)="this.router.navigate([this.router.url + '/' + item.id])"
              [ngClass]="{
                'row-max-width': dataService.sideNavState,
                'border-bottom': dataService.desktopView,
                'rounded border mb-4': dataService.mobileView
              }"
            >
              <div
                class="col-lg-3"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p
                  class="gray-primaray-text mb-0 mb-lg-2 dotted-text dotted-text-direction"
                >
                  {{
                    item.bookingInfo.hotel.cityName
                      ? item.bookingInfo.hotel.cityName
                      : ""
                  }}{{
                    item.bookingInfo.hotel.stateCode &&
                    item.bookingInfo.hotel.cityName
                      ? ", "
                      : ""
                  }}{{
                    item.bookingInfo.hotel.stateCode
                      ? item.bookingInfo.hotel.stateCode
                      : ""
                  }}
                </p>
                <p
                  class="gray-text-dark mb-0 dotted-text dotted-text-direction"
                >
                  {{ item.bookingInfo.hotel.countryName }}
                </p>
              </div>
              <div
                class="col-lg-4"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p
                  class="gray-primaray-text mb-0 mb-lg-2 dotted-text dotted-text-direction mt-4 mt-lg-0"
                >
                  {{ item.bookingInfo.hotel.name }}
                </p>
                <div class="d-flex">
                  <p
                    class="mb-0"
                    [ngClass]="{
                      'mr-2 pr-1': dataService.isSiteLtr(),
                      'ml-2 pl-1': dataService.isSiteRtl()
                    }"
                  >
                    <i class="icon-hotel-item font-20 gray-text"></i>
                  </p>
                  <p class="gray-text-dark mb-0">
                    <span
                      [innerHTML]="
                        dataService.formatDateMMMdd(
                          item.bookingInfo.searchRequest.checkIn
                        )
                      "
                    ></span>
                    -
                    <span
                      [innerHTML]="
                        dataService.formatDateMMMdy(
                          item.bookingInfo.searchRequest.checkOut
                        )
                      "
                    ></span>
                  </p>
                </div>
              </div>
              <div
                class="col-lg-2"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p class="gray-primaray-text mb-0 mb-lg-2 mt-4 mt-lg-0">
                  {{item.bookingInfo.hotel.rooms[0].rates[0]
                        .averageNightlySellingPriceConvertedCurrency}} {{
                    item.bookingInfo.hotel.rooms[0].rates[0]
                      .averageNightlySellingPriceConverted | number : "1.0-0"
                  }}<ng-container i18n="@@slashNight">/night</ng-container>
                </p>
                <p class="gray-text-dark mb-0">
                  <ng-container i18n="@@Total">Total</ng-container
                  >&nbsp;<ng-container *ngIf="dataService.isSiteLtr()"
                    >{{item.bookingInfo.hotel.lowestSellingPriceRate
                      .finalSellingPriceConvertedCurrency }}</ng-container
                  > {{
                    item.bookingInfo.hotel.lowestSellingPriceRate
                      .finalSellingPriceConverted | number : "1.0-0"
                  }}<ng-container *ngIf="dataService.isSiteRtl()"
                    >$</ng-container
                  >
                </p>
              </div>
              <div
                class="col-lg-2"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p
                  class="green-text mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'approved'"
                  i18n="@@approved"
                >
                  Approved
                </p>
                <p
                  class="text-danger mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'disapproved'"
                  i18n="@@declined"
                >
                  Declined
                </p>
                <p
                  class="yellow-text mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'pending_approval'"
                  i18n="@@pending"
                >
                  Pending
                </p>
                <p
                  class="gray-text mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'expired'"
                  i18n="@@expired"
                >
                  Expired
                </p>
                <p
                  class="gray-text mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'withdrawn'"
                  i18n="@@withdrawn"
                >
                  Withdrawn
                </p>
                <p
                  class="gray-text-dark font-12 mt-1 mb-0"
                  *ngIf="item.limitExceeded"
                >
                  <span
                    class="text-bg py-2 px-3 text-nowrap"
                    i18n="@@outOfPolicy"
                    >Out of policy</span
                  >
                </p>
              </div>
              <div
                class="col-lg-1 pt-4"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <div class="d-flex">
                  <div class="ml-auto actions">
                    <i
                      class="approval-drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                      [ngClass]="{
                        'hotel-mobile': dataService.mobileView,
                        'within-policy':
                          !item.limitExceeded && dataService.mobileView
                      }"
                      (click)="
                        $event.stopPropagation();
                        approvalActions = !approvalActions;
                        selectedIndex = i
                      "
                    ></i>
                    <div
                      class="border shadow font-14 position-absolute bg-white drop"
                      [ngClass]="{
                        'hotel-mobile-drop': dataService.mobileView
                      }"
                      *ngIf="approvalActions && i === selectedIndex"
                    >
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="
                          this.router.navigate([
                            this.router.url + '/' + item.id
                          ])
                        "
                        i18n="@@view"
                      >
                        View
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        *ngIf="item.status.toLowerCase() == 'pending_approval'"
                        (click)="
                          $event.stopPropagation();
                          withdrawApprovalRequestPopup(item.id)
                        "
                        i18n="@@withdraw"
                      >
                        Withdraw
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngFor="
              let item of dataService.requesterFilteredApprovalsFlightList;
              let i = index
            "
          >
            <div
              class="row px-3 mx-0 mx-lg-5 py-4 pointer position-relative hover-row-list"
              (click)="
                this.router.navigate([
                    '/user/flights/requests/' +
                    item.id
                ])
              "
              [ngClass]="{
                'row-max-width': dataService.sideNavState,
                'border-bottom': dataService.desktopView,
                'rounded border mb-4': dataService.mobileView
              }"
            >
              <div
                class="col-lg-3"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p
                  class="gray-primaray-text mb-0 mb-lg-2 dotted-text dotted-text-direction"
                >
                  {{
                    item.airItineraryValidationResponse.airItinerary
                      .originDestinationOptionsGroups[0].summary.arrivalAirport
                      .city
                  }}
                </p>
              </div>
              <div
                class="col-lg-4"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <div
                  class="d-flex gray-primaray-text mb-0 mb-lg-2 mt-4 mt-lg-0"
                >
                  {{
                    item.approvalRequest.bookRequest.searchRequest.roundTrip
                      ? dataService.roundTrip
                      : dataService.oneWay
                  }}
                  &nbsp;
                  <p class="dotted-text dotted-text-direction">
                    ({{
                      item.airItineraryValidationResponse.airItinerary
                        .originDestinationOptionsGroups[0].summary
                        .departureAirport.city
                    }}
                    -
                    {{
                      item.airItineraryValidationResponse.airItinerary
                        .originDestinationOptionsGroups[0].summary
                        .arrivalAirport.city
                    }})
                  </p>
                </div>
                <div class="d-flex">
                  <p
                    class="pt-1 mb-0"
                    [ngClass]="{
                      'mr-2': dataService.isSiteLtr(),
                      'ml-2': dataService.isSiteRtl()
                    }"
                  >
                    <i class="icon-flight-item font-14 gray-text"></i>
                  </p>
                  <p class="gray-text-dark mb-0">
                    <span
                      *ngIf="
                        !item.approvalRequest.bookRequest.searchRequest
                          .roundTrip
                      "
                      class="gray-text-dark"
                      [innerHTML]="
                        dataService.formatDateMMMdy(
                          item.airItineraryValidationResponse.airItinerary
                            .originDestinationOptionsGroups[0].summary
                            .departureDate
                        )
                      "
                    ></span>
                    <span
                      *ngIf="
                        item.approvalRequest.bookRequest.searchRequest.roundTrip
                      "
                      class="gray-text-dark"
                      [innerHTML]="
                        dataService.formatDateMMMdd(
                          item.airItineraryValidationResponse.airItinerary
                            .originDestinationOptionsGroups[0].summary
                            .departureDate
                        )
                      "
                    ></span>
                    <span
                      *ngIf="
                        item.approvalRequest.bookRequest.searchRequest.roundTrip
                      "
                    >
                      -
                    </span>
                    <span
                      *ngIf="
                        item.approvalRequest.bookRequest.searchRequest.roundTrip
                      "
                      class="gray-text-dark"
                      [innerHTML]="
                        dataService.formatDateMMMdy(
                          item.airItineraryValidationResponse.airItinerary
                            .originDestinationOptionsGroups[1].summary
                            .departureDate
                        )
                      "
                    ></span>
                  </p>
                </div>
              </div>
              <div class="col-lg-2 pl-0">
                <p class="gray-primaray-text mb-0 mb-lg-2 mt-4 mt-lg-0">
                  ${{
                    item.airItineraryValidationResponse.airItinerary.fareInfo
                      .sellingTotalFares.total.amount | number : "1.0-0"
                  }}
                </p>
                <ng-container *ngFor="let itemClass of vars.classType">
                  <p
                    class="gray-text-dark mb-0"
                    *ngIf="
                      itemClass.value ===
                      item.approvalRequest.bookRequest.searchRequest.cabinClass
                    "
                  >
                    {{ itemClass.name }}
                  </p></ng-container
                >
              </div>
              <div
                class="col-lg-2"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <p
                  class="green-text mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'approved'"
                  i18n="@@approved"
                >
                  Approved
                </p>
                <p
                  class="text-danger mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'disapproved'"
                  i18n="@@declined"
                >
                  Declined
                </p>
                <p
                  class="yellow-text mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'pending_approval'"
                  i18n="@@pending"
                >
                  Pending
                </p>
                <p
                  class="gray-text mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'expired'"
                  i18n="@@expired"
                >
                  Expired
                </p>
                <p
                  class="gray-text mb-2 pb-1 mt-4 mt-lg-0"
                  *ngIf="item.status.toLowerCase() === 'withdrawn'"
                  i18n="@@withdrawn"
                >
                  Withdrawn
                </p>
                <p
                  class="gray-text-dark font-12 mt-1 mb-0"
                  *ngIf="item.limitExceeded"
                >
                  <span
                    class="text-bg py-2 px-3 text-nowrap"
                    i18n="@@outOfPolicy"
                    >Out of policy</span
                  >
                </p>
              </div>
              <div
                class="col-lg-1 pt-4"
                [ngClass]="{
                  'pl-0': dataService.isSiteLtr(),
                  'pr-0': dataService.isSiteRtl()
                }"
              >
                <div class="d-flex">
                  <div class="ml-auto actions">
                    <i
                      class="flight-approval-drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                      [ngClass]="{
                        'flight-within-policy':
                          !item.limitExceeded && dataService.mobileView
                      }"
                      (click)="
                        $event.stopPropagation();
                        flightApprovalActions = !flightApprovalActions;
                        selectedIndexFlight = i
                      "
                    ></i>
                    <div
                      class="border shadow font-14 position-absolute bg-white drop"
                      *ngIf="flightApprovalActions && i === selectedIndexFlight"
                    >
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="
                          this.router.navigate([
                              '/user/flights/requests/' +
                              item.id
                          ])
                        "
                        i18n="@@view"
                      >
                        View
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        *ngIf="item.status.toLowerCase() == 'pending_approval'"
                        (click)="
                          $event.stopPropagation();
                          withdrawApprovalRequestFlight(item.id)
                        "
                        i18n="@@withdraw"
                      >
                        Withdraw
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row mx-lg-4 px-3 pt-3"
            *ngIf="!this.dataService.hotellistLoader"
          >
            <div
              class="col px-0"
              *ngIf="
                this.dataService.requesterPendingArray &&
                !dataService.requesterPendingArray.length &&
                this.dataService.requesterPendingArrayFlight &&
                !dataService.requesterPendingArrayFlight.length &&
                this.dataService.requestSortType === 'pending_approval'
              "
              i18n="@@noPending"
            >
              You do not have any pending approvals
            </div>
            <div
              class="col px-0"
              *ngIf="
                this.dataService.requesterApprovedArray &&
                !dataService.requesterApprovedArray.length &&
                this.dataService.requesterApprovedArrayFlight &&
                !dataService.requesterApprovedArrayFlight.length &&
                this.dataService.requestSortType === 'approved'
              "
              i18n="@@noApproved"
            >
              You do not have any approved request
            </div>
            <div
              class="col px-0"
              *ngIf="
                this.dataService.requesterDisapprovedArray &&
                !dataService.requesterDisapprovedArray.length &&
                this.dataService.requesterDisapprovedArrayFlight &&
                !dataService.requesterDisapprovedArrayFlight.length &&
                this.dataService.requestSortType === 'disapproved'
              "
              i18n="@@noDeclined"
            >
              You do not declined request
            </div>
            <div
              class="col px-0"
              *ngIf="
                this.dataService.requesterExpiredArray &&
                !dataService.requesterExpiredArray.length &&
                this.dataService.requesterExpiredArrayFlight &&
                !dataService.requesterExpiredArrayFlight.length &&
                this.dataService.requestSortType === 'expired'
              "
              i18n="@@noExpired"
            >
              You do not have any expired request
            </div>
            <div
              class="col px-0"
              *ngIf="
                this.dataService.requesterWithdrawnArray &&
                !dataService.requesterWithdrawnArray.length &&
                this.dataService.requesterWithdrawnArrayFlight &&
                !dataService.requesterWithdrawnArrayFlight.length &&
                this.dataService.requestSortType === 'withdrawn'
              "
              i18n="@@noWithdrawn"
            >
              You do not have any withdrawn request
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
