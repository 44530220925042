<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content pb-5">
      <app-messages></app-messages>
      <div class="items-full-list container pt-4 pt-lg-5 members-list">
        <div class="mx-lg-5">
          <p class="mb-4 font-24 gray-primaray-text bold-600" i18n="@@members">
            Members
          </p>
          <p class="gray-text-dark font-18" i18n="@@membersDesc">
            Invite your employee to the workspace to save on your business
            travel. You can set travel policies, approval and organized your
            members in teams.
          </p>

          <div
            class="my-5 pt-5"
            [ngClass]="{ 'd-flex': dataService.desktopView }"
          >
            <div
              class="min-width-workspace-btn"
              [ngClass]="{
                'mb-3': dataService.mobileView,
                'mr-lg-3': dataService.isSiteLtr(),
                'ml-lg-3': dataService.isSiteRtl()
              }"
            >
              <button
                [disabled]="auth.companyInfo && auth.companyInfo.inactive"
                (click)="inviteUsers()"
                class="text-center btn font-14 px-5 sharp-font bold-500"
                [ngClass]="{
                  'btn-outline-blue':
                    auth.companyInfo && auth.companyInfo.inactive === false,
                  'btn-outline-gray':
                    auth.companyInfo && auth.companyInfo.inactive,
                  'btn-block': dataService.desktopView
                }"
                i18n="@@inviteUsers"
              >
                Invite Users
              </button>
            </div>
            <div *ngIf="dataService.desktopView">
              <button
                [disabled]="auth.companyInfo && auth.companyInfo.inactive"
                class="text-center btn-block btn font-14 px-5 sharp-font bold-500"
                [ngClass]="{
                  'btn-outline-blue':
                    auth.companyInfo && auth.companyInfo.inactive === false,
                  'btn-outline-gray':
                    auth.companyInfo && auth.companyInfo.inactive
                }"
                (click)="
                  this.dataService.setModal(popUpTitle, 'bulkInviteUsers', '')
                "
                i18n="@@uploadViaCSV"
              >
                Upload Via CSV
              </button>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-lg-3 px-0 pt-1">
              <div class="form-group">
                <input
                  class="form-control"
                  id="search"
                  type="text"
                  name="search"
                  i18n-placeholder="@@searchForUsers"
                  placeholder="Search for users"
                  (focus)="filterByText($event)"
                  (keyup)="filterByText($event)"
                />
              </div>
            </div>
            <div
              class="col-lg-9"
              [ngClass]="{ 'px-0': dataService.mobileView }"
            >
              <div
                class="row font-16-to-14 font-weight-bold filtering blue-text rounded pb-2"
              >
                <div class="col-auto py-3 px-0">
                  <span
                    class="pointer px-lg-4"
                    [ngClass]="{
                      'border-right': dataService.isSiteLtr(),
                      'border-left': dataService.isSiteRtl(),
                      active: dataService.usersSortType === 'all',
                      'pl-4 pr-3':
                        dataService.mobileView && dataService.isSiteLtr(),
                      'pr-4 pl-3':
                        dataService.mobileView && dataService.isSiteRtl()
                    }"
                    (click)="filterBy('all')"
                    i18n="@@all"
                    >All
                    <span *ngIf="this.dataService.filteredPending"
                      >({{
                        this.dataService.companyUsersList.length +
                          this.dataService.filteredPending.length
                      }})</span
                    >
                    <span *ngIf="!this.dataService.filteredPending"
                      >({{ this.dataService.companyUsersList.length }})</span
                    >
                  </span>
                </div>
                <div class="col-auto py-3 px-0">
                  <span
                    class="pointer px-3 px-lg-4"
                    [ngClass]="{
                      'border-right': dataService.isSiteLtr(),
                      'border-left': dataService.isSiteRtl(),
                      active: dataService.usersSortType === 'active'
                    }"
                    (click)="filterBy('active')"
                    i18n="@@active"
                    >Active</span
                  >
                </div>
                <div class="col-auto py-3 px-0">
                  <span
                    class="pointer px-3 px-lg-4"
                    [ngClass]="{
                      active: dataService.usersSortType === 'inactive',
                      'border-right':
                        auth.companyInfo &&
                        auth.companyInfo.inactive === false &&
                        dataService.isSiteLtr(),
                      'border-left':
                        auth.companyInfo &&
                        auth.companyInfo.inactive === false &&
                        dataService.isSiteRtl()
                    }"
                    (click)="filterBy('inactive')"
                    i18n="@@inActive"
                    >Inactive</span
                  >
                </div>
                <div
                  class="col-auto py-3 px-0"
                  *ngIf="
                    auth.companyInfo && auth.companyInfo.inactive === false
                  "
                >
                  <span
                    class="pointer px-lg-4"
                    [ngClass]="{
                      'mr-lg-auto': dataService.isSiteLtr(),
                      'ml-lg-auto': dataService.isSiteRtl(),
                      active: dataService.usersSortType === 'pending',
                      'pr-4 pl-3':
                        dataService.mobileView && dataService.isSiteLtr(),
                      'pl-4 pr-3':
                        dataService.mobileView && dataService.isSiteRtl()
                    }"
                    (click)="filterBy('pending')"
                    i18n="@@pending"
                    >Pending</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              class="row px-3 py-3 mx-0 mb-3 gray-primaray-text bold-600 table-header rounded"
              *ngIf="dataService.desktopView && dataService.usersSortType !== 'pending'"
            >
              <div class="col-4" i18n="@@name">Name</div>
              <div class="col-3" i18n="@@role">Role</div>
              <div class="col-3" i18n="@@team">Team</div>
            </div>
            <div
              class="gray-primaray-text font-14"
              *ngFor="let item of dataService.filteredUsersList; let i = index"
            >
              <div
                class="row px-lg-3 hover-row-list py-4 mx-0"
                (click)="viewUserDetails(item.id)"
                [ngClass]="{
                  pointer:
                    auth.companyInfo && auth.companyInfo.inactive === false,
                  'prevent-pointer-event':
                    auth.companyInfo && auth.companyInfo.inactive === true,
                  'border-bottom': dataService.desktopView,
                  'border rounded shadow-sm mb-4': dataService.mobileView
                }"
              >
                <div class="col-lg-4">
                  <div class="d-flex">
                    <img
                      width="35"
                      height="35"
                      loading="lazy"
                      *ngIf="item.profileImage"
                      class="user-img d-inline-block"
                      [ngClass]="{
                        'mr-3': dataService.isSiteLtr(),
                        'ml-3': dataService.isSiteRtl()
                      }"
                      onError="this.src='assets/img/default-user.png'"
                      [src]="item.profileImage"
                    />
                    <div
                      class="name-first-letters small"
                      [ngClass]="{
                        'mr-3': dataService.isSiteLtr(),
                        'ml-3': dataService.isSiteRtl()
                      }"
                      *ngIf="!item.profileImage"
                      [ngStyle]="{
                        'background-color': item.profileImageBackgroundHexColor
                      }"
                    >
                      {{ item.firstName.substring(0, 1).toUpperCase()
                      }}{{ item.lastName.substring(0, 1).toUpperCase() }}
                    </div>
                    <div class="dotted-text">
                      <p class="mb-0 gray-primaray-text">
                        <span
                          *ngIf="item.firstName"
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                          >{{ item.firstName }}</span
                        ><span
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                          *ngIf="item.lastName"
                          >{{ item.lastName }}</span
                        ><span
                          class="text-danger"
                          *ngIf="item.inactive && dataService.mobileView"
                          i18n="@@inActive"
                          >Inactive</span
                        >
                      </p>
                      <p
                        class="gray-text mb-0 dotted-text dotted-text-direction"
                      >
                        {{ item.email }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 gray-text pt-5 pt-lg-3">
                  <span
                    *ngIf="dataService.mobileView"
                    class="gray-primaray-text"
                    ><ng-container i18n="@@role">Role</ng-container><br
                  /></span>
                  <span *ngIf="item.role === 'OWNER'" i18n="@@owner">
                    {{ item.role.toLowerCase().replace("_", " ") }}
                  </span>
                  <span *ngIf="item.role === 'ADMIN'" i18n="@@admin">
                    {{ item.role.toLowerCase().replace("_", " ") }}
                  </span>
                  <span *ngIf="item.role === 'TEAM_MANAGER'" i18n="@@teamManager">
                    {{ item.role.toLowerCase().replace("_", " ") }}
                  </span>
                  <span *ngIf="item.role === 'MEMBER'" i18n="@@member">
                    {{ item.role.toLowerCase().replace("_", " ") }}
                  </span>
                </div>

                <div class="col-lg-3 dotted-text gray-text pt-3">
                  <span
                    *ngIf="dataService.mobileView"
                    class="gray-primaray-text"
                    ><ng-container i18n="@@team">Team</ng-container><br
                  /></span>
                  <p
                    *ngIf="item.teamName"
                    class="dotted-text dotted-text-direction"
                  >
                    {{ item.teamName }}
                  </p>
                  <span *ngIf="!item.teamName">&mdash;</span>
                </div>
                <div class="col-lg-1 pt-3">
                  <p
                    class="mb-0 text-danger text-nowrap"
                    *ngIf="item.inactive && dataService.desktopView"
                    i18n="@@inActive"
                  >
                    Inactive
                  </p>
                </div>
                <div class="col-lg-1 max-col-with">
                  <div
                    class="d-flex"
                    *ngIf="
                      auth.companyInfo && auth.companyInfo.inactive === false
                    "
                  >
                    <div class="ml-auto actions">
                      <i
                        class="drop-down{{
                          i
                        }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                        (click)="
                          $event.stopPropagation();
                          showActions = !showActions;
                          selectedIndex = i
                        "
                      ></i>
                      <div
                        class="border shadow font-14 position-absolute bg-white drop"
                        *ngIf="showActions && i === selectedIndex"
                      >
                        <p
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="viewUserDetails(item.id)"
                          i18n="@@view"
                        >
                          View
                        </p>
                        <p
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="
                            this.router.navigate([
                              this.router.url + '/' + item.id + '/edit'
                            ])
                          "
                          i18n="@@editProfile"
                        >
                          Edit profile
                        </p>
                        <p
                          *ngIf="
                            item.role.toLowerCase() !== 'owner' &&
                            !item.inactive
                          "
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="
                            $event.stopPropagation(); deactivateUser(item.id)
                          "
                          i18n="@@deActivate"
                        >
                          Deactivate
                        </p>
                        <p
                          *ngIf="
                            item.role.toLowerCase() !== 'owner' && item.inactive
                          "
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="
                            $event.stopPropagation(); reactivateUser(item.id)
                          "
                          i18n="@@reActivate"
                        >
                          Reactivate
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex"
                    *ngIf="
                      auth.companyInfo &&
                      auth.companyInfo.inactive === true &&
                      item.inactive === false &&
                      item.role.toLowerCase() !== 'owner'
                    "
                  >
                    <div
                      class="actions"
                      [ngClass]="{
                        'ml-auto': dataService.isSiteLtr(),
                        'mr-auto': dataService.isSiteRtl()
                      }"
                    >
                      <i
                        class="drop-down{{
                          i
                        }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                        (click)="showActions = !showActions; selectedIndex = i"
                      ></i>
                      <div
                        class="border shadow font-14 position-absolute bg-white drop"
                        *ngIf="showActions && i === selectedIndex"
                      >
                        <p
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="deactivateUser(item.id)"
                          i18n="@@deActivate"
                        >
                          Deactivate
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row px-3 py-3 mx-0 mb-3 gray-primaray-text bold-600 table-header rounded"
            *ngIf="
              dataService.desktopView &&
              dataService.usersSortType === 'pending'
            "
          >
            <div class="col-4" i18n="@@name">Name</div>
            <div class="col-3" i18n="@@role">Role</div>
            <div class="col-3" i18n="@@team">Team</div>
            <div class="col-1"></div>
            <div class="col-1"></div>
          </div>
          <div
            *ngIf="
              dataService.usersSortType === 'all' ||
              dataService.usersSortType === 'pending'
            "
          >
            <div
              class="gray-primaray-text font-14"
              *ngFor="
                let item of this.dataService.filteredPending;
                let i = index
              "
            >
              <div
                class="row px-lg-3 hover-row-list py-4 mx-0"
                [ngClass]="{
                  'border-bottom': dataService.desktopView,
                  'border rounded shadow-sm mb-4': dataService.mobileView
                }"
              >
                <div class="col-lg-4">
                  <div class="d-flex">
                    <div
                      class="name-first-letters pending-circle small"
                      *ngIf="dataService.desktopView"
                      [ngClass]="{
                        'mr-3': dataService.isSiteLtr(),
                        'ml-3': dataService.isSiteRtl(),
                        'd-none': dataService.usersSortType === 'pending'
                      }"
                    ></div>
                    <div class="dotted-text">
                      <p
                        class="mt-0 mt-lg-3 gray-text mb-0 dotted-text dotted-text-direction"
                      >
                        {{ item.email }}
                      </p>
                      <p
                        class="mt-1 yellow-text"
                        *ngIf="dataService.mobileView"
                        i18n="@@pending"
                      >
                        Pending
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <span
                    *ngIf="dataService.mobileView"
                    class="gray-primaray-text"
                    ><ng-container i18n="@@role"></ng-container><br
                  /></span>
                  <p
                    *ngIf="!item.admin"
                    class="font-14 gray-text text-capitalize pt-lg-3"
                    i18n="@@member"
                  >
                    Member
                  </p>
                  <p
                    *ngIf="item.admin"
                    class="font-14 gray-text text-capitalize pt-lg-3"
                    i18n="@@admin"
                  >
                    Admin
                  </p>
                </div>
                <div class="col-lg-3 pt-3 dotted-text gray-text">
                  <span
                    *ngIf="dataService.mobileView"
                    class="gray-primaray-text mt-5"
                    ><ng-container i18n="@@team">Team</ng-container><br
                  /></span>
                  <p
                    *ngIf="item.teamName"
                    class="dotted-text dotted-text-direction"
                  >
                    {{ item.teamName }}
                  </p>
                  <span *ngIf="!item.teamName">&mdash;</span>
                </div>

                <div class="col-lg-1">
                  <p
                    class="mb-0 mt-3 yellow-text text-nowrap"
                    *ngIf="dataService.desktopView"
                    i18n="@@pending"
                  >
                    Pending
                  </p>
                </div>
                <div class="col-lg-1 max-col-with">
                  <div class="d-flex">
                    <div
                      class="actions"
                      [ngClass]="{
                        'ml-auto': dataService.isSiteLtr(),
                        'mr-auto': dataService.isSiteRtl()
                      }"
                    >
                      <i
                        class="drop-down-pending{{
                          i
                        }} icon-three-dots font-18 ellipsis pending gray-text pointer p-3"
                        (click)="
                          actionsPending = !actionsPending;
                          selectedIndexPending = i
                        "
                      ></i>
                      <div
                        class="border shadow font-14 position-absolute bg-white drop pending-mobile resend-invite"
                        *ngIf="actionsPending && i === selectedIndexPending"
                      >
                        <p
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="dataService.resendInvitation(item.email)"
                          i18n="@@resetInvite"
                        >
                          Resend invitation
                        </p>
                        <p
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="showCancelInvitation(item.email)"
                          i18n="@@withdrawInvite"
                        >
                          Withdraw invitation
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
