import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../services/data.service";

@Component({
  selector: 'app-accepted-cards-section',
  templateUrl: './accepted-cards-section.component.html',
  styleUrls: ['./accepted-cards-section.component.scss']
})
export class AcceptedCardsSectionComponent implements OnInit {

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
  }

}
