<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="profile-page container pt-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">
          <div class="font-weight-bold blue-text pb-4 font-16-to-14 mx-lg-4">
            <div class="row">
              <div
                [ngClass]="{
                  'col-auto': dataService.desktopView,
                  'col-md-12': dataService.mobileView
                }"
              >
                <div class="d-flex">
                  <p
                    class="text-center gray-primaray-text"
                    [ngClass]="{
                      'pr-3 border-right': dataService.isSiteLtr(),
                      'pl-3 border-left': dataService.isSiteRtl()
                    }"
                    i18n="@@profile"
                  >
                    Profile
                  </p>
                  <p
                    class="pointer px-3"
                    [ngClass]="{
                      'text-right border-right mr-3': dataService.isSiteLtr(),
                      'text-left border-left ml-3': dataService.isSiteRtl()
                    }"
                    (click)="
                      this.router.navigate([
                        '/user/payment'
                      ])
                    "
                    i18n="@@paymentMethods"
                  >
                    Payment Methods
                  </p>
                  <p
                    class="pointer"
                    *ngIf="
                      (this.auth.companyInfo.rewardProgram &&
                        this.auth.companyInfo.rewardProgram
                          .flightSavingsPercentage > 0) ||
                      (this.auth.companyInfo.rewardProgram &&
                        this.auth.companyInfo.rewardProgram
                          .hotelSavingsPercentage > 0) ||
                      (this.auth.userInfo.rewardWallet &&
                        this.auth.userInfo.rewardWallet.balance > 0)
                    "
                    (click)="
                      this.router.navigate([
                        '/user/rewards'
                      ])
                    "
                    i18n="@@rewards"
                  >
                    Rewards
                  </p>
                </div>
              </div>
              <div
                *ngIf="dataService.desktopView"
                [ngClass]="{
                  'col-auto': dataService.desktopView,
                  'col-md-12': dataService.mobileView,
                  'ml-auto': dataService.isSiteLtr() && dataService.desktopView,
                  'mr-auto': dataService.isSiteRtl() && dataService.desktopView
                }"
              >
                <button
                  (click)="this.auth.logout()"
                  class="btn-block btn btn-outline-gray px-5 gray-text-dark font-14 sharp-font bold-500"
                  i18n="@@logOutBtn"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>

          <div class="row mx-lg-4 pb-5 mt-lg-5">
            <div
              class="col-md-6"
              *ngIf="auth.userInfo"
              [ngClass]="{ 'pl-4 mb-5 pt-4 form-col': dataService.desktopView }"
            >
              <div class="d-flex" *ngIf="auth.userInfo">
                <div>
                  <div
                    class="update-user-img position-relative text-center"
                    [ngClass]="{
                      'mr-5': dataService.isSiteLtr(),
                      'ml-5': dataService.isSiteRtl()
                    }"
                  >
                    <div
                      class="image-container mx-auto"
                      *ngIf="
                        auth.userInfo.imageUrl &&
                        !this.dataService.showDefaultUserImage
                      "
                      [ngClass]="{ 'm-progress': dataService.submitting }"
                      (click)="openProfileMenu()"
                    >
                      <img
                        width="50"
                        height="50"
                        loading="lazy"
                        class="user-img d-inline-block my-3 initial-height pointer border-0"
                        onError="this.src='assets/img/default-user.png'"
                        [src]="
                          this.dataService.userProfileImageWithRandomText ||
                          this.auth.userInfo.imageUrl
                        "
                      />
                      <i
                        class="icon-cam font-12 d-inline pointer position-absolute"
                      ></i>
                    </div>

                    <div
                      class="image-container mx-auto"
                      *ngIf="
                        !auth.userInfo.imageUrl ||
                        this.dataService.showDefaultUserImage
                      "
                      [ngClass]="{ 'm-progress': dataService.submitting }"
                    >
                      <label class="mb-0" for="file1">
                        <div
                          class="name-first-letters requester-img my-3"
                          [ngStyle]="{
                            'background-color':
                              auth.userInfo.profileImageBackgroundHexColor
                          }"
                        >
                          {{ auth.userInfo.name.substring(0, 1).toUpperCase()
                          }}{{
                            auth.userInfo.lastName.substring(0, 1).toUpperCase()
                          }}
                        </div>

                        <i
                          class="icon-cam font-12 d-inline pointer position-absolute"
                        ></i>
                      </label>
                      <input
                        type="file"
                        name="file1"
                        id="file1"
                        class="form-control"
                        (change)="onFileSelect($event)"
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                    </div>
                  </div>

                  <app-user-profile-image
                    *ngIf="
                      dataService.desktopView && dataService.editProfileImage
                    "
                  ></app-user-profile-image>
                </div>
                <div class="pt-3 dotted-text">
                  <p
                    class="gray-primaray-text minus-margin-bottom my-0 font-16"
                  >
                    <span
                      [ngClass]="{
                        'pr-3': dataService.isSiteLtr(),
                        'pl-3': dataService.isSiteRtl()
                      }"
                      >{{ auth.userInfo.name }}
                      {{ auth.userInfo.lastName }}</span
                    >
                  </p>
                  <p
                    class="dotted-text dotted-text-direction gray-text pb-0 mb-0"
                  >
                    {{ auth.userInfo.email }}
                  </p>
                  <p class="gray-text mb-0">
                    <ng-container i18n="@@joinDate">Join date</ng-container
                    >:&nbsp;<span
                      class="mb-0"
                      [innerHTML]="
                        dataService.formatDateMMMdy(auth.userInfo.creationDate)
                      "
                    ></span>
                  </p>
                </div>
              </div>

              <hr class="my-4" />

              <p class="gray-primaray-text pb-3">
                <span
                  class="font-16"
                  [ngClass]="{
                    'pr-3': dataService.isSiteLtr(),
                    'pl-3': dataService.isSiteRtl()
                  }"
                  i18n="@@personalInfo"
                  >Personal Info</span
                ><span
                  class="blue-text pointer font-14"
                  (click)="editUserInfo()"
                  i18n="@@edit"
                  >Edit</span
                >
              </p>
              <p class="gray-text font-14 mb-1" i18n="@@contactNumber">
                Contact number
              </p>
              <p
                class="gray-primaray-text pb-3 font-16"
                *ngIf="auth.userInfo.phoneNumber"
                dir="ltr"
              >
                <span
                  [ngClass]="{
                    'pr-1': dataService.isSiteLtr(),
                    'pl-1': dataService.isSiteRtl()
                  }"
                  *ngIf="auth.userInfo.phoneNumberCountryCode"
                  >{{ returnPhoneCountryCode() }}</span
                >
                {{ auth.userInfo.phoneNumber }}
              </p>
              <p
                class="gray-primaray-text pb-3 font-16"
                *ngIf="!auth.userInfo.phoneNumber"
              >
                &mdash;
              </p>

              <p class="gray-text font-14 mb-1 mt-4" i18n="@@gender">Gender</p>
              <p
                class="gray-primaray-text pb-3 font-16 text-capitalize"
                *ngIf="auth.userInfo.gender"
              >
                <span *ngIf="auth.userInfo.gender === 'MALE'" i18n="@@male">
                  Male
                </span>
                <span *ngIf="auth.userInfo.gender === 'FEMALE'" i18n="@@female">
                  Female
                </span>
              </p>
              <p
                class="gray-primaray-text pb-3 font-16"
                *ngIf="!auth.userInfo.gender"
              >
                &mdash;
              </p>

              <p class="gray-text font-14 mb-1 mt-4" i18n="@@dob">
                Date of birth
              </p>
              <p
                class="gray-primaray-text pb-3 font-16"
                *ngIf="auth.userInfo.birthDate"
              >
                <span
                  [innerHTML]="
                    dataService.formatDateMMMdy(auth.userInfo.birthDate)
                  "
                ></span>
              </p>
              <p
                class="gray-primaray-text pb-3 font-16"
                *ngIf="!auth.userInfo.birthDate"
              >
                &mdash;
              </p>

              <hr />

              <p class="gray-primaray-text pb-3">
                <span
                  class="font-16"
                  [ngClass]="{
                    'pr-3': dataService.isSiteLtr(),
                    'pl-3': dataService.isSiteRtl()
                  }"
                  i18n="@@userPreferences"
                  >Preferences</span
                >&nbsp;<span
                  class="blue-text pointer font-14"
                  (click)="editPreferences(auth.userInfo.systemPreferences)"
                  i18n="@@edit"
                  >Edit</span
                >
              </p>
              <p class="gray-text font-14 mb-1" i18n="@@langPlaceHolder">
                Language
              </p>
              <p
                class="gray-primaray-text pb-3 font-16"
                *ngIf="auth.userInfo && auth.userInfo.systemPreferences"
                dir="ltr"
              >
                {{ auth.userInfo.systemPreferences.preferredLanguage.name === 'Arabic' ? 'العربية' : auth.userInfo.systemPreferences.preferredLanguage.name}}
              </p>
              <p
                class="gray-primaray-text pb-3 font-16"
                *ngIf="!auth.userInfo.systemPreferences"
              >
                &mdash;
              </p>

              <p class="gray-text font-14 mb-1 mt-4" i18n="@@preferedCurrency">Currency</p>
              <p
                class="gray-primaray-text pb-3 font-16 text-capitalize"
                *ngIf="auth.userInfo && auth.userInfo.systemPreferences"
              >
              {{auth.userInfo.systemPreferences.preferredCurrency.name}}&nbsp;({{auth.userInfo.systemPreferences.preferredCurrency.code}})
              </p>
              <p
                class="gray-primaray-text pb-3 font-16"
                *ngIf="!auth.userInfo.systemPreferences"
              >
                &mdash;
              </p>

              <hr />
              <div [ngClass]="{ 'max-width-330': dataService.desktopView }">
                <p class="gray-primaray-text font-16 mt-4 pt-2">
                  <ng-container i18n="@@travelDoc">Travel Document</ng-container
                  ><span
                    class="blue-text pointer font-14"
                    [ngClass]="{
                      'pl-3': dataService.isSiteLtr(),
                      'pr-3': dataService.isSiteRtl()
                    }"
                    (click)="addEditTravelDocument(null)"
                    i18n="@@add"
                    >Add</span
                  >
                </p>
                <p
                  class="gray-text"
                  *ngIf="
                    !auth.userInfo.travelDocuments ||
                    (auth.userInfo.travelDocuments &&
                      !auth.userInfo.travelDocuments.length)
                  "
                  i18n="@@noTravelDocs"
                >
                  You don’t have any travel document
                </p>
                <div class="items-full-list">
                  <div
                    *ngFor="
                      let passport of auth.userInfo.travelDocuments;
                      let i = index
                    "
                    class="border rounded p-3 position-relative mb-3"
                  >
                    <div class="d-flex">
                      <p
                        class="font-16 gray-primaray-text text-capitalize"
                        [ngClass]="{
                          'pr-2': dataService.isSiteLtr(),
                          'pl-2': dataService.isSiteRtl()
                        }"
                      >
                        {{ passport.type.toLowerCase().replace("_", " ") }}
                      </p>
                      <img
                        width="16"
                        height="16"
                        loading="lazy"
                        *ngIf="passport.issuingCountryCode"
                        class="mt-1 max-100 img-min-width pr-1"
                        [src]="
                          'https://www.triphop.com/cdn/flags/' +
                          passport.issuingCountryCode.toLowerCase() +
                          '.svg'
                        "
                        onError="this.src='/assets/img/transperant.png'"
                      />
                    </div>

                    <p class="font-16 bold-600 gray-primaray-text mb-0">
                      {{ passport.issuingCountry }}
                    </p>
                    <div class="d-flex">
                      <p
                        class="gray-text"
                        [ngClass]="{
                          'mr-auto': dataService.isSiteLtr(),
                          'ml-auto': dataService.isSiteRtl()
                        }"
                      >
                        {{ passport.number }}
                      </p>

                      <p
                        class="gray-text"
                        *ngIf="
                          dataService.checkExpireDate(passport.expiryDate) ===
                          'valid'
                        "
                        [ngClass]="{
                          'mr-5': dataService.isSiteLtr(),
                          'ml-5': dataService.isSiteRtl()
                        }"
                      >
                        <ng-container i18n="@@expiresOn"
                          >Expires on</ng-container
                        >&nbsp;<span
                          [innerHTML]="
                            dataService.formatDateMMMdy(passport.expiryDate)
                          "
                        ></span>
                      </p>
                      <p
                        class="orange-text"
                        *ngIf="
                          dataService.checkExpireDate(passport.expiryDate) ===
                          'willExpireSoon'
                        "
                        [ngClass]="{
                          'mr-5': dataService.isSiteLtr(),
                          'ml-5': dataService.isSiteRtl()
                        }"
                      >
                        <ng-container i18n="@@expiresOn"
                          >Expires on</ng-container
                        >&nbsp;<span
                          [innerHTML]="
                            dataService.formatDateMMMdy(passport.expiryDate)
                          "
                        ></span>
                      </p>
                      <p
                        class="text-danger"
                        *ngIf="
                          dataService.checkExpireDate(passport.expiryDate) ===
                          'expired'
                        "
                        [ngClass]="{
                          'mr-5': dataService.isSiteLtr(),
                          'ml-5': dataService.isSiteRtl()
                        }"
                      >
                        <ng-container i18n="@@expiredOn"
                          >Expired on</ng-container
                        >&nbsp;<span
                          [innerHTML]="
                            dataService.formatDateMMMdy(passport.expiryDate)
                          "
                        ></span>
                      </p>
                    </div>

                    <i
                      class="doc-drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                      (click)="
                        showActionsDoc = !showActionsDoc; selectedIndexDoc = i
                      "
                    ></i>
                    <div
                      class="border shadow font-14 position-absolute bg-white drop"
                      *ngIf="showActionsDoc && i === selectedIndexDoc"
                    >
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="addEditTravelDocument(passport)"
                        i18n="@@view"
                      >
                        View
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="deleteDoc(passport.id)"
                        i18n="@@deleteBtn"
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-5" />
              <div [ngClass]="{ 'max-width-330': dataService.desktopView }">
                <p class="gray-primaray-text font-16 mt-4 pt-2">
                  <ng-container i18n="@@flyerProgram"
                    >Frequent Flyer Program(s)</ng-container
                  >&nbsp;<span
                    class="blue-text pointer font-14"
                    [ngClass]="{
                      'pl-3': dataService.isSiteLtr(),
                      'pr-3': dataService.isSiteRtl()
                    }"
                    (click)="addEditFlyerProgram(null)"
                    i18n="@@add"
                    >Add</span
                  >
                </p>

                <p
                  class="gray-text"
                  *ngIf="
                    !auth.userInfo.frequentFlyerPrograms ||
                    (auth.userInfo.frequentFlyerPrograms &&
                      !auth.userInfo.frequentFlyerPrograms.length)
                  "
                  i18n="@@noProgram"
                >
                  You don’t have any program
                </p>
                <div class="items-full-list">
                  <div
                    *ngFor="
                      let program of auth.userInfo.frequentFlyerPrograms;
                      let i = index
                    "
                    class="border rounded p-3 position-relative mb-3"
                  >
                    <div class="d-flex">
                      <p
                        class="font-16 bold-600 gray-primaray-text"
                        [ngClass]="{
                          'pr-1': dataService.isSiteLtr(),
                          'pl-1': dataService.isSiteRtl()
                        }"
                      >
                        {{ program.name }}
                      </p>
                      <img
                        width="22.5"
                        height="23"
                        loading="lazy"
                        *ngIf="program.code"
                        class="max-100 img-min-width pr-1"
                        [src]="
                          'https://www.triphop.com/cdn/airline/logos/' +
                          program.code.toLowerCase() +
                          '.png'
                        "
                        onError="this.src='/assets/img/transperant.png'"
                      />
                    </div>

                    <p class="gray-text">{{ program.number }}</p>
                    <i
                      class="drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                      (click)="showActions = !showActions; selectedIndex = i"
                    ></i>
                    <div
                      class="border shadow font-14 position-absolute bg-white drop"
                      *ngIf="showActions && i === selectedIndex"
                    >
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="addEditFlyerProgram(program)"
                        i18n="@@view"
                      >
                        View
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="deleteProgram(program.id)"
                        i18n="@@deleteBtn"
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-5" />

              <div class="pb-3 mb-3 mt-4 pt-2">
                <p class="mb-2">
                  <span
                    class="gray-primaray-text font-16"
                    [ngClass]="{
                      'pr-3': dataService.isSiteLtr(),
                      'pl-3': dataService.isSiteRtl()
                    }"
                    i18n="@@passwordPlaceHolder"
                    >Password</span
                  ><span
                    class="blue-text pointer font-14"
                    (click)="editUserPassword()"
                    i18n="@@changePassword"
                    >Change</span
                  >
                </p>
                <p class="gray-text" i18n="@@passwordNote">
                  Your data is encrypted and shared with your company and travel
                  suppliers only when booking your business travel.
                </p>
              </div>
            </div>
            <div
              class="col-md-6"
              [ngClass]="{
                'box-col': dataService.desktopView,
                'pr-lg-0': dataService.isSiteLtr(),
                'pl-lg-0': dataService.isSiteRtl()
              }"
            >
              <div
                class="rounded-bottom p-4 font-14 right-box-style company-section-with-thick-border thick-top"
                *ngIf="dataService.desktopView"
                [ngClass]="{
                  'ml-auto': dataService.isSiteLtr(),
                  'mr-auto': dataService.isSiteRtl()
                }"
              >
                <p class="mb-0 gray-text-dark" i18n="@@profileMsg">
                  Member email address and name cannot be changed. If you want
                  to edit the email or your name, please contact your workspace
                  administrator.
                </p>
              </div>
              <div
                class="border rounded mt-4 p-4"
                [ngClass]="{
                  'right-box-style': dataService.desktopView,
                  'ml-auto': dataService.isSiteLtr() && dataService.desktopView,
                  'mr-auto': dataService.isSiteRtl() && dataService.desktopView
                }"
              >
                <p
                  class="gray-primaray-text font-18 bold-600"
                  *ngIf="dataService.mobileView"
                  i18n="@@companySetting"
                >
                  Company Setting
                </p>
                <p class="gray-text font-14 mb-1" i18n="@@team">Team</p>
                <p
                  class="gray-primaray-text pb-3 font-16"
                  *ngIf="auth.userInfo.teamName"
                >
                  {{ auth.userInfo.teamName }}
                </p>
                <p
                  class="gray-primaray-text pb-3 font-16"
                  *ngIf="!auth.userInfo.teamName"
                  i18n="@@unassigned"
                >
                  Unassigned
                </p>

                <p class="gray-text font-14 mb-1 mt-4" i18n="@@travelPolicy">
                  Travel Policy
                </p>
                <div class="pb-3">
                  <p
                    class="gray-primaray-text font-16"
                    *ngIf="auth.userInfo.effectivePolicyName"
                  >
                    {{ auth.userInfo.effectivePolicyName }}
                  </p>
                  <p
                    class="gray-primaray-text font-16"
                    *ngIf="!auth.userInfo.effectivePolicyName"
                    i18n="@@unassigned"
                  >
                    Unassigned
                  </p>
                  <ng-container *ngIf="dataService.applicablePolicy">
                    <div class="d-flex">
                      <i
                        class="icon-hotel-item font-25 gray-text"
                        [ngClass]="{
                          'mr-2': dataService.isSiteLtr(),
                          'ml-3': dataService.isSiteRtl()
                        }"
                      ></i>
                      <div>
                        <p class="mb-2 gray-text">
                          <span i18n="@@nightlyPriceLimitCapital"
                            >Nightly price limit
                          </span>
                          <ng-container
                            *ngIf="
                              dataService.applicablePolicy
                                .companyPolicyHotelRules.avgNightlyPriceLimit
                            "
                          >
                            (<span
                              ><ng-container *ngIf="dataService.isSiteLtr()"
                                >$</ng-container
                              >{{
                                dataService.applicablePolicy
                                  .companyPolicyHotelRules.avgNightlyPriceLimit
                                  | number : "1.0-0"
                              }}<ng-container *ngIf="dataService.isSiteRtl()"
                                >$</ng-container
                              ></span
                            ><ng-container i18n="@@slashNight"
                              >/night</ng-container
                            >)
                          </ng-container>
                          <span
                            class="gray-text"
                            *ngIf="
                              !dataService.applicablePolicy
                                .companyPolicyHotelRules.avgNightlyPriceLimit
                            "
                          >
                            -
                          </span>
                        </p>
                        <p
                          class="gray-text mb-2"
                          *ngFor="
                            let except of this.dataService.exceptionsHotelPolicy
                          "
                        >
                          {{ except.caption }} (<span
                            >${{
                              except.avgNightlyPriceLimit | number : "1.0-0"
                            }}</span
                          ><ng-container i18n="@@slashNight"
                            >/night</ng-container
                          >)
                        </p>
                      </div>
                    </div>

                    <div class="gray-text mt-5 d-flex">
                      <i
                        class="pt-2 icon-flight-item font-18 gray-text"
                        [ngClass]="{
                          'mr-2': dataService.isSiteLtr(),
                          'ml-3': dataService.isSiteRtl()
                        }"
                      ></i>
                      <div>
                        <p class="mb-2">
                          <span i18n="@@fareClass">Fare class</span>:
                          <span class="gray-text">{{
                            dataService.applicablePolicySelectedClass
                          }}</span>
                        </p>
                        <p
                          class="mb-2"
                          *ngIf="
                            dataService.applicablePolicy
                              .companyPolicyFlightRules
                              .domesticPriceLimitPerPassenger ===
                            dataService.applicablePolicy
                              .companyPolicyFlightRules
                              .internationalPriceLimitPerPassenger
                          "
                        >
                          <span i18n="@@priceCap">Price cap</span>:
                          <ng-container
                            *ngIf="
                              dataService.applicablePolicy
                                .companyPolicyFlightRules
                                .domesticPriceLimitPerPassenger
                            "
                          >
                            <span
                              ><ng-container *ngIf="dataService.isSiteLtr()"
                                >$</ng-container
                              >{{
                                dataService.applicablePolicy
                                  .companyPolicyFlightRules
                                  .domesticPriceLimitPerPassenger
                                  | number : "1.0-0"
                              }}<ng-container *ngIf="dataService.isSiteRtl()"
                                >$</ng-container
                              ></span
                            >
                          </ng-container>
                          <span
                            *ngIf="
                              !dataService.applicablePolicy
                                .companyPolicyFlightRules
                                .domesticPriceLimitPerPassenger
                            "
                          >
                            -
                          </span>
                        </p>

                        <ng-container
                          *ngIf="
                            dataService.applicablePolicy
                              .companyPolicyFlightRules
                              .domesticPriceLimitPerPassenger !==
                            dataService.applicablePolicy
                              .companyPolicyFlightRules
                              .internationalPriceLimitPerPassenger
                          "
                        >
                          <p class="mb-2">
                            <span i18n="@@interPriceCap"
                              >International price cap</span
                            >:
                            <span
                              ><ng-container *ngIf="dataService.isSiteLtr()"
                                >$</ng-container
                              >{{
                                dataService.applicablePolicy
                                  .companyPolicyFlightRules
                                  .internationalPriceLimitPerPassenger
                                  | number : "1.0-0"
                              }}<ng-container *ngIf="dataService.isSiteRtl()"
                                >$</ng-container
                              ></span
                            >
                          </p>
                          <p class="mb-2">
                            <span i18n="@@domPriceCap">Domestic price cap</span
                            >:
                            <span
                              ><ng-container *ngIf="dataService.isSiteLtr()"
                                >$</ng-container
                              >{{
                                dataService.applicablePolicy
                                  .companyPolicyFlightRules
                                  .domesticPriceLimitPerPassenger
                                  | number : "1.0-0"
                              }}<ng-container *ngIf="dataService.isSiteRtl()"
                                >$</ng-container
                              ></span
                            >
                          </p>
                        </ng-container>
                        <p></p>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <p class="gray-text font-14 mb-1 mt-4" i18n="@@CompCards">
                  Company Cards
                </p>
                <ng-container
                  class=""
                  *ngFor="let item of auth.userInfo.companyCards"
                >
                  <p
                    class="gray-primaray-text pb-3 font-16 mb-0"
                    *ngIf="item.alias"
                  >
                    {{ item.alias }}
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16 mb-0"
                    *ngIf="!item.alias"
                  >
                    {{ item.brand }} {{ item.lastFour }}
                  </p>
                </ng-container>
                <p
                  class="gray-primaray-text pb-3 font-16 mb-0"
                  *ngIf="
                    !auth.userInfo.companyCards ||
                    !auth.userInfo.companyCards.length
                  "
                  i18n="@@noPayment"
                >
                  No payment methods
                </p>

                <p class="gray-text font-14 mb-1 mt-4" i18n="@@role">Role</p>
                <p class="gray-primaray-text mb-0 font-16 text-capitalize">
                  <span *ngIf="auth.userInfo.role === 'OWNER'" i18n="@@owner">
                    {{ auth.userInfo.role.toLowerCase().replace("_", " ") }}
                  </span>
                  <span *ngIf="auth.userInfo.role === 'ADMIN'" i18n="@@admin">
                    {{ auth.userInfo.role.toLowerCase().replace("_", " ") }}
                  </span>
                  <span *ngIf="auth.userInfo.role === 'TEAM_MANAGER'" i18n="@@teamManager">
                    {{ auth.userInfo.role.toLowerCase().replace("_", " ") }}
                  </span>
                  <span *ngIf="auth.userInfo.role === 'MEMBER'" i18n="@@member">
                    {{ auth.userInfo.role.toLowerCase().replace("_", " ") }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="dataService.mobileView" class="col-md-6 px-0 my-5">
          <button
            (click)="this.auth.logout()"
            class="btn-block btn btn-outline-gray px-5 gray-text-dark font-14 sharp-font bold-500"
            i18n="@@logOutBtn"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
