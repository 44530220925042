    <h3 class="pt-3 pb-3 pt-lg-0 pb-lg-2 gray-primaray-text text-medium" i18n="@@WeAccept">We accept</h3>
    <ul class="list-inline card-type-list"
        [ngClass]="{
            'pr-0': dataService.isSiteRtl()
          }">
        <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/visa.png"  alt="visa"></li>
        <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/mastercard.png" alt="mastercard"></li>
        <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/american_express.png" alt="americal express"></li>
        <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/mada.png" alt="mada"></li>
    </ul>

    <h3 class="gray-primaray-text text-medium" i18n="@@countOnUs">You can count on us</h3>
    <ul class="checks">
        <li class="mb-1" i18n="@@secureData">Data is transmitted securely</li>
        <li class="mb-1" i18n="@@protectInfo">We protect your personal information</li>
    </ul>

    <div class="d-none d-md-block">
        <a style="border: none; margin-bottom: 1rem; zoom: 0.6; cursor: pointer;" ><img width="130px" src="https://cdn.ssl.com/assets/ssl_seal_1-3dfcdf69a9e544495dbcf366a74ee5c4baca7e4f8018f7ddfa22f2de9bcf464d.png" alt="Ssl seal"></a>
        <div class="" i18n="@@infoExchanged">Information exchanged is encrypted before transmission.</div>
    </div>

