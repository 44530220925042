<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div
        class="container mt-4 mt-lg-5 mb-5 extra-padding"
        *ngIf="this.dataService.approvalData"
      >
        <div class="row mx-lg-4">
          <div class="col">
            <div class="pb-2 pb-lg-5 mb-3">
              <a
                class="font-16 blue-text text-underline"
                (click)="
                  this.router.navigate([
                   '/user/requests'
                  ])
                "
                i18n="@@menuItemMyApprovals"
                >My Trip Approvals</a
              >
              <span class="font-16 gray-text-dark">
                /
                {{
                  this.dataService.approvalData.airItineraryValidationResponse
                    .airItinerary.originDestinationOptionsGroups[0].summary
                    .departureAirport.city
                }}
                -
                {{
                  this.dataService.approvalData.airItineraryValidationResponse
                    .airItinerary.originDestinationOptionsGroups[0].summary
                    .arrivalAirport.city
                }}<span
                  *ngIf="
                    this.dataService.approvalData.approvalRequest.bookRequest
                      .searchRequest.roundTrip
                  "
                >
                  -
                  {{
                    this.dataService.approvalData.airItineraryValidationResponse
                      .airItinerary.originDestinationOptionsGroups[1].summary
                      .arrivalAirport.city
                  }}</span
                >
              </span>
            </div>
            <p
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() ===
                'approved'
              "
              class="bold-600 font-16 green-text"
              i18n="@@approved"
            >
              Approved
            </p>
            <p
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() ===
                'pending_approval'
              "
              class="bold-600 font-16 yellow-text mb-0"
              i18n="@@pendingApproval"
            >
              Pending Approval&nbsp;
            </p>
            <p
              *ngIf="
                dataService.desktopView &&
                this.dataService.approvalData.status.toLowerCase() ===
                  'pending_approval'
              "
            >
              <span
                class="font-14 blue-text pointer font-weight-normal mb-0"
                (click)="
                  withdrawApprovalRequestPopup(this.dataService.approvalData.id)
                "
                i18n="@@withdraw"
                >Withdraw</span
              >
            </p>
            <p
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() ===
                'disapproved'
              "
              class="bold-600 font-16 text-danger mb-0"
              i18n="@@declined"
            >
              Declined
            </p>
            <div
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() ===
                'disapproved'
              "
            >
              <ng-container
                *ngFor="let item of this.dataService.approvalData.operations"
              >
                <p class="font-12 gray-text">{{ item.reason }}</p>
              </ng-container>
            </div>
            <p
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() === 'expired'
              "
              class="bold-600 font-16 gray-text"
              i18n="@@expired"
            >
              Expired
            </p>
            <p
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() ===
                'withdrawn'
              "
              class="bold-600 font-16 gray-text"
              i18n="@@withdrawn"
            >
              Withdrawn
            </p>
          </div>
        </div>
        <div class="row mx-lg-4">
          <div class="col-lg-8 custom-col8 pt-3">
            <div class="border rounded px-lg-4 pt-4">
              <div
                class="px-4 px-lg-0"
                [ngClass]="{ 'd-flex': dataService.desktopView }"
              >
                <div
                  class="d-flex"
                  [ngClass]="{
                    'mr-5 pr-4':
                      dataService.desktopView && dataService.isSiteLtr(),
                    'ml-5 pl-4':
                      dataService.desktopView && dataService.isSiteRtl()
                  }"
                >
                  <div>
                    <img
                      width="50"
                      height="50"
                      loading="lazy"
                      *ngIf="dataService.approvalData.requesterProfileImageUrl"
                      class="user-img d-inline-block my-3"
                      onError="this.src='assets/img/default-user.png'"
                      [src]="dataService.approvalData.requesterProfileImageUrl"
                    />
                    <div
                      *ngIf="!dataService.approvalData.requesterProfileImageUrl"
                      class="name-first-letters requester-img my-3"
                      [ngStyle]="{
                        'background-color':
                          dataService.approvalData
                            .requesterProfileImageBackgroundHexColor
                      }"
                    >
                      {{
                        dataService.approvalData.requesterFirstname
                          .substring(0, 1)
                          .toUpperCase()
                      }}{{
                        dataService.approvalData.requesterLastname
                          .substring(0, 1)
                          .toUpperCase()
                      }}
                    </div>
                  </div>

                  <div class="pt-4">
                    <p
                      class="gray-primaray-text bold-600 font-18 mb-0"
                      [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl(),
                        'mt-2': !dataService.approvalData.requesterTeamName,
                        'dotted-text dotted-text-direction':
                          dataService.mobileView
                      }"
                    >
                      <span class="pr-2">{{
                        this.dataService.approvalData.requesterFirstname
                      }}</span
                      >{{ this.dataService.approvalData.requesterLastname }}
                    </p>
                    <p
                      *ngIf="this.dataService.approvalData.requesterTeamName"
                      class="gray-text font-14"
                      [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl()
                      }"
                    >
                      {{ this.dataService.approvalData.requesterTeamName }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="gray-text mt-4 px-4 px-lg-0">
                <ng-container i18n="@@bookedFor">Booked for</ng-container>
                {{ dataService.approvalData.guestFirstname }}
                {{ dataService.approvalData.guestLastname }}
              </div>
              <div class="price-container pb-4 gray-primaray-text font-16 mt-3">
                <div class="px-4 px-lg-0">
                  <p class="bold-600 mb-2" i18n="@@tripSummary">Trip Summary</p>
                  <p
                    class="mb-1"
                    [ngClass]="{
                      'mr-0': dataService.isSiteLtr(),
                      'ml-0': dataService.isSiteRtl()
                    }"
                  >
                    <ng-container i18n="@@flight">Flight</ng-container>
                    ({{
                      this.dataService.approvalData
                        .airItineraryValidationResponse.airItinerary
                        .originDestinationOptionsGroups[0].summary
                        .departureAirport.city
                    }}
                    -
                    {{
                      this.dataService.approvalData
                        .airItineraryValidationResponse.airItinerary
                        .originDestinationOptionsGroups[0].summary
                        .arrivalAirport.city
                    }}<span
                      *ngIf="
                        this.dataService.approvalData.approvalRequest
                          .bookRequest.searchRequest.roundTrip
                      "
                    >
                      -
                      {{
                        this.dataService.approvalData
                          .airItineraryValidationResponse.airItinerary
                          .originDestinationOptionsGroups[1].summary
                          .arrivalAirport.city
                      }}</span
                    >)
                  </p>
                  <p class="gray-text mb-1">
                    {{
                      this.dataService.approvalData.approvalRequest.bookRequest
                        .searchRequest.roundTrip
                        ? dataService.roundTrip
                        : dataService.oneWay
                    }}
                  </p>
                  <ng-container *ngFor="let item of vars.classType">
                    <p
                      class="gray-text mb-1"
                      *ngIf="
                        item.value ===
                        this.dataService.approvalData.approvalRequest
                          .bookRequest.searchRequest.cabinClass
                      "
                    >
                      {{ item.name }}
                    </p>
                  </ng-container>
                  <p
                    class="mb-1 mt-4"
                    [ngClass]="{
                      'mr-0': dataService.isSiteLtr(),
                      'ml-0': dataService.isSiteRtl()
                    }"
                    i18n="@@totalPrice"
                  >
                    Total Price
                  </p>
                  <p class="gray-text mb-1">
                    ${{
                      this.dataService.approvalData
                        .airItineraryValidationResponse.airItinerary.fareInfo
                        .sellingTotalFares.total.amount | number : "1.0-2"
                    }}
                  </p>
                </div>

                <hr />
                <div class="px-4 px-lg-0">
                  <ng-container
                    *ngIf="
                      this.dataService.approvalData.policy &&
                      this.dataService.approvalData
                        .airItineraryValidationResponse.airItinerary.userData
                    "
                  >
                    <p class="bold-600 mt-4 mb-2">
                      <ng-container i18n="@@travelPolicy"
                        >Travel Policy</ng-container
                      >&nbsp;
                      <span class="font-weight-normal font-14"
                        >({{ this.dataService.approvalData.policy.name }})</span
                      >
                    </p>

                    <ng-container
                      *ngIf="
                        !this.dataService.approvalData
                          .airItineraryValidationResponse.airItinerary.userData
                          .flightBookingNotAllowed
                      "
                    >
                      <p
                        class="mb-1"
                        [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="icon-check green-text font-12"
                          *ngIf="
                            !this.dataService.approvalData
                              .airItineraryValidationResponse.airItinerary
                              .userData.priceLimitPerPassengerViolated
                          "
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <i
                          class="icon-close-light text-danger font-12"
                          *ngIf="
                            this.dataService.approvalData
                              .airItineraryValidationResponse.airItinerary
                              .userData.priceLimitPerPassengerViolated
                          "
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <span i18n="@@maxPrice" class="font-16 gray-text"
                          >Max price:</span
                        >

                        <span
                          class="gray-text mb-1 px-2"
                          *ngIf="
                            !this.dataService.approvalData
                              .airItineraryValidationResponse.airItinerary
                              .userData.domestic
                          "
                          >${{
                            this.dataService.approvalData.policy
                              .companyPolicyFlightRules
                              .internationalPriceLimitPerPassenger
                          }}</span
                        >
                        <span
                          class="gray-text mb-1 px-2"
                          *ngIf="
                            this.dataService.approvalData
                              .airItineraryValidationResponse.airItinerary
                              .userData.domestic
                          "
                          >${{
                            this.dataService.approvalData.policy
                              .companyPolicyFlightRules
                              .domesticPriceLimitPerPassenger
                          }}</span
                        >
                      </p>

                      <p
                        class="mb-1"
                        [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="icon-check green-text font-12"
                          *ngIf="
                            !this.dataService.approvalData
                              .airItineraryValidationResponse.airItinerary
                              .userData.maximumCabinClassViolated
                          "
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <i
                          class="icon-close-light text-danger font-12"
                          *ngIf="
                            this.dataService.approvalData
                              .airItineraryValidationResponse.airItinerary
                              .userData.maximumCabinClassViolated
                          "
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>

                        <span i18n="@@fareClass" class="font-16 gray-text"
                          >Fare class:</span
                        >
                        <ng-container *ngFor="let item of vars.classType">
                          <span
                            class="gray-text mb-1 px-2"
                            *ngIf="
                              item.value ===
                              this.dataService.approvalData.policy
                                .companyPolicyFlightRules.maximumCabinClass
                            "
                            >{{ item.name }}</span
                          >
                        </ng-container>
                      </p>

                      <p
                        class="mb-1 gray-text"
                        [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="icon-check green-text font-12"
                          *ngIf="
                            !this.dataService.approvalData
                              .airItineraryValidationResponse.airItinerary
                              .userData.maximumNumberOfTripsViolated
                          "
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <i
                          class="icon-close-light text-danger font-12"
                          *ngIf="
                            this.dataService.approvalData
                              .airItineraryValidationResponse.airItinerary
                              .userData.maximumNumberOfTripsViolated
                          "
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>

                        <span i18n="@@maxNoOfTrips" class="font-16 gray-text"
                          >Max trip/</span
                        >{{
                          dataService
                            .getInterval(
                              this.dataService.approvalData.policy
                                .companyPolicyFlightRules
                                .maximumNumberOfTripsInterval
                            )
                            .toLowerCase()
                        }}:<span class="gray-text mb-1 px-2">{{
                          this.dataService.approvalData.policy
                            .companyPolicyFlightRules.maximumNumberOfTrips
                        }}</span>
                      </p>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        this.dataService.approvalData
                          .airItineraryValidationResponse.airItinerary.userData
                          .flightBookingNotAllowed
                      "
                    >
                      <p
                        class="mb-1"
                        [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="icon-close-light text-danger font-12"
                          *ngIf="
                            dataService.approvalData
                              .airItineraryValidationResponse.airItinerary
                              .userData.flightBookingNotAllowed
                          "
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <span
                          i18n="@@hotelBookingAllowed"
                          class="font-16 gray-text"
                          >Flight booking allowed</span
                        >
                      </p>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="px-4 px-lg-0 mt-5">
                  <p class="bold-600 mt-4 mb-2">
                    <ng-container i18n="@@approver">Approver</ng-container>
                  </p>
                  <div
                    class="px-lg-0 pt-4"
                    [ngClass]="{ 'd-flex': dataService.desktopView }"
                  >
                    <p class="font-weight-normal font-16">
                      <span class="pr-2">{{
                        this.dataService.approvalData.approverFirstname
                      }}</span
                      >{{ this.dataService.approvalData.approverLastname }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="border rounded px-4 pt-4 mt-4">
              <p
                class="font-16 gray-primaray-text bold-600 mt-3"
                i18n="@@cancellationPolicies"
              >
                Cancellation Policies
              </p>
              <div
                class="gray-text-dark font-14 mb-0"
                *ngIf="
                  this.dataService.approvalData.airItineraryValidationResponse
                    .airItinerary.fareInfo.refundable === true
                "
                i18n="@@refundTicket"
              >
                Ticket is Refundable
              </div>
              <div
                class="non-refundable gray-text-dark mb-0"
                *ngIf="
                  this.dataService.approvalData.airItineraryValidationResponse
                    .airItinerary.fareInfo.refundable === false
                "
                i18n="@@nonRefundTicket"
              >
                Ticket is Nonrefundable
              </div>
              <p class="gray-text-dark" i18n="@@flightCancellation">
                We do not charge any change or cancellation fee. When the
                airline charge for such fee based on their rules and polices,
                the cost will be passed on you.
              </p>
              <ng-container
                *ngIf="
                  this.dataService.approvalData.status.toLowerCase() !==
                    'disapproved' &&
                  this.dataService.approvalData.status.toLowerCase() !==
                    'expired' &&
                  this.dataService.approvalData.status.toLowerCase() !==
                    'withdrawn'
                "
              >
                <p
                  class="font-16 gray-primaray-text bold-600 mt-5"
                  i18n="@@paymentMethod"
                >
                  Payment Method
                </p>
                <p
                  class="font-14 gray-text-dark"
                  *ngIf="
                    this.dataService.approvalData.status.toLowerCase() ===
                    'pending_approval'
                  "
                >
                  <span
                    *ngIf="
                      this.dataService.approvalData.payment.channelProvider ===
                      'STRIPE'
                    "
                  >
                    <ng-container i18n="@@cardEndsWith"
                      >Card ending with</ng-container
                    >
                    {{ this.dataService.approvalData.payment.lastFour }}
                    <ng-container i18n="@@willCharged"
                      >will be charged with total of</ng-container
                    >
                    ${{
                      this.dataService.approvalData
                        .airItineraryValidationResponse.airItinerary.fareInfo
                        .sellingTotalFares.total.amount | number : "1.0-2"
                    }}
                  </span>
                  <span
                    *ngIf="
                      this.dataService.approvalData.payment.channelProvider ===
                      'PAYPAL'
                    "
                  >
                    <ng-container i18n="@@payPalWillCharge"
                      >will be charged to PayPal</ng-container
                    >
                    ({{ this.dataService.approvalData.payment.email }})
                  </span>
                </p>
                <p
                  class="font-14 gray-text-dark"
                  *ngIf="
                    this.dataService.approvalData.status.toLowerCase() ===
                    'approved'
                  "
                >
                  <span
                    *ngIf="
                      this.dataService.approvalData.payment.channelProvider ===
                      'STRIPE'
                    "
                  >
                    ${{
                      this.dataService.approvalData
                        .airItineraryValidationResponse.airItinerary.fareInfo
                        .sellingTotalFares.total.amount | number : "1.0-2"
                    }}
                    <ng-container i18n="@@charged"
                      >charged to card ending with</ng-container
                    >
                    {{ this.dataService.approvalData.payment.lastFour }}
                  </span>
                  <span
                    *ngIf="
                      this.dataService.approvalData.payment.channelProvider ===
                      'PAYPAL'
                    "
                  >
                    <ng-container i18n="@@payPalCharged"
                      >Charged to PayPal</ng-container
                    >
                    ({{ this.dataService.approvalData.payment.email }})
                  </span>
                </p>
              </ng-container>
            </div>
            <div class="mt-4" *ngIf="dataService.desktopView">
              <div
                class="mb-2 pt-4"
                *ngFor="let msg of this.dataService.approvalData.operations"
              >
                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'REQUEST_MORE_INFO'"
                >
                  <div
                    class="d-flex"
                    [ngClass]="{
                      'mr-5 pr-4': dataService.isSiteLtr(),
                      'ml-5 pl-4': dataService.isSiteRtl()
                    }"
                  >
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="dataService.approvalData.approverProfileImageUrl"
                        class="user-img d-inline-block my-3"
                        onError="this.src='assets/img/default-user.png'"
                        [src]="dataService.approvalData.approverProfileImageUrl"
                      />
                      <div
                        *ngIf="
                          !dataService.approvalData.approverProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            dataService.approvalData
                              .approverProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          dataService.approvalData.approverFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          dataService.approvalData.approverLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                      >
                        <span class="pr-2">{{
                          this.dataService.approvalData.approverFirstname
                        }}</span
                        >{{ this.dataService.approvalData.approverLastname }}
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark">
                    {{ msg.comment }}
                  </div>
                </div>

                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'SUBMIT_MORE_INFO'"
                >
                  <div class="d-flex">
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="
                          dataService.approvalData.requesterProfileImageUrl
                        "
                        class="user-img d-inline-block my-3"
                        onError="this.src='assets/img/default-user.png'"
                        [src]="
                          dataService.approvalData.requesterProfileImageUrl
                        "
                      />
                      <div
                        *ngIf="
                          !dataService.approvalData.requesterProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            dataService.approvalData
                              .requesterProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          dataService.approvalData.requesterFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          dataService.approvalData.requesterLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                        i18n="@@me"
                      >
                        Me
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark gray-bg">
                    {{ msg.comment }}
                  </div>
                </div>
              </div>
              <form
                class="my-5"
                [formGroup]="requestMoreInfoForm"
                *ngIf="
                  this.dataService.approvalData.status.toLowerCase() ===
                  'pending_approval'
                "
              >
                <textarea
                  name="moreComment"
                  id="moreComment"
                  formControlName="moreComment"
                  class="form-control pt-3 mt-5"
                  i18n-placeholder="@@typeMsg"
                  placeholder="Type your message here"
                  rows="6"
                  [ngClass]="{
                    'is-invalid': submitted && c.moreComment.errors
                  }"
                ></textarea>
                <div
                  *ngIf="submitted && c.moreComment.errors"
                  class="text-danger smaller mt-2"
                >
                  <div
                    *ngIf="c.moreComment.errors.required"
                    i18n="@@commentRequired"
                  >
                    Comment is required
                  </div>
                </div>
                <div class="d-flex" *ngIf="dataService.desktopView">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <button
                      class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                      (click)="requestSubmitMoreInfo()"
                      [ngClass]="{ 'm-progress': submitting }"
                      i18n="@@send"
                    >
                      Send
                    </button>
                  </div>
                </div>
                <div
                  *ngIf="dataService.mobileView"
                  [ngClass]="{
                    'gray-text': submitting,
                    'blue-text': !submitting,
                    'text-right': dataService.isSiteLtr(),
                    'text-right': dataService.isSiteRtl()
                  }"
                  class="font-16 mt-3 font-weight-bold"
                  (click)="requestSubmitMoreInfo()"
                  i18n="@@send"
                >
                  Send
                </div>
              </form>
            </div>
          </div>
          <!---------------->
          <div
            class="col-md-4 custom-col4 hotel-details-side pt-3"
            [ngClass]="{ 'px-0': dataService.mobileView }"
            *ngIf="this.dataService.approvalData"
          >
            <div
              class="table-header p-4 p-lg-3 mb-4"
              [ngClass]="{ rounded: dataService.desktopView }"
            >
              <p class="mb-0">
                <span
                  class="font-20 primaray-text font-weight-bold"
                  [innerHTML]="
                    dataService.formatDateEEMMMdy(
                      dataService.summaryDetailsDepart.departureDate
                    )
                  "
                ></span>
              </p>
              <p>
                <span class="gray-text-dark font-16">
                  {{ dataService.summaryDetailsDepart.departureAirport.city }}
                  <span class="text-lowercase" i18n="@@to">to</span>
                  {{
                    dataService.summaryDetailsDepart.arrivalAirport.city
                  }}</span
                >
              </p>

              <div class="white-box px-0 py-3">
                <div class="row h-100">
                  <div
                    class="col-1 pt-1"
                    [ngClass]="{
                      'pr-0': dataService.isSiteLtr(),
                      'pl-0': dataService.isSiteRtl()
                    }"
                  ></div>
                  <div
                    class="col-1"
                    [ngClass]="{
                      'pl-2 pr-0': dataService.isSiteLtr(),
                      'pr-2 pl-0': dataService.isSiteRtl()
                    }"
                  >
                    <img
                      width="26.5"
                      height="27"
                      loading="lazy"
                      class="max-100 initial-height img-min-width"
                      [src]="
                        dataService.summaryDetailsDepart.airlineInfoSummary
                          .marketingAirlineLogoUrl
                      "
                      onError="this.src='/assets/img/airline.png'"
                    />
                  </div>
                  <div class="col-10 pl-2 position-relative">
                    <p class="font-18 primaray-text font-weight-bold mb-0">
                      <span
                        class="mb-0 primaray-text"
                        [innerHTML]="
                          this.dataService.formatDateTime12Hours(
                            dataService.summaryDetailsDepart.departureDate
                          )
                        "
                        >10:15pm</span
                      >
                      -
                      <span
                        class="mb-0 primaray-text"
                        [innerHTML]="
                          this.dataService.formatDateTime12Hours(
                            dataService.summaryDetailsDepart.arrivalDate
                          )
                        "
                        >10:15pm</span
                      >
                      <span
                        class="font-14 font-weight-normal"
                        *ngIf="
                          dataService.checkNextDay(
                            dataService.summaryDetailsDepart.departureDate,
                            dataService.summaryDetailsDepart.arrivalDate
                          ) === 1
                        "
                        i18n="@@nextDay"
                      >
                        (Next day)</span
                      >
                      <span
                        class="font-14 font-weight-normal"
                        *ngIf="
                          dataService.checkNextDay(
                            dataService.summaryDetailsDepart.departureDate,
                            dataService.summaryDetailsDepart.arrivalDate
                          ) > 1
                        "
                      >
                        +{{
                          dataService.checkNextDay(
                            dataService.summaryDetailsDepart.departureDate,
                            dataService.summaryDetailsDepart.arrivalDate
                          )
                        }}</span
                      >
                      <span
                        class="font-14 font-weight-normal"
                        *ngIf="
                          dataService.checkPreviousDay(
                            dataService.summaryDetailsDepart.departureDate,
                            dataService.summaryDetailsDepart.arrivalDate
                          )
                        "
                        i18n="@@prevDay"
                      >
                        (Previous day)</span
                      >
                    </p>

                    <div class="d-flex">
                      <p class="gray-text-dark font-14 text-nowrap">
                        <span>{{
                          dataService.summaryDetailsDepart.departureAirport.iata
                        }}</span
                        ><span class="super-vertical-align"> ____ </span
                        ><span>{{
                          dataService.summaryDetailsDepart.arrivalAirport.iata
                        }}</span>
                      </p>
                      <p
                        class="mb-0 primaray-text pt-small"
                        [ngClass]="{
                          'ml-3': dataService.isSiteLtr(),
                          'mr-3': dataService.isSiteRtl()
                        }"
                      >
                        <span
                          [innerHTML]="
                            this.dataService.calDuration(
                              dataService.summaryDetailsDepart
                                .journeyDurationInMins
                            )
                          "
                          >6h 45m</span
                        >
                        <span
                          [ngClass]="{
                            'pl-2': dataService.isSiteLtr(),
                            'pr-2': dataService.isSiteRtl()
                          }"
                          *ngIf="
                            dataService.summaryDetailsDepart.totalStops === 1
                          "
                          >(<ng-container i18n="@@oneStop">1 stop</ng-container
                          >)</span
                        >
                        <span
                          [ngClass]="{
                            'pl-2': dataService.isSiteLtr(),
                            'pr-2': dataService.isSiteRtl()
                          }"
                          *ngIf="
                            dataService.summaryDetailsDepart.totalStops === 2
                          "
                          >(<ng-container i18n="@@twoStops"
                            >2 stops</ng-container
                          >)</span
                        >
                        <span
                          [ngClass]="{
                            'pl-2': dataService.isSiteLtr(),
                            'pr-2': dataService.isSiteRtl()
                          }"
                          *ngIf="
                            dataService.summaryDetailsDepart.totalStops > 2
                          "
                          >({{ dataService.summaryDetailsDepart.totalStops }}
                          <ng-container i18n="@@stops">stops</ng-container
                          >)</span
                        >
                      </p>
                      <p
                        class="gray-text-dark pt-small text-lowercase"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                        *ngIf="
                          dataService.summaryDetailsDepart.totalStops === 0
                        "
                      >
                        (<ng-container i18n="@@nonStop">nonstop</ng-container>)
                      </p>
                    </div>

                    <p
                      class="mb-0 gray-text-dark"
                      *ngIf="
                        dataService.summaryDetailsDepart.airlineInfoSummary
                          .marketingAirlines.length === 1
                      "
                    >
                      {{
                        dataService.summaryDetailsDepart.airlineInfoSummary
                          .marketingAirlines[0]
                      }}.
                      {{
                        this.dataService.approvalData
                          .airItineraryValidationResponse.airItinerary
                          .originDestinationOptionsGroups[0]
                          .originDestinationOptions[0].flightSegment
                          .cabinClassName
                      }}
                    </p>
                    <p
                      class="mb-0 gray-text-dark"
                      *ngIf="
                        dataService.summaryDetailsDepart.airlineInfoSummary
                          .marketingAirlines.length > 1
                      "
                    >
                      <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                      <span
                        *ngFor="
                          let airlineItem of dataService.summaryDetailsDepart
                            .airlineInfoSummary.marketingAirlines
                        "
                      >
                        {{ airlineItem }}.
                      </span>
                      {{
                        this.dataService.approvalData
                          .airItineraryValidationResponse.airItinerary
                          .originDestinationOptionsGroups[0]
                          .originDestinationOptions[0].flightSegment
                          .cabinClassName
                      }}
                    </p>
                    <div
                      class="gray-text"
                      *ngIf="
                        dataService.summaryDetailsDepart.airlineInfoSummary
                          .operatedBy.length
                      "
                    >
                      <p
                        class="mb-0"
                        *ngFor="
                          let operator of dataService.summaryDetailsDepart
                            .airlineInfoSummary.operatedBy;
                          let indexOperator = index
                        "
                      >
                        {{ operator }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container
                *ngIf="
                  this.dataService.approvalData.approvalRequest.bookRequest
                    .searchRequest.roundTrip
                "
              >
                <p class="mt-4 mb-0">
                  <span
                    class="font-20 primaray-text font-weight-bold"
                    [innerHTML]="
                      dataService.formatDateEEMMMdy(
                        dataService.summaryDetailsReturn.departureDate
                      )
                    "
                  ></span>
                </p>
                <p>
                  <span class="gray-text-dark font-16">
                    {{ dataService.summaryDetailsReturn.departureAirport.city }}
                    <span class="text-lowercase" i18n="@@to">to</span>
                    {{
                      dataService.summaryDetailsReturn.arrivalAirport.city
                    }}</span
                  >
                </p>

                <div class="white-box px-0 py-3">
                  <div class="row h-100">
                    <div class="col-1 pr-0 pt-1"></div>
                    <div class="col-1 pl-2 pr-0">
                      <img
                        width="26.5"
                        height="27"
                        loading="lazy"
                        class="max-100 initial-height img-min-width"
                        [src]="
                          dataService.summaryDetailsReturn.airlineInfoSummary
                            .marketingAirlineLogoUrl
                        "
                        onError="this.src='/assets/img/airline.png'"
                      />
                    </div>
                    <div class="col-10 pl-2 position-relative">
                      <p class="font-18 primaray-text font-weight-bold mb-0">
                        <span
                          class="mb-0 primaray-text"
                          [innerHTML]="
                            this.dataService.formatDateTime12Hours(
                              dataService.summaryDetailsReturn.departureDate
                            )
                          "
                          >10:15pm</span
                        >
                        -
                        <span
                          class="mb-0 primaray-text"
                          [innerHTML]="
                            this.dataService.formatDateTime12Hours(
                              dataService.summaryDetailsReturn.arrivalDate
                            )
                          "
                          >10:15pm</span
                        >
                        <span
                          class="font-14 font-weight-normal"
                          *ngIf="
                            dataService.checkNextDay(
                              dataService.summaryDetailsReturn.departureDate,
                              dataService.summaryDetailsReturn.arrivalDate
                            )
                          "
                          i18n="@@nextDay"
                        >
                          (Next day)</span
                        >
                        <span
                          class="font-14 font-weight-normal"
                          *ngIf="
                            dataService.checkNextDay(
                              dataService.summaryDetailsReturn.departureDate,
                              dataService.summaryDetailsReturn.arrivalDate
                            ) > 1
                          "
                        >
                          +{{
                            dataService.checkNextDay(
                              dataService.summaryDetailsReturn.departureDate,
                              dataService.summaryDetailsReturn.arrivalDate
                            )
                          }}</span
                        >
                        <span
                          class="font-14 font-weight-normal"
                          *ngIf="
                            dataService.checkPreviousDay(
                              dataService.summaryDetailsReturn.departureDate,
                              dataService.summaryDetailsReturn.arrivalDate
                            )
                          "
                          i18n="@@prevDay"
                        >
                          (Previous day)</span
                        >
                      </p>
                      <div class="d-flex">
                        <p class="gray-text-dark font-14 text-nowrap">
                          <span>{{
                            dataService.summaryDetailsReturn.departureAirport
                              .iata
                          }}</span
                          ><span class="super-vertical-align"> ____ </span
                          ><span>{{
                            dataService.summaryDetailsReturn.arrivalAirport.iata
                          }}</span>
                        </p>
                        <p
                          class="mb-0 primaray-text pt-small"
                          [ngClass]="{
                            'ml-3': dataService.isSiteLtr(),
                            'mr-3': dataService.isSiteRtl()
                          }"
                        >
                          <span
                            [innerHTML]="
                              this.dataService.calDuration(
                                dataService.summaryDetailsReturn
                                  .journeyDurationInMins
                              )
                            "
                            >6h 45m</span
                          >
                          <span
                            [ngClass]="{
                              'pl-2': dataService.isSiteLtr(),
                              'pr-2': dataService.isSiteRtl()
                            }"
                            *ngIf="
                              dataService.summaryDetailsReturn.totalStops === 1
                            "
                            >(<ng-container i18n="@@oneStop"
                              >1 stop</ng-container
                            >)</span
                          >
                          <span
                            [ngClass]="{
                              'pl-2': dataService.isSiteLtr(),
                              'pr-2': dataService.isSiteRtl()
                            }"
                            *ngIf="
                              dataService.summaryDetailsReturn.totalStops === 2
                            "
                            >(<ng-container i18n="@@twoStops"
                              >2 stops</ng-container
                            >)</span
                          >
                          <span
                            [ngClass]="{
                              'pl-2': dataService.isSiteLtr(),
                              'pr-2': dataService.isSiteRtl()
                            }"
                            *ngIf="
                              dataService.summaryDetailsReturn.totalStops > 2
                            "
                            >({{ dataService.summaryDetailsReturn.totalStops }}
                            <ng-container i18n="@@stops">stops</ng-container
                            >)</span
                          >
                        </p>
                        <p
                          class="gray-text-dark pt-small text-lowercase"
                          [ngClass]="{
                            'ml-2': dataService.isSiteLtr(),
                            'mr-2': dataService.isSiteRtl()
                          }"
                          *ngIf="
                            dataService.summaryDetailsReturn.totalStops === 0
                          "
                        >
                          (<ng-container i18n="@@nonStop">nonstop</ng-container
                          >)
                        </p>
                      </div>
                      <p
                        class="mb-0 gray-text-dark"
                        *ngIf="
                          dataService.summaryDetailsReturn.airlineInfoSummary
                            .marketingAirlines.length === 1
                        "
                      >
                        {{
                          dataService.summaryDetailsReturn.airlineInfoSummary
                            .marketingAirlines[0]
                        }}.
                        {{
                          this.dataService.approvalData
                            .airItineraryValidationResponse.airItinerary
                            .originDestinationOptionsGroups[0]
                            .originDestinationOptions[0].flightSegment
                            .cabinClassName
                        }}
                      </p>
                      <p
                        class="mb-0 gray-text-dark"
                        *ngIf="
                          dataService.summaryDetailsReturn.airlineInfoSummary
                            .marketingAirlines.length > 1
                        "
                      >
                        <span i18n="@@multipleAirlines"
                          >Multiple Airlines:
                        </span>
                        <span
                          *ngFor="
                            let airlineItem of dataService.summaryDetailsReturn
                              .airlineInfoSummary.marketingAirlines
                          "
                        >
                          {{ airlineItem }}.
                        </span>
                        {{
                          this.dataService.approvalData
                            .airItineraryValidationResponse.airItinerary
                            .originDestinationOptionsGroups[0]
                            .originDestinationOptions[0].flightSegment
                            .cabinClassName
                        }}
                      </p>
                      <div
                        class="gray-text"
                        *ngIf="
                          dataService.summaryDetailsReturn.airlineInfoSummary
                            .operatedBy.length
                        "
                      >
                        <p
                          class="mb-0"
                          *ngFor="
                            let operator of dataService.summaryDetailsReturn
                              .airlineInfoSummary.operatedBy;
                            let indexOperator = index
                          "
                        >
                          {{ operator }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <p class="text-right mb-0 mt-3">
                <span
                  class="blue-text pointer"
                  (click)="
                    showDetails(
                      this.dataService.approvalData
                        .airItineraryValidationResponse.airItinerary,
                      this.dataService.approvalData.approvalRequest.bookRequest
                        .searchRequest.roundTrip
                    )
                  "
                  i18n="@@showTripDetails"
                  >Show trip details</span
                >
              </p>
            </div>
            <div
              class="white-box p-0 mb-4 price_summary"
              [ngClass]="{ 'mx-4': dataService.mobileView }"
            >
              <div class="p-3">
                <h5
                  class="text-big mb-3"
                  [ngClass]="{ bolder: !dataService.isCompany() }"
                  i18n="@@priceSummary"
                >
                  Price Summary
                </h5>
                <ng-container
                  *ngFor="
                    let traveler of this.dataService.approvalData
                      .airItineraryValidationResponse.airItinerary.fareInfo
                      .fareBreakdown;
                    let travelerIndex = index
                  "
                >
                  <ng-container
                    *ngFor="
                      let travelerRepeat of [].constructor(
                        traveler.passengerTypeQuantity.quantity
                      );
                      let repeatIndex = index
                    "
                  >
                    <div class="discount d-flex font-16">
                      <span
                        [ngClass]="{
                          'mr-auto': dataService.isSiteLtr(),
                          'ml-auto': dataService.isSiteRtl()
                        }"
                        *ngIf="travelerIndex === 0"
                        ><ng-container i18n="@@traveler">Traveler</ng-container
                        >{{ repeatIndex + 1 }}:
                        <span class="text-capitalize">{{
                          traveler.passengerTypeQuantity.type.toLowerCase()
                        }}</span></span
                      >
                      <span
                        [ngClass]="{
                          'mr-auto': dataService.isSiteLtr(),
                          'ml-auto': dataService.isSiteRtl()
                        }"
                        *ngIf="travelerIndex === 1"
                        ><ng-container i18n="@@traveler">Traveler</ng-container
                        >{{
                          this.dataService.approvalData
                            .airItineraryValidationResponse.airItinerary
                            .fareInfo.fareBreakdown[0].passengerTypeQuantity
                            .quantity +
                            repeatIndex +
                            1
                        }}:
                        <span class="text-capitalize">{{
                          traveler.passengerTypeQuantity.type.toLowerCase()
                        }}</span></span
                      >
                      <span
                        >${{
                          traveler.sellingPassengerFare.totalFare.amount
                            | number : "1.2-2"
                        }}</span
                      >
                    </div>
                    <div class="discount d-flex font-14 gray-text my-2">
                      <span
                        [ngClass]="{
                          'mr-auto': dataService.isSiteLtr(),
                          'ml-auto': dataService.isSiteRtl()
                        }"
                        i18n="@@flight"
                        >Flight</span
                      >
                      <span
                        >${{
                          traveler.sellingPassengerFare.baseFare.amount
                            | number : "1.2-2"
                        }}</span
                      >
                    </div>
                    <div class="discount d-flex font-14 gray-text mb-4">
                      <span
                        [ngClass]="{
                          'mr-auto': dataService.isSiteLtr(),
                          'ml-auto': dataService.isSiteRtl()
                        }"
                        i18n="@@taxAndFees"
                        >Tax and fees</span
                      >
                      <span>
                        <span
                          >${{
                            this.dataService.calTaxes(
                              traveler.sellingPassengerFare.taxes
                            ) | number : "1.2-2"
                          }}</span
                        >
                      </span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <div class="white-box-footer rounded-bottom p-3">
                <div class="d-flex font-20">
                  <span
                    class="primaray-text"
                    [ngClass]="{ bolder: !dataService.isCompany() }"
                    i18n="@@Total"
                    >Total
                  </span>
                  <span
                    class="primaray-text bolder"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                    ><span class="currency"
                      >${{
                        this.dataService.approvalData
                          .airItineraryValidationResponse.airItinerary.fareInfo
                          .sellingTotalFares.total.amount | number : "1.0-2"
                      }}</span
                    ><span class="amount"></span
                  ></span>
                </div>
              </div>
            </div>
            <app-cost-reporting-details
              [costCentersIds]="
                dataService.approvalData?.approvalRequest?.costCenterId
                  ? [dataService.approvalData?.approvalRequest?.costCenterId]
                  : ''
              "
              [labelsIds]="
                dataService.approvalData?.approvalRequest?.labelsIds
                  ? dataService.approvalData?.approvalRequest?.labelsIds
                  : []
              "
            >
            </app-cost-reporting-details>
          </div>
          <!---------------->
          <div class="col-lg-8">
            <ng-container *ngIf="dataService.mobileView">
              <div
                class="mb-2 pt-4"
                *ngFor="let msg of this.dataService.approvalData.operations"
              >
                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'REQUEST_MORE_INFO'"
                >
                  <div
                    class="d-flex"
                    [ngClass]="{
                      'mr-5 pr-4': dataService.isSiteLtr(),
                      'ml-5 pl-4': dataService.isSiteRtl()
                    }"
                  >
                    <div>
                      <img
                        width="50"
                        height="50"
                        loading="lazy"
                        *ngIf="dataService.approvalData.approverProfileImageUrl"
                        class="user-img d-inline-block my-3"
                        onError="this.src='assets/img/default-user.png'"
                        [src]="dataService.approvalData.approverProfileImageUrl"
                      />

                      <div
                        *ngIf="
                          !dataService.approvalData.approverProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                      >
                        {{
                          dataService.approvalData.approverFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          dataService.approvalData.approverLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 ml-2 minus-margin-bottom"
                      >
                        <span class="pr-2">{{
                          this.dataService.approvalData.approverFirstname
                        }}</span
                        >{{ this.dataService.approvalData.approverLastname }}
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark">
                    {{ msg.comment }}
                  </div>
                </div>

                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'SUBMIT_MORE_INFO'"
                >
                  <div class="d-flex">
                    <div>
                      <img
                        width="50"
                        height="50"
                        loading="lazy"
                        *ngIf="
                          dataService.approvalData.requesterProfileImageUrl
                        "
                        class="user-img d-inline-block my-3"
                        onError="this.src='assets/img/default-user.png'"
                        [src]="
                          dataService.approvalData.requesterProfileImageUrl
                        "
                      />
                      <div
                        *ngIf="
                          !dataService.approvalData.requesterProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            dataService.approvalData
                              .requesterProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          dataService.approvalData.requesterFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          dataService.approvalData.requesterLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 ml-2 minus-margin-bottom"
                        i18n="@@me"
                      >
                        Me
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark gray-bg">
                    {{ msg.comment }}
                  </div>
                </div>
              </div>
              <form
                class="my-5"
                [formGroup]="requestMoreInfoForm"
                *ngIf="
                  this.dataService.approvalData.status.toLowerCase() ===
                  'pending_approval'
                "
              >
                <textarea
                  name="moreComment"
                  id="moreComment"
                  formControlName="moreComment"
                  class="form-control pt-3 mt-5"
                  i18n-placeholder="@@typeMsg"
                  placeholder="Type your message here"
                  rows="6"
                  [ngClass]="{
                    'is-invalid': submitted && c.moreComment.errors
                  }"
                ></textarea>
                <div
                  *ngIf="submitted && c.moreComment.errors"
                  class="text-danger smaller mt-2"
                >
                  <div
                    *ngIf="c.moreComment.errors.required"
                    i18n="@@commentRequired"
                  >
                    Comment is required
                  </div>
                </div>
                <div class="d-flex" *ngIf="dataService.desktopView">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <button
                      class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                      (click)="requestSubmitMoreInfo()"
                      [ngClass]="{ 'm-progress': submitting }"
                      i18n="@@send"
                    >
                      Send
                    </button>
                  </div>
                </div>
                <button
                  *ngIf="dataService.mobileView"
                  class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                  [ngClass]="{ 'm-progress': submitting }"
                  (click)="requestSubmitMoreInfo()"
                >
                  Send
                </button>
                <button
                  class="mt-5 btn-block btn btn-outline-blue sharp-font bold-500 font-14 px-4 text-center"
                  (click)="
                    withdrawApprovalRequestPopup(
                      this.dataService.approvalData.id
                    )
                  "
                  i18n="@@withdraw"
                >
                  Withdraw
                </button>
              </form>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
