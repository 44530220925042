import { Component, ElementRef, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { SidenavService } from "../../workspace-dashboard/sidenav.service";
import { onMainContentChange } from "../../workspace-dashboard/animations/animations";
import { Router } from "@angular/router";
import { AuthServiceMain } from "../../../auth/auth.service";
import { VariablesService } from "../../../services/vars.service";
import { environment } from "../../../../environments/environment";
import * as moment from "moment";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-approvals-details",
  templateUrl: "./approvals-details.component.html",
  styleUrls: ["./approvals-details.component.scss"],
  animations: [onMainContentChange],
  host: {
    "(body:click)": "onClickBody($event)",
  },
})

export class ApprovalsDetailsComponent implements OnInit {
  
  constructor(
    private formBuilder: FormBuilder,
    private eref: ElementRef,
    public dataService: DataService,
    private sidenavService: SidenavService,
    public router: Router,
    public auth: AuthServiceMain,
    public vars: VariablesService,
    private titleService: Title,
    private meta: Meta
  ) {
    this.titleService.setTitle("Trip Approval Details | SafarHub");
    this.meta.updateTag({
      name: "description",
      content: "Trip approval details",
    });
    this.sidenavService.sideNavState$.subscribe((res) => {
      this.dataService.onSideNavChange = res;
    });
  }

  urlArray = this.router.url.split("/");
  approvalId = this.urlArray[this.urlArray.length - 1];
  approvalActions = false;
  data;
  approvalForm: FormGroup;
  submitting;
  submitted;
  errorMsg;

  ngOnInit(): void {
    this.dataService.getApprovalDetailsInfo(this.approvalId);
    this.approvalForm = this.formBuilder.group(
      {
        comment: [""],
      },
      {}
    );
  }

  get f() {
    return this.approvalForm.controls;
  }

  approve(approvalIdValue, averageNightlySellingPriceValue) {
    let ignoreRatePriceChangeValue = "&ignoreRatePriceChange=false";
    if (this.approvalForm.controls.comment.value) {
      ignoreRatePriceChangeValue +=
        "&commment=" + this.approvalForm.controls.comment.value;
    }
    const approvalData = {
      approvalId: approvalIdValue,
      ignoreRatePriceChange: ignoreRatePriceChangeValue,
      averageNightlySellingPrice: averageNightlySellingPriceValue,
      pageType: "details",
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@tripApproval:Trip Approval`,
        "tripApproval",
        approvalData
      );
    } else {
      this.dataService.setBottomSheet("tripApproval", approvalData);
    }
  }

  disapprove(approvalIdValue, requesterNameValue) {
    let commentAndReasonValue = "";
    if (this.approvalForm.controls.comment.value) {
      commentAndReasonValue +=
        "&commment=" + this.approvalForm.controls.comment.value;
    }
    const disapprovalData = {
      approvalId: approvalIdValue,
      requesterName: requesterNameValue,
      commentAndReason: commentAndReasonValue,
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@declineTrip:Decline trip`,
        "tripDisapproval",
        disapprovalData
      );
    } else {
      this.dataService.setBottomSheet("tripDisapproval", disapprovalData);
    }
  }

  diffInDays(checkOut, checkIn) {
    return moment.utc(checkOut).diff(moment.utc(checkIn), "days");
  }

  onClickBody(event) {
    if (
      this.eref.nativeElement.querySelector(".approval-drop-down") &&
      !this.eref.nativeElement
        .querySelector(".approval-drop-down")
        .contains(event.target)
    ) {
      this.approvalActions = false;
    }
  }

  formatDate(date) {
    return (
      "(" +
      moment(date).format("MMM") +
      " " +
      moment(date).format("DD") +
      ", " +
      moment(date).format("YYYY") +
      ")"
    );
  }

  approvalSubmitMoreInfo() {
    this.errorMsg = "";
    this.submitted = true;
    this.submitting = true;
    if (this.approvalForm.controls.comment.value === "") {
      this.errorMsg = $localize`:@@enterMsgError:Please enter you message`;
      this.submitting = false;
      return;
    }
    this.dataService
      .approvalRequestMoreInfo(
        this.approvalId,
        this.approvalForm.controls.comment.value
      )
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.dataService.getApprovalDetailsInfo(this.approvalId);
            this.submitting = false;
            this.submitted = false;
            this.approvalForm.get("comment").setValue("");
            window.scrollTo(0, document.body.scrollHeight);
          } else {
            this.dataService.messages(this.data.message, "error");
            this.submitting = false;
          }
        },
        (error) => {
          this.dataService.messages(environment.messages.error, "error");
          this.submitting = false;
        }
      );
  }

  showApprovalsMobileActions(
    approvalIdValue,
    averageNightlySellingPriceValue,
    requesterNameValue
  ) {
    let ignoreRatePriceChangeValue = "&ignoreRatePriceChange=false";
    if (this.approvalForm.controls.comment.value) {
      ignoreRatePriceChangeValue +=
        "&commment=" + this.approvalForm.controls.comment.value;
    }
    const approvalData = {
      approvalId: approvalIdValue,
      ignoreRatePriceChange: ignoreRatePriceChangeValue,
      averageNightlySellingPrice: averageNightlySellingPriceValue,
      pageType: "details",
    };
    let commentAndReasonValue = "";
    if (this.approvalForm.controls.comment.value) {
      commentAndReasonValue +=
        "&commment=" + this.approvalForm.controls.comment.value;
    }
    const disapprovalData = {
      approvalId: approvalIdValue,
      requesterName: requesterNameValue,
      commentAndReason: commentAndReasonValue,
    };
    const comment = this.approvalForm.controls.comment.value;
    const bothApprovalDisapprovalObj = {
      disapprovalObj: disapprovalData,
      approvalObj: approvalData,
      commentValue: comment,
      approvalId: approvalIdValue,
    };
    this.dataService.errorMsg = "";
    this.dataService.setBottomSheet(
      "bothApprovalDisapproval",
      bothApprovalDisapprovalObj
    );
  }
}
