<div class="top_search_fields flights-controller">
  <div class="search mini_search line_search centered">
    <div class="top_search_fields">
      <div class="search mini_search line_search">
        <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
          <div>
            <div class="row gutters-5 justify-content-end">
              <div
                class="col-md-100 d-flex top-dropdown"
                [ngClass]="{
                  'text-white': consumerSearchTabs(),
                  'gray-primaray-text':
                    !this.router.url.includes('availability'),
                  'blue-text': this.router.url.includes('availability')
                }"
              >
                <div ngbDropdown class="position-relative">
                  <div id="trip" ngbDropdownToggle class="pointer">
                    <span
                      >{{ selectedTrip
                      }}<i
                        class="icon-arrow-down"
                        [ngClass]="{
                          'pl-2': restApi.isSiteLtr(),
                          'pr-2': restApi.isSiteRtl()
                        }"
                      ></i
                    ></span>
                  </div>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="trip"
                    class="trip-drop-down px-3 pt-3 font-14 gray-text-dark"
                    [ngClass]="{ 'text-right': restApi.isSiteRtl() }"
                  >
                    <p
                      (click)="
                        selectedTrip = this.roundTripLocalized;
                        this.restApi.selectedTrip = this.roundTripLocalized;
                        showReturnDate()
                      "
                      ngbDropdownToggle
                      class="pointer"
                      i18n="@@roundTrip"
                    >
                      Round-trip
                    </p>
                    <p
                      (click)="
                        selectedTrip = this.oneWayLocalized;
                        this.restApi.selectedTrip = this.roundTripLocalized
                      "
                      ngbDropdownToggle
                      class="pointer"
                      i18n="@@oneWay"
                    >
                      One-way
                    </p>
                  </div>
                </div>
                <div ngbDropdown class="position-relative">
                  <div
                    id="classType"
                    ngbDropdownToggle
                    class="pointer"
                    [ngClass]="{
                      'ml-5': restApi.isSiteLtr(),
                      'mr-5': restApi.isSiteRtl()
                    }"
                  >
                    <span>{{ selectedClass.name }}</span
                    ><i
                      class="icon-arrow-down"
                      [ngClass]="{
                        'pl-2': restApi.isSiteLtr(),
                        'pr-2': restApi.isSiteRtl()
                      }"
                    ></i>
                  </div>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="classType"
                    class="px-3 pt-3 font-14 gray-text-dark"
                    [ngClass]="{ 'text-right': restApi.isSiteRtl() }"
                  >
                    <div *ngFor="let item of vars.classType">
                      <p
                        (click)="selectedClass = item"
                        ngbDropdownToggle
                        class="pointer"
                      >
                        {{ item.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="selectedTrip !== 'Multi-city'">
                <div
                  class="position-relative"
                  [ngClass]="{
                    'col-md-25':
                      !this.restApi.isCompany() ||
                      this.router.url.includes('availability'),
                    'col-md-100':
                      this.restApi.isCompany() &&
                      !this.router.url.includes('availability')
                  }"
                >
                  <mat-form-field class="w-100">
                    <div class="form-group position-relative m-0 with-icon">
                      <div
                        class="form-control"
                        [ngClass]="{
                          'arial-font-style': !restApi.isCompany(),
                          'is-invalid':
                            submitted &&
                            (captionDepart === '' ||
                              restApi.captionDepart === '')
                        }"
                      >
                        <input
                          [ngClass]="{
                            'is-invalid': submitted && captionDepart === '',
                            'arial-font-style': !restApi.isCompany()
                          }"
                          (ngModelChange)="this.SearchTextQuery.next($event)"
                          matInput
                          [matAutocomplete]="auto1"
                          required
                          *ngIf="!availability"
                          autocomplete="off"
                          [(ngModel)]="captionDepart"
                          name="search"
                          (focus)="autocompleteOnFocusDepart($event)"
                          i18n-placeholder="@@selectOriginPlaceholder"
                          placeholder="Select an origin"
                          type="text"
                          class="flight-input"
                          [ngModelOptions]="{ standalone: true }"
                        />
                        <input
                          [ngClass]="{
                            'is-invalid':
                              submitted && restApi.captionDepart === '',
                            'arial-font-style': !restApi.isCompany()
                          }"
                          (ngModelChange)="this.SearchTextQuery.next($event)"
                          matInput
                          [matAutocomplete]="auto1"
                          required
                          *ngIf="availability"
                          autocomplete="off"
                          [(ngModel)]="this.restApi.captionDepart"
                          [ngModelOptions]="{ standalone: true }"
                          name="search"
                          (focus)="autocompleteOnFocusDepart($event)"
                          i18n-placeholder="@@selectOriginPlaceholder"
                          placeholder="Select an origin"
                          type="text"
                          class="flight-input"
                        />
                        <span
                          *ngIf="
                            restApi.captionDepart !== undefined &&
                            restApi.captionDepart.length === 0 &&
                            availability
                          "
                          class="gray-text placeholder-search"
                          [ngClass]="{
                            'arial-font-style': !restApi.isCompany()
                          }"
                          i18n="@@selectOrigin"
                          >Select an origin</span
                        >
                        <span
                          *ngIf="
                            captionDepart !== undefined &&
                            captionDepart.length === 0 &&
                            !availability
                          "
                          class="gray-text placeholder-search"
                          [ngClass]="{
                            'arial-font-style': !restApi.isCompany()
                          }"
                          i18n="@@selectOrigin"
                          >Select an origin</span
                        >
                        <label
                          class="form-control-label gray-text font-12"
                          i18n="@@flyFrom"
                          >Flying from</label
                        >
                      </div>
                      <i class="icon-locator gray-text"></i>
                      <mat-autocomplete
                        class="flight-list"
                        #auto1="matAutocomplete"
                      >
                        <ng-container
                          *ngFor="
                            let autocompletetype of autocompletelistDepart;
                            let i = index
                          "
                        >
                          <ng-container
                            *ngIf="autocompletetype.name === 'Airports'"
                          >
                            <ng-container
                              *ngFor="let entity of autocompletetype.entities"
                            >
                              <div
                                *ngIf="
                                  autocompletetype.entities.length &&
                                  entity.code !== ''
                                "
                              >
                                <mat-option
                                  data-id="{{ entity.code }}"
                                  tabindex="-1"
                                  [value]="entity.caption"
                                  (onSelectionChange)="
                                    setSearchTextDepart(
                                      entity,
                                      autocompletetype.name
                                    )
                                  "
                                  (click)="
                                    setSearchTextDepart(
                                      entity,
                                      autocompletetype.name
                                    )
                                  "
                                >
                                  <span class="d-block">
                                    {{ entity.caption }}
                                  </span>
                                </mat-option>
                              </div>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </mat-autocomplete>
                    </div>
                  </mat-form-field>
                  <div
                    *ngIf="
                      submitted &&
                      (captionDepart === '' || restApi.captionDepart === '')
                    "
                    class="text-danger mb-2"
                  >
                    <div i18n="@@pleaseSelectOrigin">
                      Please select an origin
                    </div>
                  </div>
                  <span
                    *ngIf="fromHomePage"
                    (click)="departReturnSwitch()"
                    class="icon-top-down-switch position-absolute font-20 home-page-top-down-switch pointer"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span><span class="path4"></span
                    ><span class="path5"></span
                  ></span>
                  <span
                    *ngIf="!fromHomePage"
                    (click)="departReturnSwitch()"
                    class="icon-left-right-switch position-absolute font-20 left-right-switch pointer"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span><span class="path4"></span
                    ><span class="path5"></span
                  ></span>
                </div>
                <div
                  [ngClass]="{
                    'col-md-24':
                      !this.restApi.isCompany() &&
                      this.router.url.includes('availability'),
                    'col-md-25':
                      consumerSearchTabs() ||
                      (this.restApi.isCompany() &&
                        this.router.url.includes('availability')),
                    'col-md-100':
                      this.restApi.isCompany() &&
                      !this.router.url.includes('availability')
                  }"
                >
                  <mat-form-field class="w-100">
                    <div class="form-group m-0 with-icon">
                      <div
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            (captionReturn === '' ||
                              restApi.captionReturn === '')
                        }"
                      >
                        <input
                          [ngClass]="{
                            'is-invalid': submitted && captionReturn === ''
                          }"
                          (ngModelChange)="
                            this.SearchTextQueryReturn.next($event)
                          "
                          matInput
                          [matAutocomplete]="auto"
                          required
                          *ngIf="!availability"
                          autocomplete="off"
                          [(ngModel)]="captionReturn"
                          name="search"
                          (focus)="autocompleteOnFocusReturn($event)"
                          i18n-placeholder="@@selectDesPlaceholder"
                          placeholder="Select a destination"
                          type="text"
                          class="flight-input"
                          [ngModelOptions]="{ standalone: true }"
                        />
                        <input
                          [ngClass]="{
                            'is-invalid':
                              submitted && restApi.captionReturn === ''
                          }"
                          (ngModelChange)="
                            this.SearchTextQueryReturn.next($event)
                          "
                          matInput
                          [matAutocomplete]="auto"
                          required
                          *ngIf="availability"
                          autocomplete="off"
                          [(ngModel)]="this.restApi.captionReturn"
                          [ngModelOptions]="{ standalone: true }"
                          name="search"
                          (focus)="autocompleteOnFocusReturn($event)"
                          i18n-placeholder="@@selectDesPlaceholder"
                          placeholder="Select a destination"
                          type="text"
                          class="flight-input"
                        />
                        <span
                          *ngIf="
                            restApi.captionReturn !== undefined &&
                            restApi.captionReturn.length === 0 &&
                            availability
                          "
                          class="gray-text placeholder-search"
                          >Select a destination</span
                        >

                        <span
                          *ngIf="
                            captionReturn !== undefined &&
                            captionReturn.length === 0 &&
                            !availability
                          "
                          class="gray-text placeholder-search"
                          i18n="@@selectDes"
                          >Select a destination</span
                        >
                        <label
                          class="form-control-label gray-text font-12"
                          i18n="@@flyTo"
                          >Flying to</label
                        >
                      </div>
                      <i class="icon-locator gray-text"></i>
                      <mat-autocomplete
                        class="flight-list"
                        #auto="matAutocomplete"
                      >
                        <ng-container
                          *ngFor="
                            let autocompletetype of autocompletelistReturn;
                            let y = index
                          "
                        >
                          <ng-container
                            *ngIf="autocompletetype.name === 'Airports'"
                          >
                            <ng-container
                              *ngFor="let entity of autocompletetype.entities"
                            >
                              <div
                                *ngIf="
                                  autocompletetype.entities.length &&
                                  entity.code !== ''
                                "
                              >
                                <mat-option
                                  data-id="{{ entity.code }}"
                                  tabindex="-1"
                                  [value]="entity.caption"
                                  (onSelectionChange)="
                                    setSearchTextReturn(
                                      entity,
                                      autocompletetype.name
                                    )
                                  "
                                  (click)="
                                    setSearchTextReturn(
                                      entity,
                                      autocompletetype.name
                                    )
                                  "
                                >
                                  <span
                                    class="d-block"
                                    *ngIf="entity.code !== ''"
                                  >
                                    {{ entity.caption }}
                                  </span>
                                </mat-option>
                              </div>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </mat-autocomplete>
                    </div>
                  </mat-form-field>

                  <div
                    *ngIf="
                      (submitted &&
                        (captionReturn === '' ||
                          restApi.captionReturn === '')) ||
                      (submitted && !arrivalCode)
                    "
                    class="text-danger mb-2"
                  >
                    <div i18n="@@pleaseSelectDes">
                      Please select a destination
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  selectedTrip === 'Multi-city' ||
                  this.selectedTrip === multiCityLocalized
                "
              >
                <div class="col-md-100 pb-0">
                  <div
                    class="row px-4 position-relative"
                    *ngFor="let item of allCities; let i = index"
                  >
                    <div class="position-relative col-md-40">
                      <div [formGroupName]="i">
                        <mat-form-field class="w-100">
                          <div
                            class="form-group position-relative m-0 with-icon"
                          >
                            <div
                              class="form-control"
                              [ngClass]="{
                                'arial-font-style': !restApi.isCompany(),
                                'is-invalid':
                                  submitted &&
                                  (item.captionDepart === '' ||
                                    restApi.captionDepartArray[i] === '')
                              }"
                            >
                              <input
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && item.captionDepart === '',
                                  'arial-font-style': !restApi.isCompany()
                                }"
                                (ngModelChange)="
                                  item.SearchTextQuery.next($event)
                                "
                                matInput
                                [matAutocomplete]="auto"
                                required
                                *ngIf="!availability"
                                autocomplete="off"
                                [(ngModel)]="item.captionDepart"
                                name="search"
                                (focus)="autocompleteOnFocusDepart($event)"
                                i18n-placeholder="@@selectOriginPlaceholder"
                                placeholder="Select an origin"
                                type="text"
                                class="flight-input"
                                [ngModelOptions]="{ standalone: true }"
                              />
                              <input
                                [ngClass]="{
                                  'is-invalid':
                                    submitted &&
                                    restApi.captionDepartArray[i] === '',
                                  'arial-font-style': !restApi.isCompany()
                                }"
                                (ngModelChange)="
                                  item.SearchTextQuery.next($event)
                                "
                                matInput
                                [matAutocomplete]="auto"
                                required
                                *ngIf="availability"
                                autocomplete="off"
                                [(ngModel)]="this.restApi.captionDepartArray[i]"
                                [ngModelOptions]="{ standalone: true }"
                                name="search"
                                (focus)="autocompleteOnFocusDepart($event)"
                                i18n-placeholder="@@selectOriginPlaceholder"
                                placeholder="Select an origin"
                                type="text"
                                class="flight-input"
                              />
                              <span
                                *ngIf="
                                  restApi.captionDepartArray[i] !== undefined &&
                                  restApi.captionDepartArray[i].length === 0 &&
                                  availability
                                "
                                class="gray-text placeholder-search"
                                [ngClass]="{
                                  'arial-font-style': !restApi.isCompany()
                                }"
                                i18n="@@selectOrigin"
                                >Select an origin</span
                              >
                              <span
                                *ngIf="
                                  item.captionDepart !== undefined &&
                                  item.captionDepart.length === 0 &&
                                  !availability
                                "
                                class="gray-text placeholder-search"
                                [ngClass]="{
                                  'arial-font-style': !restApi.isCompany()
                                }"
                                i18n="@@selectOrigin"
                                >Select an origin</span
                              >
                              <label
                                class="form-control-label gray-text font-12"
                                i18n="@@flyFrom"
                                >Flying from</label
                              >
                            </div>
                            <i class="icon-locator gray-text"></i>
                            <mat-autocomplete
                              class="flight-list"
                              #auto="matAutocomplete"
                            >
                              <ng-container
                                *ngFor="
                                  let autocompletetype of item.autocompletelistDepart
                                "
                              >
                                <ng-container
                                  *ngIf="autocompletetype.name === 'Airports'"
                                >
                                  <ng-container
                                    *ngFor="
                                      let entity of autocompletetype.entities
                                    "
                                  >
                                    <div
                                      *ngIf="
                                        autocompletetype.entities.length &&
                                        entity.code !== ''
                                      "
                                    >
                                      <mat-option
                                        data-id="{{ entity.code }}"
                                        tabindex="-1"
                                        [value]="entity.caption"
                                        (onSelectionChange)="
                                          setSearchTextDepartArray(
                                            entity,
                                            autocompletetype.name,
                                            i
                                          )
                                        "
                                        (click)="
                                          setSearchTextDepartArray(
                                            entity,
                                            autocompletetype.name,
                                            i
                                          )
                                        "
                                      >
                                        <span class="d-block">
                                          {{ entity.caption }}
                                        </span>
                                      </mat-option>
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </mat-autocomplete>
                          </div>
                        </mat-form-field>
                        <div
                          *ngIf="
                            submitted &&
                            (item.captionDepart === '' ||
                              restApi.captionDepartArray[i] === '')
                          "
                          class="text-danger mb-2"
                        >
                          <div i18n="@@pleaseSelectOrigin">
                            Please select an origin
                          </div>
                        </div>
                        <span
                          (click)="departReturnSwitch()"
                          class="icon-top-down-switch position-absolute font-20 left-right-switch pointer"
                          ><span class="path1"></span><span class="path2"></span
                          ><span class="path3"></span><span class="path4"></span
                          ><span class="path5"></span
                        ></span>
                      </div>
                    </div>
                    <div class="col-md-40">
                      <div [formGroupName]="'return' + i">
                        <mat-form-field class="w-100">
                          <div class="form-group m-0 with-icon">
                            <div
                              class="form-control"
                              [ngClass]="{
                                'is-invalid':
                                  submitted &&
                                  (item.captionReturn === '' ||
                                    restApi.captionReturnArray[i] === '')
                              }"
                            >
                              <input
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && item.captionReturn === ''
                                }"
                                (ngModelChange)="
                                  item.SearchTextQueryReturn.next($event)
                                "
                                matInput
                                [matAutocomplete]="autoA"
                                required
                                *ngIf="!availability"
                                autocomplete="off"
                                [(ngModel)]="item.captionReturn"
                                name="search"
                                (focus)="autocompleteOnFocusReturn($event)"
                                placeholder="Flying to"
                                type="text"
                                class="flight-input"
                                [ngModelOptions]="{ standalone: true }"
                              />
                              <input
                                [ngClass]="{
                                  'is-invalid':
                                    submitted &&
                                    restApi.captionReturnArray[i] === ''
                                }"
                                (ngModelChange)="
                                  item.SearchTextQueryReturn.next($event)
                                "
                                matInput
                                [matAutocomplete]="autoA"
                                required
                                *ngIf="availability"
                                autocomplete="off"
                                [(ngModel)]="this.restApi.captionReturnArray[i]"
                                [ngModelOptions]="{ standalone: true }"
                                name="search"
                                (focus)="autocompleteOnFocusReturn($event)"
                                placeholder="Flying to"
                                type="text"
                                class="flight-input"
                              />
                              <span
                                *ngIf="
                                  restApi.captionReturnArray[i] !== undefined &&
                                  restApi.captionReturnArray[i].length === 0 &&
                                  availability
                                "
                                class="gray-text placeholder-search"
                                >Select a destination</span
                              >
                              <span
                                *ngIf="
                                  item.captionReturn !== undefined &&
                                  item.captionReturn.length === 0 &&
                                  !availability
                                "
                                class="gray-text placeholder-search"
                                >Select a destination</span
                              >
                              <label
                                class="form-control-label gray-text font-12"
                                >Flying to</label
                              >
                            </div>
                            <i class="icon-locator gray-text"></i>
                            <mat-autocomplete
                              class="flight-list"
                              #autoA="matAutocomplete"
                            >
                              <ng-container
                                *ngFor="
                                  let autocompletetype of item.autocompletelistReturn
                                "
                              >
                                <ng-container
                                  *ngIf="autocompletetype.name === 'Airports'"
                                >
                                  <ng-container
                                    *ngFor="
                                      let entity of autocompletetype.entities
                                    "
                                  >
                                    <div
                                      *ngIf="
                                        autocompletetype.entities.length &&
                                        entity.code !== ''
                                      "
                                    >
                                      <mat-option
                                        data-id="{{ entity.code }}"
                                        tabindex="-1"
                                        [value]="entity.caption"
                                        (onSelectionChange)="
                                          setSearchTextReturnArray(
                                            entity,
                                            autocompletetype.name,
                                            i
                                          )
                                        "
                                        (click)="
                                          setSearchTextReturnArray(
                                            entity,
                                            autocompletetype.name,
                                            i
                                          )
                                        "
                                      >
                                        <span
                                          class="d-block"
                                          *ngIf="entity.code !== ''"
                                        >
                                          {{ entity.caption }}
                                        </span>
                                      </mat-option>
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </mat-autocomplete>
                          </div>
                        </mat-form-field>
                        <div
                          *ngIf="
                            submitted &&
                            (item.captionReturn === '' ||
                              restApi.captionReturnArray[i] === '')
                          "
                          class="text-danger mb-2"
                        >
                          <div i18n="@@pleaseSelectDes">
                            Please select a destination
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-20-right">
                      <div
                        class="form-group mb-0 with-icon calendar-item overflow-hidden text-nowrap"
                        [ngClass]="{
                          border: restApi.isCompany() && restApi.desktopView
                        }"
                      >
                        <div class="calendar-value-cont font-14">
                          <span class="d-inline-block">
                            <span
                              id="depart-date-desktop{{ i }}"
                              class="ng-binding"
                              >{{ item.departingRangeFormatted }}</span
                            >
                          </span>
                        </div>
                        <input
                          type="text"
                          id="multiCity{{ i }}"
                          class="form-control form-control-sm"
                          autocomplete="off"
                          name="dates"
                        />
                        <i
                          class="icon-dates gray-text"
                          *ngIf="this.router.url.includes('availability')"
                        ></i>
                        <label
                          class="form-control-label gray-text font-12"
                          i18n="@@departing"
                          >Departing</label
                        >
                      </div>
                    </div>
                    <i
                      class="icon-close-light gray-text position-absolute delete-item pointer"
                      (click)="removeCity(i)"
                      *ngIf="allCities.length > 2"
                    ></i>
                  </div>
                </div>
                <div
                  class="col-md-100"
                  [ngClass]="{
                    'd-none':
                      allCities.length >= 4 &&
                      !this.router.url.includes('availability'),
                    invisible:
                      allCities.length >= 4 &&
                      this.router.url.includes('availability')
                  }"
                >
                  <a
                    class="blue-text add-flight"
                    (click)="addCity()"
                    [ngClass]="{
                      'text-left': restApi.isSiteLtr(),
                      'text-right': restApi.isSiteRtl()
                    }"
                    >+Add flight</a
                  >
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  selectedTrip !== 'Multi-city' &&
                  selectedTrip !== multiCityLocalized
                "
              >
                <div
                  [ngClass]="{
                    'col-md-16':
                      !this.restApi.isCompany() &&
                      this.router.url.includes('availability'),
                    'col-md-15':
                      consumerSearchTabs() ||
                      (this.restApi.isCompany() &&
                        this.router.url.includes('availability')),
                    'col-md-50-left':
                      this.restApi.isCompany() &&
                      !this.router.url.includes('availability')
                  }"
                >
                  <div class="datePick">
                    <div
                      class="form-group mb-0 with-icon calendar-item text-nowrap"
                      [ngClass]="{
                        border: restApi.isCompany() && restApi.desktopView
                      }"
                      (click)="datepicker.open()"
                    >
                      <div class="calendar-value-cont font-14">
                        <span class="d-inline-block">
                          <span id="depart-date-desktop" class="ng-binding">{{
                            departingRangeFormatted
                          }}</span>
                        </span>
                      </div>
                      <input
                        name="datepicker"
                        style="opacity: 0"
                        autocomplete="off"
                        class="form-control"
                        ngbDatepicker
                        #datepicker="ngbDatepicker"
                        [firstDayOfWeek]="7"
                        [autoClose]="'outside'"
                        [minDate]="
                          calendar.getNext(calendar.getToday(), 'd', 0)
                        "
                        [maxDate]="
                          calendar.getNext(calendar.getToday(), 'd', 365)
                        "
                        (dateSelect)="onDateSelection($event)"
                        [displayMonths]="2"
                        [dayTemplate]="t"
                        [navigation]="'arrows'"
                        [footerTemplate]="footerTemplate"
                        [startDate]="this.departureDate"
                        outsideDays="hidden"
                      />
                      <ng-template #t let-date let-focused="focused">
                        <span
                          class="custom-day"
                          *ngIf="
                            selectedTrip === 'Round-trip' ||
                            this.selectedTrip === this.roundTripLocalized
                          "
                          [class.focused]="focused"
                          [class.range]="isRange(date)"
                          [class.faded]="isHovered(date) || isInside(date)"
                          (mouseenter)="hoveredDate = date"
                          (mouseleave)="hoveredDate = null"
                        >
                          {{ date.day }}
                        </span>
                        <span
                          class="custom-day"
                          *ngIf="
                            selectedTrip === 'One-way' ||
                            this.selectedTrip === this.oneWayLocalized
                          "
                          [class.focused]="focused"
                          [class.faded]="isHovered(date)"
                          (mouseenter)="hoveredDate = date"
                          (mouseleave)="hoveredDate = null"
                        >
                          {{ date.day }}
                        </span>
                      </ng-template>
                      <ng-template #footerTemplate>
                        <hr class="my-0" />
                        <div class="d-flex">
                          <button
                            class="btn btn-primary m-2"
                            (click)="
                              doneDate();
                              datepicker.toggle();
                              $event.stopPropagation()
                            "
                            [ngClass]="{
                              'ml-auto': restApi.isSiteLtr(),
                              'mr-auto': restApi.isSiteRtl()
                            }"
                            i18n="@@done"
                          >
                            Done
                          </button>
                        </div>
                      </ng-template>
                      <i class="icon-dates gray-text"></i>
                      <label
                        class="form-control-label gray-text font-12"
                        i18n="@@departing"
                        >Departing</label
                      >
                    </div>
                  </div>
                </div>
                <div
                  [ngClass]="{
                    'col-md-15':
                      !this.restApi.isCompany() ||
                      this.router.url.includes('availability'),
                    'col-md-50-right':
                      this.restApi.isCompany() &&
                      !this.router.url.includes('availability')
                  }"
                >
                  <div class="datePick h-44">
                    <div
                      class="form-group mb-0 with-icon calendar-item h-100 overflow-hidden text-nowrap"
                      [ngClass]="{
                        'disabled-field':
                          selectedTrip === 'One-way' ||
                          this.selectedTrip === this.oneWayLocalized,
                        border: restApi.isCompany() && restApi.desktopView
                      }"
                      (click)="datepicker.open()"
                    >
                      <div class="calendar-value-cont font-14">
                        <span class="d-inline-block">
                          <span
                            *ngIf="
                              selectedTrip !== 'One-way' &&
                              this.selectedTrip !== this.oneWayLocalized
                            "
                            id="return-date-desktop"
                            >{{ returningRangeFormatted }}</span
                          >
                        </span>
                      </div>
                      <i class="icon-dates gray-text"></i>
                      <label
                        class="form-control-label gray-text font-12"
                        i18n="@@returning"
                        >Returning</label
                      >
                    </div>
                  </div>
                </div>
              </ng-container>

              <div
                *ngIf="
                  this.router.url.includes('availability') ||
                  consumerSearchTabs()
                "
                [ngClass]="{
                  'col-md-20':
                    !this.restApi.isCompany() ||
                    this.router.url.includes('availability'),
                  'col-md-50-left':
                    this.restApi.isCompany() &&
                    !this.router.url.includes('availability')
                }"
                class="pb-1 position-relative"
              >
                <mat-form-field
                  class="h-44 mb-3 border w-100 chips-container form-control"
                  [ngClass]="{ 'red-border': submitted && !selectedUser }"
                  *ngIf="restApi.desktopView"
                  (click)="userInput.select()"
                >
                  <span
                    *ngIf="!selectedUser && !userInput.value"
                    class="gray-text traveller placeholder-traveller position-absolute"
                    i18n="@@whoTravelling"
                    >Who's travelling?</span
                  >

                  <input
                    class="min-height mx-0 my-0 py-3"
                    #userInput
                    type="text"
                    matInput
                    [formControl]="userControl"
                    [matAutocomplete]="autoUser"
                    (blur)="reset()"
                    (focus)="usersAutoComplete()"
                  />
                  <mat-autocomplete
                    class="flight-travellers"
                    #autoUser="matAutocomplete"
                    [displayWith]="displayFn"
                  >
                    <mat-option
                      class="px-2"
                      *ngFor="
                        let user of filteredUsers
                          | async
                          | slice : 0 : maxNoUserToShow
                      "
                      [value]="user"
                    >
                      <div
                        class="d-flex mat-checkbox-container"
                        (click)="optionClicked($event, user)"
                        *ngIf="user.id === 'guest'"
                      >
                        <div
                          class="name-first-letters small"
                          [ngClass]="{
                            'mr-3': restApi.isSiteLtr(),
                            'ml-3': restApi.isSiteRtl()
                          }"
                          [ngStyle]="{
                            'background-color':
                              user.profileImageBackgroundHexColor
                          }"
                        >
                          <img
                            loading="lazy"
                            class="mb-1 user-img d-inline-block"
                            onError="this.src='assets/img/default-user.png'"
                            [src]="user.profileImage"
                          />
                        </div>
                        <div class="dotted-text d-flex align-items-center">
                          <p class="mb-0 gray-primaray-text">
                            <span>{{ user.description }}</span>
                          </p>
                        </div>
                      </div>

                      <div
                        class="d-flex mat-checkbox-container"
                        (click)="optionClicked($event, user)"
                        *ngIf="user.id !== 'guest'"
                      >
                        <img
                          width="35"
                          height="35"
                          loading="lazy"
                          *ngIf="user.profileImage"
                          class="user-img d-inline-block"
                          [ngClass]="{
                            'mr-3': restApi.isSiteLtr(),
                            'ml-3': restApi.isSiteRtl()
                          }"
                          onError="this.src='assets/img/default-user.png'"
                          [src]="user.profileImage"
                        />
                        <div
                          class="name-first-letters small"
                          [ngClass]="{
                            'mr-3': restApi.isSiteLtr(),
                            'ml-3': restApi.isSiteRtl()
                          }"
                          *ngIf="!user.profileImage"
                          [ngStyle]="{
                            'background-color':
                              user.profileImageBackgroundHexColor
                          }"
                        >
                          {{ user.firstName.substring(0, 1).toUpperCase()
                          }}{{ user.lastName.substring(0, 1).toUpperCase() }}
                        </div>
                        <div class="dotted-text">
                          <p class="mb-0 gray-primaray-text">
                            <span>{{ user.description }}</span>
                          </p>
                          <p
                            class="gray-text mb-0 dotted-text dotted-text-direction"
                          >
                            {{ user.email }}
                          </p>
                        </div>
                      </div>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <div
                  *ngIf="submitted && !selectedUser"
                  class="smaller text-danger"
                  i18n="@@travellerRequired"
                >
                  Traveller is required
                </div>
              </div>

              <div
                *ngIf="
                  this.router.url.includes('availability') ||
                  consumerSearchTabs()
                "
                [ngClass]="{
                  'col-md-20':
                    !this.restApi.isCompany() ||
                    this.router.url.includes('availability'),
                  'col-md-50-right':
                    this.restApi.isCompany() &&
                    !this.router.url.includes('availability'),
                  'multi-city-btn-margin':
                    selectedTrip === 'Multi-city' ||
                    selectedTrip === multiCityLocalized
                }"
              >
                <button
                  value="submit"
                  class="btn-block green_btn btn-block"
                  [ngClass]="{ 'm-progress': submitting }"
                  i18n="@@searchBtn"
                >
                  Search
                </button>
              </div>
            </div>
            <div
              class="guests-children-menu row gutters-5 justify-content-end"
              *ngIf="
                !this.router.url.includes('availability') &&
                !consumerSearchTabs()
              "
              [ngClass]="{
                'max-width-437':
                  (selectedTrip === 'Multi-city' ||
                    selectedTrip === multiCityLocalized) &&
                  !this.router.url.includes('availability')
              }"
            >
              <div
                [ngClass]="{
                  'col-md-20':
                    !this.restApi.isCompany() ||
                    this.router.url.includes('availability'),
                  'col-md-50-left':
                    this.restApi.isCompany() &&
                    !this.router.url.includes('availability')
                }"
                class="pb-1 position-relative"
              >
                <mat-form-field
                  class="mb-3 border w-100 chips-container form-control"
                  [ngClass]="{ 'red-border': submitted && !selectedUser }"
                  *ngIf="restApi.desktopView"
                  (click)="userInput.select()"
                >
                  <span
                    *ngIf="!selectedUser && !userInput.value"
                    class="gray-text traveller placeholder-traveller position-absolute"
                    i18n="@@whoTravelling"
                    >Who's travelling?</span
                  >
                  <input
                    class="min-height mx-0 my-0 py-3"
                    #userInput
                    type="text"
                    matInput
                    [formControl]="userControl"
                    [matAutocomplete]="autoUser"
                    (blur)="reset()"
                    (focus)="usersAutoComplete()"
                  />
                  <mat-autocomplete
                    class="flight-travellers"
                    #autoUser="matAutocomplete"
                    [displayWith]="displayFn"
                  >
                    <mat-option
                      class="px-2"
                      *ngFor="
                        let user of filteredUsers
                          | async
                          | slice : 0 : maxNoUserToShow
                      "
                      [value]="user"
                    >
                      <div
                        class="d-flex mat-checkbox-container"
                        (click)="optionClicked($event, user)"
                        *ngIf="user.id === 'guest'"
                      >
                        <div
                          class="name-first-letters small"
                          [ngClass]="{
                            'mr-3': restApi.isSiteLtr(),
                            'ml-3': restApi.isSiteRtl()
                          }"
                          [ngStyle]="{
                            'background-color':
                              user.profileImageBackgroundHexColor
                          }"
                        >
                          <img
                            loading="lazy"
                            class="mb-1 user-img d-inline-block"
                            onError="this.src='assets/img/default-user.png'"
                            [src]="user.profileImage"
                          />
                        </div>
                        <div class="dotted-text d-flex align-items-center">
                          <p class="mb-0 gray-primaray-text">
                            <span>{{ user.description }}</span>
                          </p>
                        </div>
                      </div>
                      <div
                        class="d-flex mat-checkbox-container"
                        (click)="optionClicked($event, user)"
                        *ngIf="user.id !== 'guest'"
                      >
                        <img
                          width="35"
                          height="35"
                          loading="lazy"
                          *ngIf="user.profileImage"
                          class="user-img d-inline-block"
                          [ngClass]="{
                            'mr-3': restApi.isSiteLtr(),
                            'ml-3': restApi.isSiteRtl()
                          }"
                          onError="this.src='assets/img/default-user.png'"
                          [src]="user.profileImage"
                        />
                        <div
                          class="name-first-letters small"
                          [ngClass]="{
                            'mr-3': restApi.isSiteLtr(),
                            'ml-3': restApi.isSiteRtl()
                          }"
                          *ngIf="!user.profileImage"
                          [ngStyle]="{
                            'background-color':
                              user.profileImageBackgroundHexColor
                          }"
                        >
                          {{ user.firstName.substring(0, 1).toUpperCase()
                          }}{{ user.lastName.substring(0, 1).toUpperCase() }}
                        </div>
                        <div class="dotted-text">
                          <p class="mb-0 gray-primaray-text">
                            <span>{{ user.description }}</span>
                          </p>
                          <p
                            class="gray-text mb-0 dotted-text dotted-text-direction"
                          >
                            {{ user.email }}
                          </p>
                        </div>
                      </div>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <div
                  *ngIf="submitted && !selectedUser"
                  class="smaller text-danger"
                  i18n="@@travellerRequired"
                >
                  Traveller is required
                </div>
              </div>

              <div
                [ngClass]="{
                  'col-md-20':
                    !this.restApi.isCompany() ||
                    this.router.url.includes('availability'),
                  'col-md-50-right':
                    this.restApi.isCompany() &&
                    !this.router.url.includes('availability')
                }"
              >
                <button
                  value="submit"
                  class="btn-block green_btn btn-block"
                  [ngClass]="{ 'm-progress': submitting }"
                  i18n="@@searchBtn"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
