import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';

import { HttpClient } from '@angular/common/http';

import * as Lightpick from '../../js/lightpickCustom/lightpick';
import {DataService} from '../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthServiceMain} from "../auth/auth.service";
import { v4 as uuid } from 'uuid';
import {Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, map, startWith} from "rxjs/operators";
import {GoogleAnalyticsService} from "../google-analytics.service";
import {DatePipe} from "@angular/common";
import {User} from "../search-controller/search-controller.component";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-search-controller-mobile',
  templateUrl: './search-controller-mobile.component.html',
  styleUrls: ['./search-controller-mobile.component.scss']
})
export class SearchControllerMobileComponent implements OnInit {

  data;
  destination = '';

  oldcaption;
  textfill = false;
  captionMobile = '';
  oldsearchQuery;
  beforeChange = '';
  setSearchTextActive = false;
  autocompletelist;
  CheckoutRangeFormated;
  CheckinRangeFormated;
  decreaseChildrenDisabled = true;
  decreaseAdultDisabled = false;
  CheckoutRange;
  CheckinRange;
  childrenList = [];
  availability = false;
  hotel = false;
  queryParams;
  CheckoutRangeFormatted;
  CheckinRangeFormatted;
  searchQuery;
  captionbyClick = 'Enter city or hotel name ...';
  adultCount = 2;
  paxCount = 2;
  childrenCount = 0;
  decreaseDisabled = false;
  increasDisabled = false;
  Query = '';
  staynights;
  entity;
  caption = '';
  children = [];
  ages = [];
  searchForm: FormGroup;
  model: any = {};
  submitted = false;
  validChild = false;
  dateActive = true;
  viewMode;
  Childrenpax = '';
  checkin;
  checkout;
  showSearchPopDates = false;
  activePopUp = false;

  showSearchPopPaxes = false;
  showSearchPopUpautocomplete = false;
  showSearchPopUpActive = false;
  endDateMobileBeforeBoll = false;
  endDateMobileBefore;
  oldChekout;
  searchValid = false;
  lat;
  lng;
  autocompleteOn = true;
  submitting = false;
  SearchTextQuery = new Subject<string>();


  /***** guest changes *****/
  userControl = new FormControl();
  users = [];
  showUsersAutoCompletePopUp = false;
  selectedUser;
  filteredUsers: Observable<any[]>;
  lastFilter: string = '';
  dataUsers;
  @ViewChild('userInput') userInput: ElementRef;
  maxNoUserToShow: number = 3;
  guest: User = {description: $localize`:@@guestSelect:Guest`, firstName: "", lastName: "", id: 'guest', email: null, inactive: false, profileImageBackgroundHexColor: "#" + '5aa4e1', profileImage: 'assets/img/guest.svg', teamName: null, selected: false};
  /***** guest changes *****/
  travelerId = null;
  constructor(public datePipe: DatePipe,
              public googleAnalyticsService: GoogleAnalyticsService, private http: HttpClient, private formBuilder: FormBuilder, public restApi: DataService, public router: Router, public route: ActivatedRoute, public Auth: AuthServiceMain) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {

      return false;
    }

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
    //  this.travelerId = params.travelerId;
      if(this.router.url.includes('travelerId=')){
        this.travelerId = ('' + this.router.url).substring(('' + this.router.url).indexOf('travelerId=') + 11);
      } else{
        this.travelerId = null;
      }

      if(this.Auth.isOwner() || this.Auth.isAdmin()){
        if (this.travelerId && this.travelerId !== 'guest') {
          this.restApi.getCompanyUserDetails(this.travelerId).subscribe(
              data => {
                this.data = data;
                if (this.data.errorCode === 0) {
                  let element = this.data.data;
                  if (element.teamName) {
                    this.selectedUser = new User(element.firstName + ' ' + element.lastName, element.firstName, element.lastName, element.id, element.email, element.inactive, element.profileImageBackgroundHexColor, element.profileImage, element.teamName);
                  } else {
                    this.selectedUser = new User(element.firstName + ' ' + element.lastName, element.firstName, element.lastName, element.id, element.email, element.inactive, element.profileImageBackgroundHexColor, element.profileImage);
                  }
                  this.userControl.setValue(this.selectedUser);
                } else {
                  this.restApi.messages(this.data.message, 'error');
                }
              }, error => {
                this.restApi.messages(environment.messages.error, 'error');
              }
          );
        } else if(this.travelerId === 'guest'){
          this.selectedUser = this.guest;
          this.userControl.setValue(this.guest);
        }
      } else {
        if (this.Auth.userInfo.teamName) {
          this.selectedUser = new User(this.Auth.userInfo.name + ' ' + this.Auth.userInfo.lastName, this.Auth.userInfo.name, this.Auth.userInfo.lastName, this.Auth.userInfo.id, this.Auth.userInfo.email, this.Auth.userInfo.inactive, this.Auth.userInfo.profileImageBackgroundHexColor, this.Auth.userInfo.profileImage, this.Auth.userInfo.teamName);
        } else {
          this.selectedUser = new User(this.Auth.userInfo.name + ' ' + this.Auth.userInfo.lastName, this.Auth.userInfo.name, this.Auth.userInfo.lastName, this.Auth.userInfo.id, this.Auth.userInfo.email, this.Auth.userInfo.inactive, this.Auth.userInfo.profileImageBackgroundHexColor, this.Auth.userInfo.profileImage);
        }
        this.userControl.setValue(this.selectedUser);
      }

    });

    this.SearchTextQuery.pipe(
      // get value
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {

        this.Query = value;
        this.autoCompleteList()


      });


    this.searchForm = this.formBuilder.group({
  //    search: [[Validators.required]],

     // Children: this.formBuilder.array(this.childrenList),


    }, {});


    for (let i = 1; i < 18; i++) {
      this.ages[i] = i.toString();
    }



   /* if (this.Auth.isLogedIn && !this.Auth.companyInfo) {
      this.Auth.fetchCompanyData();
    }
    setTimeout(() => {


    if (this.restApi.urlWithoutParams === '/availability' || (this.restApi.isCompany() && this.restApi.urlWithoutParams  === '/' + this.Auth.companyInfo.id + '/availability')) {
      this.availability = true;


    }
    if (this.restApi.urlWithoutParams  === '/availability/hotel' || (this.restApi.isCompany() && this.restApi.urlWithoutParams  === '/' + this.Auth.companyInfo.id + '/availability/hotel')) {
      this.hotel = true;


    }
    },2000);

    */



    if (this.restApi.urlWithoutParams === '/availability' || (this.restApi.isCompany() && this.restApi.urlWithoutParams  === '/availability')) {
      this.availability = true;


    }
    if (this.restApi.urlWithoutParams  === '/availability/hotel' || (this.restApi.isCompany() && this.restApi.urlWithoutParams  === '/availability/hotel')) {
      this.hotel = true;


    }

    if (localStorage.getItem('history_storage') && Array.isArray(JSON.parse(localStorage.getItem('history_storage'))) === false) {

      localStorage.removeItem('history_storage');

    }


    if (localStorage.getItem('history_storage') && !this.Auth.isLogedIn) {

      this.restApi.historyArray = JSON.parse(localStorage.getItem('history_storage'));

      if (this.hotel === false && this.availability === false && this.restApi.historyArray[0]) {
        this.Query = this.restApi.historyArray[0].caption;
        this.caption = this.restApi.historyArray[0].caption;

        this.viewMode = this.restApi.historyArray[0].viewMode;

        this.searchQuery = this.restApi.historyArray[0].searchQuery;
        this.captionbyClick = this.restApi.historyArray[0].caption;


      }
    } else {

      this.restApi.fetchSearchHistory('false').subscribe(
        success => {


          this.data = success;
          if (this.data.errorCode === 0 && this.data.data) {
            this.restApi.historyArray = this.data.data.searches.slice(0, 5);
            if (this.hotel === false && this.availability === false && this.restApi.historyArray[0]) {
              this.Query = this.restApi.historyArray[0].caption;
              this.caption = this.restApi.historyArray[0].caption;

              this.viewMode = this.restApi.historyArray[0].viewMode;

              this.searchQuery = this.restApi.historyArray[0].searchQuery;
              this.captionbyClick = this.restApi.historyArray[0].caption;


            }
          }
        }
      );
    }


    this.route.queryParams.subscribe(params => {
      let searchDates = undefined;
      if(JSON.parse(localStorage.getItem('searchDates'))  ){
        searchDates = JSON.parse(localStorage.getItem('searchDates'))

        if(moment(searchDates.checkIn).isBefore(moment(), 'day')){

          localStorage.setItem('searchDates', JSON.stringify({checkIn:moment().add(14, 'days').format('YYYY-MM-DD') , checkOut:  moment().add(17, 'days').format('YYYY-MM-DD')}));

          searchDates = {checkIn:moment().add(14, 'days').format('YYYY-MM-DD') , checkOut:  moment().add(17, 'days').format('YYYY-MM-DD')}
        }


      }
      this.queryParams = params;


      if (params.checkinOffset && !params.checkIn){

        const checkinOffset  = Number(params.checkinOffset);
        this.CheckinRange = moment().add( checkinOffset, 'days').format('YYYY-MM-DD');
        if(!params.checkOut){
          this.CheckoutRange = moment().add(3 + checkinOffset, 'days').format('YYYY-MM-DD');

        }

      }

      if (params.nights && !params.checkOut){
        const nights  = Number(params.nights);

        if(params.checkIn){
          this.CheckoutRange = moment(params.checkIn).add( nights, 'days').format('YYYY-MM-DD');
          this.CheckinRange = moment(params.checkIn).format('YYYY-MM-DD');


        } else{
          this.CheckoutRange = moment(this.CheckinRange).add( nights, 'days').format('YYYY-MM-DD');

        }

      }






      if(   params.checkOut ===  'null' || params.checkIn === 'null' || moment(params.checkIn).isBefore(moment(), 'day') || !moment(params.checkIn).isValid()|| !moment(params.checkOut).isValid() || moment(params.checkOut).diff(moment(params.checkIn), 'days') <= 0){
        if(!this.CheckinRange  || moment(this.CheckinRange).isBefore(moment(), 'day')){
          if(searchDates) {

            this.CheckinRange = searchDates.checkIn;

          } else {
            this.CheckinRange = moment().add(14, 'days').format('YYYY-MM-DD');

          }


        }
        if(!this.CheckoutRange || moment(this.CheckoutRange).isBefore(moment(), 'day')) {
          if(searchDates) {

            this.CheckoutRange = searchDates.checkOut;

          } else {
            this.CheckoutRange = moment().add(17, 'days').format('YYYY-MM-DD');
          }
        }


      }   else if (params.checkIn !== undefined &&  params.checkOut !== undefined  && params.paxes !== undefined){
        this.CheckoutRange  = moment(params.checkOut).format('YYYY-MM-DD');

        this.CheckinRange = moment(params.checkIn).format('YYYY-MM-DD');
        //    this.router.navigate([urlWithoutParams], { queryParams: { searchQuery: this.queryParams.searchQuery, checkIn:  this.CheckinRange, checkOut: this.CheckoutRange, paxes: this.queryParams.paxes  },  });


      } else{

        if (params.checkIn === undefined  && this.CheckinRange === '' ) {
          if(searchDates) {
            this.CheckinRange = searchDates.checkIn;


          } else{

            this.CheckinRange = moment().add(14, 'days').format('YYYY-MM-DD');

          }
          //      this.router.navigate([urlWithoutParams], { queryParams: { searchQuery: this.queryParams.searchQuery, checkIn:  this.CheckinRange, checkOut: params.checkOut, paxes: this.queryParams.paxes  },  });

        } else if(!this.CheckinRange) {

          this.CheckinRange = moment(params.checkIn).format('YYYY-MM-DD');



        }


        if (params.checkOut === undefined && !this.CheckoutRange) {
          if(searchDates) {
            this.CheckoutRange = searchDates.checkOut;


          } else{
            this.CheckoutRange  = moment().add(17, 'days').format('YYYY-MM-DD');


          }
          //   this.router.navigate([urlWithoutParams], { queryParams: { searchQuery: this.queryParams.searchQuery, checkIn:  params.checkIn, checkOut: this.CheckoutRange, paxes: this.queryParams.paxes  },  });

        } else if(!this.CheckoutRange){
          this.CheckoutRange  = moment(params.checkOut).format('YYYY-MM-DD');


        }


      }





      const days = moment(this.CheckoutRange).diff(moment(this.CheckinRange), 'days');
      if(days <= 0){
        this.CheckinRange = moment().add(14, 'days').format('YYYY-MM-DD');
        this.CheckoutRange = moment().add(17, 'days').format('YYYY-MM-DD');


      }









      if (this.queryParams.paxes) {
        const childrenArr = this.queryParams.paxes.split(',');
        for (let i = 1; i < childrenArr.length; i++) {


          this.childrenList.push({childList: childrenArr[i]});
          this.childrenCount++;
          this.paxCount++;
          this.decreaseChildrenDisabled = false;
          this.increasCheck();

      //    this.addButtonParams(childrenArr[i]);

        }
        this.adultCount = Number(childrenArr[0]);
        this.paxCount = childrenArr.length - 1 + Number(childrenArr[0]);
      }
//------------------ added to get paxes in dashboard from localStorage
      if(!this.queryParams.paxes) {

        if(localStorage.getItem('hotelPaxes'))
        {
          const childrenArr = (localStorage.getItem('hotelPaxes')).split(',');
          for (let i = 1 ; i < childrenArr.length; i++ ) {


            this.childrenList.push({childList: childrenArr[i]} );
            this.childrenCount++;

            this.decreaseChildrenDisabled = false;
            this.increasCheck();
          }
          this.adultCount = Number(childrenArr[0]);
          this.paxCount = childrenArr.length - 1 + Number(childrenArr[0]) ;
        }
      }
//-------------------------------------------

      if (this.adultCount === 1) {
        this.decreaseAdultDisabled = true;


      }
      this.CheckinRangeFormated = this.datePipe.transform(this.CheckinRange, 'EE, MMM dd');
      this.CheckoutRangeFormated = this.datePipe.transform(this.CheckoutRange, 'EE, MMM dd');
    });
    this.oldcaption = this.restApi.caption;
    this.staynights = moment(this.CheckoutRange).diff(moment(this.CheckinRange), 'days');

    if(this.Auth.isOwner() || this.Auth.isAdmin()) {
      this.users.push(this.guest);
    }
    this.usersAutoComplete();
  }

  getGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
          if (position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            this.searchQuery = this.lat + ',' + this.lng;
            this.caption = 'Your Location';
            this.captionbyClick = 'Your Location';
            this.Query = 'Your Location';
            this.activePopUp = false;
            this.viewMode = 'GEO';
            this.restApi.enableBodyScroll();
            this.searchValid = false;
            this.showSearchPopUpautocomplete = false;
          }
        })
        ;
    }


  }

  lightpicks() {

    const pickerMobile = new Lightpick({
      minDays: 2,
      tooltipNights: true,
      disabledDatesInRange: false,
      repick: true,
      minDate: moment(),
      maxDate: moment().add(365, 'days'),
      firstDay: 7,
      startDate: this.CheckinRange,
      endDate: this.CheckoutRange,
      //    selectForward: true,
      field: document.getElementById('calendarx'),
      inline: true,
      singleDate: false,
      maxDays: 31,
      numberOfColumns: 1,
      numberOfMonths: 12,
      locale: {

        tooltip: {
          one: $localize`:@@night:night`,
          other: $localize`:@@nights:nights`
        },
      },

      dropdowns: {
        years: false,


        months: false,

      },
      lang: this.restApi.corporateLocale,
      onSelect: () => {


        this.oldChekout = this.CheckoutRange;


        this.CheckinRangeFormated = this.datePipe.transform(pickerMobile.getStartDate(), 'EE, MMM dd');
        this.CheckoutRangeFormated = this.datePipe.transform(pickerMobile.getEndDate(), 'EE, MMM dd');


        this.CheckoutRange = moment(pickerMobile.getEndDate().format('YYYY-MM-DD'))
        this.CheckinRange = moment(pickerMobile.getStartDate().format('YYYY-MM-DD'))


        this.staynights = moment(pickerMobile.getEndDate().format('YYYY-MM-DD')).diff(moment(pickerMobile.getStartDate().format('YYYY-MM-DD')), "days")

        //  $('.totalstaynights ').text(moment(pickerMobile.getEndDate().format('YYYY-MM-DD')).diff(moment(pickerMobile.getStartDate().format('YYYY-MM-DD')), "days"));


        if (moment(pickerMobile.getEndDate().format('YYYY-MM-DD')).diff(moment(pickerMobile.getStartDate().format('YYYY-MM-DD')), "days") > 0) {


          //     $(".staynights").text(moment(pickerMobile.getEndDate().format('YYYY-MM-DD')).diff(moment(pickerMobile.getStartDate().format('YYYY-MM-DD')), "days"))


        }


        if (moment(pickerMobile.getEndDate().format('YYYY-MM-DD')).diff(moment(pickerMobile.getStartDate().format('YYYY-MM-DD')), "days") > 31) {


        }


        if (moment(pickerMobile.getEndDate().format('YYYY-MM-DD')).diff(moment(pickerMobile.getStartDate().format('YYYY-MM-DD')), "days") > 1) {
          //  $(".night-cont").text("Nights")


        } else {
          //      $(".night-cont").text("Night")


        }


        if ((moment(pickerMobile.getEndDate().format('YYYY-MM-DD')).diff(moment(pickerMobile.getStartDate().format('YYYY-MM-DD')), "days") > 31 || moment(pickerMobile.getEndDate().format('YYYY-MM-DD')).diff(moment(pickerMobile.getStartDate().format('YYYY-MM-DD')), "days") < -31)) {

          pickerMobile.setDateRange(moment(pickerMobile.getStartDate()), moment(pickerMobile.getStartDate()).add(1, 'day'));

          this.endDateMobileBefore = moment(pickerMobile.getEndDate().add(1, 'day')._i);

          this.endDateMobileBeforeBoll = true;


        }


      },


    });


    this.checkout = moment(pickerMobile.getEndDate().format('YYYY-MM-DD'));
    this.checkin = moment(pickerMobile.getStartDate().format('YYYY-MM-DD'));
    this.staynights = moment(pickerMobile.getEndDate()
      .format('YYYY-MM-DD')).diff(moment(pickerMobile.getStartDate().format('YYYY-MM-DD')), 'days');

    this.CheckinRangeFormated = this.datePipe.transform(pickerMobile.getStartDate(), 'EE, MMM dd');
    this.CheckoutRangeFormated = this.datePipe.transform(pickerMobile.getEndDate(), 'EE, MMM dd');
//   this.searchForm.value.Children = {};


  }



  onSubmit() {
    this.submitted = true;
    if(!this.selectedUser){
      return;
    }
    this.restApi.alertsOverRoute = [];
    this.Childrenpax = '';
    for (let i = 0; i < this.childrenList.length; i++) {
      if (this.childrenList.length > 0 && this.childrenList[i].childList === '') {
        this.validChild = true;
        return false;
      } else {
        this.Childrenpax += ',' + this.childrenList[i].childList;
        this.validChild = false;
      }
    }
    this.searchValid = false;

    this.oldcaption = this.restApi.caption;
    this.oldsearchQuery = this.restApi.SearchQuery;
    if (this.caption === this.oldcaption || this.caption === '') {
      if (this.restApi.caption) {
        this.caption = this.restApi.caption;

        this.Query = this.restApi.caption;

      }
      if (this.restApi.SearchQuery) {


      } else {
        this.searchQuery = this.restApi.searchCode + ';' + this.restApi.searchType;

      }

    }

    if (this.searchQuery === this.oldsearchQuery || this.searchQuery === undefined) {

      this.searchQuery = this.restApi.SearchQuery;

    }

    this.submitting = true;
    if (this.availability && this.viewMode === undefined) {

      this.viewMode = 'LIST';


      // this.router.routeReuseStrategy.shouldReuseRoute = function () {
      //   return false;
      // };


    }
    if (this.hotel && this.viewMode === undefined) {
      this.viewMode = 'DETAILS';


      //
      // this.router.routeReuseStrategy.shouldReuseRoute = function () {
      //   return false;
      // };


    }


    if (this.availability || this.hotel) {
      // (restApi.paxes '''''==''''' this.adultCount + this.Childrenpax)  // keep it as is  becouse i dont want to check the type----bassel
      if ((this.restApi.paxes == this.adultCount + this.Childrenpax) && this.oldcaption === this.caption && (this.queryParams.checkIn === moment(this.CheckinRange).format('YYYY-MM-DD') && this.queryParams.checkOut === moment(this.CheckoutRange).format('YYYY-MM-DD'))) {
        if(this.travelerId === this.selectedUser.id){
          this.Childrenpax = '';
          this.submitting = false;
          return false;
        }


      }
    }


    if (!this.availability && !this.hotel && this.caption === undefined) {
      this.submitting = false;

      return

    }

    this.oldcaption = this.caption;

    this.checkout = moment(this.CheckoutRange).format('YYYY-MM-DD');
    this.checkin = moment(this.CheckinRange).format('YYYY-MM-DD');
    this.submitted = true;
    // stop here if form is invalid
    let searchType = '';


    if (this.caption === '') {
      this.Childrenpax = '';
      this.searchValid = true;

      this.submitting = false;
      return false;
    }


    // check if type list
    if (!this.restApi.isCompany()) {
      if (this.viewMode === 'LIST' || this.viewMode === 'GEO') {
        searchType = '/availability';
      } else {
        searchType = '/availability/hotel';
      }
    } else{
      if (this.viewMode === 'LIST' || this.viewMode === 'GEO') {
        searchType = '/availability';
      } else {
        searchType = '/availability/hotel';
      }
    }
    if (!this.restApi.historyArray) {
      const historyArr = [];
      historyArr.unshift({searchQuery: this.searchQuery, caption: this.caption, viewMode: this.viewMode});
      localStorage.setItem('history_storage', JSON.stringify(historyArr));
    } else {

      let historyArr = this.restApi.historyArray;
      historyArr = historyArr.filter(cls => {

        return !this.caption.includes(cls.caption)
      });
      if (this.caption !== 'Your Location') {

        if (historyArr.length === 5) {

          historyArr.splice(-1, 1);
          historyArr.unshift({searchQuery: this.searchQuery, caption: this.caption, viewMode: this.viewMode});
          localStorage.setItem('history_storage', JSON.stringify(historyArr));
        } else {

          historyArr.unshift({searchQuery: this.searchQuery, caption: this.caption, viewMode: this.viewMode});
          localStorage.setItem('history_storage', JSON.stringify(historyArr));
        }


      }


      // if(filteredClasses.length === 0) {


    }



    localStorage.setItem('hotelPaxes', this.adultCount + this.Childrenpax);

    if (this.restApi.urlWithoutParams  === '/pass/best-hotel-rates-newsletter/welcome') {
      this.googleAnalyticsService.eventEmitter('Newsletter-Subscription', 'Search', this.destination, 1);
    }
    if (this.restApi.urlWithoutParams  === '/pass/free-trial-subscription/welcome') {
      this.googleAnalyticsService.eventEmitter('Free-Trial-Subscription', 'Search', this.destination, 1);
    }
    if (this.viewMode === 'GEO') {

      this.router.navigate([searchType], {
        queryParams: {
          searchCode: this.searchQuery,
          searchType: 'GEO',
          checkIn: this.checkin,
          checkOut: this.checkout,
          paxes: this.adultCount + this.Childrenpax,
          travelerId: this.selectedUser.id
        },
      });
      localStorage.setItem('searchDates', JSON.stringify({checkIn: this.checkin, checkOut: this.checkout}));

    } else {
      this.router.navigate([searchType], {
        queryParams: {
          searchQuery: this.searchQuery,
          checkIn: this.checkin,
          checkOut: this.checkout,
          paxes: this.adultCount + this.Childrenpax,
          travelerId: this.selectedUser.id
        },
      });
      localStorage.setItem('searchDates', JSON.stringify({checkIn: this.checkin, checkOut: this.checkout}));


    }

    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };

    this.Childrenpax = '';


  }


  setSearchText(entity, dist) {

    this.caption = entity.caption;
    this.searchQuery = entity.searchQuery;
    // this.autocompletelist = [];
    this.activePopUp = false;
    this.destination = dist;

  //  this.viewMode = entity.viewMode; // all search results now will be list view mode even the history search results
    this.viewMode = 'LIST';
    this.captionbyClick = entity.caption;
    this.setSearchTextActive = true;
    this.captionMobile = entity.caption;
    this.restApi.enableBodyScroll();
    this.textfill = true;
    this.hideSearchPopUpSearch();
    this.showSearchPopUp('dates');

    this.autocompleteOn = true;

  }


  decreaseCheck() {
    this.paxCount = this.childrenCount + this.adultCount;

  }

  increasCheck() {

    this.paxCount = this.childrenCount + this.adultCount;

    if (this.childrenCount + this.adultCount === 6) {
      this.increasDisabled = true;
      return true;


    } else {

      this.increasDisabled = false;

    }

  }


  decreaseAdult() {

    this.adultCount--;
    this.paxCount--;
    this.increasDisabled = false;
    this.decreaseCheck();
    if (this.adultCount === 1) {
      this.decreaseAdultDisabled = true;
      return true;


    } else {

      this.decreaseDisabled = false;

    }
  }

  increaseAdult() {
    this.adultCount++;
    this.paxCount++;
    this.decreaseAdultDisabled = false;
    this.increasCheck();


  }


  decreaseChildren() {
  //  this.removeButtonClick();
    this.childrenCount--;
    this.paxCount--;
    this.increasDisabled = false;

    this.childrenList.pop();
    this.decreaseCheck();
    if (this.childrenCount === 0) {

      this.decreaseChildrenDisabled = true;
      return true;


    } else {

      this.decreaseDisabled = false;

    }

  }

  increaseChildren() {
    this.childrenCount++;
    this.paxCount++;
    this.decreaseChildrenDisabled = false;
    this.increasCheck();
    this.childrenList.push({childList: ''});
  //  this.addButtonClick();
  }








  showSearchPopUp(item) {
    // this.nameField.nativeElement.focus();

    this.activePopUp = true;

    if (item === 'dates') {

      if (this.dateActive === true) {

        this.dateActive = false;
        this.lightpicks();

      }
      setTimeout(() => {
        let startDate: HTMLElement = document.querySelector('.is-start-date') as HTMLElement;
        let mobile_dates: HTMLElement = document.querySelector('.mobile_dates') as HTMLElement;
        mobile_dates.scrollTo(0, startDate.offsetTop - 300);
      }, 200);
      this.showSearchPopPaxes = false;
      this.showSearchPopUpautocomplete = false;

      this.showSearchPopDates = true;

    } else if (item === 'paxes') {
      this.showSearchPopPaxes = true;
      this.showSearchPopDates = false;
      this.showSearchPopUpautocomplete = false;


    }
    this.searchValid = false;
    this.restApi.disableBodyScroll();

  }


  hideSearchPopUp() {


    this.validChild = false;
    this.activePopUp = false;

    this.showSearchPopUpActive = false;
    this.restApi.enableBodyScroll();


    if ((this.availability || this.hotel) && this.showSearchPopDates) {
      this.onSubmit()

    }

  }

  hideSearchPopUpSearch() {
    this.Query = this.beforeChange;

    this.showSearchPopUpautocomplete = false;
    this.autocompleteOn = true;

    this.validChild = false;
    this.activePopUp = false;


    this.restApi.enableBodyScroll();


  }


  // clearSearchText() {
  //   // this.searchForm.get('search').setValue('');
  //   this.restApi.caption = '';
  //   this.caption = '';
  //   this.captionMobile = '';
  //   // this.restApi.caption = '';
  //   // this.captionbyClick = 'Enter city or hotel name ...';
  //   //  this.textfill = false
  //
  //
  // }


  autoCompleteList() {

    if (this.Query && this.Query.length > 2) {
      this.restApi.autoComplete(this.Query, sessionStorage.getItem('session-token')).subscribe(
        suc => {

          this.data = suc;
          this.autocompletelist = this.data.data;

        }
      );
    }
  }


  clearHistory() {
    this.restApi.fetchSearchHistory('true').subscribe();
    if (localStorage.getItem('history_storage')) {

      localStorage.removeItem('history_storage');

    }

    this.restApi.historyArray = [];

  }

  autocompleteOnClick(event) {

    if (this.autocompleteOn) {
      this.beforeChange = this.Query;
      this.showSearchPopDates = false;
      this.showSearchPopPaxes = false;

      this.showSearchPopUpautocomplete = true;

      if ((this.Query && this.Query.length > 0) || (this.restApi.caption && this.restApi.caption.length > 0) || (this.captionMobile && this.captionMobile.length > 0))
        this.textfill = true;

      this.restApi.sessionToken = uuid();
      sessionStorage.setItem('session-token', this.restApi.sessionToken);

      if (this.autocompleteOn) {
        this.beforeChange = this.Query;
        this.showSearchPopDates = false;
        this.showSearchPopPaxes = false;

        this.showSearchPopUpautocomplete = true;

        if ((this.Query && this.Query.length > 0) || (this.restApi.caption && this.restApi.caption.length > 0) || (this.captionMobile && this.captionMobile.length > 0))
          this.textfill = true;

        this.restApi.sessionToken = uuid();
        sessionStorage.setItem('session-token', this.restApi.sessionToken);

        this.showSearchPopUpautocomplete = true;
        this.autocompleteOn = false;
      }

      this.restApi.disableBodyScroll();
      setTimeout(() => {
        this.restApi.scrollTop();

        event.target.select();
      }, 200);

    }

  }







  /********* guest changes *********/
  usersAutoComplete() {
    if(this.Auth.isOwner() || this.Auth.isAdmin()){
      this.restApi.getCompanyUsersList().subscribe(
          dataList => {
            this.dataUsers = dataList;
            if (this.dataUsers.errorCode === 0) {
              this.dataUsers = this.dataUsers.data;
              this.dataUsers.forEach((element) => {
                if(!element.inactive){
                  if (element.teamName) {
                    this.users.push(new User(element.description, element.firstName, element.lastName, element.id, element.email, element.inactive, element.profileImageBackgroundHexColor, element.profileImage, element.teamName));
                  } else {
                    this.users.push(new User(element.description, element.firstName, element.lastName, element.id, element.email, element.inactive, element.profileImageBackgroundHexColor, element.profileImage));
                  }
                }
              });
              this.filteredUsers = this.userControl.valueChanges.pipe(
                  startWith<string | User[]>(''),
                  map(value => typeof value === 'string' ? value : this.lastFilter),
                  map(filter => this.filter(filter))
              );

            } else {
              this.restApi.messages(this.data.message, 'error');
            }
          }, error => {
            this.restApi.messages(environment.messages.error, 'error');
          }
      );
    } else {
      if (this.Auth.userInfo.teamName) {
        this.users.push(new User(this.Auth.userInfo.name + ' ' + this.Auth.userInfo.lastName, this.Auth.userInfo.name, this.Auth.userInfo.lastName, this.Auth.userInfo.id, this.Auth.userInfo.email, this.Auth.userInfo.inactive, this.Auth.userInfo.profileImageBackgroundHexColor, this.Auth.userInfo.profileImage, this.Auth.userInfo.teamName));
      } else {
        this.users.push(new User(this.Auth.userInfo.name + ' ' + this.Auth.userInfo.lastName, this.Auth.userInfo.name, this.Auth.userInfo.lastName, this.Auth.userInfo.id, this.Auth.userInfo.email, this.Auth.userInfo.inactive, this.Auth.userInfo.profileImageBackgroundHexColor, this.Auth.userInfo.profileImage));
      }
      this.filteredUsers = this.userControl.valueChanges.pipe(
          startWith<string | User[]>(''),
          map(value => typeof value === 'string' ? value : this.lastFilter),
          map(filter => this.filter(filter))
      );
    }

  }
  filter(filter: string): User[] {
    this.lastFilter = filter;
    if (filter) {
      return this.users.filter(option => {
        return option.description.toLowerCase().includes(filter.toLowerCase());
      });
    } else {
      return this.users.slice();
    }
  }
  displayFn(user: User): string {
    return user && user.description ? user.description : '';
  }
  reset() {
    setTimeout(() => {
      if (typeof this.userControl.value !== 'object') {
        this.userInput.nativeElement.value = '';
        this.userControl.setValue(null);
        this.lastFilter = '';
        this.selectedUser = null;
        this.filteredUsers = this.userControl.valueChanges.pipe(
            startWith<string | User[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter))
        );
      }
    }, 400);
  }

  optionClicked(event: Event, user: User) {
    this.selectedUser = user;
    this.showUsersAutoCompletePopUp = false;
    this.restApi.enableBodyScroll();
  }


  showUsersPopUp() {
    this.showUsersAutoCompletePopUp = true;
    this.restApi.disableBodyScroll();
    if (this.selectedUser) {
      this.userControl.setValue(this.selectedUser);
    }
    setTimeout(() => {
      const input = document.getElementById('userInput') as HTMLInputElement;
      input.select();
      input.focus();
    }, 200);
    return;
  }




}
