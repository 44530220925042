import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from "../../../../../environments/environment";
import {AuthServiceMain} from "../../../../auth/auth.service";

@Component({
  selector: 'app-user-deactivate',
  templateUrl: './user-deactivate.component.html',
  styleUrls: ['./user-deactivate.component.scss']
})
export class UserDeactivateComponent implements OnInit {
  data;
  userDeactivateForm: FormGroup;
  submitted = false;
  submitting = false;
  reasonsArray: any = [
    {id: 'User is not part of the company anymore', text: $localize`:@@userDeactivate1:User is not part of the company anymore`},
    {id: 'The service is expensive and we are cutting the cost', text: $localize`:@@userDeactivate2:The service is expensive and we are cutting the cost`},
    {id: 'The user is not traveling anymore', text: $localize`:@@userDeactivate3:The user is not traveling anymore`},
    {id: 'The user is not traveling anytime soon', text: $localize`:@@userDeactivate4:The user is not traveling anytime soon`},
    {id: 'Switching to another product', text: $localize`:@@userDeactivate5:Switching to another product`},
    {id: 'Other', text: $localize`:@@declineArray4:Other`},
    ];
  urlArray;
  userId;
  previousURL;
  errorMsg;
  deactivateReason;
  constructor(private route: ActivatedRoute,
              public auth: AuthServiceMain,
              private formBuilder: FormBuilder,
              public dataService: DataService,
              public router: Router) { }
  ngOnInit(): void {
    this.urlArray = (this.router.url).split('/');
    if(this.dataService.desktopView){
      this.userId = this.urlArray[this.urlArray.length - 1];
    } else {
      this.userId = this.urlArray[this.urlArray.length - 2];
    }
    if(this.userId === undefined){
      this.userId = this.dataService.modalData;
    }
    const currentUrl = this.route.snapshot.url;  // Get the current URL segments
    this.previousURL = currentUrl.slice(0, currentUrl.length - 1).join('/');  // Remove the last segment

   // this.dataService.companyUserInfo = null;
    this.dataService.getCompanyUserInfo(this.userId);
    this.userDeactivateForm = this.formBuilder.group({
      reasons: ['', Validators.required],
      improveService: [''],
    }, {
    });
  }
  get ctrl() { return this.userDeactivateForm.controls; }
  deactivateUser() {
    this.submitted = true;
    this.submitting = true;
    if (this.userDeactivateForm.invalid) {
      this.submitting = false;
      return;
    }
    if (this.userDeactivateForm.controls.improveService.value) {
      this.deactivateReason = this.userDeactivateForm.controls.reasons.value + '. Comment: ' + this.userDeactivateForm.controls.improveService.value;
    } else  {
      this.deactivateReason = this.userDeactivateForm.controls.reasons.value;
    }
    this.dataService.deactivateUser(this.userId, this.deactivateReason).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.submitting = false;
          if (this.dataService.desktopView) {
            this.dataService.modalOf();
            const url = (this.router.url).split('/');
            if (url[url.length - 1] === 'members') {
              this.dataService.getAllCompanyUsers();
            } else {
              this.dataService.getCompanyUserInfo(this.userId);
            }
          } else {
            this.router.navigate([this.previousURL]);
          }
        } else {
          this.errorMsg = this.data.message;
          this.submitting = false;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
        this.submitting = false;
      }
    );
  }
  cancelDeactivate() {
    if (this.dataService.desktopView) {
      this.dataService.modalOf();
    } else {
      this.router.navigate([this.router.url.replace('/deactivate' , '')]);
    }
  }
}
